import {api, parseAPIError} from "../../services/api";
import Cookies from "universal-cookie";


export function removeAuthentication() {
  const cookies = new Cookies();
  cookies.remove("userAuth", {path: "/"});
  localStorage.removeItem("userPermissions");
}

export async function logout() {
  try {
    await api.get("/logout");
    removeAuthentication();
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getProfile() {
  try {
    const r = await api.get("/users/profile");
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function changePassword(data) {
  try {
    await api.post("/users/password-change", data);
  } catch (err) {
    throw parseAPIError(err);
  }
}
