import Layout from "../../components/layouts/layout";
import { useLang } from "../../hooks";
import { GeneralSettings } from "./components/GeneralSettings";
import { SocialSettings } from "./components/SocialSettings";
import { CommonSettings } from "./components/CommonSettings";
import { HeaderSettings } from "./components/HeaderSettings";
import { FooterSettingsOne } from "./components/FooterSettingsOne";
import { FooterSettingsTwo } from "./components/FooterSettingsTwo";
import { FooterSettingsThree } from "./components/FooterSettingsThree";
import ComponentGuard from "../../helpers/ComponentGuard";
import { Permission } from "../../configs/constants";
import { useState } from "react";
import { SettingsLangEdit } from "./components/SettingsLangEdit";
import { FooterSettingsFour } from "./components/FooterSettingsFour";
import { FooterSettingsFive } from "./components/FooterSettingsFive";
import {PopupSettings} from "./components/PopupSettings";
import {MainFooterSettings} from "./components/MainFooterSettings";

export function SettingsPage() {
  const { t } = useLang();
  const [langEditId, setLangEditId] = useState();

  const handleLanguageEdit = (id) => {
    setLangEditId(id);
  };

  return <Layout>
    {langEditId && <SettingsLangEdit gsOptionId={langEditId} onClose={() => setLangEditId(null)} />}
    <h1 className="pb-2 border-bottom">{t("settings")}</h1>
    <ComponentGuard needsPermission={[Permission.GeneralSettingsIndex]}>
      <GeneralSettings onLanguageEdit={handleLanguageEdit} />
    </ComponentGuard>
    <ComponentGuard needsPermission={[Permission.GeneralSettingsIndex]}>
      <PopupSettings onLanguageEdit={handleLanguageEdit} />
    </ComponentGuard>
    <ComponentGuard needsPermission={[Permission.SocialMediaIndex]}>
      <SocialSettings onLanguageEdit={handleLanguageEdit} />
    </ComponentGuard>
    <ComponentGuard needsPermission={[Permission.CommonSettingIndex]}>
      <CommonSettings onLanguageEdit={handleLanguageEdit} />
    </ComponentGuard>
    <HeaderSettings onLanguageEdit={handleLanguageEdit} />
    <section>
      <h2>{t("settings_footer")}</h2>
      <ComponentGuard needsPermission={[Permission.FooterOneSettingIndex]}>
        <MainFooterSettings />
      </ComponentGuard>
      <ComponentGuard needsPermission={[Permission.FooterOneSettingIndex]}>
        <FooterSettingsOne onLanguageEdit={handleLanguageEdit} />
      </ComponentGuard>
      <ComponentGuard needsPermission={[Permission.FooterTwoSettingIndex]}>
        <FooterSettingsTwo onLanguageEdit={handleLanguageEdit} />
      </ComponentGuard>
      <ComponentGuard needsPermission={[Permission.FooterThreeSettingIndex]}>
        <FooterSettingsThree onLanguageEdit={handleLanguageEdit} />
      </ComponentGuard>
      <ComponentGuard needsPermission={[Permission.FooterFourSettingIndex]}>
        <FooterSettingsFour onLanguageEdit={handleLanguageEdit} />
      </ComponentGuard>
      <ComponentGuard needsPermission={[Permission.FooterFiveSettingIndex]}>
        <FooterSettingsFive onLanguageEdit={handleLanguageEdit} />
      </ComponentGuard>
    </section>
  </Layout>;
}




