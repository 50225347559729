import {useCallback, useEffect, useState} from "react";
import {getAllLanguages} from "../services/language";

export function useLanguages() {
  const [langs, setLangs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      setLangs(await getAllLanguages());
      setIsLoading(false);
    } catch (err) {
      setError({
        title: "Failed to fetch languages",
        message: err.message
      });
    }
  }, []);


  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    langs,
    isLoading,
    error
  };
}