import {useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import useTaxonomy from "../../hooks/useTaxonomy";
import {createLanguage} from "../../services/language";
import {useLanguages} from "../../hooks/useLanguages";
import {TaxonomyLanguageSchema} from "./schema";
import {LanguageSelector} from "../../components/Language/LanguageSelector";
import {processTaxonomyInput} from "../../services/taxonomy";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {getUrlFromStoragePath} from "../../configs";
import {useLang} from "../../hooks";
import {BackButton} from "../../components/BackButton/BackButton";

export function TaxonomyLanguageCreatePage() {
  const {t} = useLang();
  const {resourceId} = useParams();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {taxonomy, isLoading: isTaxonomyLoading, error} = useTaxonomy(resourceId)
  const {langs, isLoading: isLangLoading, error: isLangError} = useLanguages();

  if (error || isLangError) {
    notifyError({
      title: "Something went wrong!",
      message: error.message
    })
    navigate("/taxonomies/manage")
  }

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(TaxonomyLanguageSchema()),
  });

  useEffect(() => {
    if (taxonomy) {
      setValue("name", taxonomy.name ?? "");
      setValue("description", taxonomy.description ?? "");
    }
  }, [taxonomy]);


  const create = async (data) => {
    try {
      setIsLoading(true);
      data.taxonomy_id = resourceId
      data.image = data.image ?? "same_as_parent"
      const l = await createLanguage("taxonomy", processTaxonomyInput(data))
      notifySuccess({
        title: "Taxonomy Langauge created successfully!",
        message: `Taxonomy "${l.name}" created!`,
      });
      navigate("/taxonomies/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Input validation failed",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: "Taxonomy creation failed!",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      {(isLoading || isLangLoading) && <CircleLoader/>}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={"/taxonomies/manage"}>{t("taxonomies_manage_taxonomies")}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/langs/taxonomy/${resourceId}`}>{taxonomy?.name} Languages</Link>
            </li>
            <Breadcrumb.Item active>Create language</Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("taxonomies_manage_taxonomies")}/>
        </div>
      </header>
      <section id="#taxonomy-create">

        <Form
          onSubmit={handleSubmit(create)}
          className="mt-4 d-flex  flex-column gap-3"
        >
          <h3>Taxonomy Language</h3>
          <LanguageSelector loading={isLangLoading} control={control} langs={langs} errors={errors} />
          <div className="form-row">
            <Form.Group className="flex-grow-1" controlId="name">
              <Form.Label className="mark-required">Name</Form.Label>
              <Form.Control
                {...register("name")}
                placeholder="Title of Term"
                type="text"
              />
              {errors.name?.message && (
                <Form.Text className="text-danger">
                  {errors.name.message}
                </Form.Text>
              )}
            </Form.Group>
          </div>
          <Form.Group controlId="description">
            <Form.Label className="mark-required">Description</Form.Label>
            <Form.Control
              {...register("description")}
              placeholder="Taxonomy description"
              as="textarea"
              rows={3}
            />
            {errors.description?.message && (
              <Form.Text className="text-danger">
                {errors.description.message}
              </Form.Text>
            )}
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button className="px-4" type="submit">
              Create
            </Button>
          </div>
        </Form>
      </section>
    </Layout>
  );
}
