import {api, parseAPIError} from "../../services/api";


export function processSubscriberInput(input) {
  // input.feature = input.feature.map(({title, status}) => ({title, status}))
  input.status=input.status? 'active':'inactive';
  return input;
}

export async function createSubscriber(input) {
  try {
    const r = await api.post("/subscribers/store",
    processSubscriberInput(input),
      {headers: {"content-type": "application/json"}});
    return r.data.data;

  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function emailSubscriber(input) {
  try {
    const r = await api.post("/subscribers/store",
    input,
      {headers: {"content-type": "application/json"}});
    return r.data.data;

  } catch (err) {
    throw parseAPIError(err);
  }
}



export async function getPaginatedSubscription(options) {
  
  const r = await api.get("/subscribers/list", {
    params: options
  });
  if (r.data.data) {
    
    return r.data.data;
  }
  return {
    data: [],
    total: 0
  };
  
}

export async function removeSubscriber(id) {
  const r = await api.delete(`/subscribers/delete/${id}`);
  return r.data.data;
}

