import {useRef} from "react";
import {Breadcrumb, Button} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {BsGlobe, BsListNested, BsPencilSquare} from "react-icons/bs";
import {CgPlayListAdd} from "react-icons/cg";
import {FaSearch} from "react-icons/fa";
import {MdDeleteOutline} from "react-icons/md";
import {Link, useNavigate} from "react-router-dom";
import {Input, InputGroup} from "rsuite";
import {FloatingButton} from "../../components";
import Layout from "../../components/layouts/layout";
import {Permission} from "../../configs/constants";
import ComponentGuard from "../../helpers/ComponentGuard";
import {useLang, useTaxonomyList} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import {DeleteButton, EditButton, LanguageButton, TermsButton} from "../../components/Buttons";

export function TaxonomyListPage() {
  const {t} = useLang();
  const {
    taxonomyList,
    pagination,
    changePagination,
    error,
    isLoading,
    filterDispatch,
    deleteTaxonomy
  } = useTaxonomyList();
  const {notifyError, notifySuccess} = useNotification();
  const searchBoxRef = useRef();
  const navigate = useNavigate();

  if (error) {
    notifyError(error);
    setTimeout(() => navigate("/"), 200);
  }

  const handleDelete = async (id) => {
    try {
      await deleteTaxonomy(id);
      notifySuccess({
        title: "Taxonomy removed successfully"
      });
    } catch (err) {
      notifyError({
        title: "Failed to remove taxonomy!",
        message: err.message
      });
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) =>
        (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
      width: "50px"
    },
    {
      name: t("taxonomies_manage_col_name"),
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: t("taxonomies_manage_col_slug"),
      selector: (m) => m.slug
    },
    {
      name: t("taxonomies_manage_col_action"),
      selector: (m) => {
        if (m.id > 10) {
          return (
            <div className="d-flex gap-2">
              <ComponentGuard needsPermission={[Permission.TaxonomyUpdate]}>
                <EditButton to={`/taxonomies/${m.id}/edit`} />
              </ComponentGuard>
              <ComponentGuard needsPermission={[Permission.TermsList]}>
                <TermsButton to={`/taxonomies/${m.id}/terms`}/>
              </ComponentGuard>
              <ComponentGuard needsPermission={[Permission.TaxonomyDelete]}>
                <DeleteButton handleDelete={() => handleDelete(m.id)}/>
              </ComponentGuard>
              <ComponentGuard needsPermission={[Permission.TaxonomyLanguagesList]}>
                <LanguageButton to={`/langs/taxonomy/${m.id}`}/>
              </ComponentGuard>
            </div>
          );
        } else {
          return (
            <div className="d-flex gap-2">
              <ComponentGuard needsPermission={[Permission.TermsList]}>
                <TermsButton to={`/taxonomies/${m.id}/terms`}/>
              </ComponentGuard>
              <ComponentGuard needsPermission={[Permission.TaxonomyLanguagesList]}>
                <LanguageButton to={`/langs/taxonomy/${m.id}`}/>
              </ComponentGuard>
            </div>
          );
        }
      }
    }
  ];

  return (
    <>
      <Layout>
        <header>
          <div className="d-flex justify-content-between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item active>
                  {t("taxonomies_manage_taxonomies")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </header>
        <section id="taxonomyList" className="mb-5 pb-5">
          <h2>{t("taxonomies_manage_taxonomies")}</h2>
          <DataTable
            columns={columns}
            data={taxonomyList.taxonomies}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationComponentOptions={{noRowsPerPage: true}}
            paginationTotalRows={taxonomyList.total}
            onChangePage={(page) => {
              changePagination({page});
            }}
            fixedHeader
            fixedHeaderScrollHeight="800px"
            highlightOnHover
            subHeader
            subHeaderComponent={
              <InputGroup className="mb-3" style={{width: "300px"}}>
                <Input ref={searchBoxRef} placeholder={t("taxonomies_manage_searchByName")} />
                <InputGroup.Button
                  onClick={() => filterDispatch({
                    type: "set",
                    payload: {
                      key: "term",
                      value: searchBoxRef.current.value
                    }
                  })}
                >
                  <FaSearch />
                </InputGroup.Button>
              </InputGroup>
            }
            subHeaderAlign="right"
          />
        </section>
      </Layout>
      <ComponentGuard needsPermission={[Permission.TaxonomyStore]}>
        <FloatingButton
          onClick={() => {
            navigate("/taxonomies/new");
          }}
          icon={<CgPlayListAdd />}
          name={t("taxonomies_manage_newTaxonomies")}
        />
      </ComponentGuard>
    </>
  );
}

export default TaxonomyListPage;
