import {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import { Checkbox, CheckboxGroup } from "rsuite";
import { Container } from "react-bootstrap";

import "../../pages/MediaManagement/media.css";
import SearchAndFilter from "../../pages/MediaManagement/SearchAndFilter";
import {useLang} from "../../hooks";
import CircleLoader from "../Loaders/CircleLoader";
import {useMediaList} from "../../pages/MediaManagement/Hooks/useMediaList";
import MediaCard from "../../pages/MediaManagement/components/MediaCard";

function MediaManager({
                        selectedMedia, setSelectedMedia, isSingleMode, checkValue, setCheckValue,
                        handleMediaModalClose
                      }) {
  const {t} = useLang();

  const { mediaList: media, isLoading, changePagination, pagination,
    filter, filterDispatch, error, deleteMedia } = useMediaList();

  //CheckBox Consts
  const [data, setData] = useState([]);
  const handleCheckAll = (value, checked) => {
    if (checked) {
      let newList = data?.filter((d) => !checkValue.includes(d));
      let updatedList = [...checkValue, ...newList];

      let checkedData = media?.data?.filter((m) => newList.includes(m.id));
      let newData = [...selectedMedia, ...checkedData];
      setSelectedMedia(newData);
      setCheckValue(updatedList);
    } else {
      let updatedList = checkValue?.filter((c) => !data?.includes(c));
      let newData = selectedMedia?.filter((m) => !data?.includes(m.id));
      setSelectedMedia(newData);
      setCheckValue(updatedList);
    }
  };

  const handleChange = (value) => {
    if(isSingleMode && value?.length <= 1) {
      setCheckValue(value);
      handleMediaModalClose();
    } else {
      setCheckValue(value);
    }
  };

  const handleSingleChange = (value, checked) => {
    if (checked) {
      let checkedData = media?.data?.filter((m) => m.id === value);
      let newData = [...selectedMedia, ...checkedData];
      setSelectedMedia(newData);
    } else {
      let newData = selectedMedia?.filter((m) => m.id !== value);
      setSelectedMedia(newData);
    }
  };

  const handlePageClick = (data) => {
    changePagination({...pagination, page: data.selected +1});
  };

  useEffect(()=>{
    if(media?.data.length > 0) {
      let tempAr = [];
      media?.data.map((md) => tempAr.push(md.id));
      setData(tempAr);
    }
  },[media])

  return (
    <>
      {isLoading && <CircleLoader/>}
      <SearchAndFilter filterDispatch={filterDispatch} changePagination={changePagination} />
      <Checkbox indeterminate={checkValue.length > 0 && checkValue.length < data.length}
                checked={data?.length === data?.filter((d) => checkValue?.includes(d)).length}
                onChange={handleCheckAll} disabled>
        {t("media_selectAll")}
      </Checkbox>
      <CheckboxGroup name="checkboxList" value={checkValue} onChange={handleChange}>
        <Container>
          <div className="d-flex flex-wrap gap-2 justify-content-center">
            {media?.data?.map((md) => (
              <MediaCard isSelect media={md} onSingleChange={handleSingleChange} isSelected={checkValue.includes(md.id)}/>
            ))}
          </div>
        </Container>
      </CheckboxGroup>
      <div>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={media?.last_page}
          marginPagesDisplayed={3}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center m-0"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
}

export default MediaManager;
