import {Link, useNavigate, useParams} from "react-router-dom";
import {Breadcrumb, Button, Card, Col, Form} from "react-bootstrap";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import useAlbum from "../../hooks/useAlbum";
import {useNotification} from "../../hooks/useNotification";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";
import ReactPaginate from "react-paginate";
import {BackButton} from "../../components/BackButton/BackButton";
import {useLang} from "../../hooks";
import MediaCard from "../MediaManagement/components/MediaCard";
import {useMediaSelector} from "../../components/MediaSelectorModal";

function AlbumEditPage() {
  const {
    checkValue,
    setCheckValue,
    selectedMedia,
    setSelectedMedia,
    allMediaId,
    setAllMediaId,
  } = useMediaSelector();
  const {t} = useLang();

  const {id} = useParams();
  const {
    album, albumMedia, editAlbum,
    fetchAlbumMedia, removeMediaFromAlbum, saveOnClick,
    isLoading, error
  }
    = useAlbum(id, setSelectedMedia, setCheckValue, setAllMediaId);
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();

  if (error) {
    notifyError(error);
    setTimeout(() => navigate("/albums"), 500);
  }

  const handleMediaAddClick = async () => {
    const newIds = checkValue.filter(id => !allMediaId.some(obj => obj.media_id === id));
    if (newIds.length === 0) return;
    try {
      await saveOnClick(id, newIds);
      fetchAlbumMedia();
      notifySuccess({
        title: "Media Added to Album!",
        message: ""
      });
    } catch (err) {
      notifyError({
        title: "Failed to add media!",
        message: err.message
      });
    }
  };

  const handlePageClick = (data) => {
    let currentPage = data.selected + 1;
    fetchAlbumMedia(currentPage);
  };

  const handleServerRemove = (id) => {
    let newCheckList = checkValue?.filter((c) => c !== id);
    let newSelectedMedia = selectedMedia?.filter((m) => m.id !== id);
    let newAllMediaId = allMediaId?.filter((m) => m.media_id !== id)
    setSelectedMedia(newSelectedMedia);
    setCheckValue(newCheckList);
    setAllMediaId(newAllMediaId);
    let gallery = allMediaId?.filter((m) => m.media_id === id)[0];
    try {
      removeMediaFromAlbum(gallery.id);
      notifySuccess({
        title: `Media Removed successfully`
      });
    } catch (err) {
      notifyError({
        title: "Failed to Removed media!",
        message: err.message
      });
    }
  };

  return (
    <Layout>
      {isLoading && <CircleLoader />}
      <section id="#taxonomy-create">
        <header>
          <div className="d-flex justify-content-between align-items-center">
            <Breadcrumb className="d-flex align-items-center">
              <li className="breadcrumb-item">
                <Link to={`/albums/manage`}> {t("album_manage_albums")}</Link>
              </li>
              <Breadcrumb.Item active>
                {t("album_edit_editAlbum")}
              </Breadcrumb.Item>
            </Breadcrumb>
            <BackButton path={-1} text={t("album_manage_albums")} />
          </div>
        </header>
        <h2>{t("album_edit_editAlbum")}</h2>
      </section>
      <div className="mt-4 container-fluid">
        <div className="row">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title font-weight-bold">{t('album_input_name')}</h5>
              <p className="card-text font-weight-normal">{album?.name}</p>
              <h5 className="mt-3 card-title font-weight-bold">{t('album_input_description')}</h5>
              <p className="card-text font-weight-normal">{album?.description}</p>
              <Link to={`/taxonomies/7/terms/` + id + `/edit`} className="mt-3 btn btn-sm btn-primary">
                {t('album_edit_update')}
              </Link>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="d-flex">
            {/* <Button className="px-4">
                            + Add Media
                        </Button> */}
            <MediaModal selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                        checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}
                        handleMediaAddClick={handleMediaAddClick}>
              <Button variant="outline-primary">
                + {t("album_input_addMedia")}
              </Button>
            </MediaModal>
            {/*<MediaModal selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}*/}
            {/*            checkValue={checkValue} setCheckValue={setCheckValue} />*/}
          </div>
        </div>
        <div className="row mt-4">
        </div>
        <div className="row mt-4">
          {selectedMedia?.length > 0 ? (
            <Card>
              <Card.Body style={{minHeight: "320px"}}>
                <div className="d-flex flex-wrap flex-row gap-2">
                  {selectedMedia?.map((md) =>
                    <MediaCard key={md.id} media={md} isDeleteServer handleServerRemove={handleServerRemove} />
                  )}
                </div>
              </Card.Body>
            </Card>
          ) : (
            <Card className="text-center">
              <Card.Body>
                <div className="d-flex flex-column align-items-center">
                  <i className="bi bi-images" style={{ fontSize: "3rem", color: "#ccc" }}></i>
                  <h5 className="mt-3">No Media Selected</h5>
                  <p className="text-muted">
                    Please select media items to display them here.
                  </p>
                </div>
              </Card.Body>
            </Card>
          )}
          <div className="col-md-6 mx-auto mt-4">
            {selectedMedia.length > 0 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={albumMedia?.last_page}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center m-0"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end mt-4 gap-2">
          <Link className="btn btn-primary px-3" to="/albums/manage">
            {t("album_edit_cancel")}
          </Link>
        </div>
      </div>
    </Layout>
  );
}

export default AlbumEditPage;
