import React, { useEffect, useState } from "react";
import {Badge, Breadcrumb, Button, Form} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CheckPicker } from "rsuite";
import Cookies from "universal-cookie";
import TextLineLoader from "../../components/Loaders/TextLineLoader";
import Layout from "../../components/layouts/layout";
import loginCheck from "../../helpers/loginCheck";
import http from "../../http";
import "./role.css";
import {useLang} from "../../hooks";
import {BackButton} from "../../components/BackButton/BackButton";
import {jsonToFormData} from "../../helpers";

const RoleRevokePermission = () => {
  const { register, handleSubmit } = useForm();
  const [data, setData] = useState("");
  const [roles, setRoles] = useState("");
  const [keyss, setKeys] = useState([]);
  const [values, setValues] = useState([]);
  const [getError, setError] = useState("");
  const [getErrorObj, setErrorObj] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [getPer, setPer] = useState([]);
  const [test, setTest] = useState([]);
  const [getLoader, setLoader] = useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const { id } = useParams();
  const {t} = useLang();

  useEffect(() => {
    if (!loginCheck()) {
      navigate("/login");
    }
  });

  useEffect(() => {
    fetchAllPermission();
  }, []);

  const fetchAllPermission = () => {
    http
      .get(`/role/get/${id}`, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuth").token,
        },
      })
      .then((res) => {
        setPer(res.data.data);
        setTest(hello(res.data.data));
      });
  };

  const hello = (val) => {
    let testArr = [];
    val.permissions.map((ele) => {
      // testArr.push(ele.id);
      testArr.push(ele.name);
    });
    return testArr;
  };

  useEffect(() => {
    if (getPer.permissions) getSearch();
  }, [getPer.permissions]);

  const getSearch = (val = "") => {
    let val1 = val.length > 0 ? val : "*";

    if (val.length > 0) {
      http
        .get(`/permission/search/${val}`, {
          headers: {
            Authorization: "Bearer " + cookies.get("userAuth").token,
          },
        })
        .then((response) => {
          setValues(response.data.data);
        });
    } else if (getPer.permissions) {
      http
        .get(`/role/get/${id}`, {
          headers: {
            Authorization: "Bearer " + cookies.get("userAuth").token,
          },
        })
        .then((response) => {
          let a = response.data.data.permissions.map((ele) => ({
            name: ele.name,
            id: ele.id,
          }));
          setValues({ data: a });
        });
    } else {
      http
        .get(`/permission/search/${val1}`, {
          headers: {
            Authorization: "Bearer " + cookies.get("userAuth").token,
          },
        })
        .then((response) => {
          setValues({ data: [] });
        });
    }
  };

  const onSubmit = (data, e) => {
    data = { ...data, ...permissions };
    // data.user_id = cookies.get('userAuth').id

    data.permissions =
      data.permissions === undefined ? data.test : data.permissions;


    setLoader(true);
    http
      .post(`/role/revoke-permission-from-role?id=${id}`, data, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuth").token,
          "Content-type": "Application/json",
        },
      })
      .then((res) => {
        localStorage.setItem("successEdit", true);
        // setLoader(false);
        setTimeout(() => {
          navigate("/role");
        }, 500);
      })
      .catch((err) => {
        setLoader(false);
        // console.log(Object.keys(err.response.data.data))
        let errs = err.response.data.data;
        let keys = Object.keys(errs);
        let errors = {};
        keys.map((key) => (errors[key] = errs[key][0]));
        setError(err.response.data);

        setErrorObj(errors);
      });
  };
  const onError = (errors, e) => console.log(errors, e);

  return (
    <>
      <Layout>
        {getLoader && (
            <div className="preloader">
              <div className="overlay__inner">
                <div className="overlay__content">
                  <span className="spin"></span>
                </div>
              </div>
            </div>
        )}
        <header>
          <div className="d-flex justify-content-between">
            <Breadcrumb>
              <li className="breadcrumb-item">
                <Link to={`/role`}> {t("acl_roleList_roleList")}</Link>
              </li>
              <Breadcrumb.Item active>
                {t("acl_revokePermission_revokePermission")}
              </Breadcrumb.Item>
            </Breadcrumb>
            <BackButton path={-1} text={t("acl_roleList_roleList")}/>
          </div>
        </header>
        {test == "" ? (
            <>
              <TextLineLoader/>
              <TextLineLoader/>
              <TextLineLoader/>
            </>
        ) : (
            <div className="form_design">
              <h2 style={{color: "black", textDecoration: "underline"}}>
                {t("acl_revokePermission_revokePermission")}
              </h2>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                  {getPer && (
                      <>
                        <Form.Label className="form_label">
                          {t("acl_revokePermission_permissionList")}
                        </Form.Label>
                        <p>{t("acl_revokePermission_roleName")} {getPer.name}</p>
                        <div
                            className="d-flex gap-1 p-2 flex-wrap"
                            style={{
                              border: "1px solid #e5e5ea",
                              borderRadius: "6px",
                            }}
                        >
                          {getPer?.permissions?.map((ele) => (
                              <Badge style={{fontSize: "1em"}}>{ele.name}</Badge>
                          ))}
                        </div>
                      </>
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.Label className="form_label">
                    {t("acl_revokePermission_deletePermission")}
                  </Form.Label>
                  <CheckPicker
                      name="permission"
                      defaultValue={test}
                      data={values.data?.map((item) => ({
                        label: item.name,
                        value: item.name,
                      }))}
                      style={{width: "100%"}}
                      // onSearch={(value) => getSearch(value)}
                      onChange={(value) =>
                          setPermissions({...permissions, permissions: value})
                      }
                  />

                  {getErrorObj.permissions ? (
                      <span className="text-danger">{getErrorObj.permissions}</span>
                  ) : null}
                </Form.Group>

                <div className="mt-4">
                  <Button className="action__btn" variant="primary" type="submit">
                    {t("acl_revokePermission_save")}
                  </Button>
                  <Link to="/role">
                    <Button className="action__btn" variant="info">
                      {t("acl_revokePermission_backToHome")}
                    </Button>
                  </Link>
                </div>
              </Form>
            </div>
        )}
      </Layout>
    </>
  );
};

export default RoleRevokePermission;

