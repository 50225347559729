import * as yup from "yup";
import {defaultImageValidation, languageSchema, SeoSchema,} from "../../configs/constants";

export const EventInputSchema = (t) => SeoSchema().shape({
  title: yup.string().max(200).required().label("Title").label(t("events_input_title")),
  event_category_id: yup
    .number()
    .min(1, "Please select a event category")
    .required()
    .label("Event Category ID").label(t("events_input_category")),
  description: yup.string().label("Description").required().label(t("events_input_description")),

  image: defaultImageValidation.label("Image").label(t("events_input_image")),
  status: yup.bool().default(false).label("Status").label(t("events_input_status")),
  dateTimeRange: yup.tuple([
    yup.date().required().label("Start Date").required(),
    yup.date().required().label("End Date").required()
  ]).required().label(t("events_input_startEndDateTime")),

  type: yup.string().oneOf(["Ongoing", "Upcoming", "Concluded"]).required().label("Event Type").label(t("events_input_eventType")),

  organised_by: yup.string().nullable().max(255).label("Organizer").label(t("events_input_organisedBy")),
  resource_person: yup.string().nullable().max(255).label("Resource Person").label(t("events_input_resourcePerson")),
  location: yup.string().label("Location").required().label(t("events_input_location"))
});

export const EventLanguageInputSchema = (t) => languageSchema.shape({
  title: yup.string().max(200).required().label(t("events_input_title")),
  description: yup.string().max(20000).label(t("events_input_description")),
  image: defaultImageValidation.label(t("events_input_image")),
  organised_by: yup.string().max(200).label(t("events_input_organisedBy")),
  resource_person: yup.string().max(200).label(t("events_input_resourcePerson")),
  location: yup.string().max(200).label(t("events_input_location")),
  start_date: yup.string().required().label(t("events_input_startEndDateTime")),
  end_date: yup.string().required().label(t("events_input_startEndDateTime")),
});