import React from "react";
import Layout from "../../components/layouts/layout";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {useComponentCategories, useLang} from "../../hooks";
import DataTable from "react-data-table-component";
import {usePaginatedList} from "../../hooks/usePaginatedList";
import {getPaginatedLayout, removeLayout} from "../../services/component";
import {useNotification} from "../../hooks/useNotification";
import {Badge} from "react-bootstrap";
import ComponentGuard from "../../helpers/ComponentGuard";
import {Permission} from "../../configs/constants";
import {MdDeleteOutline, MdEdit} from "react-icons/md";
import {Input, InputGroup, Modal, DatePicker, SelectPicker} from "rsuite";
import {useRef, useState} from "react";
import {FaSearch} from "react-icons/fa";
import {FloatingButton} from "../../components";
import {Link, useNavigate} from "react-router-dom";
import {CgPlayListAdd} from "react-icons/cg";
import {BsPencilSquare} from "react-icons/bs";
import moment from "moment/moment";
import {Controller, useForm} from "react-hook-form";

export function LayoutListPage() {
  const {t} = useLang();
  const searchBoxRef = useRef();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {
    list,
    changePagination,
    isLoading,
    pagination,
    filterDispatch,
    removeItem
  } = usePaginatedList(getPaginatedLayout, removeLayout);

  const {categories, isLoading: isCategoryLoading, error: catErr} = useComponentCategories();
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [currentLayout, setCurrentLayout] = useState(null);
  const {
    register,
    handleSubmit,
    control
  } = useForm();

  const handleDelete = async (id) => {
    try {
      await removeItem(id);
      notifySuccess({
        title: "Item removed successfully"
      });
    } catch (err) {
      notifyError({
        title: "Failed remove",
        message: err.message
      });
    }
  };
  const handleEdit = (layout) => {
    setCurrentLayout(layout);
    setEditModalVisible(true);
  };

  console.log(categories);
  
  const columns = [
    {
      name: "#",
      cell: (row, index) =>
        (pagination.page - 1) * pagination.limit + (index + 1),
      width: "50px"
    },
    {
      name: t("components_manage_col_name"),
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: t("component_layout_component_type"),
      selector: (row) => row.term.name
    },
    {
      name: t("status"),
      selector: (row) =>
        row.status === "active" ? (
          <Badge bg="success">Active</Badge>
        ) : (
          <Badge bg="warning">InActive</Badge>
        ),
      sortable: true
    },
    {
      name: t("action"),
      selector: (m) => {
        return (
          <div className="d-flex gap-2">
            <ComponentGuard needsPermission={[Permission.SubscriberEdit]}>
              <Link to={`/components/layout-edit/${m.id}`} className="btn btn-secondary">
                <BsPencilSquare />
              </Link>
            </ComponentGuard>
            <ComponentGuard needsPermission={[Permission.SubscriberDelete]}>
              <Button variant="danger" onClick={() => handleDelete(m.id)}>
                <MdDeleteOutline />
              </Button>
            </ComponentGuard>
          </div>
        );
      }
    }
  ];
  return (
    <>
    <Layout>
      <header>
        <div className="d-flex justify-content-between">
          <div>
            <Breadcrumb>
              <Breadcrumb.Item active>{"Layout List"}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </header>
      <section id="list" className="mb-5 pb-5">
        <h2>{t("component_layout_list")}</h2>
        <div className="bg-light rounded p-4 mt-4">
          <Form
            onSubmit={handleSubmit((data) => {
              filterDispatch({
                type: "reInitialize",
                payload: data
              });
            })}
          >
            <div className="d-flex flex-column align-items-stretch align-items-md-end flex-md-row gap-2">
              <Form.Group as={Col} lg={4} controlId="component_type">
                <Form.Label>{t("posts_manage_category")}</Form.Label>
                <Form.Control {...register("component_type")} as="select" custom>
                  <option value="">None</option>
                  {categories.map((term) => (
                    <option value={term.value}>{term.label}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group
                as={Col} lg={4}
                controlId="search"
                onSubmit={handleSubmit((data) => {
                })}
              >
                <Form.Label>{t("posts_manage_search")}</Form.Label>
                <Form.Control
                  {...register("term")}
                  placeholder={t("post_manage_search_plhd")}
                  custom
                ></Form.Control>
              </Form.Group>
              <div className="ml-auto">
                <Button className="px-5" type="submit">
                  {t("posts_manage_go")}
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <DataTable
          columns={columns}
          data={list.data}
          progressPending={isLoading}
          pagination
          paginationServer
          paginationComponentOptions={{noRowsPerPage: true}}
          paginationTotalRows={list.total}
          onChangePage={(page) => {
            changePagination({page});
          }}
          fixedHeader
          fixedHeaderScrollHeight="800px"
          highlightOnHover
          subHeader
          subHeaderAlign="right"
        />
      </section>
    </Layout>
      <ComponentGuard needsPermission={[Permission.FormBuilderStore]}>
        <FloatingButton
          onClick={() => {
            navigate("/components/layout-create");
          }}
          icon={<CgPlayListAdd />}
          name={t("component_layout_add")}
        />
      </ComponentGuard>
    </>
  );
}
