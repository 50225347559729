import {useCallback, useEffect, useState} from "react";
import {getPost, removePostMedia, updatePost} from "../services/post";

export function usePost(id) {
  const [post, setPost] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const fetchPost = useCallback(async () => {
    try {
      setIsLoading(true);
      setPost(await getPost(id));
    } catch (err) {
      setError({
        title: "Failed to fetch post!",
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  async function editPost(data) {
    try {
      setIsLoading(true);
      setPost(await updatePost(id, data));
    } finally {
      setIsLoading(false);
    }
  }

  async function handleMediaRemove(data) {
    try {
      setIsLoading(true);
      let removeIds = {
        media_id: [data]
      };
      await removePostMedia(id, removeIds)
    } catch (e) {
      setError({
        title: "Failed to Remove Media!",
        message: e.message,
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchPost();
  }, [fetchPost]);

  return {post, isLoading, editPost, handleMediaRemove, error};
}
