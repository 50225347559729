import {api, parseAPIError} from "../../services/api";

export function processBlogCommentInput(input) {
  return input;
}

export async function createBlogComment(input) {
  try {
    const r = await api.post("/comment/store", processBlogCommentInput(input));
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getPaginatedBlogComment(options) {
  const r = await api.get("/comment/list", {
    params: options
  });
  if (r.data.data) {
    return r.data.data;
  }
  return {
    data: [],
    total: 0
  };
}

export async function removeBlogComment(id) {
  const r = await api.delete(`/comment/delete/${id}`);
  return r.data.data;
}

export async function updateBlogComment(id, input) {
  try {
    const r = await api.post(
      `/comment/status-update/${id}`,
      processBlogCommentInput(input),
      {headers: {"content-type": "application/json"}}
    );
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}
