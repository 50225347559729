import React, { useState, useRef } from 'react';
import { Breadcrumb, Button, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { SelectPicker, CheckTreePicker, InputNumber } from 'rsuite';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import { useLang, useProductList } from "../../hooks";
import { useNotification } from "../../hooks/useNotification";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import { BackButton } from "../../components/BackButton/BackButton";
import { createProduct, createProductInventory } from "../../services/product";
import { ProductInventoryInputSchema } from "./schema";
import { useProductAttributes } from "../../hooks";
import { useMediaSelector } from "../../components/MediaSelectorModal";
import { InputValidationError } from "../../libs/error";
import "./Product.css";

export function ProductInventoryCreatePage() {
    const { t } = useLang();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { notifyError, notifySuccess } = useNotification();
    const { attributes, isLoading: issAttrLoading, error: errAtt } = useProductAttributes();
    const searchBoxRef = useRef();

    const {
        productList, pagination,
        changePagination, deleteProduct, searchByTerm,
        error, filter, filterDispatch
    } = useProductList();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setError
    } = useForm({
        resolver: yupResolver(ProductInventoryInputSchema(t)),
    });

    const create = async (data) => {
        console.log(data)
        try {
            setIsLoading(true);
            const product = await createProductInventory(data);
            notifySuccess({
                title: "Product Inventory created successfully",
                message: `Product Inventory Created!`,
            })
            navigate("/products/inventory");
        } catch (err) {
            if (err instanceof InputValidationError) {
                err.setRHFError(setError);
                notifyError({
                    title: "Invalid input data!",
                    message: err.message,
                });
                return;
            }
            notifyError({
                title: "Something went wrong",
                message: err.message,
            });
        } finally {
            setIsLoading(false);
        }
    }

    const renderMenu = (menu) => {
        if (!productList.products || productList.products.length === 0) {
            return (
                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                    <SpinnerIcon spin /> {t('loading')}
                </p>
            );
        }
        return menu;
    };


    return (
        <Layout>
            {isLoading && <CircleLoader />}
            <header>
                <div className="d-flex justify-content-between align-items-center">
                    <Breadcrumb className="d-flex align-items-center">
                        <li className="breadcrumb-item">
                            <Link to={`/products/inventory`}>{t("productsInventory_manage_products")}</Link>
                        </li>
                        <Breadcrumb.Item active>
                            {t("productsInventory_create_createNewProduct")}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <BackButton path={-1} text={t("productsInventory_manage_products")} />
                </div>
            </header>
            <section id="#taxonomy-create">
                <h2>{t("productsInventory_create_createNewProduct")}</h2>
            </section>
            <Form onSubmit={handleSubmit(create)} className="container-fluid mt-4">
                <div className="row mt-2">
                    <div className="">
                        <Form.Group className="flex-grow-1" controlId="productId">
                            <Form.Label className="mark-required">{t("productsInventory_create_category")}</Form.Label>
                            <Controller
                                control={control}
                                name="product_id"
                                render={({ field }) => (
                                    <SelectPicker
                                        {...field}
                                        data={productList?.products?.map((item) => ({ label: item.name, value: Number(item.id) }))}
                                        onSearch={(value) => filterDispatch({
                                            type: "set",
                                            payload: { key: "term", value: value }
                                        })}
                                        style={{ width: '100%' }}
                                        renderMenu={renderMenu}
                                        placeholder={t("productsInventory_create_category_plhd")}
                                    />
                                )}
                            />
                            {errors.product_id?.message && (
                                <Form.Text className="text-danger">{errors.product_id.message}</Form.Text>
                            )}
                        </Form.Group>
                    </div>
                    <div className="">
                        <Form.Group className="flex-grow-1" controlId="attribute_id">
                            <Form.Label className="mark-required">{t("products_create_attributes")}</Form.Label>
                            <Controller control={control} name="attribute_id"
                                render={({ field }) =>
                                (<SelectPicker loading={issAttrLoading}
                                    {...field}
                                    defaultExpandAll
                                    data={attributes}
                                    block
                                    searchable={false}
                                    placeholder={t("productsInventory_create_attributes")} cascade={false} />)} />
                            {errors.attribute_id?.message && (
                                <Form.Text className="text-danger">{errors.attribute_id.message}</Form.Text>)}
                        </Form.Group>
                    </div>
                    <div className="">
                        <Form.Group className="flex-grow-1" controlId="quantity">
                            <Form.Label className="mark-required">{t("productsInventory_create_quantity")}</Form.Label>
                            <Form.Control {...register("quantity")} placeholder={t("products_create_name_plhd")} type="text" />
                            {errors.quantity?.message && (
                                <Form.Text className="text-danger">{errors.quantity.message}</Form.Text>)}
                        </Form.Group>
                    </div>

                </div>
                <div className="row mt-2">
                    <div className="d-flex justify-content-end">
                        <Button variant="primary" type="submit">{t("productsInventory_create_save")}</Button>
                    </div>
                </div>
            </Form>
        </Layout>
    );
}
