import {Breadcrumb, Container, Form} from "react-bootstrap";
import Layout from "../../components/layouts/layout";
import {useMemo, useState} from "react";
import {useLang} from "../../hooks";
import CircleLoader from "../../components/Loaders/CircleLoader";
import {Link, useParams} from "react-router-dom";
import {BackButton} from "../../components/BackButton/BackButton";
import {useNotification} from "../../hooks/useNotification";
import {MenuItemEditForm} from "./components/MenuItemEditForm";
import {convertMenuListToTreeArray} from "./menu.service";
import {MenuItemCreateForm} from "./components";
import {MenuTreeView} from "./components/MenuTreeView";
import {useMenuContent} from "./hooks/useMenuContent";

function getParentList(mTreeArr, excludeId) {
  const eid = String(excludeId);
  return mTreeArr.filter(m => String(m.id) !== eid).map(m => {
    let children = [];
    if (m.children?.length > 0) {
      children = getParentList(m.children, excludeId);
    }
    const item = {
      label: m.name,
      value: String(m.id)
    };

    if (children.length > 0) {
      item.children = children;
    }

    return item;
  });
}

export function MenuContentPage() {
  const {id} = useParams();
  const {data: menuList, error, addItem, updateItem, deleteItem, isLoading} = useMenuContent(id);
  const [searchTerm, setSearchTerm] = useState();
  const [editItem, setEditItem] = useState(undefined);
  const {notifyError, notifySuccess} = useNotification();
  const mtreeArray = useMemo(() => convertMenuListToTreeArray(menuList), [menuList]);
  const parentList = useMemo(() => mtreeArray?.length > 0 ? getParentList(mtreeArray, editItem?.id) : [], [mtreeArray, editItem]);
  console.log(JSON.stringify(parentList, null, 2));

  const {t} = useLang();

  if (error) {
    notifyError(error, "/error");
  }

  const handleMenuItemDelete = async (id) => {
    try {
      setEditItem(null);
      await deleteItem(id);
      notifySuccess({
        title: "Menu item removed"
      });
    } catch (err) {
      notifyError({
        title: "Failed to remove item",
        message: err.message
      });
    }
  };

  const handleInitiateMenuEdit = (menuId) => {
    const id = Number(menuId);
    const menu = menuList.find(m => m.id === id);
    setEditItem(menu);
  };

  const handleMenuEditClose = () => {
    setEditItem(null);
  };

  const handleMenuItemUpdate = async (menuItemId, data) => {
    await updateItem(menuItemId, {...data, menu_type_id: id});
  };

  const handleMenuItemCreate = async (data) => {
    await addItem({...data, menu_type_id: id});
  };

  return (
    <>
      {editItem && <MenuItemEditForm
        menu={editItem}
        parentList={parentList}
        onSubmit={handleMenuItemUpdate}
        onClose={handleMenuEditClose}
      />}
      <Layout>
        {isLoading && <CircleLoader />}
        <Container as="section" className="d-block" id="menu-editor">
          <header>
            <div className="d-flex justify-content-between align-items-center">
              <Breadcrumb className="d-flex align-items-center">
                <li className="breadcrumb-item">
                  <Link to={`/menus/manage`}>{t("menu_manageMenu")}</Link>
                </li>
                <Breadcrumb.Item active>
                  {t("menu_sliderMenu_sliderMenu")}
                </Breadcrumb.Item>
              </Breadcrumb>
              <BackButton path={-1} text={t("menu_manageMenu")} />
            </div>
          </header>
          <h2>{t("menu_sliderMenu_sliderMenu")}</h2>
          <MenuItemCreateForm
            onSubmit={handleMenuItemCreate}
            parentList={parentList}
          />
          <div
            style={{maxWidth: "800px", minWidth: "700px"}}
            className="p-3 bg-light mt-3 d-flex flex-sm-column gap-4"
          >
            <div>
              <Form.Control
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={t("menu_sliderMenu_searchMenu")}
              ></Form.Control>
            </div>
            <MenuTreeView
              data={mtreeArray}
              height={menuList.length * 40}
              searchTerm={searchTerm}
              onItemEdit={handleInitiateMenuEdit}
              onItemDelete={handleMenuItemDelete}
            />
          </div>
        </Container>
      </Layout>
    </>
  );
}