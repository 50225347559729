import {useCallback, useEffect, useReducer, useState} from "react";
import filterReducers from "../reducers/filterReducer";
import {usePagination} from "./usePagination";
import {getPaginatedContent, removeContent} from "../services/content";

export function useContentList() {
  const [contentList, setContentList] = useState({contents: [], total: 0});
  const [filter, filterDispatch] = useReducer(filterReducers, {});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const {pagination, changePagination} = usePagination({
    page: 1,
    limit: 10,
  });

  const fetchContents = useCallback(async () => {
    try {
      setIsLoading(true);
      const contents = await getPaginatedContent({
        page: pagination.page,
        ...filter,
      });
      setContentList({
        contents: contents.data,
        total: contents.total,
      });
      setIsLoading(false);
    } catch (err) {
      setError({
        title: "Something went wrong",
        message: err.message,
      });
    }
  }, [pagination, filter]);

  const deleteContent = async (id) => {
    await removeContent(id);
    await fetchContents();
  };

  useEffect(() => {
    fetchContents();
  }, [fetchContents]);

  return {
    contentList,
    filterDispatch,
    pagination,
    changePagination,
    deleteContent,
    isLoading,
    error,
    filter,
  };
}
