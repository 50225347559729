import React from 'react'
import Layout from '../../components/layouts/layout'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FaEdit, FaRegFileImage, FaTrash } from 'react-icons/fa'
import useAlbumMedia from '../../hooks/useAlbumMedia'
import { useNotification } from '../../hooks/useNotification'
import CircleLoader from '../../components/Loaders/CircleLoader'
import { ImageEdit } from '../../components'
import { getUrlFromStoragePath } from '../../configs'
import { Button, Image } from 'react-bootstrap'
import Card from 'react-bootstrap/Card';
import NoImage from '../../images/No-Image-Placeholder.png'

function AlbumMediaMangement() {
    const { id } = useParams()
    const { album, albumMedia, isLoading, error } = useAlbumMedia(id)
    const navigate = useNavigate()
    const { notifyError, notifySuccess } = useNotification()

    if (error) {
        notifyError(error)
        setTimeout(() => navigate('/albums'), 500)
    }

    console.log(albumMedia)

    return (
        <>
            <Layout>
                {isLoading && <CircleLoader />}
                <h1>Album Media Edit</h1>
                <hr />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2">
                            <div>
                                {album?.image ? (
                                    <Image src={getUrlFromStoragePath(album?.image)} height={`150px`} width={`150px`} thumbnail />
                                ) : (
                                    <Image src={NoImage} height={`150px`} width={`150px`} thumbnail fluid />
                                )}
                            </div>
                        </div>
                        <div className="col-lg-10">
                            <h3>{album?.name}</h3>
                            <p>{album?.description}</p>
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        {
                            albumMedia?.total > 0 ? (
                                albumMedia?.data?.map((media)=>
                                    (
                                        <Card className='position-relative col-md-3'>
                                            <div className='' style={{ height:'200px', width:'200px'}}>
                                                <Image src={getUrlFromStoragePath(media.album_pic.original_url)} width={'100%'}/>
                                            </div>
                                            <div className='d-flex gap-1' style={{ position: 'absolute', top: 5, right: 5}}>
                                                <Button size='sm'><FaEdit/></Button>
                                                <Button variant='danger' size='sm'><FaTrash/></Button>
                                            </div>
                                        </Card>
                                    )
                                )
                            ) 
                            : (<h1>Nothing to Show</h1>)
                        }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default AlbumMediaMangement
