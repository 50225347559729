import { useCallback, useEffect, useReducer, useState } from 'react'
import { usePagination } from "./usePagination";
import { getPaginatedOrder, removeOrder, updateOrder } from "../services/order";
import filterReducers from "../reducers/filterReducer";


export function useOrderList() {
    const [orderList, setOrderList] = useState({
        orders: [],
        total: 0,
    })
    const { pagination, changePagination } = usePagination({
        page: 1,
        limit: 10,
    })
    const [searchTerm, setSearchTerm] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [filter, filterDispatch] = useReducer(filterReducers, {});
    const [error, setError] = useState(false)
    const searchByTerm = async (value) => {
        setSearchTerm(value)
    }
    const fetch = useCallback(async () => {
        try {
            setIsLoading(true)
            const orders = await getPaginatedOrder({
                page: pagination.page,
                ...filter,
            })
            setOrderList({
                orders: orders.data,
                total: orders.total,
            })
            setIsLoading(false)
        } catch (err) {
            setError({
                title: 'Something went wrong',
                message: err.message,
            })
        }
    }, [pagination, filter])

    const deleteOrder = async (id) => {
        await removeOrder(id)
        await fetch()
    }

    useEffect(() => {
        fetch()
    }, [fetch])

    return {
        orderList,
        pagination,
        changePagination,
        deleteOrder,
        searchByTerm,
        isLoading,
        error,
        filter,
        filterDispatch
    }
}