import React, {useEffect, useState} from "react";
import {FaKey, FaUserAlt} from "react-icons/fa";
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {Button, Form} from "react-bootstrap";
import http from "../../http";
import Cookies from "universal-cookie";
import update_logo from "../../images/image.png";
import showPwdImg from "../../images/show-password.svg";
import hidePwdImg from "../../images/hide-password.svg";
import styles from "./css/style.css";
import {getUrlFromStoragePath} from "../../configs";

const cookies = new Cookies();
const Login = () => {
  const {register, handleSubmit} = useForm();
  const [getLoginData, setLoginData] = useState("");
  const navigate = useNavigate();
  const [getError, setError] = useState({});
  const [getAuthError, setAuthError] = useState(false);
  const [getUnAuthorized, setUnAuthorized] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [adminLogo, setAdminLogo] = useState(null);

  const onSubmit = async (data, e) => {
    setError({});
    setAuthError(false);
    setUnAuthorized(false);

    data = {...getLoginData};
    http
      .post("/login", data)
      .then((res) => {
        if (res.status == 200) {
          res.data.data.isSuper =
            res.data.data.roles_names.includes("SuperAdmin");
          localStorage.setItem(
            "userPermissions",
            JSON.stringify(res.data.data.permissions)
          );
          delete res.data.data.permissions;
          delete res.data.data.roles_names;
          delete res.data.data.refresh_token;
          delete res.data.data.expires_at;
          delete res.data.data.user.roles;
          // return
          cookies.set("userAuth", JSON.stringify(res.data.data));
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message == "Validation Error.") {
          let errs = err.response.data.data;
          let keys = Object.keys(errs);
          let errors = {};
          keys.map((key) => (errors[key] = errs[key][0]));
          setError(errors);
        } else if (err?.response?.data?.message == "Logical Error.") {
          setAuthError(true);
        } else {
          setUnAuthorized(true);
        }
      });
  };
  // console.log(getAuthError)
  const onError = (errors, e) => console.log(errors, e);

  const handleChange = (e) => {
    setError("");
    setAuthError(false);
    setUnAuthorized(false);

    console.log(e);
    let loginData = {...getLoginData};
    loginData[e.target.name] = e.target.value;
    setLoginData(loginData);
  };

  useEffect(() => {
    http
      .get("/frontend/menus")
      .then((res) => {
        setAdminLogo(res.data.data[0].images.original_url)
      })
  },[])

  return (
    <>
      <div className="limiter" style={styles}>
        <div
          className="container-login100"
          style={{backgroundImage: "url(\"Group-1241.png\")"}}
        >
          <div className="wrap-login100 pt-4">
            {adminLogo ? (
              <img src={getUrlFromStoragePath(adminLogo)} className="mx-auto d-block img-fluid pt-4 pb-5" alt="Logo" />
            ) : (
              <span className="login100-form-title pt-4 pb-5">CMS Admin Panel</span>
            )}
            {getAuthError ? (
              <div className="pb-4">
              <p className="authError">The Credential is not Correct</p>
              </div>
            ) : null}
            {getUnAuthorized ? (
              <div className="pb-4">
                <p className="authError">
                  This user is not active, contact with admin
                </p>
              </div>
            ) : null}
            <Form
              className="login100-form validate-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Form.Group
                className={`wrap-input100 ${
                  getError.email ? "alert-validate" : null
                } validate-input mb-3`}
                data-validate="Email is required"
              >
                <Form.Control
                  id="input100"
                  type="text"
                  name="email"
                  placeholder="Enter the Email"
                  onChange={(e) => handleChange(e)}
                />
                <span className="focus-input100" />
                <span className="symbol-input100">
                  <FaUserAlt />
                </span>
              </Form.Group>

              <Form.Group
                className={`wrap-input100 ${
                  getError.password ? "alert-validate" : null
                } validate-input mb-3`}
                data-validate="Password is required"
              >
                <Form.Control
                  id="input100"
                  type={isRevealPwd ? "text" : "password"}
                  name="password"
                  placeholder=" Enter the Password"
                  onChange={(e) => handleChange(e)}
                />
                <img
                  className="pwd"
                  title={isRevealPwd ? "Hide password" : "Show password"}
                  src={isRevealPwd ? showPwdImg : hidePwdImg}
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                />
                <span className="focus-input100" />
                <span className="symbol-input100">
                  <FaKey />
                </span>
              </Form.Group>

              <div className="container-login100-form-btn pt-3">
                <Button
                  id="login100-form-btn"
                  variant="success"
                  type="submit"
                >
                  LOGIN
                </Button>
                <div className="text-center p-3">
                  <Link to="/forgot-password" style={{color: "black"}}>
                    Forgot Password?
                  </Link>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
