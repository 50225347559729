import React, { useState, useRef } from 'react';
import { Breadcrumb, Button, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { SelectPicker, CheckTreePicker, InputNumber } from 'rsuite';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import { useLang, useOrderList } from "../../hooks";
import { useNotification } from "../../hooks/useNotification";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import { BackButton } from "../../components/BackButton/BackButton";
import { createProduct, createOrder } from "../../services/order";

import { useMediaSelector } from "../../components/MediaSelectorModal";
import { InputValidationError } from "../../libs/error";
import "./Order.css";

export function OrderCreatePage() {
    const { t } = useLang();
    const [isLoading, setIsLoading] = useState();
    const navigate = useNavigate();
    const { notifyError, notifySuccess } = useNotification();
    const searchBoxRef = useRef();

    const {
        orderList,
        pagination,
        changePagination,
        deleteOrder,
        searchByTerm,
        error,
        filter,
        filterDispatch
    } = useOrderList();
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setError
    } = useForm({
        resolver: yupResolver((t)),
    });

    const create = async (data) => {
        console.log(data)
        try {
            setIsLoading(true);
            const product = await createOrder(data);
            notifySuccess({
                title: "Order created successfully",
                message: `Order Created!`,
            })
            navigate("/orders/inventory");
        } catch (err) {
            if (err instanceof InputValidationError) {
                err.setRHFError(setError);
                notifyError({
                    title: "Invalid input data!",
                    message: err.message,
                });
                return;
            }
            notifyError({
                title: "Something went wrong",
                message: err.message,
            });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Layout>
            {isLoading && <CircleLoader />}
            <header>
                <div className="d-flex justify-content-between align-items-center">
                    <Breadcrumb className="d-flex align-items-center">
                        <li className="breadcrumb-item">
                            <Link to={`/orders/manage`}>{t("orders_manage_orders")}</Link>
                        </li>
                        <Breadcrumb.Item active>
                            {t("orders_create_createNewOrder")}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <BackButton path={-1} text={t("orders_create_createNewOrder")} />
                </div>
            </header>
            <section id="#taxonomy-create">
                <h2>{t("orders_create_createNewOrder")}</h2>
            </section>
            <Form onSubmit={handleSubmit(create)} className="container-fluid mt-4">
                <div className="row mt-2">

                    <div className="col-md-6">
                        <Form.Group className="flex-grow-1 mt-2" controlId="client_id">
                            <Form.Label className="mark-required">{t("orders_create_client_id")}</Form.Label>
                            <Form.Control {...register("client_id")} placeholder={t("orders_create_client_id_plhd")}
                                type="number" />
                            {errors.client_id?.message && (
                                <Form.Text className="text-danger">{errors.client_id.message}</Form.Text>)}
                        </Form.Group>
                        <Form.Group className="flex-grow-1" controlId="order_date">
                            <Form.Label className="mark-required">{t("orders_create_order_date")}</Form.Label>
                            <Form.Control {...register("date")} placeholder={t("orders_create_order_date_plhd")} type="date" />
                            {errors.order_date?.message && (
                                <Form.Text className="text-danger">{errors.order_date.message}</Form.Text>)}
                        </Form.Group>
                        <Form.Group className="flex-grow-1 mt-2" controlId="order_id">
                            <Form.Label className="mark-required">{t("orders_create_order_id")}</Form.Label>
                            <Form.Control {...register("order_id")} placeholder={t("orders_create_order_id_plhd")}
                                type="number" />
                            {errors.order_id?.message && (
                                <Form.Text className="text-danger">{errors.order_id.message}</Form.Text>)}
                        </Form.Group>
                        <Form.Group className="flex-grow-1 mt-2" controlId="payment_type">
                            <Form.Label className="mark-required">{t("orders_create_payment_type")}</Form.Label>
                            <Form.Control {...register("payment_type")} placeholder={t("orders_create_payment_type_plhd")}
                                type="text" />
                            {errors.payment_type?.message && (
                                <Form.Text className="text-danger">{errors.payment_type.message}</Form.Text>)}
                        </Form.Group>
                        <Form.Group className="flex-grow-1 mt-2" controlId="address_id">
                            <Form.Label className="mark-required">{t("orders_create_address_id")}</Form.Label>
                            <Form.Control {...register("address_id")} placeholder={t("orders_create_address_id_plhd")}
                                type="number" />
                            {errors.address_id?.message && (
                                <Form.Text className="text-danger">{errors.address_id.message}</Form.Text>)}
                        </Form.Group>
                        <Form.Group className="flex-grow-1 mt-2" controlId="shipping_type">
                            <Form.Label className="mark-required">{t("orders_create_shipping_type")}</Form.Label>
                            <Form.Control {...register("shipping_type")} placeholder={t("orders_create_shipping_type_plhd")}
                                type="text" />
                            {errors.shipping_type?.message && (
                                <Form.Text className="text-danger">{errors.shipping_type.message}</Form.Text>)}
                        </Form.Group>

                        <Form.Group className="flex-grow-1 mt-2" controlId="notes">
                            <Form.Label>{t("orders_create_notes")}</Form.Label>
                            <Form.Control {...register("notes")} placeholder={t("orders_create_notes_plhd")}
                                as="textarea" rows={3} />
                            {errors.notes?.message && (
                                <Form.Text className="text-danger">{errors.notes.message}</Form.Text>)}
                        </Form.Group>

                    </div>
                </div>
                <div className="row mt-2">
                    <div className="d-flex justify-content-end">
                        <Button variant="primary" type="submit">{t("orders_create_save")}</Button>
                    </div>
                </div>
            </Form>
        </Layout>
    );
}
