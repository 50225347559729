import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import "./style.scss"
import {BsPlus} from "react-icons/bs";
import {Badge, Button} from "react-bootstrap";
import {MdDelete, MdEdit} from "react-icons/md";
import {FeatureCreateForm} from "./FeatureCreateForm";
import {useState} from "react";
import {useLang} from "../../../../hooks";
import {FeatureEditForm} from "./FeatureEditForm";

function createDragKey(id) {
  return String(id) + Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export function processFeature(contents) {
  if (contents?.length > 0) {
   return JSON.parse(contents).map(c => ({
      dKey: createDragKey(c.id),
      ...c,
    }))
  }
  return []
}

export function FeatureBuilder({value, onChange}) {
  const [insertIdx, setInsertIdx] = useState(0)
  const [editIdx, setEditIdx] = useState(null)
  const [showCreateForm, setShowCreateForm] = useState(false)
  const {t} = useLang();

  const  handleChange = (c) => {
    const changes = c.map((c, i) => ({
      ...c,
    }))
    onChange(changes)
  }
  const handleDragEnd = (result) => {
    if (!result.destination) {
      removeItem(result.draggableId)
      return
    }
    changeOrder(result.source.index, result.destination.index)
  }

  const handleCreate = (position, feature) => {
    feature = {
      dKey: createDragKey(feature.id),
      ...feature
    }

    if (value && value.length > 0)  {
      handleChange(value.toSpliced(position, 0, feature));
    } else {
      handleChange([feature] )
    }
  }
  const removeItem = (dKey) => {
    handleChange(value.filter((c) =>
        c.dKey!== dKey))
  }

  const handleEdit = (position, data) => {
    if (value?.[position]) {
      value[position] = data
      handleChange(value)
    }
  }

  const initEditItem =(dKey)  => {
    const idx = value.findIndex(v => v.dKey === dKey)
    console.log(value, dKey, idx)
    if (idx !== -1) {
      setEditIdx(idx)
    }
  }

  const changeOrder = (from, to) => {
      const temp = value[from]
      if (from > to) {
        for (let i = from; i > to; i--) {
          value[i] = value[i - 1]
        }
      } else if (from < to) {
        for (let i = from; i < to; i++) {
          value[i] = value[i + 1]
        }
      }
      value[to] = temp
      handleChange(value);
  }

  const itemToEdit = value?.[editIdx]

  return <div>
    <FeatureCreateForm onCreate={handleCreate} onClose={() => setShowCreateForm(false)}  insertIdx={insertIdx} show={showCreateForm}/>
    {itemToEdit && <FeatureEditForm
     onSubmit={handleEdit} onClose={() => {
      setEditIdx(null)
    }} data={itemToEdit} idx={editIdx} show={itemToEdit}/>}

    <div className="item-builder">
      <h3>{t("pricing_feature_builder")}</h3>
      {
        value?.length > 0 ?
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={snapshot.isDraggingOver ? "ondrag" : ""}
                >
                  <DividerAddButton onClick={() => {
                    setInsertIdx(0)
                    setShowCreateForm(true)
                  }}/>
                  {value?.map((c, index) => (
                    <Draggable
                      key={c.dKey}
                      draggableId={c.dKey}
                      index={index}>
                      {(provided, snapshot) => (
                        <div>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`item p-2 px-3 w-100 d-flex bg-light shadow gap-2 align-items-start ${snapshot.isDragging ? "dragged" : ""}`}
                          >
                              <div className="lead">
                                {c.title}
                                <Badge bg={c.status ? "success": "secondary"} className="mx-3">{c.status ? "Active" : "Inactive"}</Badge>
                              </div>

                              <Button className="ml-auto" onClick={() => initEditItem(c.dKey)} ><MdEdit/></Button>
                              <Button onClick={() => removeItem(c.dKey)}  variant={"outline-danger"}><MdDelete/></Button>
                            </div>
                          <DividerAddButton onClick={() => {
                            setInsertIdx(index + 1)
                            setShowCreateForm(true)
                          }}/>
                        </div>
                      )}
                    </Draggable>

                  ))}
                  {provided.placeholder}
                </div>
              )}

            </Droppable>
          </DragDropContext>
          : <div className="new-item-btn d-flex flex-column align-items-center justify-content-center"
                 onClick={
                   () => {
                     setInsertIdx(0);
                      setShowCreateForm(true);
                   }}>
            <BsPlus className="display-1"/>
            <div className="lead">
              {t("pricing_feature_create_new")}
            </div>
          </div>
      }
    </div>
  </div>
}

function DividerAddButton({onClick}) {
  return <div onClick={onClick} className="item-add">
    <div className="item-add-content item-add-divider">
    </div>
    <div className="item-add-content item-add-btn">
    </div>
  </div>
}
