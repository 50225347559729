import * as yup from "yup";
import {defaultImageValidation, languageSchema, SeoSchema,} from "../../configs/constants";

export const PostInputSchema = (t) => {
  return SeoSchema().shape({
    name: yup.string().max(255).required().label(t("posts_create_name")),
    post_category_id: yup.number().min(1, "Please select a post category").required().label(t("posts_create_category")),
    description: yup.string().required().label(t("posts_create_description")),
    image: defaultImageValidation.label(t("posts_create_image")),
    is_show: yup.bool().required().default(false).label(t("posts_create_visibility")),
    post_date: yup.string().required().label(t("posts_create_postDate")),
  });
}

export const PostLanguageInputSchema = (t) => languageSchema.shape({
  name: yup.string().max(255).required().label(t("posts_create_name")),
  description: yup.string().max(10000).label(t("posts_create_description")),
  image: defaultImageValidation.label(t("posts_create_image")),
  post_date: yup.string(),
})
