import {api, parseAPIError} from "../../services/api";
import {jsonToFormData} from "../../helpers";

export function processAlbumInput(input) {
  input.taxonomy_id = 7;
  return input;
}

export async function createAlbum(input) {
  try {
    const r_album = await api.post("terms/store", processAlbumInput(input));
    const album_id = r_album.data.data.id;
    if (input.checkValue.length > 0) {
      const galley_data = {
        album_id: album_id,
        media_id: input.checkValue
      };
      let fd = jsonToFormData(galley_data);
      const r_gallery = await api.post("gallery/store", fd);
    }
    return r_album.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getAlbum(id) {
  try {
    return await api.get(`terms/get/${id}`);
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function updateAlbum(id, data) {
  try {
    return await api.post(`terms/update/${id}`, data);
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function removeAlbum(id) {
  const r = await api.delete(`/terms/delete/${id}`);
  return r.data.data;
}

export async function getAlbumMedia(id, page) {
  try {
    return await api.get(`gallery/album/${id}?page=${page}`);
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function deleteMediaFromAlbum(id) {
  try {
    return await api.delete(`gallery/delete/${id}`);
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function saveOnAddMedia(form_data) {
  try {
    return await api.post('gallery/store', form_data);
  } catch (err) {
    throw parseAPIError(err);
  }
}


export async function getPaginatedAlbum(options) {
  const r = await api.get("/terms/list", {
    params: options
  });
  if (r.data.data) {
    return r.data.data;
  }
  return {
    data: [],
    total: 0
  };
}

