import Layout from "../../components/layouts/layout";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {SelectPicker} from "rsuite";
import CircleLoader from "../../components/Loaders/CircleLoader";
import {useState} from "react";
import {Link} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useComponentCategories, useIdentifiers, useLang} from "../../hooks";
import {InputValidationError} from "../../libs/error";
import {ComponentInputSchema} from "./schema";
import {BackButton} from "../../components/BackButton/BackButton";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {createComponent} from "../../services/component";
import {useNotification} from "../../hooks/useNotification";
import {TextEdit} from "../../components";
import {useMediaSelector} from "../../components/MediaSelectorModal";
import MediaCard from "../MediaManagement/components/MediaCard";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";
import {LayoutInput} from "../../components/MediaInputs/LayoutInput";
import ComponentSelectorModal from "../../components/ComponentSelectorModal";
import MediaCardSmall from "../../components/MediaSelectorModal/MediaCardSmall";
import LayoutCard from "../../components/ComponentSelectorModal/LayoutCard";
import {usePageList} from "../../hooks/usePageList";

export function ComponentCreatePage() {
  const {t} = useLang();
  const [loading, setLoading] = useState();
  const {notifyError, notifySuccess} = useNotification();
  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    resetField,
    watch
  } = useForm({resolver: yupResolver(ComponentInputSchema(t))});

  const {
    selectedMedia,
    setSelectedMedia,
    checkValue,
    setCheckValue,
    allMediaId,
    setAllMediaId,
    handleRemove,
  } = useMediaSelector();

  const [selectedLayout, setSelectedLayout] = useState(null);

  const typeId = watch("type_id");
  const {categories, isLoading: isCategoryLoading, error: catErr} = useComponentCategories();
  const {identifiers, isLoading: isIdentifiersLoading, idErr} = useIdentifiers(typeId);
  const {
    pageList,
    isLoading: pageLoading,
    error: pageErr
  } = usePageList(null, {page: 1, limit: 100});

  if (catErr || idErr) {
    notifyError(catErr ?? idErr, "/error");
  }

  const create = async (data) => {
    try {
      setLoading(true);
      let componentData = {...data, media_id: checkValue, layout_pack_id: selectedLayout.id};
      const component = await createComponent(componentData);
      notifySuccess({
        title: "components created successfully",
        message: `Component: ${component?.title} created!`
      }, "/components/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Input validation failed",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: err.message
      });
    } finally {
      setLoading(false);
    }
  };
  const handleRemoveLayout = (event, media) => {
    event.stopPropagation();
    setSelectedLayout(null);
  }

  let type_id = Number(watch('type_id'))

  return (
    <>
      <Layout>
        {loading && <CircleLoader />}
        <header>
          <div className="d-flex justify-content-between align-items-center">
            <Breadcrumb className="d-flex align-items-center">
              <li className="breadcrumb-item">
                <Link to={`/components/manage`}> {t("components_manage_newComponent")}</Link>
              </li>
              <Breadcrumb.Item active>
                {t("components_create_createNewComponent")}
              </Breadcrumb.Item>
            </Breadcrumb>
            <BackButton path={-1} text={t("users_page_title")} />
          </div>
        </header>

        <section id="#component-create">
          <h2>{t("components_create_createNewComponent")}</h2>
          <Form onSubmit={handleSubmit(create)} className="d-flex flex-column gap-3 mt-3">
            <div className="form-row gap-3 gap-md-0">
              <Form.Group as={Col} controlId="type_id">
                <Form.Label
                  className="mark-required">{t("components_create_selectComponentType")}</Form.Label>
                <Controller name="type_id" control={control} render={({field: {onChange, value}}) => (
                  <SelectPicker onChange={(value) => {
                    onChange(value);
                    resetField("identifier_id");
                  }} value={value} data={categories} block
                                searchable={false} />
                )} />
                {errors.type_id?.message && (
                  <Form.Text className="text-danger">{errors.type_id.message}</Form.Text>)}
              </Form.Group>
              <Form.Group as={Col} controlId="identifier_id" className={`${type_id === 12 ? "d-none" : ""}`}>
                <Form.Label
                  className="mark-required">{t("components_create_selectIdentifier")}</Form.Label>
                <Controller name="identifier_id" control={control}
                            render={({field: {onChange, value}}) => (
                              <SelectPicker value={value} onChange={onChange} data={identifiers}
                                            block loading={isIdentifiersLoading} disabled={type_id === 12} />
                            )} />
                {errors.identifier_id?.message && (
                  <Form.Text className="text-danger">{errors.identifier_id.message}</Form.Text>)}
              </Form.Group>
            </div>
            <Form.Group controlId="page_id">
              <Form.Label>{t("menu_content_page")}</Form.Label>
              <Controller control={control} render={
                ({field}) =>
                  <SelectPicker onChange={field.onChange} value={field.value} data={pageList?.pages.map(p => ({
                    label: p.title,
                    value: p.id
                  }))} style={{width: "100%"}} />
              } name="page_id" />

              {errors?.page_id?.message && (
                <Form.Text className="text-danger">{errors.page_id.message}</Form.Text>
              )}
            </Form.Group>
            <div className="form-row gap-2 gap-md-0">
              <Form.Group as={Col} controlId="layout_pack_id">
                <Form.Label
                  className="mark-required">{t("components_create_selectLayout")}</Form.Label>
                  <Controller
                    control={control}
                    name="layout_pack_id"
                    render={({field}) => (
                      <ComponentSelectorModal isSingleMode selectedMedia={selectedLayout} setSelectedMedia={setSelectedLayout} type={type_id}>
                        {
                          selectedLayout ? (
                              <LayoutCard media={selectedLayout} isDelete handleRemove={handleRemoveLayout}/>
                          ) : (
                            <div style={{height: "300px", width: "100%"}}>
                              <LayoutInput
                                errorMessage={ type_id ? null : "Select a Component First."}
                                onChange={field.onChange}
                                onError={() => {
                                  setError("image", {message: "Invalid image file!"});
                                }}
                              />
                            </div>
                          )
                        }
                      </ComponentSelectorModal>
                    )
                    } />
                {errors.layout_pack_id?.message && (
                  <Form.Text className="text-danger">{errors.layout_pack_id.message}</Form.Text>)}
              </Form.Group>
            </div>
            <div className="form-row gap-2 gap-md-0">
              <Form.Group as={Col} md={12} className="flex-grow-1" controlId="title">
                <Form.Label className="mark-required">{t("components_create_title")}</Form.Label>
                <Form.Control {...register("title")} placeholder={t("components_create_title_plhd")}
                              type="text" />
                {errors.title?.message && (
                  <Form.Text className="text-danger">{errors.title.message}</Form.Text>)}
                </Form.Group>
              </div>
              <Form.Group className="flex-grow-1" controlId="name">
                <Form.Label>{t("subtitle")}</Form.Label>
                <Form.Control {...register("subTitle")} placeholder={t("subtitle_phld")}
                              type="text" />
                {errors.subTitle?.message && (
                  <Form.Text className="text-danger">{errors.subTitle.message}</Form.Text>)}
              </Form.Group>
              <div className="row">
                <Form.Group className="col-md-12" controlId="image1">
                  <Form.Label>{t("image")}</Form.Label>
                  <div className="w-100 overflow-auto">
                    <Controller
                      control={control}
                      name="image1"
                      render={({field}) => (
                        <MediaModal selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                                    checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                          {
                            selectedMedia?.length > 0 ? (
                              selectedMedia?.map((md) =>
                                <MediaCard key={md.id} media={md} isDelete handleRemove={handleRemove} />
                              )
                            ) : (
                              <div style={{height: "300px", width: "100%"}}>
                                <ImageInput
                                  errorMessage={errors.image?.message}
                                  onChange={field.onChange}
                                  onError={() => {
                                    setError("image", {message: "Invalid image file!"});
                                  }}
                                />
                              </div>
                            )
                          }
                        </MediaModal>
                      )}
                    />
                  </div>
                </Form.Group>
              </div>
              <Form.Group controlId="description1">
                <Form.Label>{t("description")} {t("1")}</Form.Label>
                <Controller
                  control={control}
                  name="description1"
                  render={({field}) => <TextEdit {...field} />}
                />
                {errors.description1?.message && (
                  <Form.Text className="text-danger">
                    {errors.description1.message}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group controlId="description2">
                <Form.Label>{t("description")} {t("2")}</Form.Label>
                <Controller
                  control={control}
                  name="description2"
                  render={({field}) => <TextEdit {...field} />}
                />
                {errors.description2?.message && (
                  <Form.Text className="text-danger">
                    {errors.description2.message}
                  </Form.Text>
                )}
              </Form.Group>
              <div className="form-row gap-3 md-2 gap-md-0">
                <Form.Group as={Col} md={6} className="flex-grow-1" controlId="link1">
                  <Form.Label>{t("link")} {t("1")}</Form.Label>
                  <Form.Control {...register("link1")} placeholder={t("link_phld")}
                                type="text" />
                  {errors.link1?.message && (
                    <Form.Text className="text-danger">{errors.link1.message}</Form.Text>)}
                </Form.Group>
                <Form.Group as={Col} md={6} className="flex-grow-1" controlId="link2">
                  <Form.Label>{t("link")} {t("2")}</Form.Label>
                  <Form.Control {...register("link2")} placeholder={t("link_phld")}
                                type="text" />
                  {errors.link2?.message && (
                    <Form.Text className="text-danger">{errors.link2.message}</Form.Text>)}
                </Form.Group>
              </div>
              <div className="form-row gap-3 md-2 gap-md-0">
                <Form.Group as={Col} md={6} className="flex-grow-1" controlId="link3">
                  <Form.Label>{t("link")} {t("3")}</Form.Label>
                  <Form.Control {...register("link3")} placeholder={t("link_phld")}
                                type="text" />
                  {errors.link3?.message && (
                    <Form.Text className="text-danger">{errors.link3.message}</Form.Text>)}
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="youTubeID">
                  <Form.Label>{t("components_create_youtubeID")}</Form.Label>
                  <Form.Control {...register("youTubeID")}
                                placeholder={t("components_create_youtubeID_plhd")} type="text" />
                  {errors.youTubeID?.message && (
                    <Form.Text className="text-danger">{errors.youTubeID.message}</Form.Text>)}
                </Form.Group>
              </div>
              <div className="row mt-2">
                <div className="d-flex justify-content-end">
                  <Button className="px-4" type="submit">
                    {t("components_create_create")}
                  </Button>
                </div>
              </div>
          </Form>
        </section>
      </Layout>
    </>
  );
}