import {api} from "../../services/api";

export async function getDashboardStat() {
  const r = await api.get("/dashboard");
  return r.data.data;
}

export async function getPaginatedActivityLog(options) {
  const r = await api.get("/activity", {
    params: options
  });
  if (r.data.data) {
    return r.data.data;
  }
  return {
    data: [],
    total: 0
  };
}