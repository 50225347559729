import {useCallback, useEffect, useReducer, useState} from "react";
import filterReducers from "../reducers/filterReducer";
import {usePagination} from "./usePagination";
import {createLanguage, deleteLanguage, getPaginatedLanguage, updateLanguage} from "../services/language";

const resourceParamMap = {
  "taxonomy": {id: "taxonomy_id"},
  "term": {id: "term_id"},
  "content": {id: "master_crud_id"},
  "event": {id: "event_id"},
  "post": {id: "post_id"},
  "product": {id: "product_id"},
  "component": {id: "component_id"},
  "menu": {id: "menu_id"},
  "settings": {id: "gs_option_id"}
};

export function useResourceLangList({resourceName, resourceId}) {
  const [list, setList] = useState({pages: [], total: 0});
  const [filter, filterDispatch] = useReducer(filterReducers, {});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const {pagination, changePagination} = usePagination({
    page: 1,
    limit: 10
  });

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      const languages = await getPaginatedLanguage(resourceName, {
        page: pagination.page,
        ...filter,
        [resourceParamMap[resourceName]?.id]: resourceId
      });
      setList({
        events: languages.data,
        total: languages.total
      });

      setIsLoading(false);
    } catch (err) {
      setError({
        title: "Failed to fetch resource languages",
        message: err.message
      });
    }
  }, [pagination, filter, resourceId, resourceName]);

  const deleteItem = async (id) => {
    await deleteLanguage(resourceName, id);
    await fetch();
  };

  const createItem = async (data) => {
    try {
      setIsLoading(true);
      const l = await createLanguage("settings", data);
      await fetch();
    } finally {
      setIsLoading(false);
    }
  };

  const editItem = async (langId, data) => {
    try {
      setIsLoading(true);
      await updateLanguage("settings", langId, data);
      await fetch();
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    list,
    filterDispatch,
    pagination,
    changePagination,
    createItem, editItem,
    deleteItem,
    isLoading,
    error,
    filter
  };
}
