import React, {useRef, useState} from "react";
import Layout from "../../components/layouts/layout";
import "../../App.css";
import {useLang} from "../../hooks";
import ReactDiffViewer from "react-diff-viewer-continued";
import {StatCard} from "./components/StatCard";
import {useNotification} from "../../hooks/useNotification";
import {useActivityList, useDashBoardStat} from "./hooks";
import {FaAngleRight, FaBlog, FaClipboard, FaSearch, FaUsers} from "react-icons/fa";
import moment from "moment";
import {Badge, Collapse, Table} from "react-bootstrap";
import {Input, InputGroup, Pagination} from "rsuite";
import {MdProductionQuantityLimits} from "react-icons/md";
import {GiFamilyTree} from "react-icons/gi";
import ComponentGuard from "../../helpers/ComponentGuard";
import {Permission} from "../../configs/constants";


export function ActivityLogItem({log}) {
  const [open, setOpen] = useState(false);
  const {t, lang} = useLang();
  let formattedTime;

  if (lang === 'BD') {
    require('moment/locale/bn');
    moment.locale('bn');
    formattedTime = moment(log.created_at).format('a h:mm, DD-MMMM-YYYY');
  } else {
    moment.locale('en');
    formattedTime = moment(log.created_at).format('h:mm a, DD-MMMM-YYYY');
  }

  return <div className="border p-3">
    <div className="d-flex align-items-center gap-2">
      <FaAngleRight onClick={() => setOpen(prev => !prev)} />
      <h5>{t("activity_log_collection_type")}: {log.table_name}</h5>
      <div className="ml-auto">
        <Badge bg={"light"} text={"dark"}>
          {t("activity_log_time")}: {formattedTime}
        </Badge>
      </div>
    </div>
    <Collapse in={open}>
      <div className="mt-3">
        <h6 className="mb-3">{t("activity_log_details")}</h6>
        <Table>
          <thead>
          <tr>
            <th>{t("activity_log_prev_data")}</th>
            <th>{t("activity_log_new_data")}</th>
          </tr>
          </thead>
          <tbody>
          </tbody>
        </Table>
        <h1>TO DO</h1>
        <ReactDiffViewer oldValue={JSON.stringify(JSON.parse(log.previous_content ?? "null"), null, 2)}
                         newValue={JSON.stringify(JSON.parse(log.present_content ?? "null"), null, 2)}
        />
      </div>
    </Collapse>
  </div>;

}

export default function DashboardPage() {
  const {t} = useLang();
  const {stat, isLoading, error} = useDashBoardStat();
  // const {
  //   activityList,
  //   pagination,
  //   error: aErr,
  //   isLoading: aLoading,
  //   changePagination,
  //   filterDispatch
  // } = useActivityList();
  const searchBoxRef = useRef();
  const {notifyError} = useNotification();

  if (error) {
    notifyError(error);
  }

  // if (aErr) {
  //   notifyError(aErr);
  // }


  return (
    <Layout>
      <section id="dashboard">
        <article id="stat" className="container-fluid">
          <div className="d-flex flex-wrap flex-md-row flex-sm-row justify-content-center gap-2">
            <ComponentGuard needsPermission={[Permission.PostList]}>
              <StatCard icon={<FaBlog />} className="flex-grow-1" name={t("posts_manage_posts")}
                        value={stat?.post} />
            </ComponentGuard>
            <ComponentGuard needsPermission={[Permission.ProductIndex]}>
              <StatCard icon={<MdProductionQuantityLimits />} className="flex-grow-1"
                        name={t("products_manage_products")} value={stat?.product} />
            </ComponentGuard>
            <ComponentGuard needsPermission={[Permission.UserList]}>
              <StatCard icon={<FaUsers />} className="flex-grow-1" name={t("users_page_title")}
                        value={stat?.user} />
            </ComponentGuard>
            <ComponentGuard needsPermission={[Permission.PageList]}>
              <StatCard icon={<GiFamilyTree />} className="flex-grow-1" name={t("pages_manage_pages")}
                        value={stat?.page} />
            </ComponentGuard>
            <ComponentGuard needsPermission={[Permission.ContactForm]}>
              <StatCard icon={<FaClipboard />} className="flex-grow-1"
                        name={t("contactForm_manage_contactForms")} value={stat?.contact} />
            </ComponentGuard>
          </div>
        </article>

        {/*Activity Log*/}
        {/*<ComponentGuard needsPermission={[Permission.ActivityList]}>*/}
        {/*  <article id="activity-log" className="mt-5">*/}
        {/*    <div className="d-flex flex-column flex-md-row mb-3 justify-content-between">*/}
        {/*      <h3>{t("dashboard_activity_log")}</h3>*/}
        {/*      <InputGroup className="mb-3" style={{width: "300px"}}>*/}
        {/*        <Input ref={searchBoxRef} placeholder={t("pages_manage_searchByName")} />*/}
        {/*        <InputGroup.Button*/}
        {/*          onClick={() => {*/}
        {/*            filterDispatch({*/}
        {/*              action: "set",*/}
        {/*              payload: {key: "term", value: searchBoxRef.current.value}*/}
        {/*            });*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          <FaSearch />*/}
        {/*        </InputGroup.Button>*/}
        {/*      </InputGroup>*/}
        {/*    </div>*/}

        {/*    <div className="d-flex gap-4 flex-column">*/}
        {/*      {activityList?.activities?.map((a) => <ActivityLogItem key={a.id} log={a} />)}*/}
        {/*    </div>*/}
        {/*    {activityList.total > pagination.limit &&*/}
        {/*      <div className="d-flex">*/}
        {/*        <div*/}
        {/*          className="m-auto mt-4 flex-grow-0"*/}
        {/*        >*/}
        {/*          <Pagination*/}
        {/*            className="m-auto"*/}
        {/*            prev*/}
        {/*            last*/}
        {/*            next*/}
        {/*            first*/}
        {/*            size="lg"*/}
        {/*            total={activityList.total}*/}
        {/*            limit={pagination.limit}*/}
        {/*            activePage={pagination.page}*/}
        {/*            onChangePage={(page) => {*/}
        {/*              changePagination({page: page});*/}
        {/*            }}*/}
        {/*          />*/}
        {/*        </div>*/}
        {/*      </div>*/}

        {/*    }*/}
        {/*  </article>*/}
        {/*</ComponentGuard>*/}
      </section>
    </Layout>
  );
};