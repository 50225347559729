import {useRef, useState} from "react";
import {Breadcrumb, Button} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {BsPencilSquare} from "react-icons/bs";
import {CgPlayListAdd} from "react-icons/cg";
import {FaEye, FaSearch} from "react-icons/fa";
import {MdDeleteOutline} from "react-icons/md";
import {Link, useNavigate} from "react-router-dom";
import {Input, InputGroup} from "rsuite";
import {FloatingButton} from "../../components";
import Layout from "../../components/layouts/layout";
import {Permission} from "../../configs/constants";
import ComponentGuard from "../../helpers/ComponentGuard";
import {useNotification} from "../../hooks/useNotification";
import {useLang} from "../../hooks";
import {usePaginatedList} from "../../hooks/usePaginatedList";
import {getPaginatedForm, removeForm} from "../../services/formBuilder";
import {FromPreview} from "./components/FormPreview";

export function FormBuilderListPage() {
  const searchBoxRef = useRef();
  const [previewData, setPreviewData] = useState(null);
  const {t} = useLang();
  const {
    list,
    changePagination,
    error,
    isLoading,
    pagination,
    filterDispatch,
    removeItem,
  } = usePaginatedList(getPaginatedForm, removeForm)

  const {notifyError, notifySuccess} = useNotification();

  const navigate = useNavigate();

  if (error) {
    notifyError(error);
    setTimeout(() => navigate("/"), 200);
  }

  const handleDelete = async (id) => {
    try {
      await removeItem(id);
      notifySuccess({
        title: "Form removed successfully"
      });
    } catch (err) {
      notifyError({
        title: "Failed remove",
        message: err.message
      });
    }
  };


  const columns = [
    {
      name: "#",
      cell: (row, index) =>
        (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
      width: "50px"
    },
    {
      name: t("name"),
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: t("details"),
      selector: (row) => <Button onClick={() => setPreviewData(JSON.parse(JSON.parse(row.details)))}><FaEye/></Button>
    },
    {
      name: t("action"),
      selector: (m) => {
        return (
          <div className="d-flex gap-2">
            <ComponentGuard needsPermission={[Permission.FormBuilderUpdate]}>
              <Link
                to={`/form-builder/${m.id}/edit`}
                className="btn btn-secondary"
              >
                <BsPencilSquare />
              </Link>
            </ComponentGuard>
            <ComponentGuard needsPermission={[Permission.FormBuilderDelete]}>
              <Button onClick={() => handleDelete(m.id)}>
                <MdDeleteOutline />
              </Button>
            </ComponentGuard>
          </div>
        );
      }
    }
  ];

  return (
    <>{previewData &&
      <FromPreview open={true} onClose={()=>setPreviewData(null)} data={previewData}/>}
      <Layout>
        <header>
          <div className="d-flex justify-content-between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item active>
                  {t("formBuilder_manage_forms")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </header>
        <section id="list" className="mb-5 pb-5">
          <h2>{t("formBuilder_manage_forms")}</h2>
          <DataTable
            columns={columns}
            data={list.data}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationComponentOptions={{noRowsPerPage: true}}
            paginationTotalRows={list.total}
            onChangePage={(page) => {
              changePagination({page});
            }}
            fixedHeader
            fixedHeaderScrollHeight="800px"
            highlightOnHover
            subHeader
            subHeaderComponent={
              <InputGroup className="mb-3" style={{width: "300px"}}>
                <Input ref={searchBoxRef} placeholder={t("pages_manage_searchByName")} />
                <InputGroup.Button
                  onClick={() => {
                    filterDispatch({
                      type: "set",
                      payload: {
                        key: "term",
                        value: searchBoxRef.current.value
                      }
                    });
                  }}
                >
                  <FaSearch />
                </InputGroup.Button>
              </InputGroup>
            }
            subHeaderAlign="right"
          />
        </section>
      </Layout>
      <ComponentGuard needsPermission={[Permission.FormBuilderStore]}>
        <FloatingButton
          onClick={() => {
            navigate("/form-builder/new");
          }}
          icon={<CgPlayListAdd />}
          name={t("formBuilder_manage_newForms")}
        />
      </ComponentGuard>
    </>
  );
}

