import http from "../http";
import Cookies from "universal-cookie";
import {useCallback, useMemo} from "react";

/**
 * A custom axios hook for accessing protected resources from api
 * @deprecated Use api from services instead
 * @returns {{http: *}}
 */
export function usePrivateAPI() {

  const getHttp = useCallback(() => {
    const privateAPI = http.create({});
    const cookies = new Cookies()

    privateAPI.interceptors.request.use((config) => {
      config.headers.Authorization =  "Bearer " + cookies.get("userAuth").token
      return config;
    });

    privateAPI.interceptors.response.use(
        undefined,
        (err) => {
          console.log(err)
          // console.log(err.response.status);
          // if (err.response.status === 401 && !err.config._retry) {
          //   console.log("Unauthorized! Calling logout!");
          // }
          return Promise.reject(err);
        }
    );
    return  privateAPI
  }, [])

  const privateAPI = useMemo(getHttp, [getHttp])
  return  {http: privateAPI}
}
