import {useCallback, useEffect, useReducer, useState} from "react";
import filterReducers from "../reducers/filterReducer";
import {usePagination} from "./usePagination";
import {getPaginatedComponent, removeComponent} from "../services/component";

export function useComponentList(defaultFilter, defaultPagination) {
  const [componentList, setComponentList] = useState({components: [], total: 0});
  const [filter, filterDispatch] = useReducer(filterReducers, defaultFilter ?? {});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const {pagination, changePagination} = usePagination(defaultPagination ?? {
    page: 1,
    limit: 10
  });

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      const cc = await getPaginatedComponent(
        {
          page: pagination.page,
          limit: pagination.limit,
          ...filter
        }
      );
      setComponentList({
        components: cc.data,
        total: cc.total
      });

      setIsLoading(false);
    } catch (err) {
      setError({
        title: "Failed to fetch component list",
        message: err.message
      });
    }
  }, [pagination, filter]);

  const deleteComponent = useCallback(async (id) => {
    await removeComponent(id);
    await fetch();
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    componentList,
    filterDispatch,
    pagination,
    changePagination,
    deleteComponent,
    isLoading,
    error,
    filter
  };
}
