import { useCallback, useEffect, useState } from "react";
import { getProductInventory, getProductInventoryAttrbutes, getProductInventoryCategories, updateProduct, updateProductInventory } from "../services/product";
import { organizeDataIntoTree } from "../helpers";
import { removeEventMedia } from "../services/event";

export function useProductInventory(id) {
    const [product, setProduct] = useState()
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const fetchProduct = async () => {
        try {
            setIsLoading(true);
            // let myData = await getProductInventory(id)
            // // @TODO: @Joy Explain me this
            // let attributes = myData.product_pivot_terms.map(term => String(term.attribute_id));
            // myData.attributes = attributes
            // setProduct(myData);
            let myData = await getProductInventory(id);
            let attributes = Array.isArray(myData.product_pivot_terms)
                ? myData.product_pivot_terms.map(term => String(term.attribute_id))
                : [];
            myData.attributes = attributes;
            setProduct(myData);
        } catch (err) {
            setError({
                title: "Failed to fetch product!",
                message: err.message,
            });
        } finally {
            setIsLoading(false);
        }
    };
    async function editProductInventory(data) {
        try {
            setIsLoading(true);
            const myData = await updateProductInventory(id, data)
            // let attributes = myData.product_pivot_terms.map(term => String(term.attribute_id));
            let attributes = Array.isArray(myData.product_pivot_terms)
                ? myData.product_pivot_terms.map(term => String(term.attribute_id))
                : [];
            myData.attributes = attributes
            setProduct(myData);
        } finally {
            setIsLoading(false);
        }
    }
    async function handleMediaRemove(data) {
        try {
            setIsLoading(true);
            let removeIds = {
                media_id: [data]
            };
            await removeEventMedia(id, removeIds)
        } catch (e) {
            setError({
                title: "Failed to Remove Media!",
                message: e.message,
            });
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchProduct();
    }, [id]);


    return { product, isLoading, handleMediaRemove, editProductInventory, error }
}

