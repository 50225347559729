import {animated, useSpring} from "@react-spring/web";
import React, {memo} from "react";

export const CountUpInt = memo(({from, to}) => {
  const props =
    useSpring({
      from: {val: from ?? 0},
      to: {val: to ?? 0},
      delay: 500,
      reset: true
    });

  return <div>
    <animated.div className="display-1">
      {props.val?.to(val => Math.floor(val))}
    </animated.div>
  </div>;
});