import {useState,useRef,useEffect} from "react";
import {Modal} from "rsuite";
import {Button} from "react-bootstrap";
import MediaManager from "./MediaManager";
import {useLang} from "../../hooks";

function MediaModal({
                      selectedMedia,
                      setSelectedMedia,
                      checkValue,
                      setCheckValue,
                      allMediaId,
                      isSingleMode,
                      handleMediaAddClick = () => {
                      },
                      children
                    }) {
  const [mediaModalOpen, setMediaModalOpen] = useState(false);
  const handleMediaModalOpen = () => {
    if(isSingleMode) {
      if(checkValue.length >= 1) return;
    }
    setMediaModalOpen(true)
  };
  const handleMediaModalClose = () => {
    setMediaModalOpen(false);
    handleMediaAddClick();
  };
  const {t} = useLang();

  return (
    <>
      <div onClick={() => handleMediaModalOpen("full")} className="d-flex gap-2">
        { children }
      </div>
      <Modal size={"lg"} open={mediaModalOpen} onClose={handleMediaModalClose} backdrop={"static"}>
        <Modal.Header>
          <Modal.Title>{t("album_input_addMedia")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MediaManager selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                        isSingleMode={isSingleMode} checkValue={checkValue} setCheckValue={setCheckValue}
                        allMediaId={allMediaId} handleMediaModalClose={handleMediaModalClose}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleMediaModalClose}>
            {t("save_btn")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MediaModal;
