import React, {useEffect, useRef, useState} from "react";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Cookies from "universal-cookie";
import http from "../../http";
import {useLang} from "../../hooks";
import {Input, InputGroup} from "rsuite";
import {FaSearch} from "react-icons/fa";
import {mediaType} from "../../configs/constants";

function SearchAndFilter({filterDispatch, changePagination}) {
  const {t} = useLang();
  const searchRef = useRef();
  const changeCategory = (value) => {
    if(value != '') {
      filterDispatch({
        type: "set",
        payload: {key: "category", value: value}
      });
      changePagination({page: 1, limit: 10});
    } else {
      filterDispatch({
        type: "unset",
        payload: "category"
      });
      changePagination({page: 1, limit: 10});
    }
  }

  const searchByTerm = (value) => {
    if(value != '') {
      filterDispatch({
        type: "set",
        payload: {key: "term", value: searchRef.current.value}
      });
      changePagination({page: 1, limit: 10});
    } else {
      filterDispatch({
        type: "unset",
        payload:  "term"
      });
      changePagination({page: 1, limit: 10});
    }
  }

  return (
    <Container fluid>
      <Row sm={1} md={2} lg={2}>
        <Col>
          <Nav variant="pills" defaultActiveKey="" onSelect={(key) => changeCategory(key)}>
            <Nav.Item>
              <Nav.Link eventKey='' defaultChecked>All</Nav.Link>
            </Nav.Item>
            {mediaType.length > 0 &&
              mediaType.map((media) => (
                <Nav.Item>
                  <Nav.Link eventKey={media.id}>{media.name}</Nav.Link>
                </Nav.Item>
              ))}
          </Nav>
        </Col>
        <Col>
          <InputGroup className="mb-3" style={{minWidth: "200px"}}>
            <Input ref={searchRef} placeholder={t("media_search")} />
            <InputGroup.Button onClick={() => searchByTerm(searchRef.current.value)}
            >
              <FaSearch />
            </InputGroup.Button>
          </InputGroup>
        </Col>
      </Row>
    </Container>
  );
}

export default SearchAndFilter;
