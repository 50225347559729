import http from "../http";
import Cookies from "universal-cookie";
import {InputValidationError} from "../libs/error";

const cookies = new Cookies()
export const api = http.create({});
api.interceptors.request.use((config) => {
  config.headers.Authorization = "Bearer " + cookies.get("userAuth").token
  return config;
});

api.interceptors.response.use(
  undefined,
  (err) => {
    console.log(err)
    return Promise.reject(err);
  }
);

export function parseAPIError(err) {
  if (InputValidationError.isAPIInputValidationError(err)) {
    return InputValidationError.fromAPIError(err);
  }
  return err
}
