import {Input, InputGroup, Modal, Pagination, Form} from "rsuite"
import {Badge, Button, Card, Container, Image} from "react-bootstrap";
import {useTermList} from "../../hooks";
import {useRef, useState} from "react";
import {useComponentList} from "../../hooks/useComponentList";
import "./index.scss";
import {getUrlFromStoragePath} from "../../configs";
import { FaSearch } from "react-icons/fa";

export  function  ComponentSelector ({open, handleClose, onComponentSelect, insertIdx}) {
    const searchBoxRef = useRef();
    const [activeCard, setActiveCard] = useState(null);
    const {termList} = useTermList(10, {page: 1, limit: 1000})
    const {
        componentList,
        pagination,
        changePagination,
        filterDispatch
    } = useComponentList({}, {page: 1, limit:10})

    function truncateString(str, maxLength) {
        if (str.length > maxLength) {
            return str.slice(0, maxLength) + '...';
        }
        return str;
    }

    const handleCategoryChange = (id) => {
        if(searchBoxRef.current.value.length === 0){
            filterDispatch({
                type: "unset",
                payload:  "term"
            });
        }
        if (id === -1) {
            filterDispatch({
                type: "clear"
            })
            return
        }

        filterDispatch({
            type: "set",
            payload: {
                "key": "category",
                "value": id,
            }
        })
        changePagination({page: 1})
    }

    let placeholderImage = "https://dummyimage.com/600x400/96caf4/ffffff.png&text=No+Layout";

    return <Modal size="full" open={open} onClose={handleClose}>
        <Modal.Header>
            <Modal.Title><h5>Select Component</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {/*<Container>*/}
            {/*        <div className="mt-2 d-flex flex-wrap gap-2">*/}
            {/*            <Button size="sm" variant="outline-primary" className="rounded-pill" onClick={() => handleCategoryChange(-1)}>*/}
            {/*                All*/}
            {/*            </Button>*/}
            {/*            {termList.terms?.length > 0 && termList.terms.map(t => {*/}
            {/*                return <Button size="sm" variant="outline-primary" className="rounded-pill" onClick={() => handleCategoryChange(t.id)}>{t.name.replace(/ Component$/, "")}</Button>*/}
            {/*            })}*/}
            {/*        </div>*/}

            {/*        <div className="flex-grow-1">*/}
            {/*            <div className="masonry" style={{width: "80vw"}}>*/}
            {/*                {componentList.components.map(c => <Card key={c.id} className="component-card"*/}
            {/*                                                         onClick={() => {*/}
            {/*                                                             onComponentSelect(insertIdx, {*/}
            {/*                                                                 name: c.layout_pack_details?.name,*/}
            {/*                                                                 layout_pack_id: c.layout_pack_id,*/}
            {/*                                                                 component_id: c.id,*/}
            {/*                                                                 title: c.title,*/}
            {/*                                                                 details: {*/}
            {/*                                                                     title: c.title,*/}
            {/*                                                                     image1: c.image1,*/}
            {/*                                                                     image2: c.image2,*/}
            {/*                                                                     image3: c.image3,*/}
            {/*                                                                 }*/}
            {/*                                                             })*/}
            {/*                                                         }}>*/}
            {/*                      {(c.image1 || c.image2 || c.image3) && <Card.Img variant="top"*/}
            {/*                                                                       src={getUrlFromStoragePath(c.image1 ?? c.image2 ?? c.image3)} />}*/}
            {/*                      <Card.Body>*/}
            {/*                          <Card.Title>{c.title}</Card.Title>*/}
            {/*                          {c.description && <Card.Text>*/}
            {/*                              {c.description}*/}
            {/*                          </Card.Text>}*/}
            {/*                          <Badge>{c.layout_pack_details?.name}</Badge>*/}
            {/*                      </Card.Body>*/}
            {/*                  </Card>*/}
            {/*                )}*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*</Container>*/}
            <Container>
                <div className="mt-2 d-flex flex-wrap gap-2">
                    <Button size="sm" variant="outline-primary" className="rounded-pill"
                            onClick={() => handleCategoryChange(-1)}>
                        All
                    </Button>
                    {termList.terms?.length > 0 && termList.terms.map(t => {
                        return <Button size="sm" variant="outline-primary" className="rounded-pill"
                                       onClick={() => handleCategoryChange(t.id)}>{t.name.replace(/ Component$/, "")}</Button>;
                    })}
                </div>
                <div className="row mt-2" style={{height: "60vh"}}>
                    <div className="col-md-12">
                        <div className>
                            <Form.Group controlId="name-1">
                                <InputGroup inside>
                                    <Input ref={searchBoxRef} placeholder="Search"/>
                                    <InputGroup.Button onClick={() => filterDispatch({
                                        type: "set",
                                        payload: {key: "term", value: searchBoxRef.current.value}
                                    })}>
                                        <FaSearch />
                                    </InputGroup.Button>
                                </InputGroup>
                            </Form.Group>
                        </div>
                        <div className="d-flex flex-wrap overflow-y-scroll sidebar-scroll" style={{height: "50vh"}}>
                            {componentList.components.map(c => (
                              <Card
                                className={`component-card m-2 shadow-sm ${activeCard?.id === c.id ? "active-card" : ""}`}
                                // onClick={() => setActiveCard(c)}
                                onClick={() => {
                                    onComponentSelect(insertIdx, {
                                        name: c.layout_pack_details?.name,
                                        layout_pack_id: c.layout_pack_id,
                                        component_id: c.id,
                                        title: c.title,
                                        details: {
                                            title: c.title,
                                            image1: c.image1,
                                            image2: c.image2,
                                            image3: c.image3,
                                        }
                                    })
                                }}
                                style={{height: "300px", width: "300px"}}>
                                  <div style={{height: "200px", backgroundColor: "#EEEDEB"}} className="d-flex">
                                      <Image className="w-100 h-100 p-2"
                                             style={{objectFit: "contain"}}
                                             src={getUrlFromStoragePath(c?.layout_pack_details?.media_content?.original_url ?? placeholderImage)} />
                                  </div>
                                      <Card.Body>
                                          <b>{truncateString(c?.title, 55)}</b>
                                          <br/>
                                          {(c?.component_category?.name === 12 ?
                                              <Badge>{c?.component_category?.name}</Badge> :
                                            <>
                                                <Badge pill>{c?.component_category?.name}</Badge>
                                                <br/>
                                                <Badge pill bg="secondary">{c?.identifier_id_details?.name}</Badge>
                                            </>
                                          )}

                                      </Card.Body>
                              </Card>
                                ))}
                        </div>
                    </div>
                </div>
            </Container>
        </Modal.Body>
        <Modal.Footer className="pt-4 d-flex">
            {componentList.total > pagination.limit &&
              <div
                className="flex-grow-1 d-flex justify-content-center"
              >
                  <div>
                      <Pagination
                        className="m-auto"
                        prev
                        last
                        next
                        first
                        size="lg"
                        total={componentList.total}
                        limit={pagination.limit}
                        activePage={pagination.page}
                        onChangePage={(page) => {
                            changePagination({page: page})
                        }}
                      />
                  </div>
              </div>
            }
            <Button onClick={handleClose} variant="danger">
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>
}