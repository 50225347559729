import {api, parseAPIError} from "./api";
import {jsonToFormData} from "../helpers";

const languageAPIEndpoints = {
  taxonomy: {
    list: () => "taxonomy_languages/list",
    create: () => `taxonomy_languages/store`,
    get: (id) => `taxonomy_languages/get/${id}`,
    update: (id) => `taxonomy_languages/update/${id}`,
    delete: (id) => `taxonomy_languages/delete/${id}`
  },
  term: {
    list: () => "term_languages/list",
    create: () => `term_languages/store`,
    get: (id) => `term_languages/get/${id}`,
    update: (id) => `term_languages/update/${id}`,
    delete: (id) => `term_languages/delete/${id}`
  },
  content: {
    list: () => "master_crud_languages/show",
    create: () => `master_crud_languages/store`,
    get: (id) => `master_crud_languages/get/${id}`,
    update: (id) => `master_crud_languages/update/${id}`,
    delete: (id) => `master_crud_languages/delete/${id}`
  },
  event: {
    list: () => "event_language/list",
    create: () => `event_language/store`,
    get: (id) => `event_language/get/${id}`,
    update: (id) => `event_language/update/${id}`,
    delete: (id) => `event_language/delete/${id}`,
    deleteMedia: (id) => `event_language/delete-media-id/${id}`
  },
  post: {
    list: () => "post_language/list",
    create: () => `post_language/store`,
    get: (id) => `post_language/get/${id}`,
    update: (id) => `post_language/update/${id}`,
    delete: (id) => `post_language/delete/${id}`,
    deleteMedia: (id) => `post_language/delete-media-id/${id}`
  },
  product: {
    list: () => "product_languages/show",
    create: () => `product_languages/store`,
    get: (id) => `product_languages/get/${id}`,
    update: (id) => `product_languages/update/${id}`,
    delete: (id) => `product_languages/destroy/${id}`,
    deleteMedia: (id) => `product_languages/delete-media-id/${id}`
  },
  component: {
    list: () => "component_language/list",
    create: () => `component_language/store`,
    get: (id) => `component_language/get/${id}`,
    update: (id) => `component_language/update/${id}`,
    delete: (id) => `component_language/delete/${id}`,
    deleteMedia: (id) => `component_language/delete-media-id/${id}`
  },
  menu: {
    list: () => "menu_languages/show",
    create: () => `menu_languages/store`,
    get: (id) => `menu_languages/get/${id}`,
    update: (id) => `menu_languages/update/${id}`,
    delete: (id) => `menu_languages/delete/${id}`
  },
  settings: {
    list: () => "gs_option_languages/show",
    create: () => `gs_option_languages/store`,
    get: (id) => `gs_option_languages/get/${id}`,
    update: (id) => `gs_option_languages/update/${id}`,
    delete: (id) => `gs_option_languages/delete/${id}`
  }
};

export async function getAllLanguages() {
  const r = await api.get("/languages-all");
  return r.data.data;
}

export async function createLanguage(resourceName, data) {
  try {
    const form_data = jsonToFormData(data);
    const r = await api.post(languageAPIEndpoints[resourceName]?.create?.(), form_data);
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getLanguage(resourceName, languageId) {
  const r = await api.get(languageAPIEndpoints[resourceName]?.get?.(languageId));
  return r.data.data;
}

export async function updateLanguage(resourceName, languageId, data) {
  try {
    const form_data = jsonToFormData(data);
    const r = await api.post(languageAPIEndpoints[resourceName]?.update?.(languageId), form_data);
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getPaginatedLanguage(resourceName, options) {
  const r = await api.get(languageAPIEndpoints[resourceName]?.list?.(), {
    params: options
  });
  if (r.data.data) {
    return r.data.data;
  }
  return {
    data: [],
    total: 0
  };
}

export async function deleteLanguage(resourceName, id) {
  const r = await api.delete(languageAPIEndpoints[resourceName]?.delete?.(id));
  return r.data.data;
}

export async function removeLanguageMedia(resourceName, languageId, data) {
  let processData = jsonToFormData(data)
  const r = await api.post(languageAPIEndpoints[resourceName]?.deleteMedia?.(languageId), processData);
  return r.data;
}

