import Layout from "../../components/layouts/layout";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import { TestimonialInputSchema} from "./schema";
import {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useLang, useTermList} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import CircleLoader from "../../components/Loaders/CircleLoader";
import {Rate, SelectPicker} from "rsuite";
import {InputValidationError} from "../../libs/error";
import {BackButton} from "../../components/BackButton/BackButton";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {useTestimonial} from "./hooks/useTestimonial";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";
import MediaCardList from "../../components/MediaSelectorModal/MediaCardList";
import {useMediaSelector} from "../../components/MediaSelectorModal";

export function TestimonialEditPage() {
  const {id} = useParams();
  const {t} = useLang();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const [hoverValue, setHoverValue] = useState(3);
  const texts = {
    1: 'Very Poor',
    2: 'Poor',
    3: 'Average',
    4: 'Good',
    5: 'Excellent'
  };

  const textStyle = {
    verticalAlign: 'top',
    lineHeight: '42px',
    display: 'inline-block'
  };

  const {termList, isLoading: isTermLoading, error: errTerm} =
    useTermList(12, {page: 1, limit: 1000,})
  const {testimonial, edit, isLoading: isTestimonialLoading,
    error: tstErr} = useTestimonial(id)

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    setValue
  } = useForm({
    resolver: yupResolver(TestimonialInputSchema(t)),
  });

  const {
    selectedMedia,
    setSelectedMedia,
    checkValue,
    setCheckValue,
    allMediaId,
    setAllMediaId,
    handleRemove
  } = useMediaSelector();

  const handleEdit = async (data) => {
    try {
      const testimonialData = {...data, image: checkValue[0] ?? null}
      await edit(testimonialData);
      notifySuccess({
        title: `Events "${testimonial?.title}" updated successfully`,
      });
      navigate("/testimonials/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Failed to update testimonial!",
        message: err.message,
      });
    }
  };

  if (errTerm || tstErr) {
    notifyError(errTerm ?? tstErr, '/testimonials/manage')
  }

  useEffect(() => {
    if (testimonial) {
      setValue("name", testimonial?.name ?? '');
      setValue("designation", testimonial?.designation ?? '')
      setValue("description", testimonial?.description ?? '')
      setValue("rating", testimonial?.rating ?? 0)
      setValue("category_id", testimonial?.category_id)

      let mediaId = testimonial?.image ? [testimonial.image] : [];
      let media = testimonial?.media_content ? [testimonial.media_content] : [];

      setSelectedMedia(media);
      setCheckValue(mediaId);
      setAllMediaId(mediaId);
    }
  }, [testimonial, setValue]);

  return (
    <Layout>
      {isLoading && <CircleLoader/>}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/testimonials/manage`}> {t("testimonial_manage_testimonial")}</Link>
            </li>
            <Breadcrumb.Item active>
              {t("testimonial_edit_editTestimonial")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("testimonial_manage_testimonial")}/>
        </div>
      </header>
      <section id="#taxonomy-create">
        <h2>{t("testimonial_edit_editTestimonial")}</h2>
      </section>
      <Form onSubmit={handleSubmit(handleEdit)} className="container-fluid mt-4">
        <div className="form-row">
          <Form.Group as={Col} md={3}>
            <Form.Label>{t("testimonial_input_image")}</Form.Label>
            <Controller
              control={control}
              name="image"
              render={({field}) => (
                <MediaModal selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                            checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                  {
                    selectedMedia?.length > 0 ? (
                      <MediaCardList selectedMedia={selectedMedia} isDelete handleRemove={handleRemove}/>
                    ): (
                      <div style={{height: "300px", width: "100%"}}>
                        <ImageInput
                          errorMessage={errors.image?.message}
                          onChange={field.onChange}
                          onError={() => {
                            setError("image", {message: "Invalid image file!"});
                          }}
                        />
                      </div>
                    )
                  }
                </MediaModal>
              )}
            />
          </Form.Group>
          <Col>
            <Form.Group className="flex-grow-1" controlId="name">
              <Form.Label className="mark-required">{t("testimonial_input_name")}</Form.Label>
              <Form.Control {...register("name")} placeholder={t("testimonial_input_name_p")} type="text"/>
              {errors.name?.message && (
                <Form.Text className="text-danger">{errors.name.message}</Form.Text>)}
            </Form.Group>
            <Form.Group className="flex-grow-1 mt-2" controlId="designation">
              <Form.Label className="mark-required">{t("testimonial_input_designation")}</Form.Label>
              <Form.Control {...register("designation")} placeholder={t("testimonial_input_designation_p")}
                            type="text"/>
              {errors.designation?.message && (
                <Form.Text className="text-danger">{errors.designation.message}</Form.Text>)}
            </Form.Group>
            <Form.Group className="flex-grow-1 mt-2" controlId="description">
              <Form.Label className="mark-required">{t("testimonial_input_description")}</Form.Label>
              <Form.Control {...register("description")} placeholder={t("testimonial_input_description_p")}
                            as="textarea" rows={3}/>
              {errors.description?.message && (
                <Form.Text className="text-danger">{errors.description.message}</Form.Text>)}
            </Form.Group>
          </Col>
        </div>
        <div className="row mt-2">
          <div className="col-sm-6 col-lg-6">
            <Form.Group className="flex-grow-1" controlId="categoryId">
              <Form.Label className="mark-required">{t("testimonial_input_category")}</Form.Label>
              <Controller control={control} name="category_id"
                          render={({field}) =>
                            (<SelectPicker loading={isTermLoading} {...field} defaultExpandAll
                                           data={termList.terms.map(
                                             item => ({label: item.name, value: item.id})
                                           )} block searchable={false} placeholder="Select Categories" />)} />
              {errors.category_id?.message && (
                <Form.Text className="text-danger">{errors.category_id.message}</Form.Text>)}
            </Form.Group>
          </div>
          <div className="col-sm-6 col-lg-6">
            <Form.Group className="flex-grow-1" controlId="rating">
              <Form.Label className="mark-required">{t("testimonial_input_rating")}</Form.Label>
              <Controller name="rating" control={control} render={({field}) => (
                <>
                  <br/>
                  <Rate {...field} defaultValue={3} onChangeActive={setHoverValue} />{' '}
                  <span style={textStyle}>{texts[hoverValue]}</span>
                </>
              )}/>
              {errors.rating?.message && (
                <Form.Text className="text-danger">{errors.rating.message}</Form.Text>)}
            </Form.Group>
          </div>
        </div>
        <div className="row mt-2">
          <div className="d-flex justify-content-end gap-2">
            <Button variant="primary" type="submit">{t("testimonial_edit_update")}</Button>
            <Link className="btn btn-danger" to={`/testimonials/manage`}>
              {t("testimonial_edit_cancel")}
            </Link>
          </div>
        </div>
      </Form>
    </Layout>
  );
}