import {api, parseAPIError} from "./api";

export function processTaxonomyInput(input) {
  return input
}


export async function createTaxonomy(input) {
  try {
    const r = await api.post("taxonomy/store", processTaxonomyInput(input));
    return r.data.data
  } catch (err) {
    throw parseAPIError(err)
  }
}

export async function getTaxonomy(id) {
  const r = await api.get(`taxonomy/get/${id}`);
  return r.data.data
}

export async function updateTaxonomy(id, input) {
  try {
    const r = await api.post(`taxonomy/update/${id}`, processTaxonomyInput(input));
    return r.data.data
  } catch (err) {
    throw parseAPIError(err)
  }
}

export async function getPaginatedTaxonomies(filter) {
  const r = await api.get("/taxonomy/list", {
    params: filter
  })
  if (r.data.data) {
    return r.data.data
  }
  return {
    data: [],
    total: 0,
  }
}

export async function removeTaxonomy(id) {
  const r = await api.delete(`/taxonomy/delete/${id}`);
  return r.data.data
}
