import {useCallback, useEffect, useState} from "react";
import {usePrivateAPI} from "./usePrivateApi";
import {InputValidationError} from "../libs/error";

export function usePage(id) {
  const [page, setPage] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { http } = usePrivateAPI()

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await http.get(`page/get/${id}`);
      setPage(response.data.data);
    } catch (err) {
      setError({
        title: "Failed to fetch page!",
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [id, http]);

  async function edit(data) {
    try {
      setIsLoading(true);
      data.meta_title = data.meta_title ? data.meta_title : undefined
      data.meta_description = data.meta_description ? data.meta_description : undefined
      if (data.meta_tag && data.meta_tag.length > 0) {
        data.meta_tag = data.meta_tag.join(",")
      } else {
        data.meta_tag = undefined
      }

      data.contents = data.contents?.map(c => ({name: c.name, layout_pack_id: c.layout_pack_id, component_id: c.component_id, page_component_order: c.page_component_order}))

      const response = await http.post(`page/update/${id}`,data, {
        headers: { 'content-type': 'application/json' },
      });
    } catch (err) {
      if (InputValidationError.isAPIInputValidationError(err)) {
        throw InputValidationError.fromAPIError(err);
      }
      throw err;
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { page, isLoading, edit, error };
}
