import React, {useEffect, useRef, useState} from "react";
import {MdClose} from "react-icons/md";
import {FaFileCirclePlus} from "react-icons/fa6";
import "./index.scss";
import {Tooltip, Whisper} from "rsuite";
import {defaultStyles, FileIcon} from "react-file-icon";
import {bytesToMb, formatDate} from "../../libs/utility/helper";

const get_url_extension = (url) => {
  return url?.split(/[#?]/)[0].split(".").pop().trim();
};

export function FilePreview({
                              file, supportedFileTypes = ["doc", "docx", "xlx", "xlsx", "pdf", "ppt"], onError = () => {
  }
                            }) {
  const ext = get_url_extension(file.name);
  useEffect(() => {
    if (!supportedFileTypes.includes(ext)) {
      onError("Invalid File Type!");
    }
  }, [supportedFileTypes, onError]);
  return (
    <div className="d-flex gap-4">
      <div className="fileIcon">
        <FileIcon extension={ext} {...defaultStyles[ext]} />
      </div>
      <div>
        <p>Name: <b>{file.name}</b></p>
        <p>Size: {file.size ? (<b>{bytesToMb(file.size)}</b>) : "Unknown"}</p>
        <p>Last Modified: {file.lastModified ? (<b>{formatDate(file.lastModified)}</b>) : "Unknown"}</p>
      </div>
    </div>
  );
}


export function FileInput({
                            defaultPreviewImage: defaultPreviewFile,
                            previousImage: previousFile,
                            onChange,
                            onError,
                            errorMessage
                          }) {
  const inputRef = useRef();
  const [touched, setTouched] = useState(false);
  const [newFile, setNewFile] = useState(null);
  const [fileInfo, setFileInfo] = useState({
    name: null,
    size: null,
    lastModified: null
  });

  previousFile = touched ? null : previousFile;

  const handleCancelInput = (e) => {
    e.preventDefault();
    inputRef.current.value = "";
    inputRef.current.type = "text";
    inputRef.current.type = "file";

    setNewFile(null);
    setTouched(true);
    onChange?.(null);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const upload = e.target.files?.[0] ?? null;
    setNewFile(URL.createObjectURL(upload));
    setTouched(true);
    console.log("upload", upload);
    let currentFileInfo = {
      name: upload.name,
      size: upload.size,
      lastModified: upload.lastModified
    };
    setFileInfo(currentFileInfo);
    onChange?.(upload);
  };

  const handleError = () => {
    onError?.("Invalid image file");
  };

  const tooltip = errorMessage ? <Tooltip className="text-danger">{errorMessage}</Tooltip> :
    <Tooltip>{newFile?.toString() ?? previousFile ?? "Upload File"}</Tooltip>;

  return (
    <Whisper placement="auto" controlId="control-id-hover" trigger="hover" speaker={tooltip}>
      <div
        className={"media-edit " + (!errorMessage ? "" : " error")}
      >
        <input
          ref={inputRef}
          accept=".doc,.docx,.xlx,.xlsx,.pdf,.ppt"
          type="file"
          onChange={handleChange}
        />

        {newFile ? <>
          <FilePreview file={fileInfo} onError={handleError}
                       supportedFileTypes={["doc", "docx", "xlx", "xlsx", "pdf", "ppt"]} />
        </> : previousFile ?? defaultPreviewFile ? <img
            onError={handleError}
            alt="Invalid"
            src={previousFile ?? defaultPreviewFile}
          /> :
          (<FaFileCirclePlus size={30} />)}

        {(previousFile || newFile) && (<button onClick={handleCancelInput} className="close-btn">
          <MdClose className="close-btn-content" />
        </button>)}
      </div>
    </Whisper>
  );
}
