import {useCallback, useEffect, useState} from "react";
import {getLanguage, removeLanguageMedia, updateLanguage} from "../services/language";

export function useLanguage(resourceName, languageId) {
  const [language, setLanguage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      setLanguage(await getLanguage(resourceName, languageId));
    } catch (err) {
      setError({
        title: "Failed to fetch language",
        message: err.message
      });
    } finally {
      setIsLoading(false);
    }
  }, [languageId, resourceName]);


  const edit = useCallback(async (data) => {
    try {
      setIsLoading(true);
      setLanguage(await updateLanguage(resourceName, languageId, data));
    } finally {
      setIsLoading(false);
    }
  });

  const deleteLanguageMedia = useCallback(async (data) => {
    try {
      setIsLoading(true);
      let removeIds = {
        media_id: [data]
      };
      await removeLanguageMedia(resourceName, languageId, removeIds);
    } finally {
      setIsLoading(false);
    }
  })

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {language, edit, deleteLanguageMedia, isLoading, error};
}

