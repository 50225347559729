import { useCallback, useEffect, useReducer, useState } from 'react'
import { usePagination } from "./usePagination";
import { getPaginatedProductInvetory, removeProductInventory, updateProductInventory } from "../services/product";
import filterReducers from "../reducers/filterReducer";


export function useProductInventoryList() {
    const [productInventory, setProductInventory] = useState({
        products: [],
        total: 0,
    })
    const { pagination, changePagination } = usePagination({
        page: 1,
        limit: 10,
    })
    const [searchTerm, setSearchTerm] = useState('')
    const [product, setProduct] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [filter, filterDispatch] = useReducer(filterReducers, {});
    const [error, setError] = useState(false)
    const searchByTerm = async (value) => {
        setSearchTerm(value)
    }
    const fetch = useCallback(async () => {
        try {
            setIsLoading(true)
            const products = await getPaginatedProductInvetory({
                page: pagination.page,
                ...filter,
            })
            setProductInventory({
                products: products.data,
                total: products.total,
            })
            setIsLoading(false)
        } catch (err) {
            setError({
                title: 'Something went wrong',
                message: err.message,
            })
        }
    }, [pagination, filter])

    const deleteProductInventory = async (id) => {
        await removeProductInventory(id)
        await fetch()
    }

    useEffect(() => {
        fetch()
    }, [fetch])

    return {
        productInventory,
        pagination,
        changePagination,
        deleteProductInventory,
        searchByTerm,
        isLoading,
        error,
        filter,
        filterDispatch
    }
}