import {BsGlobe} from "react-icons/bs";
import {Link} from "react-router-dom";
import {Tooltip, Whisper} from "rsuite";

export const LanguageButton = ({ to }) => {

  const tooltip = (
    <Tooltip>
      Language
    </Tooltip>
  );

  return (
    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={tooltip}>
      <Link to={to} className="btn btn-info">
        <BsGlobe />
      </Link>
    </Whisper>
  )
}