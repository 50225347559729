import { useFooterSettings } from "../hooks/useFooterSettings";
import { useLang, useTermList } from "../../../hooks";
import { useNotification } from "../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FooterSettingsFiveSchema } from "../schema";
import { useEffect } from "react";
import { InputValidationError } from "../../../libs/error";
import { Button, Col, Form } from "react-bootstrap";
import { ImageInput } from "../../../components/MediaInputs/imageInput";
import { getUrlFromStoragePath } from "../../../configs";
import { InputGroup, Placeholder, SelectPicker } from "rsuite";
import { BsGlobe } from "react-icons/bs";
import { useMediaSelector } from "../../../components/MediaSelectorModal";
import MediaCardListSmall from "../../../components/MediaSelectorModal/MediaCardListSmall";
import MediaModal from "../../../components/MediaSelectorModal/MediaModal";

export function FooterSettingsFive({ onLanguageEdit }) {
    const { settings, error, isLoading, save } = useFooterSettings("five");
    const { t } = useLang();
    const { notifyError, notifySuccess } = useNotification();
    const { termList, isLoading: isTermsLoading, error: termError } =
        useTermList(8, { page: 1, limit: 10000 });
    const navigate = useNavigate();

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
        setError
    } = useForm({
        resolver: yupResolver(FooterSettingsFiveSchema(t))
    });

    const {
        selectedMedia,
        setSelectedMedia,
        checkValue,
        setCheckValue,
        allMediaId,
        setAllMediaId,
        handleRemove
    } = useMediaSelector();

    if (error || termError) {
        navigate(error ?? termError);
        navigate("/dashboard");
    }

    useEffect(() => {
        if (settings) {
            console.log(settings);
            const { footer_logo_five, footer_menu_id_five, ...newInput } = settings;
            reset({
                footer_title_five: settings.footer_title_five?.value ?? "",
                footer_content_five: settings.footer_content_five?.value ?? "",
                footer_is_social_five: settings.footer_is_social_five?.value,
                footer_menu_id_five:
                    settings.footer_menu_id_five ? String(settings.footer_menu_id_five.value) : "",
                is_search_five: settings.is_search_five?.value,
                is_subscription_five: settings.is_subscription_five?.value,
                is_gallery_five: settings.is_gallery_five?.value,
                is_post_five: settings.is_post_five?.value,
                button_text_five: settings.button_text_five?.value ?? "",
                button_link_five: settings.button_link_five?.value ?? "",
                footer_html_area_five: settings.footer_html_area_five?.value ?? "",
                select_form_five: settings.select_form_five?.value ?? "",
                title_footer_five: settings.title_footer_five?.value ?? "",
                description_footer_five: settings.description_footer_five?.value ?? "",
                copy_right_five: settings.copy_right_five?.value ?? "",
                copy_right_html_area_five: settings.copy_right_html_area_five?.value ?? "",
            });

            let footerLogo = footer_logo_five.value;
            let mediaId = footerLogo ? [footerLogo?.id] : [];
            let media = footerLogo ? [footerLogo] : [];

            setSelectedMedia(media);
            setCheckValue(mediaId);
            setAllMediaId(mediaId);
        }
    }, [settings]);

    const handleUpdate = async (data) => {
        try {
            await save({ ...data, footer_logo_five: checkValue[0] ?? null });
            notifySuccess({
                title: "Success",
                message: "Second Column Updated Successfully"
            })
        } catch (err) {
            if (err instanceof InputValidationError) {
                err.setRHFError(setError);
                notifyError({
                    title: "Invalid input!",
                    message: err.message
                });
                return;
            }
            notifyError({
                title: "Failed to update",
                message: err.message
            });
        }
    };

    return <section id="header-settings-form" className="my-4">
        <h3 className="mb-3">{t("settings_footer_five")}</h3>
        {isLoading ? <Placeholder.Graph active /> :
            <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit(handleUpdate)}>
                <div className="form-row">
                    <Form.Group as={Col} xl={2} style={{ minWidth: '250px' }}>
                        <Form.Label>{t("settings_site_footer_logo")}</Form.Label>
                        <div style={{ height: "200px", justifyContent: "center" }}>
                            <Controller
                                control={control}
                                name="footer_logo_five"
                                render={({ field }) => (
                                    <MediaModal isSingleMode selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                                        checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                                        {
                                            selectedMedia?.length > 0 ? (
                                                <MediaCardListSmall selectedMedia={selectedMedia} isDelete handleRemove={handleRemove} />
                                            ) : (
                                                <div style={{ height: "190px", width: "100%" }}>
                                                    <ImageInput
                                                        errorMessage={errors.image?.message}
                                                        onChange={field.onChange}
                                                        onError={() => {
                                                            setError("image", { message: "Invalid image file!" });
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }
                                    </MediaModal>
                                )}
                            />
                        </div>
                    </Form.Group>

                    <Col className="gap-2 d-flex flex-column">
                        <div className="form-row">
                            <Form.Group as={Col} className="flex-grow-1" controlId="footer_title_five">
                                <Form.Label className="mark-required">{t("settings_footer_title")}</Form.Label>
                                <InputGroup>
                                    <Form.Control {...register("footer_title_five")} placeholder={t("settings_footer_title_phld")}
                                        type="text" />

                                    <Button onClick={() => {
                                        onLanguageEdit(settings.footer_title_five?.id);
                                    }}>
                                        <BsGlobe />
                                    </Button>
                                </InputGroup>
                                {errors.footer_title_five?.message && (
                                    <Form.Text className="text-danger">{errors.footer_title_five.message}</Form.Text>)}
                            </Form.Group>

                            <Form.Group as={Col} className="flex-grow-1" controlId="footer_content_five">
                                <Form.Label>{t("settings_footer_content")}</Form.Label>
                                <InputGroup>
                                    <Form.Control {...register("footer_content_five")} placeholder={t("settings_footer_content_phld")}
                                        type="text" />

                                    <Button onClick={() => {
                                        onLanguageEdit(settings.footer_content_five?.id);
                                    }}>
                                        <BsGlobe />
                                    </Button>
                                </InputGroup>
                                {errors.footer_content_five?.message && (
                                    <Form.Text className="text-danger">{errors.footer_content_five.message}</Form.Text>)}
                            </Form.Group>
                        </div>
                        <Form.Group controlId="footer_menu_id_five">
                            <Form.Label>
                                {t("settings_footer_menu")}
                            </Form.Label>
                            <div>
                                <Controller render={({ field }) => <SelectPicker
                                    onChange={field.onChange}
                                    value={field.value}
                                    style={{ width: "100%" }}
                                    data={termList?.terms.map(t => ({ value: String(t.id), label: t.name }))}
                                    placeholder={t("settings_header_main_placeholder")}
                                    loading={isTermsLoading}
                                />
                                } name="footer_menu_id_five" control={control} />
                            </div>
                            {errors.footer_menu_id_five?.message && (
                                <Form.Text className="text-danger">
                                    {errors.footer_menu_id_five.message}
                                </Form.Text>
                            )}
                        </Form.Group>
                        <Form.Group controlId="footer_is_social_five">
                            <Form.Label >{t("settings_footer_is_social")}</Form.Label>
                            <Form.Control
                                {...register("footer_is_social_five")}
                                as="select"
                            >
                                <option value="1">{t("settings_yes")}</option>
                                <option value="0">{t("settings_no")}</option>
                            </Form.Control>
                            {errors.footer_is_social_five?.message && (
                                <Form.Text className="text-danger">
                                    {errors.footer_is_social_five.message}
                                </Form.Text>
                            )}
                        </Form.Group>
                    </Col>
                </div>
                <div className="form-row">
                    <Form.Group as={Col} className="flex-grow-1" controlId="is_search_five">
                        <Form.Label>{t("settings_is_search_five")}</Form.Label>
                        <Form.Control
                            {...register("is_search_five")}
                            as="select"
                        >
                            <option value="1">{t("settings_yes")}</option>
                            <option value="0">{t("settings_no")}</option>
                        </Form.Control>
                        {errors.is_search_five?.message && (
                            <Form.Text className="text-danger">
                                {errors.is_search_five.message}
                            </Form.Text>
                        )}
                    </Form.Group>
                    <Form.Group as={Col} className="flex-grow-1" controlId="is_subscription_five">
                        <Form.Label>{t("settings_is_subscription_five")}</Form.Label>
                        <Form.Control
                            {...register("is_subscription_five")}
                            as="select"
                        >
                            <option value="1">{t("settings_yes")}</option>
                            <option value="0">{t("settings_no")}</option>
                        </Form.Control>
                        {errors.is_subscription_five?.message && (
                            <Form.Text className="text-danger">
                                {errors.is_subscription_five.message}
                            </Form.Text>
                        )}
                    </Form.Group>
                </div>
                <div className="form-row">
                    <Form.Group as={Col} className="flex-grow-1" controlId="is_gallery_five">
                        <Form.Label>{t("settings_is_gallery_five")}</Form.Label>
                        <Form.Control
                            {...register("is_gallery_five")}
                            as="select"
                        >
                            <option value="1">{t("settings_yes")}</option>
                            <option value="0">{t("settings_no")}</option>
                        </Form.Control>
                        {errors.is_gallery_five?.message && (
                            <Form.Text className="text-danger">
                                {errors.is_gallery_five.message}
                            </Form.Text>
                        )}
                    </Form.Group>
                    <Form.Group as={Col} className="flex-grow-1" controlId="is_post_five">
                        <Form.Label>{t("settings_is_post_five")}</Form.Label>
                        <Form.Control
                            {...register("is_post_five")}
                            as="select"
                        >
                            <option value="1">{t("settings_yes")}</option>
                            <option value="0">{t("settings_no")}</option>
                        </Form.Control>
                        {errors.is_post_five?.message && (
                            <Form.Text className="text-danger">
                                {errors.is_post_five.message}
                            </Form.Text>
                        )}
                    </Form.Group>
                </div>
                <div className="form-row">
                    <Form.Group as={Col} className="flex-grow-1" controlId="button_text_five">
                        <Form.Label className="">{t("settings_button_text_five")}</Form.Label>
                        <Form.Control {...register("button_text_five")} placeholder={t("settings_button_text_five_phld")}
                            type="text" />
                        {errors.button_text_five?.message && (
                            <Form.Text className="text-danger">{errors.button_text_five.message}</Form.Text>)}
                    </Form.Group>
                    <Form.Group as={Col} className="flex-grow-1" controlId="button_link_five">
                        <Form.Label className="">{t("settings_button_link_five")}</Form.Label>
                        <Form.Control {...register("button_link_five")} placeholder={t("settings_button_link_five_phld")}
                            type="text" />
                        {errors.button_link_five?.message && (
                            <Form.Text className="text-danger">{errors.button_link_five.message}</Form.Text>)}
                    </Form.Group>
                </div>
                <div className="form-row">
                    <Form.Group className="flex-grow-1" controlId="footer_html_area_five">
                        <Form.Label className="">{t("settings_footer_html_area_five")}</Form.Label>
                        <Form.Control {...register("footer_html_area_five")} placeholder={t("settings_footer_html_area_five_phld")}
                            type="text" />
                        {errors.footer_html_area_five?.message && (
                            <Form.Text className="text-danger">{errors.footer_html_area_five.message}</Form.Text>)}
                    </Form.Group>
                </div>
                <div className="form-row">
                    <Form.Group as={Col} className="flex-grow-1" controlId="select_form_five">
                        <Form.Label className="">{t("settings_select_form_five")}</Form.Label>
                        <Form.Control {...register("select_form_five")} placeholder={t("settings_select_form_five_phld")}
                            type="text" />
                        {errors.select_form_five?.message && (
                            <Form.Text className="text-danger">{errors.select_form_five.message}</Form.Text>)}
                    </Form.Group>
                </div>
                <div className="d-flex justify-content-end gap-2">
                    <Button className="px-4" variant="success" type="submit">
                        {t("common_settings_save")}
                    </Button>
                </div>
            </Form>}
    </section>;
}
