import {useCallback, useEffect, useState} from "react";
import {getCategories} from "../services/media";

export function useMediaCategories() {
  const [categories, setCategories] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCategories = useCallback(async () => {
    try {
      setIsLoading(true);
      setCategories(await getCategories());
    } catch (err) {
      setError({
        title: "Failed to fetch Media Categories!",
        message: err.message
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, []);

  return {categories, isLoading, error};
}