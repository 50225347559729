import {Badge, Card, Image} from "react-bootstrap";
import {getUrlFromStoragePath} from "../../configs";
import React from "react";
import './index.css';
import {IconButton} from "rsuite";
import {FaTrashAlt} from "react-icons/fa";
import {get_url_extension} from "../../pages/MediaManagement/helpers/media";

export default function LayoutCard({ media, handleOpen = (md) => {}, isDelete, handleRemove = () => {}}) {
  return (
    <div key={media.id} style={{marginBottom: "10px", minWidth: "240px", maxWidth: "240px"}}>
      <div style={{display: "flex", position: "relative"}}>
        <div className="editBtnCard">
          {isDelete && <IconButton icon={<FaTrashAlt />} size="sm" className="shadow"
                                   onClick={(event) => handleRemove(event, media?.id)} />}
        </div>
        <Card
          className={`w-100 shadow media-card`}
          onClick={(e) => {
            handleOpen(media);
          }}
        >
          <div style={{height: "200px", backgroundColor: "#EEEDEB"}} className="d-flex">
            <Image className="w-100 h-100 p-2"
                   style={{objectFit: "contain"}}
                   src={getUrlFromStoragePath(media?.media_content?.original_url)} />
          </div>
          <Card.Body style={{maxHeight: "150px"}}>
            <div className="d-flex justify-content-between">
              <div className="d-inline-block">
                <p className="text-wrap font-weight-bold">
                  {media.name?.length > 20 ? media.name.substring(0, 20 - 3) + "..." : media.name}
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}