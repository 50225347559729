import {useCallback, useEffect, useReducer, useState} from "react";
import {usePagination} from "./usePagination";
import {getPaginatedAlbum, removeAlbum} from "../pages/Album/album.service";
import filterReducers from "../reducers/filterReducer";

export function useAlbumList() {
  const [albumList, setAlbumList] = useState({
    albums: [],
    total: 0
  });
  const {pagination, changePagination} = usePagination({
    page: 1,
    limit: 10
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [filter, filterDispatch] = useReducer(filterReducers, {});

  const fetchAlbums = useCallback(async () => {
    try {
      setIsLoading(true);
      const albums = await getPaginatedAlbum({
        taxonomy: 7,
        page: pagination.page,
        ...filter
      });
      setAlbumList({
        albums: albums.data,
        total: albums.total
      });
    } catch (err) {
      setError({
        title: "Something went wrong",
        message: err.message
      });
    } finally {
      setIsLoading(false);
    }
  }, [pagination, filter]);

  const deleteAlbum = async (id) => {
    await removeAlbum(id);
    await fetchAlbums();
  };

  useEffect(() => {
    fetchAlbums();
  }, [fetchAlbums]);

  return {
    albumList,
    pagination,
    changePagination,
    deleteAlbum,
    filterDispatch,
    isLoading,
    error
  };
}
