import { useRef } from "react";
import { Breadcrumb, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { BsGlobe, BsPencilSquare } from "react-icons/bs";
import { GrView } from "react-icons/gr";
import { CgPlayListAdd } from "react-icons/cg";
import { FaSearch } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { Input, InputGroup } from "rsuite";
import { FloatingButton } from "../../components";
import Layout from "../../components/layouts/layout";
import { Permission } from "../../configs/constants";
import ComponentGuard from "../../helpers/ComponentGuard";
import { useLang, useOrderList } from "../../hooks";
import { useNotification } from "../../hooks/useNotification";

export function OrderListPage() {
    const { t } = useLang();
    const {
        orderList,
        pagination,
        changePagination,
        deleteOrder,
        searchByTerm,
        isLoading,
        error,
        filter,
        filterDispatch
    } = useOrderList();
    const { notifyError, notifySuccess } = useNotification();
    const searchBoxRef = useRef();
    const navigate = useNavigate();

    if (error) {
        notifyError(error);
        setTimeout(() => navigate("/"), 200);
    }

    const handleDelete = async (id) => {
        try {
            await deleteOrder(id);
            notifySuccess({
                title: "order removed successfully"
            });
        } catch (err) {
            notifyError({
                title: "Failed to remove order!",
                message: err.message
            });
        }
    };

    const columns = [
        {
            name: "#",
            cell: (row, index) => (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
            width: "50px"
        },
        {
            name: t("orders_manage_col_name"),
            selector: (row) => row.client_id,
            sortable: true
        },
        {
            name: t("orders_manage_col_action"),
            selector: (m) => {
                return (
                    <div className="d-flex gap-2">
                        <ComponentGuard needsPermission={[Permission.OrderGet]}>
                            <Link to={`/orders/view/${m.id}`} className="btn btn-secondary">
                                <GrView />
                            </Link>
                        </ComponentGuard>
                    </div>
                );
            }
        }
    ];

    return (
        <>
            <Layout>
                <header>
                    <div className="d-flex justify-content-between">
                        <div>
                            <Breadcrumb>
                                <Breadcrumb.Item active>
                                    {t("orders_manage_orders")}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                </header>
                <section id="albumList" className="mb-5 pb-5">
                    <h2>{t("orders_manage_orders")}</h2>
                    <DataTable
                        columns={columns}
                        data={orderList}
                        progressPending={isLoading}
                        pagination
                        paginationServer
                        paginationComponentOptions={{ noRowsPerPage: true }}
                        paginationTotalRows={orderList.total}
                        onChangePage={(page) => {
                            changePagination({ page });
                        }}
                        fixedHeader
                        fixedHeaderScrollHeight="800px"
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                            <InputGroup className="mb-3" style={{ width: "300px" }}>
                                <Input ref={searchBoxRef} placeholder={t("orders_manage_searchByName")} />
                                <InputGroup.Button onClick={() => filterDispatch({
                                    type: "set",
                                    payload: { key: "term", value: searchBoxRef.current.value }
                                })}>
                                    <FaSearch />
                                </InputGroup.Button>
                            </InputGroup>
                        }
                        subHeaderAlign="right"
                    />
                </section>
            </Layout>

        </>
    );
}



