import {useCallback, useEffect, useState} from "react";

export function useQuery(queryFunc, ...params)  {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setErr] = useState(null)


  const fetch = useCallback(async () => {
      try {
        setLoading(true)
        setData(await queryFunc(...params))
      } catch (err) {
        setErr({
          title: "Something went wrong",
          message: err.message
        })
      }finally {
        setLoading(false)
      }
  }, [...params])

  useEffect(() => {
    fetch()
  }, [fetch]);

  return {fetch, loading, error, data}
}