import {useCallback, useEffect, useReducer, useState} from "react";
import {usePagination} from "./usePagination";
import filterReducers from "../reducers/filterReducer";
import {getPaginatedTerm, removeTerm} from "../services/term";

export function useTermList(defaultTaxonomyId, defaultPagination) {
  const [taxonomyId, setTaxonomyId] = useState(defaultTaxonomyId);
  const [termList, setTermList] = useState({
    terms: [],
    total: 0,
  });

  const {pagination, changePagination} = usePagination(
    defaultPagination ?? {
      page: 1,
      limit: 10,
    }
  );

  const [filter, filterDispatch] = useReducer(filterReducers);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      const paginatedTerms = await getPaginatedTerm({
        page: pagination.page,
        limit: pagination.limit,
        ...filter,
        taxonomy: taxonomyId ?? undefined,
      })
      setTermList({
        terms: paginatedTerms.data,
        total: paginatedTerms.total,
      });
      setIsLoading(false);
    } catch (err) {
      setError({
        title: "Something went wrong",
        message: err.message,
      });
    }
  }, [pagination, taxonomyId, filter]);

  const deleteTerm = async (id) => {
    await removeTerm(id)
    await fetch();
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    termList,
    pagination,
    changePagination,
    deleteTerm,
    isLoading,
    filterDispatch,
    error,
    setTaxonomyId,
  };
}
