import {api, parseAPIError} from "./api";
import {jsonToFormData} from "../helpers";
import moment from "moment";

export function parseSettingsFromAPIResponse(data) {
  try {
    const settings = {};
    for (const s of data) {
      settings[s.slug] = {
        id: s.id,
        value: s.value
      };
    }
    return settings;
  } catch (err) {
    throw new Error("Invalid Settings data");
  }
}

export function processPopUpInput({dateTimeRange, ...input}) {
  input.pop_up_status = input.pop_up_status ? "active" : "inactive";
  if (dateTimeRange && dateTimeRange.length === 2) {
    input.pop_up_start_date_time = moment(dateTimeRange[0]).format("yyyy-MM-DD HH:mm:ss");
    input.pop_up_end_date_time = moment(dateTimeRange[1]).format("yyyy-MM-DD HH:mm:ss");
  }
  return input
}

export function parsePopUpSettingsFromAPIResponse(data) {
  try {
    const settings = {};
    for (const s of data) {
      settings[s.slug] =  s.value;
    }
    return settings;
  } catch (err) {
    throw new Error("Invalid Settings data");
  }
}

export async function getGeneralSettings() {
  const r = await api.get(`/general_setting/show`);
  return parseSettingsFromAPIResponse(r.data.data);
}

export async function updateGeneralSettings(input) {
  try {
    const r = await api.post("/general_setting/update", input);
    return parseSettingsFromAPIResponse(r.data.data);
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getSocialSettings() {
  const r = await api.get(`/social_media/show`);
  return parseSettingsFromAPIResponse(r.data.data);
}

export async function updateSocialSettings(input) {
  try {
    const r = await api.post("/social_media/update", input);
    return parseSettingsFromAPIResponse(r.data.data);
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getPopUpSettings() {
  const r = await api.get(`/general_setting/popup`);
  return r.data.data;
}

export async function updatePopUpSettings(input) {
  try {
    const r = await api.post("/general_setting/updatePopup", processPopUpInput(input));
    return parsePopUpSettingsFromAPIResponse(r.data.data);
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getCommonSettings() {
  const r = await api.get(`/common_setting/show`);
  return parseSettingsFromAPIResponse(r.data.data);
}

export async function updateCommonSettings(input) {
  try {
    const r = await api.post("/common_setting/update", input);
    return parseSettingsFromAPIResponse(r.data.data);
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getHeaderSettings() {
  const r = await api.get(`/header_setting/show`);
  return parseSettingsFromAPIResponse(r.data.data);
}

function processHeaderSettingsInput(input) {
  return Object.fromEntries(
    Object.entries(input)
      .filter(([_, v]) => v != null && v !== "")
      .map(([k, v]) => [k, v === Object(v) ? processHeaderSettingsInput(v) : v])
  );
}

export async function updateHeaderSettings(input) {
  try {
    const r = await api.post("/header_setting/update", processHeaderSettingsInput(input));
    return parseSettingsFromAPIResponse(r.data.data);
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getFooterSettings(columnName) {
  const r = await api.get(`/footer_${columnName}_setting/show`);
  return parseSettingsFromAPIResponse(r.data.data);
}

function footerProcessInput(input) {
  return Object.entries(input).reduce((acc, [key, value]) => {
    acc[key] = value === '' ? null : value;
    return acc;
  }, {});
}

export async function updateFooterSettings(columnName, input) {
  try {
    let postData = footerProcessInput(input);
    postData = jsonToFormData(postData);
    const r = await api.post(`/footer_${columnName}_setting/update`, postData);
    return parseSettingsFromAPIResponse(r.data.data);
  } catch (err) {
    throw parseAPIError(err);
  }
}