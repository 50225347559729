import {useCallback, useEffect, useReducer, useState} from 'react'
import {usePagination} from "../../../hooks";
import filterReducers from "../../../reducers/filterReducer";
import {getPaginatedMedia, removeMedia} from "../../../services/media";

export function useMediaList() {
  const [mediaList, setMediaList] = useState(null)
  const {pagination, changePagination} = usePagination({
    page: 1,
    limit: 10,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [filter, filterDispatch] = useReducer(filterReducers, {});
  const [error, setError] = useState(false)

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true)
      const media = await getPaginatedMedia({
        page: pagination.page,
        ...filter,
      })
      setMediaList(media)
      setIsLoading(false)
    } catch (err) {
      setError({
        title: 'Something went wrong',
        message: err.message,
      })
    }
  }, [pagination, filter])

  const deleteMedia = async (id) => {
    try {
      await removeMedia(id)
      await fetch()
    } catch (err) {
      setError({
        title: 'Something went wrong',
        message: err.message,
      })
    }
  }

  useEffect(() => {
    fetch()
  }, [fetch])

  return {
    mediaList,
    pagination,
    changePagination,
    isLoading,
    error,
    filter,
    filterDispatch,
    deleteMedia
  }
}