import React from "react";
import {Badge, Card, Container, Image} from "react-bootstrap";
import {Checkbox, IconButton} from "rsuite";
import {Link} from "react-router-dom";
import {FaFile, FaLink, FaPencilAlt, FaTrashAlt} from "react-icons/fa";
import {getUrlFromStoragePath} from "../../configs";
import {defaultStyles, FileIcon} from "react-file-icon";
import "../../pages/MediaManagement/media.css";
import {useNotification} from "../../hooks/useNotification";
import MediaCard from "../../pages/MediaManagement/components/MediaCard";

function MediaCardList({selectedMedia, isDelete, isDeleteServer , handleRemove, handleServerRemove}) {
  if(isDelete) {
    return (
      <div className='d-flex gap-2'>
        {
          selectedMedia?.map((md) =>
            <MediaCard key={md.id} media={md} isDelete handleRemove={handleRemove}/>
          )
        }
      </div>
    );
  } else if(isDeleteServer) {
    return (
      <div className='d-flex gap-2'>
        {
          selectedMedia?.map((md) =>
            <MediaCard key={md.id} media={md} isDeleteServer handleServerRemove={handleServerRemove}/>
          )
        }
      </div>
    );
  }
}

export default MediaCardList;
