import { useLang, useTermList } from "../../../hooks";
import { useHeaderSettings } from "../hooks/useHeaderSettings";
import { useNotification } from "../../../hooks/useNotification";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { HeaderSettingsSchema } from "../schema";
import { useEffect } from "react";
import { InputValidationError } from "../../../libs/error";
import { Button, Col, Form } from "react-bootstrap";
import { ImageInput } from "../../../components/MediaInputs/imageInput";
import { getUrlFromStoragePath } from "../../../configs";
import { InputGroup, Placeholder, SelectPicker, Toggle, Dropdown } from "rsuite";
import { useNavigate } from "react-router-dom";
import { BsGlobe } from "react-icons/bs";
import { useMediaSelector } from "../../../components/MediaSelectorModal";
import MediaModal from "../../../components/MediaSelectorModal/MediaModal";
import MediaCardListSmall from "../../../components/MediaSelectorModal/MediaCardListSmall";
import ComponentSelectorModal from "../../../components/ComponentSelectorModal";
import LayoutCard from "../../../components/ComponentSelectorModal/LayoutCard";
import {LayoutInput} from "../../../components/MediaInputs/LayoutInput";

export function HeaderSettings({ onLanguageEdit }) {
  const { t } = useLang();
  const { settings, error, isLoading, save } = useHeaderSettings();
  const { notifyError, notifySuccess } = useNotification();
  const { termList, istTerm: isTermsLoading, error: termError } =
    useTermList(8, { page: 1, limit: 10000 });
  const navigate = useNavigate();
  const langData = ['Bangla', 'English'].map(
    item => ({ label: item, value: item })
  );

  const {
    selectedMedia,
    setSelectedMedia,
    checkValue,
    setCheckValue,
    allMediaId,
    setAllMediaId,
    selectedLayout,
    setSelectedLayout,
    handleRemoveLayout,
    handleRemove
  } = useMediaSelector();

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    control,
    setError
  } = useForm({
    resolver: yupResolver(HeaderSettingsSchema(t)),
    defaultValues: {
      status: false,
    },
  });

  if (error || termError) {
    notifyError(error ?? termError);
    navigate("/dashboard");
  }

  useEffect(() => {
    if (settings) {
      reset({
        site_address_header: settings.site_address_header?.value ?? "",
        site_email: settings.site_email?.value ?? "",
        site_phone: settings.site_phone?.value ?? "",
        alter_text: settings.alter_text?.value ?? "",
        button_title: settings.button_title?.value ?? "",
        button_link: settings.button_link?.value ?? "",
        main_menu_id: settings.main_menu_id?.value ?? "",
        mobile_menu_id: settings.mobile_menu_id?.value ?? "",
        select_search_type_id: settings.select_search_type_id?.value ?? "",
        ad_area: settings.ad_area?.value ?? "",
        header_code_area: settings.header_code_area?.value ?? "",
        social_link: settings.social_link?.value ?? "",
        scroller_content: settings.scroller_content?.value ?? "",
        language_selector: settings.language_selector?.value ?? "",
        is_frontend_user: Number(settings.is_frontend_user?.value) ? true : false,
        is_wishlist: Number(settings.is_wishlist?.value) ? true : false,
        is_cart: Number(settings.is_cart?.value) ? true : false,

      });
      let mediaId = settings?.logo?.value?.id ? [settings?.logo?.value?.id] : [];
      let media = settings?.logo?.value ? [settings?.logo?.value] : [];

      setSelectedMedia(media);
      setCheckValue(mediaId);
      setAllMediaId(mediaId);
      setSelectedLayout(settings?.header_layout_pack_id?.value);
    }
  }, [settings]);

  const handleUpdate = async (data) => {
    data.is_frontend_user = data.is_frontend_user ? '1' : '0';
    data.is_wishlist = data.is_wishlist ? '1' : '0';
    data.is_cart = data.is_cart ? '1' : '0';
    data = { ...data, logo: checkValue[0] ?? null, header_layout_pack_id: selectedLayout?.id ?? null }
    try {
      await save(data);
      notifySuccess({
        title: "Success",
        message: "Header Settings Updated Successfully"
      })
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input!",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Failed to update",
        message: err.message
      });
    }
  };

  return <section id="header-settings-form" className="my-4">
    <h2 className="mb-3">{t("settings_header")}</h2>
    {isLoading ?
      <Placeholder.Graph active /> :
      <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit(handleUpdate)}>
        <div className="form-row">
          <Form.Group as={Col} xl={3}>
            <Form.Label>{t("settings_site_logo")}</Form.Label>
            <div >
              <Controller
                control={control}
                name="image"
                render={({ field }) => (
                  <MediaModal isSingleMode selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                    checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                    {
                      selectedMedia?.length > 0 ? (
                        <MediaCardListSmall selectedMedia={selectedMedia} isDelete handleRemove={handleRemove} />
                      ) : (
                        <div style={{ height: "180px", width: "100%" }}>
                          <ImageInput
                            errorMessage={errors.image?.message}
                            onChange={field.onChange}
                            onError={() => {
                              setError("image", { message: "Invalid image file!" });
                            }}
                          />
                        </div>
                      )
                    }
                  </MediaModal>
                )}
              />
            </div>
          </Form.Group>
          <Col>
            <Form.Group className="flex-grow-1" controlId="site_address_header">
              <Form.Label className="mark-required">{t("settings_header_site_address")}</Form.Label>
              <InputGroup>
                <Form.Control {...register("site_address_header")} placeholder={t("settings_header_site_address_phld")}
                  type="text" />
                <Button onClick={() => {
                  onLanguageEdit(settings.site_address_header?.id);
                }}>
                  <BsGlobe />
                </Button>
              </InputGroup>
              {errors.site_address_header?.message && (
                <Form.Text className="text-danger">{errors.site_address_header.message}</Form.Text>)}
            </Form.Group>

            <Form.Group className="flex-grow-1" controlId="site_email">
              <Form.Label className="mark-required">{t("settings_header_email")}</Form.Label>
              <Form.Control {...register("site_email")} placeholder={t("settings_header_email_phld")}
                type="email" />
              {errors.site_email?.message && (
                <Form.Text className="text-danger">{errors.site_email.message}</Form.Text>)}
            </Form.Group>

            <Form.Group className="flex-grow-1" controlId="site_phone">
              <Form.Label className="mark-required">{t("settings_header_phone")}</Form.Label>
              <Form.Control {...register("site_phone")} placeholder={t("settings_header_phone_phld")}
                type="phone" />
              {errors.site_phone?.message && (
                <Form.Text className="text-danger">{errors.site_phone.message}</Form.Text>)}
            </Form.Group>
          </Col>
        </div>
        <div className="row">
          <div className="col-xl-3">
            <Form.Group controlId="layout_pack_id">
              <Form.Label
                className="mark-required">{t("components_create_selectIdentifier")}</Form.Label>
              <Controller
                control={control}
                name="layout_pack_id"
                render={({field}) => (
                  <ComponentSelectorModal isSingleMode selectedMedia={selectedLayout} setSelectedMedia={setSelectedLayout}>
                    {
                      selectedLayout ? (
                        <LayoutCard media={selectedLayout} isDelete handleRemove={handleRemoveLayout}/>
                      ) : (
                        <div style={{height: "300px", width: "100%"}}>
                          <LayoutInput
                            onChange={field.onChange}
                            onError={() => {
                              setError("image", {message: "Invalid image file!"});
                            }}
                          />
                        </div>
                      )
                    }
                  </ComponentSelectorModal>
                )
                } />
              {errors.layout_pack_id?.message && (
                <Form.Text className="text-danger">{errors.layout_pack_id.message}</Form.Text>)}
            </Form.Group>
          </div>
          <div className="col-xl-9">
            <Form.Group className="flex-grow-1" controlId="alter_text">
              <Form.Label>{t("settings_header_logo_alt_text")}</Form.Label>
              <InputGroup>
                <Form.Control {...register("alter_text")} placeholder={t("settings_header_logo_alt_text_phld")}
                              type="text" />
                <Button onClick={() => {
                  onLanguageEdit(settings.site_title?.id);
                }}>
                  <BsGlobe />
                </Button>
              </InputGroup>
              {errors.alter_text?.message && (
                <Form.Text className="text-danger">{errors.alter_text.message}</Form.Text>)}
            </Form.Group>
            <div className="form-row mt-2">
              <Form.Group as={Col} controlId="main_menu_id">
                <Form.Label>
                  {t("settings_header_main_menu")}
                </Form.Label>
                <div>
                  <Controller render={({field}) => <SelectPicker
                    onChange={field.onChange}
                    value={field.value}
                    style={{width: "100%"}}
                    data={termList?.terms.map(t => ({value: String(t.id), label: t.name}))}
                    placeholder={t("settings_header_main_placeholder")}
                    loading={isTermsLoading}
                  />
                  } name="main_menu_id" control={control} />
                </div>
                {errors.main_menu_id?.message && (
                  <Form.Text className="text-danger">
                    {errors.main_menu_id.message}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group as={Col} controlId="mobile_menu_id">
                <Form.Label>
                  {t("settings_header_mobile_menu")}
                </Form.Label>
                <div>
                  <Controller render={({field}) => <SelectPicker
                    onChange={field.onChange}
                    value={field.value}
                    style={{width: "100%"}}
                    data={termList?.terms.map(t => ({value: String(t.id), label: t.name}))}
                    placeholder={t("settings_header_menu_placeholder")}
                    loading={isTermsLoading}
                  />
                  } name="mobile_menu_id" control={control} />
                </div>
                {errors.mobile_menu_id?.message && (
                  <Form.Text className="text-danger">
                    {errors.mobile_menu_id.message}
                  </Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="form-row mt-2">
              <Form.Group as={Col} className="flex-grow-1" controlId="button_title">
                <Form.Label className="mark-required">{t("settings_header_button_txt")}</Form.Label>
                <InputGroup>
                  <Form.Control {...register("button_title")} placeholder={t("settings_header_button_txt_phld")}
                                type="phone" />
                  <Button onClick={() => {
                    onLanguageEdit(settings.site_title?.id);
                  }}>
                    <BsGlobe />
                  </Button>
                </InputGroup>
                {errors.button_title?.message && (
                  <Form.Text className="text-danger">{errors.button_title.message}</Form.Text>)}
              </Form.Group>
              <Form.Group as={Col} className="flex-grow-1" controlId="button_link">
                <Form.Label className="mark-required">{t("settings_header_button_link")}</Form.Label>
                <Form.Control {...register("button_link")} placeholder={t("settings_header_button_link_phld")}
                              type="phone" />
                {errors.button_link?.message && (
                  <Form.Text className="text-danger">{errors.button_link.message}</Form.Text>)}
              </Form.Group>
            </div>
            <div className="form-row mt-2">
              <Form.Group as={Col} className="flex-grow-1" controlId="select_search_type_id">
                <Form.Label className="">{t("settings_header_select_search_type_id")}</Form.Label>
                <Form.Control {...register("select_search_type_id")}
                              placeholder={t("settings_header_select_search_type_id_phld")}
                              type="phone" />
                {errors.select_search_type_id?.message && (
                  <Form.Text className="text-danger">{errors.select_search_type_id.message}</Form.Text>)}
              </Form.Group>
              <Form.Group as={Col} className="flex-grow-1" controlId="ad_area">
                <Form.Label className="">{t("settings_header_ad_area")}</Form.Label>
                <Form.Control {...register("ad_area")} placeholder={t("settings_header_ad_area_phld")}
                              type="phone" />
                {errors.ad_area?.message && (
                  <Form.Text className="text-danger">{errors.ad_area.message}</Form.Text>)}
              </Form.Group>
            </div>
          </div>
        </div>
        <div className="form-row">
          <Form.Group as={Col} className="flex-grow-1" controlId="header_code_area">
            <Form.Label className="">{t("settings_header_header_code_area")}</Form.Label>
            <Form.Control {...register("header_code_area_id")}
                          placeholder={t("settings_header_header_code_area_id_phld")}
                          type="phone" />
            {errors.header_code_area?.message && (
              <Form.Text className="text-danger">{errors.header_code_area.message}</Form.Text>)}
          </Form.Group>
          <Form.Group as={Col} className="flex-grow-1" controlId="social_link">
            <Form.Label className="">{t("settings_header_social_link")}</Form.Label>
            <Form.Control {...register("social_link")} placeholder={t("settings_header_social_link_phld")}
                          type="phone" />
            {errors.social_link?.message && (
              <Form.Text className="text-danger">{errors.social_link.message}</Form.Text>)}
          </Form.Group>
        </div>
        <div className="form-row">
          <Form.Group as={Col} className="flex-grow-1" controlId="language_selector">
            <Form.Label className="form-label">{t("settings_header_language_selector")}</Form.Label>
            <Controller
              control={control}
              name="language_selector"
              render={({ field }) => (
                <SelectPicker
                  block
                  {...field}
                  data={langData}
                  searchable={false}
                  size="lg"
                  value={field.value}
                  placeholder={t("settings_header_menu_placeholder")}
                  title={field.value ? (field.value === 'bangla' ? 'Bangla' : 'English') : t("settings_header_menu_placeholder")}
                  onSelect={(value) => field.onChange(value)}
                />

              )}
            />
            {errors.language_selector?.message && (
              <Form.HelpText className="text-danger">{errors.language_selector.message}</Form.HelpText>
            )}
          </Form.Group>
          <Form.Group as={Col} className="flex-grow-1" controlId="scroller_content">
            <Form.Label className="">{t("settings_header_scroller_content")}</Form.Label>
            <Form.Control {...register("scroller_content")} placeholder={t("settings_header_scroller_content_phld")}
              type="phone" />
            {errors.scroller_content?.message && (
              <Form.Text className="text-danger">{errors.scroller_content.message}</Form.Text>)}
          </Form.Group>
        </div>
        <div className="form-row">
          <Form.Group as={Col} controlId="is_frontend_user">
            <Form.Label>{t("settings_header_is_frontend_user")}</Form.Label>
            <Controller
              control={control}
              name="is_frontend_user"
              render={({ field }) => (
                <div>
                  <Toggle
                    {...field}
                    checked={field.value}
                    size="lg"
                    checkedChildren={<span>True</span>}
                    unCheckedChildren={<span>False</span>}
                    className="m-auto"
                  />
                </div>
              )}
            />
            {errors.is_frontend_user?.message && <Form.Text className="text-danger"> {errors.is_frontend_user.message} </Form.Text>}
          </Form.Group>
          <Form.Group as={Col} controlId="is_wishlist">
            <Form.Label>{t("settings_header_is_wishlist")}</Form.Label>
            <Controller
              control={control}
              name="is_wishlist"
              render={({ field }) => (
                <div>
                  <Toggle
                    {...field}
                    size="lg"
                    checked={field.value}
                    checkedChildren={<span>True</span>}
                    unCheckedChildren={<span>False</span>}
                    className="m-auto"
                  />
                </div>
              )}
            />
            {errors.is_wishlist?.message && <Form.Text className="text-danger"> {errors.is_wishlist.message} </Form.Text>}
          </Form.Group>
          <Form.Group as={Col} controlId="is_cart">
            <Form.Label>{t("settings_header_is_cart")}</Form.Label>
            <Controller
              control={control}
              name="is_cart"
              render={({ field }) => (
                <div>
                  <Toggle
                    {...field}
                    checked={field.value}
                    size="lg"
                    checkedChildren={<span>True</span>}
                    unCheckedChildren={<span>False</span>}
                    className="m-auto"
                  />
                </div>
              )}
            />
            {errors.is_cart?.message && <Form.Text className="text-danger"> {errors.is_cart.message} </Form.Text>}
          </Form.Group>
        </div>
        <div className="d-flex justify-content-end gap-2">
          <Button className="px-4" variant="success" type="submit">
            {t("common_settings_save")}
          </Button>
        </div>
      </Form>
    }

  </section>;
}