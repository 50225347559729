import {printValue} from 'yup';

export const mixed = {
    default: '${path} সঠিক নয়',
    required: '${path} একটি প্রয়োজনীয় ফিল্ড',
    defined: '${path} অবশ্যই পূরণ করতে হতে হবে',
    notNull: '${path} খালি হতে পারবে না',
    oneOf: '${path} এর মান অবশ্যই ${values} এর যেকোনো একটি হতে হবে',
    notOneOf: '${path} এর মান ${values} এগুলোর মধ্যে হতে পারবে না:',
    notType: ({path, type, value, originalValue}) => {
        const isCast = originalValue != null && originalValue !== value;
        let msg =
            `${path} অবশ্যই \`${type}\` টাইপ হতে হবে, ` +
            `তবে চূড়ান্ত মান ছিল: \`${printValue(value, true)}\`` +
            (isCast
                ? ` (যা \`${printValue(originalValue, true)}\` থেকে কাস্ট করা হয়েছে).`
                : '.');

        if (value === null) {
            msg +=
                `\n যদি "null" খালি মান হিসেবে বিবেচ্য হয় তের অবশ্যই স্কিমায় ডিফাইন করে দিতে হবে: ` +
                '`.nullable()`';
        }

        return msg;
    },
};
export const string = {
    length: '${path} অবশ্যই ${length} অক্ষর হতে হবে',
    min: '${path} অবশ্যই কমপক্ষে ${min} অক্ষর হতে হবে',
    max: '${path} অবশ্যই সর্বাধিক ${max} অক্ষর হতে হবে',
    matches: '${path} অবশ্যই এই রিগেক্সটির "${regex}" সাথে মিলতে হবে',
    email: '${path} একটি সঠিক ইমেইল হতে হবে',
    url: '${path} একটি সঠিক URL হতে হবে',
    uuid: '${path} একটি সঠিক UUID হতে হবে',
    trim: '${path} একটি ট্রিমড স্ট্রিং হতে হবে',
    lowercase: '${path} একটি ছোট হাতের অক্ষরের হতে হবে',
    uppercase: '${path} একটি বড় হাতের অক্ষরের হতে হবে',
};
export const number = {
    min: '${path} অবশ্যই ${min} বা তার বেশি হতে হবে',
    max: '${path} অবশ্যই ${max} বা তার কম হতে হবে',
    lessThan: '${path} অবশ্যই ${less} এর কম হতে হবে',
    moreThan: '${path} অবশ্যই ${more} এর বেশি হতে হবে',
    positive: '${path} অবশ্যই একটি ধনাত্মক সংখ্যা হতে হবে',
    negative: '${path} অবশ্যই একটি ঋনাত্নক সংখ্যা হতে হবে',
    integer: '${path} অবশ্যই একটি পূর্ণসংখ্যা হতে হবে',
};
export const date = {
    min: '${path} ফিল্ড অবশ্যই ${min} এর পরে হতে হবে',
    max: '${path} ফিল্ড অবশ্যই ${max} এর আগে হতে হবে',
};
export const object = {
    noUnknown: '${path} ফিল্ডটির কি অজানা: ${unknown}',
};
export const array = {
    min: '${path} ফিল্ডটিতে অবশ্যই কমপক্ষে ${min} আইটেম থাকতে হবে',
    max: '${path} ফিল্ডটিতে অবশ্যই ${max} এর চেয়ে বেশি অথবা সমান আইটেম থাকতে হবে',
    length: '${path} এ অবশ্যই ${length} টি আইটেম থাকতে হবে',
};
export const boolean = {
    isValue: '${path} ফিল্ড অবশ্যই ${value} হতে হবে',
};