import {useEffect, useReducer, useState} from "react";
import {usePagination} from "./usePagination";
import filterReducers from "../reducers/filterReducer";
import {getPaginatedUser} from "../services/user";

export function useUserList() {
  const [userList, setUserList] = useState({
    users: [],
    total: 0
  });
  const {pagination, changePagination} = usePagination({page: 1, limit: 10});
  const [filter, filterDispatch] = useReducer(filterReducers, {});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setIsLoading(true);
        const users = await getPaginatedUser({
          page: pagination.page,
          ...filter
        });
        setUserList({
          users: users.data,
          total: users.total
        });
        setIsLoading(false);
      } catch (err) {
        setError({
          title: "Failed to fetch user!",
          message: err.message
        });
      }
    };

    fetchUser();

  }, [pagination, filter]);


  return {userList, pagination, filterDispatch, changePagination, isLoading, error};
}

