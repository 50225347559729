import {yupResolver} from "@hookform/resolvers/yup";
import {useState} from "react";
import {Breadcrumb, Button, Card, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {Notification, TagInput, useToaster} from "rsuite";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {DefaultDescriptionValidation, DefaultNameValidation, SeoSchema,} from "../../configs/constants";
import {useLang, usePrivateAPI} from "../../hooks";
import {useError} from "../../hooks/useError";
import {BackButton} from "../../components/BackButton/BackButton";
import {MenuCreateSchema} from "./schema";
import {processSeoInput} from "../../services/seo";
import {createTerm} from "../../services/term";
import {InputValidationError} from "../../libs/error";
import {useNotification} from "../../hooks/useNotification";

export function NewMenuPage() {
    const {t} = useLang();
    const [isLoading, setIsLoading] = useState(false);
    const { notifySuccess, notifyError } = useNotification()
    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
        setError
    } = useForm({
        resolver: yupResolver(MenuCreateSchema(t)),
    });
    const navigate = useNavigate();

    const createMenu = async (data) => {
        try {
            setIsLoading(true);
            const t = await createTerm({...data, taxonomy_id: 8});
            notifySuccess({
                title: "Menu created successfully",
                message: `Menu "${t.name}" created!`,
            });
            navigate(`/menus/manage`);
        } catch (err) {
            if (err instanceof InputValidationError) {
                err.setRHFError(setError);
                notifyError({
                    title: "Input validation failed",
                    message: err.message,
                });
                return;
            }
            notifyError({
                title: "Something went wrong",
                message: err.message,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Layout>
            {isLoading && <CircleLoader/>}
            <header>
                <div className="d-flex justify-content-between align-items-center">
                    <Breadcrumb className="d-flex align-items-center">
                        <li className="breadcrumb-item">
                            <Link to={`/menus/manage`}>{t("menu_manageMenu")}</Link>
                        </li>
                        <Breadcrumb.Item active>
                            {t("menu_create_createNewMenu")}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <BackButton path={-1} text={t("menu_manageMenu")}/>
                </div>
            </header>
            <h2>{t("menu_create_createNewMenu")}</h2>
            <section id="createMenuForm" className="pr-md-5 mr-md-5">
                <Form
                    onSubmit={handleSubmit(createMenu)}
                    className="mt-4 d-flex flex-column gap-3"
                >
                    <Form.Group controlId="name">
                        <Form.Label className="mark-required">{t("menu_create_name")}</Form.Label>
                        <Form.Control
                            {...register("name")}
                            placeholder={t("menu_create_name_plhd")}
                            type="text"
                        />
                        {errors.name?.message && (
                            <Form.Text className="text-danger">
                                {errors.name.message}
                            </Form.Text>
                        )}
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>{t("menu_create_description")}</Form.Label>
                        <Form.Control
                          {...register("description")}
                          placeholder={t("menu_create_description_plhd")}
                          as="textarea"
                          rows={3}
                        />
                        {errors.description?.message && (
                          <Form.Text className="text-danger">
                              {errors.description.message}
                          </Form.Text>
                        )}
                    </Form.Group>
                    <h3>SEO</h3>
                    <Form.Group controlId="metaTitle">
                        <Form.Label>{t("menu_create_metaTitle")}</Form.Label>
                        <Form.Control
                          {...register("meta_title")}
                          placeholder={t("menu_create_metaTitle_plhd")}
                          type="text"
                        />
                        {errors.meta_title?.message && (
                          <Form.Text className="text-danger">
                              {errors.meta_title.message}
                          </Form.Text>
                        )}
                    </Form.Group>
                    <Form.Group controlId="metaTags">
                        <Form.Label>{t("menu_create_metaTags")}</Form.Label>
                        <Controller
                          control={control}
                          name="meta_tag"
                          render={({field}) => (
                            <TagInput
                              {...field}
                              placeholder={t("menu_create_metaTags_plhd")}
                              style={{borderColor: "#ced4da"}}
                              className="d-block"
                            />
                          )}
                        />
                        {(errors.meta_tag?.message || Array.isArray(errors.meta_tag)) && (
                          <Form.Text className="text-danger">
                              {errors?.meta_tag?.message ??
                                errors.meta_tag.find(Boolean).message}
                          </Form.Text>
                        )}
                    </Form.Group>
                    <Form.Group controlId="metaDescription">
                        <Form.Label>{t("menu_create_metaDescription")}</Form.Label>
                        <Form.Control
                          {...register("meta_description")}
                          as="textarea"
                          rows={3}
                          placeholder={t("menu_create_metaDescription_plhd")}
                        />
                        {errors.meta_description?.message && (
                          <Form.Text className="text-danger">
                              {errors.meta_description.message}
                          </Form.Text>
                        )}
                    </Form.Group>
                    <div className="d-flex justify-content-end">
                        <Button className="px-4" type="submit">
                            {t("menu_create_create")}
                        </Button>
                    </div>
                </Form>
            </section>
        </Layout>
    );
}
