import * as yup from "yup";
import {DefaultDescriptionValidation, DefaultLinkValidation, DefaultNameValidation} from "../../configs/constants";

export const MediaCreateSchema = (t) => yup.object({
  media_category_id: yup.number().required().label(t("media_input_selectMediaType")),
  title: DefaultNameValidation().required().label(t("media_input_title")),
  alt_text: yup.string().max(300).label(t("media_input_alternativeText")),
  description: DefaultDescriptionValidation().label(t("media_input_description")),
  image: yup.mixed().label(t("media_input_selectImage")).when("media_category_id", (values, schema) => {
    if (values[0] === 1) return schema.required();
    else return schema;
  }),
  video: yup.mixed().label(t("media_input_selectVideo")).when("media_category_id", (values, schema) => {
    if (values[0] === 2) return schema.required();
    else return schema;
  }),
  document: yup.mixed().label(t("media_input_selectDocument")).when("media_category_id", (values, schema) => {
    if (values[0] === 3) return schema.required();
    else return schema;
  }),
  external_url: DefaultLinkValidation().label(t("media_input_selectUrl")).when("media_category_id", (values, schema) => {
    if (values[0] === 4) return schema.required();
    else return schema;
  })
});

export const MediaEditSchema = (t) => MediaCreateSchema(t).omit(["image", "video", "document", "external_url"]);


