import {useEffect, useState} from "react";
import {
  getComponent,
  getComponentLayout,
  removeComponentLanguageMedia,
  removeComponentMedia,
  updateComponent, updateComponentLayout
} from "../services/component";
import {removeEventMedia} from "../services/event";

export default function useLayout(id) {
  const [layout, setLayout] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const fetchLayout = async () => {
    try {
      setIsLoading(true);
      setLayout(await getComponentLayout(id));
    } catch (err) {
      setError({
        title: "Failed to fetch component!",
        message: err.message
      });
    } finally {
      setIsLoading(false);
    }
  };

  async function editLayout(data, id) {
    try {
      setIsLoading(true);
      setLayout(await updateComponentLayout(data, id));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchLayout();
  }, []);

  return {layout, isLoading, setIsLoading, editLayout, error};
}
