import {useEffect, useState} from "react";
import {getUser, updateUser} from "../services/user";

export default function useUser(id) {
  const [user, setUser] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUser = async () => {
    try {
      setIsLoading(true);
      setUser(await getUser(id));
    } catch (err) {
      setError({
        title: "Failed to fetch user!",
        message: err.message
      });
    } finally {
      setIsLoading(false);
    }
  };

  async function editUser(data) {
    try {
      setIsLoading(true);
      setUser(await updateUser(id, data));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchUser();
  }, []);

  return {user, isLoading, editUser, error};
}
