import {api, parseAPIError} from "./api";
import {processSeoInput} from "./seo";
import moment from "moment/moment";
import {jsonToFormData} from "../helpers";

export function processPostInput(input) {
  input = processSeoInput(input)
  input.is_show = input.is_show ? 1 : 0;
  input.post_date = moment(input.post_date).format("yyyy-MM-DD");
  return input
}

export async function createPost(input) {
  try {
    let data = processPostInput(input);
    data = jsonToFormData(data);
    const r = await api.post("/post/store", data);
    return r.data.data
  } catch (err) {
    throw parseAPIError(err)
  }
}

export async function getPost(id) {
  const r = await api.get(`/post/get/${id}`);
  return r.data.data
}

export async function updatePost(id, input) {
  try {
    let data = processPostInput(input);
    data = jsonToFormData(data);
    const r = await api.post(`/post/update/${id}`, data);
    return r.data.data
  } catch (err) {
    throw parseAPIError(err)
  }
}

export async function getPaginatedPost(options) {
  const r = await api.get("/post/list", {
    params: options
  })
  if (r.data.data) {
    return r.data.data
  }
  return {
    data: [],
    total: 0,
  }
}

export async function removePost(id) {
  const r = await api.delete(`/post/delete/${id}`);
  return r.data.data
}

export async function removePostMedia(id, data) {
  let processData = jsonToFormData(data)
  const r = await api.post(`/post/delete-media-id/${id}`, processData);
  return r.data;
}
