import Layout from "../../components/layouts/layout";
import {Link, useNavigate, useParams} from "react-router-dom";
import { useState, useEffect } from "react";
import * as yup from "yup";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import { TagInput, useToaster, Notification } from "rsuite";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import { useError } from "../../hooks/useError";
import {useLang, usePrivateAPI, useTerm} from "../../hooks";
import {BackButton} from "../../components/BackButton/BackButton";
import {MenuEditSchema} from "./schema";
import {processSeoInput} from "../../services/seo";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

export function EditMenuPage() {
  const {t} = useLang();
  const { id } = useParams();
  const {term, editTerm, isLoading, error} = useTerm(id);
  const { notifyError, notifySuccess } = useNotification();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    setError
  } = useForm({
    resolver: yupResolver(MenuEditSchema(t)),
  });

  useEffect(() => {
    if (term) {
      setValue("name", term.name ?? "");
      setValue("description", term.description ?? "");
      setValue("meta_title", term.meta_title ?? "");
      setValue("meta_tag", term.meta_tag?.split(",") ?? []);
      setValue("meta_description", term.meta_description ?? "");
    }
  }, [term]);

  if (errors) {
    console.log(errors);
  }

  const handleMenuEdit = async (data) => {
    try {
      await editTerm({...data, taxonomy_id: 8});
      notifySuccess({
        title: `Menu "${term.name}" updated successfully`,
      });
      navigate(`/menus/manage`);
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Failed to update Menu!",
        message: err.message,
      });
    }
  };

  return (
      <Layout>
        {isLoading && <CircleLoader/>}
        <header>
          <div className="d-flex justify-content-between align-items-center">
            <Breadcrumb className="d-flex align-items-center">
              <li className="breadcrumb-item">
                <Link to={`/menus/manage`}>{t("menu_manageMenu")}</Link>
              </li>
              <Breadcrumb.Item active>
                {t("menu_edit_editMenu")}
              </Breadcrumb.Item>
            </Breadcrumb>
            <BackButton path={-1} text={t("menu_manageMenu")}/>
          </div>
        </header>
        <h2>{t("menu_edit_editMenu")}</h2>
        <section id="createMenuForm" className="pr-md-5 mr-md-5">
          <Form
              onSubmit={handleSubmit(handleMenuEdit)}
              className="mt-4 d-flex flex-column gap-3"
          >
            <Form.Group controlId="name">
              <Form.Label className="mark-required">{t("menu_edit_name")}</Form.Label>
              <Form.Control
                  {...register("name")}
                  placeholder={t("menu_edit_name_plhd")}
                  type="text"
              />
              {errors.name?.message && (
                  <Form.Text className="text-danger">
                    {errors.name.message}
                  </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>{t("menu_edit_description")}</Form.Label>
              <Form.Control
                {...register("description")}
                placeholder={t("menu_edit_description_plhd")}
                as="textarea"
                rows={3}
              />
              {errors.description?.message && (
                <Form.Text className="text-danger">
                  {errors.description.message}
                </Form.Text>
              )}
            </Form.Group>
            <h3>SEO</h3>
            <Form.Group controlId="metaTitle">
              <Form.Label>{t("menu_edit_metaTitle")}</Form.Label>
              <Form.Control
                  {...register("meta_title")}
                  placeholder={t("menu_edit_metaTitle_plhd")}
                  type="text"
              />
              {errors.meta_title?.message && (
                  <Form.Text className="text-danger">
                    {errors.meta_title.message}
                  </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="metaTags">
              <Form.Label>{t("menu_edit_metaTags")}</Form.Label>
              <Controller
                  control={control}
                  name="meta_tag"
                  render={({field}) => (
                      <TagInput
                          {...field}
                          placeholder={t("menu_edit_metaTags_plhd")}
                          style={{borderColor: "#ced4da"}}
                          className="d-block"
                      />
                  )}
              />
              {(errors.meta_tag?.message || Array.isArray(errors.meta_tag)) && (
                  <Form.Text className="text-danger">
                    {errors?.meta_tag?.message ??
                        errors.meta_tag.find(Boolean).message}
                  </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="metaDescription">
              <Form.Label>{t("menu_edit_metaDescription")}</Form.Label>
              <Form.Control
                  {...register("meta_description")}
                  as="textarea"
                  rows={3}
                  placeholder={t("menu_edit_metaDescription_plhd")}
              />
              {errors.meta_description?.message && (
                  <Form.Text className="text-danger">
                    {errors.meta_description.message}
                  </Form.Text>
              )}
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button className="px-4" type="submit">
                {t("menu_edit_update")}
              </Button>
            </div>
          </Form>
        </section>
      </Layout>
  );
}
