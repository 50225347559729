import {api, parseAPIError} from "./api";

export function processUserInput(input) {
  return {...input, password: input.phone};
}

export async function createUser(input) {
  try {
    const r = await api.post("/users/add", processUserInput(input));
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getUser(id) {
  const r = await api.get(`/users/get/${id}`);
  return r.data.data;
}

export async function updateUser(id, input) {
  try {
    const r = await api.post(`/users/update/${id}`, processUserInput(input));
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getPaginatedUser(options) {
  const r = await api.get("/users/list", {
    params: options
  });
  if (r.data.data) {
    return r.data.data;
  }
  return {
    data: [],
    total: 0
  };
}