import {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import { Container } from "react-bootstrap";
import "../../pages/MediaManagement/media.css";
import {useLang} from "../../hooks";
import CircleLoader from "../Loaders/CircleLoader";
import LayoutCard from "./LayoutCard";
import {usePaginatedList} from "../../hooks/usePaginatedList";
import {getPaginatedLayout} from "./service";
import SearchAndFilterLayout from "./SearchAndFilterLayout";

function LayoutManager({
                        selectedMedia, setSelectedMedia, isSingleMode, checkValue, setCheckValue,
                        handleMediaModalClose, type
                      }) {

  const {t} = useLang();
  const {
    list,
    changePagination,
    error,
    isLoading,
    pagination,
    filterDispatch,
    removeItem
  } = usePaginatedList(
    getPaginatedLayout,
    () => {},
    {"component_type" : type }
  );

  //CheckBox Consts
  const handlePageClick = (data) => {
    changePagination({...pagination, page: data.selected +1});
  };
  const handleSelect = (media)  => {
    setSelectedMedia(media);
    handleMediaModalClose();
  }

  useEffect(()=>{
    console.log(list);
  },[list])

  return (
    <>
      {isLoading && <CircleLoader/>}
      <SearchAndFilterLayout filterDispatch={filterDispatch} changePagination={changePagination}/>
        <Container>
          <div className="d-flex flex-wrap gap-2 justify-content-center">
            {list?.data?.data?.map((md) => (
              <LayoutCard media={md} handleOpen={handleSelect} setSelectedMedia={setSelectedMedia}/>
            ))}
          </div>
        </Container>
      <div>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={list?.data?.last_page}
          marginPagesDisplayed={3}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center m-0"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
}

export default LayoutManager;
