import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build";
import { forwardRef } from "react";

export const TextEdit = forwardRef(({ defaultValue, onChange, onBlur, onFocus }, ref) => 
  (
      <CKEditor
        editor={ClassicEditor}
        data={defaultValue ?? ""}
        onChange={(e, editor) => {
          if (onChange) {
            onChange(editor.getData());
          }
        }}
        ref={ref}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    ));
