import * as yup from "yup";
import {
  DefaultDescriptionValidation,
  defaultImageValidation,
  DefaultLinkValidation,
  DefaultNameValidation,
  languageSchema
} from "../../configs/constants";

export const ComponentInputSchema = (t) =>  yup.object({
  title: DefaultNameValidation().required().max(512).label(t("title")),
  subTitle: yup.string().nullable().label(t("subtitle")),

  description1: yup.string().label(`${t("description")} ${t("1")}`).nullable(),
  description2: yup.string().label(`${t("description")} ${t("2")}`).nullable(),

  image1: defaultImageValidation.nullable().label(`${t("image")} ${t("1")}`),
  image2: defaultImageValidation.nullable().label(`${t("image")} ${t("2")}`),
  image3: defaultImageValidation.nullable().label(`${t("image")} ${t("3")}`),

  link1: yup.string().nullable().label(`${t("link")} ${t("1")}`),
  link2: yup.string().nullable().label(`${t("link")} ${t("2")}`),
  link3: yup.string().nullable().label(`${t("link")} ${t("3")}`),

  youTubeID: yup.string().nullable().max(200).label("YouTubeID"),

  type_id: yup.string().required().label("components Type"),
  identifier_id: yup.string().nullable().when("type_id", (values, schema) => {
    // if (values[0] === "12") {
    //   return schema.required();
    // }
    // console.log('MYYYYYY', values[0]);
    return schema;
  }).label("Identifier ID"),
});


export function ComponentLanguageSchema() {
  return languageSchema.shape({
    image: defaultImageValidation,
    title: DefaultNameValidation().required(),
    description: DefaultDescriptionValidation()
  }).label("Identifier ID");
}


export const ComponentLayoutInputSchema = (t) => yup.object({
  // name: DefaultNameValidation().required().max(255).label(t('subscriber_name')),
  // component_type: yup.string().required().label("Components Type"),
  // image: defaultImageValidation.nullable().label(`${t("image")} ${t("1")}`),
  // status: yup.boolean().label(t("subscriber_status")).default(false)
});