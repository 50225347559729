import Layout from "../../components/layouts/layout";
import { Breadcrumb, Button, Col, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProductInputSchema } from "./schema";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLang, useProductCategories } from "../../hooks";
import { useNotification } from "../../hooks/useNotification";
import CircleLoader from "../../components/Loaders/CircleLoader";
import { CheckTreePicker, TagInput, TreePicker } from 'rsuite';
import { InputValidationError } from "../../libs/error";
import "./Product.css";
import { BackButton } from "../../components/BackButton/BackButton";
import { createProduct } from "../../services/product";
import { ImageInput } from "../../components/MediaInputs/imageInput";
import { useProductAttributes } from "../../hooks"
import { useMediaSelector } from "../../components/MediaSelectorModal";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";
import MediaCardList from "../../components/MediaSelectorModal/MediaCardList";

export function ProductCreatePage() {
  const { t } = useLang();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useNotification();

  const { attributes, isLoading: issAttrLoading, error: errAtt } = useProductAttributes()
  const { categories, isLoading: isCatLoading, error: errCat } = useProductCategories()

  const {
    selectedMedia,
    setSelectedMedia,
    checkValue,
    setCheckValue,
    allMediaId,
    setAllMediaId,
    handleRemove
  } = useMediaSelector();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError
  } = useForm({
    resolver: yupResolver(ProductInputSchema(t)),
  });

  if (errAtt || errCat) {
    notifyError(errAtt ?? errCat)
    navigate(-1)
  }

  const create = async (data) => {
    try {
      setIsLoading(true);
      let productData = { ...data, media_id: checkValue }
      const product = await createProduct(productData)
      notifySuccess({
        title: "Product created successfully",
        message: `Product ${product.name} created!`,
      });
      navigate("/products/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Layout>
      {isLoading && <CircleLoader />}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/products/manage`}> {t("products_manage_products")}</Link>
            </li>
            <Breadcrumb.Item active>
              {t("products_create_createNewProduct")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("products_manage_products")} />
        </div>
      </header>
      <section id="#taxonomy-create">
        <h2>{t("products_create_createNewProduct")}</h2>
      </section>
      <Form onSubmit={handleSubmit(create)} className="container-fluid mt-4">
        <div className="row">
          <Form.Group className="col-md-6 overflow-auto">
            <Form.Label>{t("products_create_image")}</Form.Label>
            <Controller
              control={control}
              name="image"
              render={({ field }) => (
                <MediaModal selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                  checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                  {
                    selectedMedia?.length > 0 ? (
                      <MediaCardList selectedMedia={selectedMedia} isDelete handleRemove={handleRemove} />
                    ) : (
                      <div style={{ height: "300px", width: "100%" }}>
                        <ImageInput
                          errorMessage={errors.image?.message}
                          onChange={field.onChange}
                          onError={() => {
                            setError("image", { message: "Invalid image file!" });
                          }}
                        />
                      </div>
                    )
                  }
                </MediaModal>
              )}
            />
          </Form.Group>
          <div className="col-md-6">
            <Form.Group className="flex-grow-1" controlId="name">
              <Form.Label className="mark-required">{t("products_create_name")}</Form.Label>
              <Form.Control {...register("name")} placeholder={t("products_create_name_plhd")} type="text" />
              {errors.name?.message && (
                <Form.Text className="text-danger">{errors.name.message}</Form.Text>)}
            </Form.Group>
            <Form.Group className="flex-grow-1 mt-2" controlId="code">
              <Form.Label className="mark-required">{t("products_create_code")}</Form.Label>
              <Form.Control {...register("code")} placeholder={t("products_create_code_plhd")}
                type="number" />
              {errors.code?.message && (
                <Form.Text className="text-danger">{errors.code.message}</Form.Text>)}
            </Form.Group>
            <Form.Group className="flex-grow-1 mt-2" controlId="description">
              <Form.Label>{t("products_create_description")}</Form.Label>
              <Form.Control {...register("description")} placeholder={t("products_create_description_plhd")}
                as="textarea" rows={3} />
              {errors.description?.message && (
                <Form.Text className="text-danger">{errors.description.message}</Form.Text>)}
            </Form.Group>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-6 col-lg-6">
            <Form.Group className="flex-grow-1" controlId="categoryId">
              <Form.Label className="mark-required">{t("products_create_category")}</Form.Label>
              <Controller control={control} name="categoryId"
                render={({ field }) =>
                (<TreePicker loading={isCatLoading} {...field} defaultExpandAll data={categories} block
                  searchable={false}
                  placeholder="Select Categories" />)} />
              {errors.categoryId?.message && (
                <Form.Text className="text-danger">{errors.categoryId.message}</Form.Text>)}
            </Form.Group>
          </div>
          <div className="col-sm-6 col-lg-6">
            <Form.Group className="flex-grow-1" controlId="attribute_id">
              <Form.Label className="mark-required">{t("products_create_attributes")}</Form.Label>
              <Controller control={control} name="attribute_id"
                render={({ field }) =>
                (<CheckTreePicker loading={issAttrLoading} {...field} defaultExpandAll data={attributes}
                  block
                  searchable={false}
                  placeholder="Select Attributes" cascade={false} />)} />
              {errors.attribute_id?.message && (
                <Form.Text className="text-danger">{errors.attribute_id.message}</Form.Text>)}
            </Form.Group>
          </div>
        </div>

        <div className="row mt-2">
          <h3>SEO</h3>
          <hr />
          <div className="col-sm-6 col-lg-6">
            <Form.Group className="flex-grow-1" controlId="name">
              <Form.Label>{t("products_create_metaTitle")}</Form.Label>
              <Form.Control {...register("meta_title")} placeholder={t("products_create_metaTitle_plhd")}
                type="text" />
              {errors.meta_title?.message && (
                <Form.Text className="text-danger">{errors.meta_title.message}</Form.Text>)}
            </Form.Group>
          </div>
          <div className="col-sm-6 col-lg-6">
            <Form.Group controlId="metaTags">
              <Form.Label>{t("products_create_metaTags")}</Form.Label>
              <Controller
                control={control}
                name="meta_tag"
                render={({ field }) => (
                  <TagInput
                    {...field}
                    placeholder={t("products_create_metaTags_plhd")}
                    style={{ borderColor: "#ced4da" }}
                    className="d-block"
                  />
                )}
              />
              {(errors.meta_tag?.message || Array.isArray(errors.meta_tag)) && (
                <Form.Text className="text-danger">
                  {errors?.meta_tag?.message ??
                    errors.meta_tag.find(Boolean).message}
                </Form.Text>
              )}
            </Form.Group>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-12 col-lg-12">
            <Form.Group className="flex-grow-1" controlId="name">
              <Form.Label>{t("products_create_metaDescription")}</Form.Label>
              <Form.Control {...register("meta_description")}
                placeholder={t("products_create_metaDescription_plhd")}
                as="textarea" rows={3} />
              {errors.meta_description?.message && (
                <Form.Text className="text-danger">{errors.meta_description.message}</Form.Text>)}
            </Form.Group>
          </div>
        </div>
        <div className="row mt-2">
          <div className="d-flex justify-content-end">
            <Button variant="primary" type="submit">{t("products_create_save")}</Button>
          </div>
        </div>
      </Form>
    </Layout>
  );
}