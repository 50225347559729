import * as yup from "yup";
import {defaultImageValidation, languageSchema, SeoSchema,} from "../../configs/constants";

export const TestimonialInputSchema = (t) => {
  return yup.object({
    name: yup.string().required().max(255).label(t("testimonial_input_name")),
    description: yup.string().required().label(t("testimonial_input_description")),
    image: defaultImageValidation.label(t("testimonial_input_image")),
    designation: yup.string().required().max(255).label(t("testimonial_input_designation")),
    rating: yup.number().max(5).required().label(t("testimonial_input_rating")),
    category_id: yup.number().min(1, "Please select a post category").required().label(t("testimonial_input_category")),
  });
}
