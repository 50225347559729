import {api} from "../../services/api";

export async function getPaginatedLayout(options) {
  const r = await api.get("/layout_packs/show", {
    params: options
  });
  if (r.data) {
    return r.data;
  }
  return {
    data: [],
    total: 0
  };
}