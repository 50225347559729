import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {SelectPicker} from "rsuite";
import Cookies from "universal-cookie";
import Layout from "../../components/layouts/layout";
import loginCheck from "../../helpers/loginCheck";
import http from "../../http";
import "./role.css";
import {useLang} from "../../hooks";
import {BackButton} from "../../components/BackButton/BackButton";

const RoleAssignToUser = () => {
  const {register, handleSubmit} = useForm();
  const [data, setData] = useState("");
  const [roles, setRoles] = useState("");
  const [keyss, setKeys] = useState([]);
  const [valuess, setvalues] = useState([]);
  const [getSuccess, setSuccess] = useState(false);
  const [getError, setError] = useState("");
  const [getErrorObj, setErrorObj] = useState({});
  const [users, setUsers] = useState([]);
  const [getLoader, setLoader] = useState(false);
  const cookies = new Cookies();
  const {id} = useParams();
  const {t} = useLang();

  const navigate = useNavigate();

  useEffect(() => {
    if (!loginCheck()) {
      navigate("/login");
    }
  });

  const getSearch = (val) => {
    if (val.length > 0) {
      http
        .get(`role/users/search/${val}`, {
          headers: {
            Authorization: "Bearer " + cookies.get("userAuth").token,
          },
        })
        .then((response) => {
          setUsers(response.data.data.data);
        });
    } else if (roles.user_id) {
      http
        .get(`/admin/user/get/${roles.user_id}`, {
          headers: {
            Authorization: "Bearer " + cookies.get("userAuth").token,
          },
        })
        .then((response) => {
          setUsers([response.data.data]);
        });
    } else {
      setUsers([]);
    }
  };

  function removeEmpty(obj) {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v != null && v != "")
        .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
    );
  }

  const onSubmit = (data, e) => {
    data = {...data, ...roles};
    // data.user_id = cookies.get('userAuth').id
    data = removeEmpty(data);
    console.log(data);
    setLoader(true);
    http
      .post(`/role/assign-role?role_id=${id}`, data, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuth").token,
        },
      })
      .then((res) => {
        console.log(res);
        // setSuccess(true);
        localStorage.setItem("success", true);
        // setLoader(false);
        setTimeout(() => {
          navigate("/assign-user-role-list");
        }, 1000);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        // console.log(Object.keys(err.response.data.data))
        let errs = err.response.data.data;
        let keys = Object.keys(errs);
        console.log(keys);
        let errors = {};
        keys.map((key) => (errors[key] = errs[key][0]));
        console.info(errors);
        setError(err.response.data);

        setErrorObj(errors);
      });
  };
  const onError = (errors, e) => console.log(errors, e);

  const handleChange = (e) => {
    console.log(e);
    let role = {...roles};
    role[e.target.name] = e.target.value;
    setRoles(role);
  };
  return (
    <>
      <Layout>
        {getLoader && (
          <div className="preloader">
            <div className="overlay__inner">
              <div className="overlay__content">
                <span className="spin"></span>
              </div>
            </div>
          </div>
        )}

        <header>
          <div className="d-flex justify-content-between">
            <Breadcrumb>
              <li className="breadcrumb-item">
                <Link to={`/role`}> {t("acl_roleList_roleList")}</Link>
              </li>
              <Breadcrumb.Item active>
                {t("acl_addUser_addUser")}
              </Breadcrumb.Item>
            </Breadcrumb>
            <BackButton path={-1} text={t("acl_roleList_roleList")}/>
          </div>
        </header>

        <div className="form_design">
          <h2 style={{color: "black", textDecoration: "underline"}}>
            {t("acl_addUser_addUser")}
          </h2>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Label className="form_label mark-required">{t("acl_addUser_userName")}</Form.Label>
              <SelectPicker
                name="user_id"
                data={users?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                placeholder={t("acl_addUser_userName_plhd")}
                style={{width: "100%"}}
                onSearch={(value) => getSearch(value)}
                onChange={(value) => setRoles({...roles, user_id: value})}
              />

              {getErrorObj.user_id ? (
                <span className="text-danger">{getErrorObj.user_id}</span>
              ) : null}
            </Form.Group>

            <div className="mt-4">
              <Button className="action__btn" variant="primary" type="submit">
                {t("acl_addUser_save")}
              </Button>
              <Link to="/role">
                <Button className="action__btn" variant="info">
                  {t("acl_addUser_backToHome")}
                </Button>
              </Link>
            </div>
          </Form>
        </div>
      </Layout>
    </>
  );
};

export default RoleAssignToUser;
