import React, {useEffect, useRef, useState} from "react";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Cookies from "universal-cookie";
import http from "../../http";
import {useLang} from "../../hooks";
import {Input, InputGroup} from "rsuite";
import {FaSearch} from "react-icons/fa";
import {mediaType} from "../../configs/constants";

function SearchAndFilterLayout({filterDispatch, changePagination, type}) {
  const {t} = useLang();
  const searchRef = useRef();
  const searchByTerm = (value) => {
    if(value !== '') {
      filterDispatch({
        type: "set",
        payload: {key: "term", value: searchRef.current.value}
      });
      changePagination({page: 1, limit: 10});
    } else {
      filterDispatch({
        type: "unset",
        payload:  "term"
      });
      changePagination({page: 1, limit: 10});
    }
  }

  return (
    <Container fluid>
        <InputGroup className="mb-3">
          <Input ref={searchRef} placeholder="Layout Name" />
          <InputGroup.Button onClick={() => searchByTerm(searchRef.current.value)}>
            <FaSearch />
          </InputGroup.Button>
        </InputGroup>
    </Container>
  );
}

export default SearchAndFilterLayout;
