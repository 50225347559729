import {api, parseAPIError} from "../../services/api";

export function processTestimonialInput(input) {
  return input
}

export async function createTestimonial(input) {
  try {
    const r = await api.post("testimonial/store", processTestimonialInput(input));
    return r.data.data
  } catch (err) {
    throw parseAPIError(err)
  }
}

export async function getTestimonial(id) {
  const r = await api.get(`/testimonial/list?id=${id}`);
  return r.data.data.data[0]
}

export async function updateTestimonial(id, input) {
  try {
    const r = await api.post(`/testimonial/update/${id}`, processTestimonialInput(input));
    return r.data.data
  } catch (err) {
    throw parseAPIError(err)
  }
}

export async function getPaginatedTestimonial(options) {
  const r = await api.get("/testimonial/list", {
    params: options
  })
  if (r.data.data) {
    return r.data.data
  }
  return {
    data: [],
    total: 0,
  }
}

export async function removeTestimonial(id) {
  const r = await api.delete(`/testimonial/delete/${id}`);
  return r.data.data
}
