import {BsPencilSquare} from "react-icons/bs";
import {Link} from "react-router-dom";
import {Tooltip, Whisper} from "rsuite";
import {Button} from "react-bootstrap";
import {MdDeleteOutline} from "react-icons/md";

export const DeleteButton = ({ handleDelete }) => {

  const tooltip = (
    <Tooltip>
      Delete
    </Tooltip>
  );

  return (
    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={tooltip}>
      <Button variant="danger" onClick={handleDelete}>
        <MdDeleteOutline />
      </Button>
    </Whisper>
  )
}