import {useEffect} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useParams} from "react-router-dom";
import {SelectPicker} from "rsuite";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";

import {useLang, useTermList} from "../../hooks";
import {PricingInputSchema} from "./schema.js";
import {BackButton} from "../../components/BackButton/BackButton";
import {usePricing} from "./hooks/usePricing";
import {FeatureBuilder, processFeature} from "./components/FeatureBuilder/FeatureBuilder";

export function PricingEditPage() {
  const {t} = useLang();
  const {id} = useParams();
  const {pricing, edit, isLoading, error} = usePricing(id)
  const {notifyError, notifySuccess} = useNotification();
  const {
    termList,
    error: termsError,
    isLoading: termLoading,
  } = useTermList(13, {limit: 1000, page: 1});

  if (termsError || error) {
    notifyError(termsError ?? error, "/error");
  }

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setValue,
    setError,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(PricingInputSchema(t)),
  });

  const name = watch("package_name");
  console.log(errors)

  useEffect(() => {
    if (pricing) {
      const {feature, ...prev} = pricing
      reset({
        ...prev,
        feature: processFeature(feature)
      })
    }
  }, [pricing, setValue]);

  const handleEdit = async (data) => {
    try {
      await edit(data);
      notifySuccess({
        title: `Pricing updated successfully`,
      }, "/pricing/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Failed to update pricing!",
        message: err.message,
      });
    }
  };

  return (
    <Layout>
      {isLoading && <CircleLoader/>}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/pricing/manage`}> {t("pricing_list")}</Link>
            </li>
            <Breadcrumb.Item active>
              {t("events_edit_edit")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("events_manage_events")}/>
        </div>
      </header>
      <section id="#event-edit">
        <h2>{t("events_edit_edit")} {name}</h2>
      </section>
      <Form
        onSubmit={handleSubmit(handleEdit)}
        className="mt-4 d-flex  flex-column gap-3"
      >
        <div className="form-row">
          <Form.Group as={Col} controlId="package_name">
            <Form.Label className="mark-required">{t("pricing_package_name")}</Form.Label>
            <Form.Control
              {...register("package_name")}
              placeholder={t("pricing_package_name_phld")}
              type="text"
            />
            {errors.package_name?.message && (
              <Form.Text className="text-danger">
                {errors.package_name.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="pricing_category_id">
            <Form.Label className="mark-required">{t('pricing_category')}</Form.Label>
            <div>
              <Controller render={({field}) => <SelectPicker
                onChange={field.onChange}
                value={field.value}
                style={{width: "100%"}}
                data={termList?.terms?.map(t => ({value: t.id, label: t.name})) ?? []}
                placeholder={t('pricing_category_phld')}
                loading={termLoading}
              />
              } name="pricing_category_id" control={control} />
            </div>
            {errors.pricing_category_id?.message && (
              <Form.Text className="text-danger">
                {errors.pricing_category_id.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>


        <div className="form-row">
          <Form.Group as={Col} controlId="price">
            <Form.Label>{t("pricing_price")}</Form.Label>
            <Form.Control
              {...register("price")}
              placeholder={t("pricing_price_phld")}
              type="text"
            />
            {errors.price?.message && (
              <Form.Text className="text-danger">
                {errors.price.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col} controlId="duration">
            <Form.Label>{t("pricing_duration")}</Form.Label>
            <Form.Control
              {...register("duration")}
              placeholder={t("pricing_duration_phld")}
              type="text"
            />
            {errors.duration?.message && (
              <Form.Text className="text-danger">
                {errors.duration.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>

        <Form.Group controlId="link">
          <Form.Label>{t("link")}</Form.Label>
          <Form.Control
            {...register("link")}
            placeholder={t("link_phld")}
            type="text"
          />
          {errors.link?.message && (
            <Form.Text className="text-danger">
              {errors.link.message}
            </Form.Text>
          )}
        </Form.Group>

        <Form.Group>
          <Controller
            control={control}
            name="feature"
            render={({field}) => (
              <FeatureBuilder value={field.value} onChange={field.onChange}/>
            )}
          />
          {(errors.feature?.message || Array.isArray(errors.feature)) && (
            <Form.Text className="text-danger">
              { errors.feature.message ?? JSON.stringify(errors.feature.find(Boolean))}
            </Form.Text>
          )}
        </Form.Group>

        <div className="d-flex justify-content-end gap-2">
          <Button className="px-4" variant="success" type="submit">
            {t("save_btn")}
          </Button>
          <Link className="btn btn-danger" to={`/pricing/manage`}>
            {t("cancel_btn")}
          </Link>
        </div>
      </Form>
    </Layout>
  );
}
