import {useEffect, useState} from "react";
import {Breadcrumb, Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {createLanguage} from "../../services/language";
import {useLanguages} from "../../hooks/useLanguages";
import {LanguageSelector} from "../../components/Language/LanguageSelector";
import {useLang} from "../../hooks";
import {MenuItemLanguageSchema} from "./schema";
import {useMenu} from "./hooks/useMenu";

export function MenuLanguageCreatePage() {
  const {resourceId} = useParams();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {t} = useLang();

  const {menu, isLoading: menuLoading, error: menuErr} = useMenu(resourceId);
  const {langs, isLoading: isLangLoading, error: isLangError} = useLanguages();

  if (menuErr || isLangError) {
    notifyError({
      title: "Something went wrong!",
      message: menuErr.message
    }, "/error");
  }

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    setValue
  } = useForm({
    resolver: yupResolver(MenuItemLanguageSchema(t))
  });

  useEffect(() => {
    if (menu) {
      setValue("title", menu.title ?? "");
      setValue("description", menu.description ?? "");
    }
  }, [menu]);

  const create = async (data) => {
    try {
      setIsLoading(true);
      data.menu_id = resourceId;
      if(data.description === '') {
        delete data.description;
      }
      const l = await createLanguage("menu", {
        ...data,
        order: menu.order,
        menu_type_id: menu.menu_type_id,
        link: menu.link
      });
      notifySuccess({
        title: "language created successfully!",
        message: "Language Created"
      });
      navigate(-1);
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Failed to create language",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: "Language creation failed!"
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      {(isLoading || menuLoading || isLangLoading) && <CircleLoader />}
      <section id="#menu-lang-create">
        <header>
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to={`/menus/manage`}>Menus</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/langs/menu/${resourceId}`}>{menu?.title ?? resourceId} Languages</Link>
            </li>
            <Breadcrumb.Item active>create language</Breadcrumb.Item>
          </Breadcrumb>
          <h3>Create Language ({menu?.name ?? resourceId})</h3>
        </header>
        <Form
          onSubmit={handleSubmit(create)}
          className="mt-4 d-flex  flex-column gap-3"
        >

          <LanguageSelector loading={isLangLoading} control={control} langs={langs} errors={errors} />
          <Form.Group controlId="menuTitle">
            <Form.Label className="mark-required">{t("menu_content_title")}</Form.Label>
            <Form.Control
              {...register("title")}
              type="text"
              placeholder={t("menu_content_title_plhd")}
            />
            {errors?.title?.message && (
              <Form.Text className="text-danger">
                {errors.title.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="menuDescription">
            <Form.Label>{t("menu_content_Description")}</Form.Label>
            <Form.Control {...register("description")} as="textarea" rows={3} />
            {errors?.description?.message && (
              <Form.Text className="text-danger">
                {errors.description.message}
              </Form.Text>
            )}
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button className="px-4" type="submit">
              Create
            </Button>
          </div>
        </Form>
      </section>
    </Layout>
  );
}
