import React from "react";
import Layout from "../../components/layouts/layout";
import {useState} from "react";
import CircleLoader from "../../components/Loaders/CircleLoader";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import {BackButton} from "../../components/BackButton/BackButton";
import {useComponentCategories, useIdentifiers, useLang} from "../../hooks";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";
import {ComponentLayoutInputSchema} from "./schema";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";
import {SelectPicker, Toggle} from "rsuite";
import {useMediaSelector} from "../../components/MediaSelectorModal";
import MediaCardListSmall from "../../components/MediaSelectorModal/MediaCardListSmall";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import { createComponentLayout } from "../../services/component";

function ComponentFileUpload() {
  const [loading, setLoading] = useState();
  const {notifyError, notifySuccess} = useNotification();
  const {
    categories,
    isLoading: isCategoryLoading,
    error: catErr
  } = useComponentCategories();

  const {t} = useLang();
  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({resolver: yupResolver(ComponentLayoutInputSchema(t))});
  const {
    selectedMedia,
    setSelectedMedia,
    checkValue,
    setCheckValue,
    allMediaId,
    handleRemove
  } = useMediaSelector();
  const create = async (data) => {
    console.log(data,'data');
    try {
      setLoading(true);
      const dataModel = {
        name: data.name ,
        component_type:data.component_type,
        layout_pack_description: data.layout_pack_description,
        media_id: selectedMedia[0].id,
        status: data.status===true? 'active' :'inactive'
      };
        const component = await createComponentLayout(dataModel);
      notifySuccess(
        {
          title: "components created successfully",
          message: `Component: ${component.name} created!`
        },
        "/components/layout-list"
      );
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Input validation failed",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: err.message
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      {loading && <CircleLoader />}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/components/layout-list`}>
                
                {t("component_layout")}
              </Link>
            </li>
            <Breadcrumb.Item active>
              {t("components_create_createLayout")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("users_page_title")} />
        </div>
      </header>
      <section id="#component-create">
        <h2>{t("components_create_createLayout")}</h2>
        <Form
          onSubmit={handleSubmit(create)}
          className="d-flex flex-column gap-3 mt-3"
        >
            <div className="row">
                <div className="col-md-12 col-lg-3" style={{ minWidth: '260px'}}>
                <Form.Group as={Col} xl={2} style={{minWidth: "250px"}}>
                    <Form.Label>{t("components_create_image")}</Form.Label>
                    <div >
                        <Controller
                        control={control}
                        name="footer_logo_one"
                        render={({field}) => (
                            <MediaModal
                            isSingleMode
                            selectedMedia={selectedMedia}
                            setSelectedMedia={setSelectedMedia}
                            checkValue={checkValue}
                            setCheckValue={setCheckValue}
                            allMediaId={allMediaId}
                            >
                            {selectedMedia?.length > 0 ? (
                                <MediaCardListSmall
                                selectedMedia={selectedMedia}
                                isDelete
                                handleRemove={handleRemove}
                                />
                            ) : (
                                <div style={{height: "190px", width: "100%"}}>
                                <ImageInput
                                    errorMessage={errors.image?.message}
                                    onChange={field.onChange}
                                    onError={() => {
                                    setError("image", {
                                        message: "Invalid image file!"
                                    });
                                    }}
                                />
                                </div>
                            )}
                            </MediaModal>
                        )}
                        />
                    </div>
                    </Form.Group>
                </div>
                <div className="col-lg-9">
                    <div className=" gap-3 gap-md-0">
                        <Form.Group  controlId="component_type" className="mb-3">
                        <Form.Label className="mark-required">
                            {t("components_create_selectComponentType")}
                        </Form.Label>
                        <Controller
                            name="component_type"
                            control={control}
                            render={({field: {onChange, value}}) => (
                            <SelectPicker
                                onChange={(value) => {
                                onChange(value);
                                }}
                                value={value}
                                data={categories}
                                block
                                searchable={false}
                            />
                            )}
                        />
                        {errors.component_type?.message && (
                            <Form.Text className="text-danger">
                            {errors.component_type.message}
                            </Form.Text>
                        )}
                        </Form.Group>
                        <Form.Group className="flex-grow-1 mb-3" controlId="name">
                          <Form.Label className="mark-required">
                              {t("components_manage_col_name")}
                          </Form.Label>
                          <Form.Control {...register("name")} placeholder={t("components_manage_col_name")} type="text" />
                          {errors.title?.message && (
                              <Form.Text className="text-danger">{errors.title.message}</Form.Text>
                          )}
                        </Form.Group>
                        <Form.Group className="flex-grow-1 mb-3" controlId="layout_pack_description">
                          <Form.Label>
                            {t("description")}
                          </Form.Label>
                          <Form.Control as="textarea" {...register("layout_pack_description")} placeholder={t("description")} type="text"/>
                          {errors.layout_pack_description?.message && (
                            <Form.Text className="text-danger">{errors.layout_pack_description.message}</Form.Text>
                          )}
                        </Form.Group>
                        <Form.Group  controlId="status">
                        <Form.Label>{t("subscriber_isActive")}</Form.Label>
                        <Controller
                            control={control}
                            name="status"
                            render={({field}) => (
                            <div>
                                <Toggle
                                {...field}
                                size="lg"
                                checkedChildren={<span>Active</span>}
                                unCheckedChildren={<span>InActive</span>}
                                va
                                className="m-auto"
                                />
                            </div>
                            )}
                        />
                        {errors.status?.message && (
                            <Form.Text className="text-danger">
                            {errors.status.message}
                            </Form.Text>
                        )}
                        </Form.Group>
                    </div>
                </div>
            </div>
          
          
          <div className="row mt-2">
            <div className="d-flex justify-content-end">
              <Button className="px-4" type="submit">
                {t("components_create_create")}
              </Button>
            </div>
          </div>
        </Form>
      </section>
    </Layout>
  );
}

export default ComponentFileUpload;
