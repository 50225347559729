import {useRef, useState} from "react";
import {MdClose, MdVideocam} from "react-icons/md";
import "./index.scss";
import {Tooltip, Whisper} from "rsuite";
import ReactPlayer from "react-player";


export function VideoInput({defaultPreviousVideo, previousVideo, onChange, onError, errorMessage}) {
  const inputRef = useRef();
  const [touched, setTouched] = useState(false);
  const [newVideo, setNewVideo] = useState(null)
  const [playing, setPlaying] = useState(false)

  previousVideo = touched ? null : previousVideo;

  const handleCancelInput = (e) => {
    e.preventDefault();
    inputRef.current.value = "";
    inputRef.current.type = "text";
    inputRef.current.type = "file";

    setNewVideo(null)
    setTouched(true);

    onChange?.(null)
  };

  const handleChange = (e) => {
    e.preventDefault();
    const upload = e.target.files?.[0] ?? null
    setNewVideo(URL.createObjectURL(upload));
    setTouched(true)
    onChange?.(upload)
  }

  const handleError = () => {
    onError?.("Invalid video file")
  }

  const tooltip = errorMessage ? <Tooltip className="text-danger">{errorMessage}</Tooltip> :
    <Tooltip>{newVideo?.toString() ?? previousVideo ?? "Upload Video"}</Tooltip>


  return (
    <Whisper placement="auto" controlId="control-id-hover" trigger="hover" speaker={tooltip}>
      <div
        onMouseOver={() => {
          setPlaying(true)
        }}
        onMouseLeave={() => {
          setPlaying(false)
        }}
        className={"media-edit " + (!errorMessage ? "" : " error")}
      >
        <input
          ref={inputRef}
          accept=".ogg,.webm,.mp4"
          type="file"
          onChange={handleChange}
        />
        {newVideo ?? previousVideo ?? defaultPreviousVideo ?
          <ReactPlayer width="100%" height="100%" loop muted
                       playing={playing}
                       playbackRate={3}
                       url={newVideo ?? previousVideo ?? defaultPreviousVideo}
                       onError={handleError}
          /> : (<MdVideocam size={30}/>)}

        {(previousVideo || newVideo) && (<button onClick={handleCancelInput} className="close-btn">
          <MdClose className="close-btn-content"/>
        </button>)}
      </div>
    </Whisper>
  );
}
