import {useCallback, useEffect, useState} from "react";
import {getTaxonomy, updateTaxonomy} from "../services/taxonomy";

export default function useTaxonomy(id) {
    const [taxonomy, setTaxonomy] = useState();
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const fetchTaxonomy = useCallback(async () => {
        try {
            setIsLoading(true);
            setTaxonomy(await getTaxonomy(id));
        } catch (err) {
            setError({
                title: "Failed to fetch taxonomy!",
                message: err.message,
            });
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    const edit = useCallback(async (data) => {
        try {
            setIsLoading(true);
            setTaxonomy(await updateTaxonomy(id, data));
        } finally {
            setIsLoading(false);
        }
    });

    useEffect(() => {
        fetchTaxonomy();
    }, [fetchTaxonomy]);


    return {taxonomy, isLoading, edit, error};
}
