import * as yup from "yup";
import {
  defaultImageValidation,
  DefaultLinkValidation,
  languageSchema
} from "../../configs/constants/validation";
import {phoneRegExp} from "../../configs/constants";

export const GeneralSettingsSchema = (t) =>
  yup.object({
    site_title: yup
      .string()
      .max(60)
      .label(t("settings_general_siteTitle")),
    tag_line: yup
      .string()
      .max(255)
      .label(t("settings_general_tagLine")),
    site_address: yup
      .string()
      .url()
      .label(t("settings_general_siteAddress")),
    email_address: yup
      .array()
      .of(yup.string().email())
      .max(50)
      .label(t("settings_header_clientEmail")),
    roboket_chat_base_url: yup
      .string()
      .url()
      .label(t("settings_general_roboketChatBaseUrl")),
    roboket_chat_website_token: yup
      .string()
      .label(t("settings_general_roboketChatWebsiteToken"))
  });

export const SocialSettingsSchema = (t) =>
  yup.object({
    facebook: DefaultLinkValidation().nullable()
      .label(t("social_settings_facebook")),
    instagram: DefaultLinkValidation().nullable()
      .label(t("social_settings_instagram")),
    linkedin: DefaultLinkValidation().nullable()
      .label(t("social_settings_linkedin")),
    youtube: DefaultLinkValidation().nullable()
      .label(t("social_settings_youtube"))
  });

export const PopUpSettingsSchema = (t) =>
  yup.object({
      dateTimeRange: yup.tuple([
          yup.date().required().label("Start Date").required(),
          yup.date().required().label("End Date").required()
      ]).required().label(t("events_input_startEndDateTime")),
      status: yup.bool().default(false).label("Status").label(t("events_input_status")),
  });

export const CommonSettingsSchema = (t) =>
  yup
    .object({
      is_blog: yup.string().oneOf(["yes", "no"]).required(),
      is_ecommerce: yup.string().oneOf(["yes", "no"]).required()
    })
    .required();

export const HeaderSettingsSchema = (t) =>
  yup.object({
    site_address_header: yup
      .string()
      .max(60).nullable()
      .label(t("settings_header_site_address")),
    site_email: yup
      .string()
      .email().nullable()
      .label(t("settings_header_email")),
    site_phone: yup
      .string().nullable()
      .matches(phoneRegExp)
      .label(t("settings_header_phone")),
    main_menu_id: yup.string().nullable().label(t("settings_header_main_menu")),
    mobile_menu_id: yup
      .string().nullable()
      .label(t("settings_header_mobile_menu")),
    logo: defaultImageValidation.label(t("settings_site_logo")),
    alter_text: yup.string().label(t("settings_header_logo_alt_text")),
    button_title: yup
      .string().nullable()
      .label(t("settings_header_button_txt")),
    button_link: yup
      .string().nullable()
      .url()
      .label(t("settings_header_button_link"))
  });

export const FooterSettingsOneSchema = (t) =>
  yup.object({
    footer_title_one: yup.string().label(t("settings_footer_title")),
    footer_content_one: yup.string().label(t("settings_footer_content")),
    footer_logo_one: defaultImageValidation.label(
      t("settings_site_footer_logo")
    ),
    footer_is_social_one: yup
      .string()
      .oneOf(["1", "0"])
      .default("0")
      .label(t("settings_footer_is_social")),
    footer_menu_id_one: yup
      .string()
      .nullable()
      .label(t("settings_footer_menu")),
    is_search_one: yup.string().nullable().label(t("settings_footer_menu")),
    is_subscription_one: yup
      .string()
      .nullable()
      .label(t("settings_footer_menu")),
    is_gallery_one: yup.string().nullable().label(t("settings_footer_menu")),
    is_post_one: yup.string().nullable().label(t("settings_footer_menu")),
    button_text_one: yup
      .string()
      .nullable()
      .label(t("settings_header_button_txt")),
    button_link_one: yup
      .string()
      .url()
      .label(t("settings_header_button_link")),
    footer_html_area_one: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link")),
    select_form_one: yup.string().nullable().label(t("settings_footer_menu")),
    title_footer_one: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link")),

    description_footer_one: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link")),
    copy_right_one: yup.string().nullable().label(t("settings_footer_menu")),
    copy_right_html_area_one: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link"))
  });

export const FooterSettingsTwoSchema = (t) =>
  yup.object({
    footer_title_two: yup.string().label(t("settings_footer_title")),
    footer_content_two: yup.string().label(t("settings_footer_content")),
    footer_logo_two: defaultImageValidation.label(
      t("settings_site_footer_logo")
    ),
    footer_is_social_two: yup
      .string()
      .oneOf(["1", "0"])
      .default("0")
      .label(t("settings_footer_is_social")),
    footer_menu_id_two: yup
      .string()
      .nullable()
      .label(t("settings_footer_menu")),
    is_search_two: yup.string().nullable().label(t("settings_footer_menu")),
    is_subscription_two: yup
      .string()
      .nullable()
      .label(t("settings_footer_menu")),
    is_gallery_two: yup.string().nullable().label(t("settings_footer_menu")),
    is_post_two: yup.string().nullable().label(t("settings_footer_menu")),
    button_text_two: yup
      .string()
      .nullable()
      .label(t("settings_header_button_txt")),
    button_link_two: yup
      .string()
      .url()
      .label(t("settings_header_button_link")),
    footer_html_area_two: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link")),
    select_form_two: yup.string().nullable().label(t("settings_footer_menu")),
    title_footer_two: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link")),
    description_footer_two: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link")),
    copy_right_two: yup.string().nullable().label(t("settings_footer_menu")),
    copy_right_html_area_two: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link"))
  });

export const FooterSettingsThreeSchema = (t) =>
  yup.object({
    footer_title_three: yup
      .string()
      .label(t("settings_footer_title")),
    footer_content_three: yup.string().label(t("settings_footer_content")),
    footer_logo_three: defaultImageValidation.label(
      t("settings_site_footer_logo")
    ),
    footer_is_social_three: yup
      .string()
      .oneOf(["1", "0"])
      .default("1")
      .label(t("settings_footer_is_social")),
    footer_menu_id_three: yup
      .string()
      .nullable()
      .label(t("settings_footer_menu")),
    is_search_three: yup.string().nullable().label(t("settings_footer_menu")),
    is_subscription_three: yup
      .string()
      .nullable()
      .label(t("settings_footer_menu")),
    is_gallery_three: yup.string().nullable().label(t("settings_footer_menu")),
    is_post_three: yup.string().nullable().label(t("settings_footer_menu")),
    button_text_three: yup
      .string()
      .nullable()
      .label(t("settings_header_button_txt")),
    button_link_three: yup
      .string()
      .url()
      .label(t("settings_header_button_link")),
    footer_html_area_three: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link")),
    select_form_three: yup.string().nullable().label(t("settings_footer_menu")),
    title_footer_three: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link")),
    description_footer_three: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link")),
    copy_right_three: yup.string().nullable().label(t("settings_footer_menu")),
    copy_right_html_area_three: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link"))
  });
export const FooterSettingsFourSchema = (t) =>
  yup.object({
    footer_title_four: yup
      .string()
      .required()
      .label(t("settings_footer_title")),
    footer_content_four: yup.string().label(t("settings_footer_content")),
    footer_logo_four: defaultImageValidation.label(
      t("settings_site_footer_logo")
    ),
    footer_is_social_four: yup
      .string()
      .oneOf(["1", "0"])
      .default("0")
      .label(t("settings_footer_is_social")),
    footer_menu_id_four: yup
      .string()
      .nullable()
      .label(t("settings_footer_menu")),
    is_search_four: yup.string().nullable().label(t("settings_footer_menu")),
    is_subscription_four: yup
      .string()
      .nullable()
      .label(t("settings_footer_menu")),
    is_gallery_four: yup.string().nullable().label(t("settings_footer_menu")),
    is_post_four: yup.string().nullable().label(t("settings_footer_menu")),
    button_text_four: yup
      .string()
      .nullable()
      .required()
      .label(t("settings_header_button_txt")),
    button_link_four: yup
      .string()
      .url()
      .required()
      .label(t("settings_header_button_link")),
    footer_html_area_four: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link")),
    select_form_four: yup.string().nullable().label(t("settings_footer_menu")),
    title_footer_four: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link")),

    description_footer_four: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link")),
    copy_right_four: yup.string().nullable().label(t("settings_footer_menu")),
    copy_right_html_area_four: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link"))
  });
export const FooterSettingsFiveSchema = (t) =>
  yup.object({
    footer_title_five: yup
      .string()
      .required()
      .label(t("settings_footer_title")),
    footer_content_five: yup.string().label(t("settings_footer_content")),
    footer_logo_five: defaultImageValidation.label(
      t("settings_site_footer_logo")
    ),
    footer_is_social_five: yup
      .string()
      .oneOf(["1", "0"])
      .default("0")
      .label(t("settings_footer_is_social")),
    footer_menu_id_five: yup
      .string()
      .nullable()
      .label(t("settings_footer_menu")),
    is_search_five: yup.string().nullable().label(t("settings_footer_menu")),
    is_subscription_five: yup
      .string()
      .nullable()
      .label(t("settings_footer_menu")),
    is_gallery_five: yup.string().nullable().label(t("settings_footer_menu")),
    is_post_five: yup.string().nullable().label(t("settings_footer_menu")),
    button_text_five: yup
      .string()
      .nullable()
      .required()
      .label(t("settings_header_button_txt")),
    button_link_five: yup
      .string()
      .url()
      .required()
      .label(t("settings_header_button_link")),
    footer_html_area_five: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link")),
    select_form_five: yup.string().nullable().label(t("settings_footer_menu")),
    title_footer_five: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link")),

    description_footer_five: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link")),
    copy_right_five: yup.string().nullable().label(t("settings_footer_menu")),
    copy_right_html_area_five: yup
      .string()
      .nullable()
      .label(t("settings_header_button_link"))
  });
export const SettingsLanguageSchema = () =>
  languageSchema.shape({
    value: yup.string().required().label("Value")
  });
