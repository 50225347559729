import {api} from "./api";

export async function getPaginatedContactForm(options) {
  const r = await api.get("/contact-form", {
    params: options
  })
  if (r.data.data) {
    return r.data.data
  }
  return {
    data: [],
    total: 0,
  }
}