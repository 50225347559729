import * as yup from "yup";
import {
  DefaultDescriptionValidation,
  defaultImageValidation,
  DefaultNameValidation,
  languageSchema,
  SeoSchema
} from "../../configs/constants";

export const ProductInputSchema = (t) =>
  SeoSchema().shape({
    image: defaultImageValidation,
    name: DefaultNameValidation()
      .required()
      .max(255)
      .label(t("products_create_name")),
    code: yup.string().required().label(t("products_create_code")),
    description: DefaultDescriptionValidation().label(
      t("products_create_description")
    ),
    categoryId: yup.number().required().label(t("products_create_category")),
    attribute_id: yup
      .array()
      .of(yup.string().max(31))
      .required()
      .label(t("products_create_attributes"))
  });

export const ProductInventoryInputSchema = (t) =>
  SeoSchema().shape({
    quantity: yup.number().required().label(t("products_create_code")),
    product_id: yup.number().required().label(t("products_create_category"))
    // attribute_id: yup
    //   .array()
    //   .of(yup.string().max(31))
    //   .required()
    //   .label(t("products_create_attributes"))
  });

export const ProductLanguageInputSchema = (t) =>
  languageSchema.shape({
    name: DefaultNameValidation().required().label(t("products_create_name")),
    image: defaultImageValidation,
    description: DefaultDescriptionValidation().label(
      t("products_create_description")
    )
  });
