import { useState, useEffect } from 'react'
import { usePrivateAPI } from './usePrivateApi'
import { InputValidationError } from "../libs/error";

export default function useAlbumMedia(id) {
    const [album, setAlbum] = useState()
    const [albumMedia, setAlbumMedia] = useState([])
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { http } = usePrivateAPI()

    const fetchAlbumMedia = async () => {
        try {
            setIsLoading(true)
            const response = await http.get(`gallery/album/${id}`)
            setAlbumMedia(response.data.data)
        } catch (err) {
            setError({
                title: 'Failed to fetch album deatails!',
                message: err.message,
            })
        } finally {
            setIsLoading(false)
        }
    }

    const fetchAlbum = async () => {
        try {
            setIsLoading(true)
            const response = await http.get(`terms/get/${id}`)
            setAlbum(response.data.data)
        } catch (err) {
            setError({
                title: 'Failed to fetch album!',
                message: err.message,
            })
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchAlbum()
    }, [])

    useEffect(() => {
        fetchAlbumMedia()
    }, [])

    return { album,albumMedia, isLoading, error };
}