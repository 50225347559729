import {useCallback, useEffect, useReducer, useState} from "react";
import {usePagination} from "./usePagination";
import filterReducers from "../reducers/filterReducer";

export function usePaginatedList(
  paginatedListFunc,
  deleteFunc,
  defaultFilter,
  defaultPagination
) {
  const [isLoading, setIsLoading] = useState(false);
  const [filter, filterDispatch] = useReducer(
    filterReducers,
    defaultFilter ?? {}
  );
  const [error, setError] = useState(false);
  const {pagination, changePagination} = usePagination(
    defaultPagination ?? {
      page: 1,
      limit: 10
    }
  );

  const [list, setList] = useState({
    data: [],
    total: 0
  });

  const fetch = useCallback(async () => {
    if (!paginatedListFunc) {
      throw new Error("deleteFunc is not defined");
    }

    try {
      setIsLoading(true);
      const {data, total} = await paginatedListFunc({
        page: pagination.page,
        ...filter
      });
      setList({data, total});
      setIsLoading(false);
    } catch (err) {
      setError({
        title: "Something went wrong",
        message: err.message
      });
    }
  }, [pagination, filter]);

  const removeItem = async (id) => {
    if (!deleteFunc) {
      throw new Error("deleteFunc is not defined");
    }
    await deleteFunc(id);
    await fetch();
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    list,
    pagination,
    changePagination,
    removeItem,
    isLoading,
    error,
    filter,
    fetch,
    filterDispatch
  };
}
