import {useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {createLanguage} from "../../services/language";
import {useLanguages} from "../../hooks/useLanguages";
import {LanguageSelector} from "../../components/Language/LanguageSelector";
import {useContent, useLang} from "../../hooks";
import {ContentLanguageSchema} from "./schema";
import {BackButton} from "../../components/BackButton/BackButton";
import {TextEdit} from "../../components";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";
import MediaCard from "../MediaManagement/components/MediaCard";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {useMediaSelector} from "../../components/MediaSelectorModal";

export function ContentLanguageCreate() {
  const {resourceId} = useParams();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {t} = useLang()

  const {content, isLoading: isContentLoading, error} = useContent(resourceId)

  const {langs, isLoading: isLangLoading, error: isLangError} = useLanguages();

  if (error || isLangError) {
    notifyError({
      title: "Something went wrong!",
      message: error.message
    })
    navigate(-1)
  }

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(ContentLanguageSchema(t)),
  });
  const {
    selectedMedia,
    setSelectedMedia,
    checkValue,
    setCheckValue,
    allMediaId,
    setAllMediaId,
    handleRemove
  } = useMediaSelector();

  useEffect(() => {
    if (content) {
      setValue("title", content.title ?? "");
      setValue("description", content.description ?? "");

      let mediaIds = content?.files ? content?.files.map(m => m.media_id) : [];
      let media = content?.files ? content?.files.map(m => m.media_content) : [];

      setSelectedMedia(media);
      setCheckValue(mediaIds);
      setAllMediaId(mediaIds);
    }
  }, [content]);

  console.log(errors)


  const create = async (data) => {
    try {
      setIsLoading(true);
      data.master_crud_id = resourceId
      const l = await createLanguage("content", {...data, media_id: checkValue})
      notifySuccess({
        title: "language created successfully!",
        message: "Language Created",
      });
      navigate(-1);
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Failed to create language",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: "Language creation failed!",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
      <Layout>
        {(isLoading || isContentLoading || isLangLoading) && <CircleLoader/>}
        <header>
          <div className="d-flex justify-content-between align-items-center">
            <Breadcrumb className="d-flex align-items-center">
              <li className="breadcrumb-item">
                <Link to={`/contents/manage`}> {t("contents_contents")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/langs/content/${resourceId}`}>{content?.title ?? resourceId} Languages</Link>
              </li>
              <Breadcrumb.Item active>Create Language</Breadcrumb.Item>
            </Breadcrumb>
            <BackButton path={-1} text={t("contents_contents")}/>
          </div>
        </header>
        <section id="#content-lang-create">
          <h3>Create Language ({content?.name ?? resourceId})</h3>
          <Form
            onSubmit={handleSubmit(create)}
            className="mt-4 d-flex  flex-column gap-3"
          >

            <LanguageSelector loading={isLangLoading} control={control} langs={langs} errors={errors} />
            <div className="form-row">
              <Form.Group as={Col} className="flex-grow-1" controlId="name">
                <Form.Label className="mark-required">
                  {t("contents_create_name")}
                </Form.Label>
                <Form.Control
                  {...register("title")}
                  placeholder={t("contents_create_name_plhd")}
                  type="text"
                />
                {errors.title?.message && (
                  <Form.Text className="text-danger">
                    {errors.title.message}
                  </Form.Text>
                )}
              </Form.Group>
            </div>
            <Form.Group controlId="subtitle">
              <Form.Label>{t("contents_edit_subtitle")}</Form.Label>
              <Form.Control
                {...register("subtitle")}
                placeholder={t("contents_edit_subtitle_plhd")}
                type="text"
              />
              {errors.subtitle?.message && (
                <Form.Text className="text-danger">
                  {errors.subtitle.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>
                {t("contents_create_Description")}
              </Form.Label>
              <Controller
                control={control}
                name="description"
                render={({field}) => <TextEdit {...field} />}
              />
              {errors.description?.message && (
                <Form.Text className="text-danger">
                  {errors.description.message}
                </Form.Text>
              )}
            </Form.Group>
            <div className="form-row">
              <Form.Group as={Col} md={12} lg={12} className="mb-4">
                <Form.Label>{t("contents_create_image")}</Form.Label>
                <Controller
                  control={control}
                  name="image"
                  render={({field}) => (
                    <MediaModal selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                                checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                      {
                        selectedMedia?.length > 0 ? (
                          selectedMedia?.map((md) =>
                            <MediaCard key={md.id} media={md} isDelete handleRemove={handleRemove} />
                          )
                        ) : (
                          <div style={{height: "300px", width: "100%"}}>
                            <ImageInput
                              errorMessage={errors.image?.message}
                              onChange={field.onChange}
                              onError={() => {
                                setError("image", {message: "Invalid image file!"});
                              }}
                            />
                          </div>
                        )
                      }
                    </MediaModal>
                  )}
                />
              </Form.Group>
            </div>
            <Form.Group controlId="Link">
              <Form.Label>
                {t("contents_create_link")}
              </Form.Label>
              <Form.Control
                {...register("link")}
                placeholder={t("contents_create_link_plhd")}
                type="text"
              />
              {errors.link?.message && (
                <Form.Text className="text-danger">{errors.link.message}</Form.Text>
              )}
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button className="px-4" type="submit">
                Create
              </Button>
            </div>
          </Form>
        </section>
      </Layout>
  );
}
