import { useFooterSettings } from "../hooks/useFooterSettings";
import { useLang, useTermList } from "../../../hooks";
import { useNotification } from "../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { footerSettingsThree, FooterSettingsThreeSchema } from "../schema";
import { useEffect } from "react";
import { InputValidationError } from "../../../libs/error";
import { Button, Col, Form } from "react-bootstrap";
import { ImageInput } from "../../../components/MediaInputs/imageInput";
import { getUrlFromStoragePath } from "../../../configs";
import { InputGroup, Placeholder, SelectPicker } from "rsuite";
import { BsGlobe } from "react-icons/bs";
import { useMediaSelector } from "../../../components/MediaSelectorModal";
import MediaCardListSmall from "../../../components/MediaSelectorModal/MediaCardListSmall";
import MediaModal from "../../../components/MediaSelectorModal/MediaModal";

export function FooterSettingsThree({ onLanguageEdit }) {
  const { settings, error, isLoading, save } = useFooterSettings("three");
  const { t } = useLang();
  const { notifyError, notifySuccess } = useNotification();
  const {
    termList,
    isLoading: isTermsLoading,
    error: termError
  } = useTermList(8, { page: 1, limit: 10000 });
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    control,
    setError
  } = useForm({
    resolver: yupResolver(FooterSettingsThreeSchema(t))
  });

  const {
    selectedMedia,
    setSelectedMedia,
    checkValue,
    setCheckValue,
    allMediaId,
    setAllMediaId,
    handleRemove
  } = useMediaSelector();

  if (error || termError) {
    navigate(error ?? termError);
    navigate("/dashboard");
  }

  useEffect(() => {
    if (settings) {
      const { footer_logo_three, footer_menu_id_three, ...newInput } = settings;
      reset({
        footer_title_three: settings.footer_title_three?.value ?? "",
        footer_content_three: settings.footer_content_three?.value ?? "",
        footer_is_social_three: settings.footer_is_social_three?.value ?? "0",
        footer_menu_id_three: settings.footer_menu_id_three
          ? String(footer_menu_id_three.value)
          : "",
        is_search_three: settings.is_search_three?.value,
        is_subscription_three: settings.is_subscription_three?.value,
        is_gallery_three: settings.is_gallery_three?.value,
        is_post_three: settings.is_post_three?.value,
        button_text_three: settings.button_text_three?.value ?? "",
        button_link_three: settings.button_link_three?.value ?? "",
        footer_html_area_three: settings.footer_html_area_three?.value ?? "",
        select_form_three: settings.select_form_three?.value ?? "",
        title_footer_three: settings.title_footer_three?.value ?? "",
        description_footer_three: settings.description_footer_three?.value ?? "",
        copy_right_three: settings.copy_right_three?.value ?? "",
        copy_right_html_area_three: settings.copy_right_html_area_three?.value ?? "",
      });

      let footerLogo = footer_logo_three.value;
      let mediaId = footerLogo?.id ? [footerLogo?.id] : [];
      let media = footerLogo ? [footerLogo] : [];

      setSelectedMedia(media);
      setCheckValue(mediaId);
      setAllMediaId(mediaId);
    }
  }, [settings]);

  const handleUpdate = async (data) => {
    try {
      await save({ ...data, footer_logo_three: checkValue[0] ?? null });
      notifySuccess({
        title: "Success",
        message: "Third Column Updated Successfully"
      });
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input!",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Failed to update",
        message: err.message
      });
    }
  };

  return (
    <section id="header-settings-form" className="my-4">
      <h3 className="mb-3">{t("settings_footer_three")}</h3>
      {isLoading ? (
        <Placeholder.Graph active />
      ) : (
        <Form
          className="d-flex flex-column gap-3"
          onSubmit={handleSubmit(handleUpdate)}
        >
          <div className="form-row">
            <Form.Group as={Col} xl={2} style={{ minWidth: "250px" }}>
              <Form.Label>{t("settings_site_footer_logo")}</Form.Label>
              <div style={{ height: "190px", justifyContent: "center" }}>
                <Controller
                  control={control}
                  name="footer_logo_three"
                  render={({ field }) => (
                    <MediaModal
                      isSingleMode
                      selectedMedia={selectedMedia}
                      setSelectedMedia={setSelectedMedia}
                      checkValue={checkValue}
                      setCheckValue={setCheckValue}
                      allMediaId={allMediaId}
                    >
                      {selectedMedia?.length > 0 ? (
                        <MediaCardListSmall
                          selectedMedia={selectedMedia}
                          isDelete
                          handleRemove={handleRemove}
                        />
                      ) : (
                        <div style={{ height: "190px", width: "100%" }}>
                          <ImageInput
                            errorMessage={errors.image?.message}
                            onChange={field.onChange}
                            onError={() => {
                              setError("image", {
                                message: "Invalid image file!"
                              });
                            }}
                          />
                        </div>
                      )}
                    </MediaModal>
                  )}
                />
              </div>
            </Form.Group>

            <Col className="gap-2 d-flex flex-column">
              <div className="form-row">
                <Form.Group
                  as={Col}
                  className="flex-grow-1"
                  controlId="footer_title_three"
                >
                  <Form.Label className="mark-required">
                    {t("settings_footer_title")}
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      {...register("footer_title_three")}
                      placeholder={t("settings_footer_title_phld")}
                      type="text"
                    />
                    <Button
                      onClick={() => {
                        onLanguageEdit(settings.footer_title_three?.id);
                      }}
                    >
                      <BsGlobe />
                    </Button>
                  </InputGroup>
                  {errors.footer_title_three?.message && (
                    <Form.Text className="text-danger">
                      {errors.footer_title_three.message}
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group
                  as={Col}
                  className="flex-grow-1"
                  controlId="footer_content_three"
                >
                  <Form.Label>{t("settings_footer_content")}</Form.Label>
                  <InputGroup>
                    <Form.Control
                      {...register("footer_content_three")}
                      placeholder={t("settings_footer_content_phld")}
                      type="text"
                    />
                    <Button
                      onClick={() => {
                        onLanguageEdit(settings.footer_content_three?.id);
                      }}
                    >
                      <BsGlobe />
                    </Button>
                  </InputGroup>
                  {errors.footer_content_three?.message && (
                    <Form.Text className="text-danger">
                      {errors.footer_content_three.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </div>
              <Form.Group controlId="footer_menu_id_three">
                <Form.Label>{t("settings_footer_menu")}</Form.Label>
                <div>
                  <Controller
                    render={({ field }) => (
                      <SelectPicker
                        onChange={field.onChange}
                        value={field.value}
                        style={{ width: "100%" }}
                        data={termList?.terms.map((t) => ({
                          value: String(t.id),
                          label: t.name
                        }))}
                        placeholder={t("settings_header_main_placeholder")}
                        loading={isTermsLoading}
                      />
                    )}
                    name="footer_menu_id_three"
                    control={control}
                  />
                </div>
                {errors.footer_menu_id_three?.message && (
                  <Form.Text className="text-danger">
                    {errors.footer_menu_id_three.message}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group controlId="footer_is_social_three">
                <Form.Label>{t("settings_footer_is_social")}</Form.Label>
                <Form.Control
                  {...register("footer_is_social_three")}
                  as="select"
                >
                  <option value="1">{t("settings_yes")}</option>
                  <option value="0">{t("settings_no")}</option>
                </Form.Control>
                {errors.footer_is_social_three?.message && (
                  <Form.Text className="text-danger">
                    {errors.footer_is_social_three.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </div>



          <div className="form-row">
            <Form.Group as={Col} className="flex-grow-1" controlId="is_search_three">
              <Form.Label>{t("settings_is_search_three")}</Form.Label>
              <Form.Control
                {...register("is_search_three")}
                as="select"
              >
                <option value="1">{t("settings_yes")}</option>
                <option value="0">{t("settings_no")}</option>
              </Form.Control>
              {errors.is_search_three?.message && (
                <Form.Text className="text-danger">
                  {errors.is_search_three.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group as={Col} className="flex-grow-1" controlId="is_subscription_three">
              <Form.Label>{t("settings_is_subscription_three")}</Form.Label>
              <Form.Control
                {...register("is_subscription_three")}
                as="select"
              >
                <option value="1">{t("settings_yes")}</option>
                <option value="0">{t("settings_no")}</option>
              </Form.Control>
              {errors.is_subscription_three?.message && (
                <Form.Text className="text-danger">
                  {errors.is_subscription_three.message}
                </Form.Text>
              )}
            </Form.Group>
          </div>
          <div className="form-row">
            <Form.Group as={Col} className="flex-grow-1" controlId="is_gallery_three">
              <Form.Label>{t("settings_is_gallery_three")}</Form.Label>
              <Form.Control
                {...register("is_gallery_three")}
                as="select"
              >
                <option value="1">{t("settings_yes")}</option>
                <option value="0">{t("settings_no")}</option>
              </Form.Control>
              {errors.is_gallery_three?.message && (
                <Form.Text className="text-danger">
                  {errors.is_gallery_three.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group as={Col} className="flex-grow-1" controlId="is_post_three">
              <Form.Label>{t("settings_is_post_three")}</Form.Label>
              <Form.Control
                {...register("is_post_three")}
                as="select"
              >
                <option value="1">{t("settings_yes")}</option>
                <option value="0">{t("settings_no")}</option>
              </Form.Control>
              {errors.is_post_three?.message && (
                <Form.Text className="text-danger">
                  {errors.is_post_three.message}
                </Form.Text>
              )}
            </Form.Group>
          </div>
          <div className="form-row">
            <Form.Group as={Col} className="flex-grow-1" controlId="button_text_three">
              <Form.Label className="">{t("settings_button_text_three")}</Form.Label>
              <Form.Control {...register("button_text_three")} placeholder={t("settings_button_text_three_phld")}
                type="text" />
              {errors.button_text_three?.message && (
                <Form.Text className="text-danger">{errors.button_text_three.message}</Form.Text>)}
            </Form.Group>
            <Form.Group as={Col} className="flex-grow-1" controlId="button_link_three">
              <Form.Label className="">{t("settings_button_link_three")}</Form.Label>
              <Form.Control {...register("button_link_three")} placeholder={t("settings_button_link_three_phld")}
                type="text" />
              {errors.button_link_three?.message && (
                <Form.Text className="text-danger">{errors.button_link_three.message}</Form.Text>)}
            </Form.Group>
          </div>
          <div className="form-row">

            <Form.Group className="flex-grow-1" controlId="footer_html_area_three">
              <Form.Label className="">{t("settings_footer_html_area_three")}</Form.Label>
              <Form.Control {...register("footer_html_area_three")} placeholder={t("settings_footer_html_area_three_phld")}
                type="text" />
              {errors.footer_html_area_three?.message && (
                <Form.Text className="text-danger">{errors.footer_html_area_three.message}</Form.Text>)}
            </Form.Group>

          </div>
          <div className="form-row">
            <Form.Group as={Col} className="flex-grow-1" controlId="select_form_three">
              <Form.Label className="">{t("settings_select_form_three")}</Form.Label>
              <Form.Control {...register("select_form_three")} placeholder={t("settings_select_form_three_phld")}
                type="text" />
              {errors.select_form_three?.message && (
                <Form.Text className="text-danger">{errors.select_form_three.message}</Form.Text>)}
            </Form.Group>
          </div>
          <div className="d-flex justify-content-end gap-2">
            <Button className="px-4" variant="success" type="submit">
              {t("common_settings_save")}
            </Button>
          </div>
        </Form>
      )}
    </section>
  );
}
