import "./misc.scss";
import {Link} from "react-router-dom";
import {FaHome} from "react-icons/fa";
import {MdBugReport} from "react-icons/md";
import NotFoundImage from "../../assets/svg/not-found.svg";

export default function NotFoundPage() {
  const thisYear = new Date().getFullYear();

  return (
    <section
      id="error-report-page"
      className="misc-page"
    >
      <div className="misc-content">
        <div className="jumbotron d-flex flex-column align-items-center gap-3">
          <img src={NotFoundImage} className="misc-image" alt="" />
          <h1 className="display-2 text-danger">404</h1>
          <p className="lead">Page not found</p>
          <p className="text-muted">If this is unusual are unexpected to you. please contact the admin.</p>
          <div className="d-inline-flex flex-column flex-md-row gap-2">
            <Link className="btn btn-outline-success" to={"/"}>
              <FaHome className="mr-2" />
              Back to home</Link>
            <Link className="btn btn-outline-danger" to={"/"}>
              <MdBugReport className="mr-2" />
              Report Problem</Link>
          </div>
        </div>
      </div>

      <small className="misc-copyright my-5">© {thisYear} ADN DigiNet CMS. All rights
        reserved </small>
    </section>
  );
}
