export default function filterReducers(state = {}, action) {
  switch (action.type) {
    case "set":
      return { ...state, [action.payload.key]: action.payload.value };
    case "unset":
      const { [action.payload]: _, ...remainingState } = state;
      return remainingState;
    case "clear":
      return {};
    case "reInitialize":
      return action.payload
    default:
      return state;
  }
}
