import {useCallback, useEffect, useState} from "react";
import {InputValidationError} from "../libs/error";
import {jsonToFormData} from "../helpers";
import {
    deleteMediaFromAlbum,
    getAlbum,
    getAlbumMedia,
    saveOnAddMedia,
    updateAlbum
} from "../pages/Album/album.service";

export default function useAlbum(id, setSelectedMedia, setCheckValue, setAllMediaId) {
    const [album, setAlbum] = useState()
    const [albumMedia, setAlbumMedia] = useState()
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const fetchAlbum = useCallback( async () => {
        try {
            setIsLoading(true)
            const response = await getAlbum(id)
            setAlbum(response.data.data)
        } catch (err) {
            setError({
                title: 'Failed to fetch album!',
                message: err.message,
            })
        } finally {
            setIsLoading(false)
        }
    },[])

    const fetchAlbumMedia = useCallback(async (page) => {
        try {
            setIsLoading(true)
            const response = await getAlbumMedia(id, page, 50)
            let customData = Object.entries(response.data.data.data).map(([key, value]) => value.album_pic);
            setAlbumMedia(response.data.data)
            setSelectedMedia(customData)

            let prevChecked =  Object.entries(response.data.data.data).map(([key, value]) => value.media_id);
            setCheckValue(prevChecked)
            let mediaIds =  Object.entries(response.data.data.data).map(([key, value]) => {
                return {
                    id: value.id,
                    media_id: value.media_id,
                }
            });
            setAllMediaId(mediaIds)
        } catch (err) {
            setError({
                title: 'Failed to fetch album!',
                message: err.message,
            })
        } finally {
            setIsLoading(false)
        }
    },[])

    const removeMediaFromAlbum = async (gallery_id) => {
        try {
            setIsLoading(true)
            const response = await deleteMediaFromAlbum(gallery_id)
        }catch (err) {
            setError({
                title: 'Failed to Delete Media!',
                message: err.message,
            })
        } finally {
            setIsLoading(false)
        }
    }

    async function editAlbum(data) {
        try {
            setIsLoading(true);
            data.taxonomy_id = 7
            if (data.image?.[0]) {
                data.image = data.image?.[0];
            }
            const response = await updateAlbum(id, data);
            setAlbum(response.data.data);
        } catch (err) {
            if (InputValidationError.isAPIInputValidationError(err)) {
                throw InputValidationError.fromAPIError(err);
            }
            throw err;
        } finally {
            setIsLoading(false);
        }
    }

    async function saveOnClick(album_id, data) {
        try {
            setIsLoading(true)
            const galley_data = {
                album_id: album_id,
                media_id: data
            }
            let form_data = jsonToFormData(galley_data)
            await saveOnAddMedia(form_data);
        } catch (err) {
            if (InputValidationError.isAPIInputValidationError(err)) {
                throw InputValidationError.fromAPIError(err);
            }
            throw err;
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchAlbum()
        fetchAlbumMedia()
    }, [fetchAlbum, fetchAlbumMedia])

    return { album, albumMedia, isLoading, editAlbum, fetchAlbumMedia, removeMediaFromAlbum, saveOnClick, error };
}
