import {useEffect} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {useLanguages} from "../../hooks/useLanguages";
import {LanguageSelector} from "../../components/Language/LanguageSelector";
import {useLanguage} from "../../hooks/useLanguage";
import {useLang} from "../../hooks";
import {EventLanguageInputSchema} from "./schema";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {useEvent} from "../../hooks/useEvent";
import {TextEdit} from "../../components";
import {getUrlFromStoragePath} from "../../configs";
import {useMediaSelector} from "../../components/MediaSelectorModal";
import MediaCardList from "../../components/MediaSelectorModal/MediaCardList";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";

export function EventLanguageEditPage() {
  const {langId, resourceId} = useParams();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {t} = useLang();

  const {language, isLoading, error, edit, deleteLanguageMedia} = useLanguage("event", langId);
  const {event, isLoading: isContentLoading, eventError} = useEvent(resourceId)

  const {langs, isLoading: isLangLoading, error: isLangError} = useLanguages();

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(EventLanguageInputSchema(t)),
  });

  const {
    checkValue,
    setCheckValue,
    selectedMedia,
    setSelectedMedia,
    allMediaId,
    setAllMediaId,
  } = useMediaSelector();

  if (error || isLangError || eventError) {
    notifyError(error)
    navigate(-1);
  }

  const handleServerRemove = async (media_id) => {
    try {
      await deleteLanguageMedia(media_id);
      notifySuccess({
        title: `Media Removed successfully`
      });
      setCheckValue(checkValue.filter(m => m!== media_id))
      setSelectedMedia(selectedMedia.filter(m => m.id !== media_id))
      setAllMediaId(allMediaId.filter(m => m!==media_id));
    } catch (err) {
      notifyError({
        title: "Failed to Removed media!",
        message: err.message
      });
    }
  };

  useEffect(() => {
    if (language) {
      setValue("language_id", language.language_id)
      setValue("title", language.title ?? "");
      setValue("description", language.description ?? "");
      setValue("organised_by", language.organised_by ?? "");
      setValue("resource_person", language.resource_person ?? "");
      setValue("location", language.location ?? "");
      setValue("start_date", language.start_date ?? "")
      setValue("end_date", language.end_date ?? "")

      let mediaIds = language?.files?.map(m => m.media_id) || [];
      let media = language?.files?.map(m => m.media_content) || [];

      setSelectedMedia(media);
      setCheckValue(mediaIds);
      setAllMediaId(mediaIds);
    }
  }, [language]);


  const handleEdit = async (data) => {
    try {
      const eventLanguageData = {...data, event_id: resourceId, media_id: checkValue}
      await edit(eventLanguageData);
      notifySuccess({
        title: `Language  updated successfully`,
      });
      navigate(-1);
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Failed to update language!",
        message: err.message,
      });
    }
  };

  return (
    <Layout>
      {(isLoading || isLangLoading || isContentLoading) && <CircleLoader/>}
      <section id="#event-language-create">
        <header>
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to={`/events/manage`}>Contents</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/langs/event/${resourceId}`}>{event?.title} Languages</Link>
            </li>
            <Breadcrumb.Item active>Edit language/ {langId}</Breadcrumb.Item>
          </Breadcrumb>
          <h3>Edit Language ({event?.name ?? resourceId})</h3>
        </header>
        <Form
          onSubmit={handleSubmit(handleEdit)}
          className="mt-4 d-flex  flex-column gap-3"
        >

          <LanguageSelector loading={isLangLoading} control={control} langs={langs} errors={errors}/>
          <Form.Group controlId="title">
            <Form.Label className="mark-required">{t("events_input_title")}</Form.Label>
            <Form.Control
              {...register("title")}
              placeholder={t("events_input_title_p")}
              type="text"
            />
            {errors.title?.message && (
              <Form.Text className="text-danger">
                {errors.title.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label className="mark-required">{t("events_input_startEndDateTime")}</Form.Label>
            <div className="d-flex gap-2">
              <div className="flex-grow-1">
                <Form.Control
                  {...register("start_date")}
                  type="text"
                />
                {errors.start_date?.message && (
                  <Form.Text className="text-danger">
                    {errors.start_date.message}
                  </Form.Text>
                )}
              </div>
              <div className="flex-grow-1">
                <Form.Control
                  {...register("end_date")}
                  type="text"
                />
                {errors.end_date?.message && (
                  <Form.Text className="text-danger">
                    {errors.end_date.message}
                  </Form.Text>
                )}
              </div>
            </div>
          </Form.Group>
          <Form.Group controlId="image">
            <Form.Label>{t("events_input_image")}</Form.Label>
            <div className="w-100">
              <Controller
                control={control}
                name="image"
                render={({field}) => (
                  <MediaModal selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                              checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                    {
                      selectedMedia?.length > 0 ? (
                        <MediaCardList selectedMedia={selectedMedia} isDeleteServer handleServerRemove={handleServerRemove}/>
                      ): (
                        <div style={{height: "300px", width: "100%"}}>
                          <ImageInput
                            errorMessage={errors.image?.message}
                            onChange={field.onChange}
                            onError={() => {
                              setError("image", {message: "Invalid image file!"});
                            }}
                          />
                        </div>
                      )
                    }
                  </MediaModal>
                )}
              />
            </div>
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label className="mark-required">{t("events_input_description")}</Form.Label>
            <Controller
              control={control}
              name="description"
              render={({field}) => <TextEdit defaultValue={language?.description} {...field} />}
            />
            {errors.description?.message && (
              <Form.Text className="text-danger">
                {errors.description.message}
              </Form.Text>
            )}
          </Form.Group>


          <div className="form-row">
            <Form.Group as={Col} md={5} controlId="organised_by">
              <Form.Label>{t("events_input_organisedBy")}</Form.Label>
              <Form.Control
                {...register("organised_by")}
                placeholder={t("events_input_organisedBy_p")}
                type="text"
              />
              {errors.organised_by?.message && (
                <Form.Text className="text-danger">
                  {errors.organised_by.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group as={Col} md={5} controlId="resource_person">
              <Form.Label>{t("events_input_resourcePerson")}</Form.Label>
              <Form.Control
                {...register("resource_person")}
                placeholder={t("events_input_resourcePerson_p")}
                type="text"
              />
              {errors.resource_person?.message && (
                <Form.Text className="text-danger">
                  {errors.resource_person.message}
                </Form.Text>
              )}
            </Form.Group>
          </div>

          <Form.Group controlId="location">
            <Form.Label>{t("events_input_location")}</Form.Label>
            <Form.Control
              {...register("location")}
              placeholder={t("events_input_location_p")}
              as="textarea"
              rows={3}
            />
            {errors.location?.message && (
              <Form.Text className="text-danger">
                {errors.location.message}
              </Form.Text>
            )}
          </Form.Group>
          <div className="d-flex gap-2 justify-content-end">
            <Button className="px-4" type="submit">
              Save
            </Button>
            <Link to={-1} className="px-4 btn btn-danger">
              Cancel
            </Link>
          </div>
        </Form>
      </section>
    </Layout>
  );
}
