import Layout from "../../components/layouts/layout";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {TestimonialInputSchema} from "./schema";
import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useLang, useTermList} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import CircleLoader from "../../components/Loaders/CircleLoader";
import { SelectPicker, Rate } from "rsuite";
import {InputValidationError} from "../../libs/error";
import {BackButton} from "../../components/BackButton/BackButton";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {createTestimonial} from "./testimonial.service";
import {useMediaSelector} from "../../components/MediaSelectorModal";
import MediaCard from "../MediaManagement/components/MediaCard";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";

export function TestimonialCreatePage() {
  const {t} = useLang();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const [hoverValue, setHoverValue] = useState(3);
  const texts = {
    1: 'Very Poor',
    2: 'Poor',
    3: 'Average',
    4: 'Good',
    5: 'Excellent'
  };

  const textStyle = {
    verticalAlign: 'top',
    lineHeight: '42px',
    display: 'inline-block'
  };

  const {termList, isLoading: isTermLoading, error: errTerm} =
    useTermList(12, {page: 1, limit: 1000,})

  const {
    selectedMedia,
    setSelectedMedia,
    checkValue,
    setCheckValue,
    allMediaId,
    setAllMediaId,
    handleRemove
  } = useMediaSelector();

  if (errTerm) {
    notifyError(errTerm, '/error')
  }

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    resolver: yupResolver(TestimonialInputSchema(t)),
  });

  const create = async (data) => {
    try {
      setIsLoading(true);
      const testimonialData = {...data, image: checkValue[0]};
      const testimonial = await createTestimonial(testimonialData);
      notifySuccess({
        title: "Testimonial created successfully",
        message: `Testimonial ${testimonial?.name} created!`,
      });
      navigate("/testimonials/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Layout>
      {isLoading && <CircleLoader/>}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/testimonials/manage`}> {t("testimonial_manage_testimonial")}</Link>
            </li>
            <Breadcrumb.Item active>
              {t("testimonial_create_createNewTestimonial")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("testimonial_manage_testimonial")}/>
        </div>
      </header>
      <section id="#taxonomy-create">
        <h2>{t("testimonial_create_createNewTestimonial")}</h2>
      </section>
      <Form onSubmit={handleSubmit(create)} className="container-fluid mt-4">
        <div className="form-row">
          <Form.Group as={Col} md={3}>
            <Form.Label>{t("testimonial_input_image")}</Form.Label>
            <Controller
              control={control}
              name="image"
              render={({field}) => (
                <MediaModal isSingleMode selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                            checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                  {
                    selectedMedia?.length > 0 ? (
                      selectedMedia?.map((md) =>
                        <MediaCard key={md.id} media={md} isDelete handleRemove={handleRemove}/>
                      )
                    ): (
                      <div style={{height: "300px", width: "100%"}}>
                        <ImageInput
                          errorMessage={errors.image?.message}
                          onChange={field.onChange}
                          onError={() => {
                            setError("image", {message: "Invalid image file!"});
                          }}
                        />
                      </div>
                    )
                  }
                </MediaModal>
              )}
            />
          </Form.Group>
          <Col>
            <Form.Group className="flex-grow-1" controlId="name">
              <Form.Label className="mark-required">{t("testimonial_input_name")}</Form.Label>
              <Form.Control {...register("name")} placeholder={t("testimonial_input_name_p")} type="text"/>
              {errors.name?.message && (
                <Form.Text className="text-danger">{errors.name.message}</Form.Text>)}
            </Form.Group>
            <Form.Group className="flex-grow-1 mt-2" controlId="designation">
              <Form.Label className="mark-required">{t("testimonial_input_designation")}</Form.Label>
              <Form.Control {...register("designation")} placeholder={t("testimonial_input_designation_p")}
                            type="text"/>
              {errors.designation?.message && (
                <Form.Text className="text-danger">{errors.designation.message}</Form.Text>)}
            </Form.Group>
            <Form.Group className="flex-grow-1 mt-2" controlId="description">
              <Form.Label className="mark-required">{t("testimonial_input_description")}</Form.Label>
              <Form.Control {...register("description")} placeholder={t("testimonial_input_description_p")}
                            as="textarea" rows={5}/>
              {errors.description?.message && (
                <Form.Text className="text-danger">{errors.description.message}</Form.Text>)}
            </Form.Group>
          </Col>
        </div>
        <div className="row mt-2">
          <div className="col-sm-6 col-lg-6">
            <Form.Group className="flex-grow-1" controlId="categoryId">
              <Form.Label className="mark-required">{t("testimonial_input_category")}</Form.Label>
              <Controller control={control} name="category_id"
                          render={({field}) =>
                            (<SelectPicker loading={isTermLoading} {...field} defaultExpandAll
                                           data={termList.terms.map(
                                             item => ({label: item.name, value: item.id})
                                           )} block searchable={false} placeholder="Select Categories" />)} />
              {errors.category_id?.message && (
                <Form.Text className="text-danger">{errors.category_id.message}</Form.Text>)}
            </Form.Group>
          </div>
          <div className="col-sm-6 col-lg-6">
            <Form.Group className="flex-grow-1" controlId="rating">
              <Form.Label className="mark-required">{t("testimonial_input_rating")}</Form.Label>
              <Controller name="rating" control={control} render={({field}) => (
                <>
                  <br/>
                  <Rate {...field} defaultValue={3} onChangeActive={setHoverValue} />{' '}
                  <span style={textStyle}>{texts[hoverValue]}</span>
                </>
              )}/>
              {errors.rating?.message && (
                <Form.Text className="text-danger">{errors.rating.message}</Form.Text>)}
            </Form.Group>
          </div>
        </div>

        <div className="row mt-2">

        </div>
        <div className="row mt-2">
          <div className="d-flex justify-content-end">
            <Button variant="primary" type="submit">{t("products_create_save")}</Button>
          </div>
        </div>
      </Form>
    </Layout>
  );
}