import {useRef} from "react";
import {Breadcrumb, Button} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {BsPencilSquare} from "react-icons/bs";
import {CgPlayListAdd} from "react-icons/cg";
import {FaSearch} from "react-icons/fa";
import {MdDeleteOutline} from "react-icons/md";
import {Link, useNavigate} from "react-router-dom";
import {Input, InputGroup} from "rsuite";
import {FloatingButton} from "../../components";
import Layout from "../../components/layouts/layout";
import {Permission} from "../../configs/constants";
import ComponentGuard from "../../helpers/ComponentGuard";
import {useAlbumList, useLang} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";

function AlbumListPage() {
  const {t} = useLang();
  const {
    albumList, pagination, filterDispatch,
    changePagination, deleteAlbum, isLoading, error
  } = useAlbumList();
  const {notifyError, notifySuccess} = useNotification();
  const searchBoxRef = useRef();
  const navigate = useNavigate();

  if (error) {
    notifyError({title: "Error", message: error.message}
      , "/");
  }

  const handleDelete = async (id) => {
    try {
      await deleteAlbum(id);
      notifySuccess({
        title: "Album removed successfully"
      });
    } catch (err) {
      notifyError({
        title: "Failed to remove album!",
        message: err.message
      });
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) => (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
      width: "50px"
    },
    {
      name: t("album_manage_col_name"),
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: t("album_manage_col_action"),
      selector: (m) => {
        return (
          <div className="d-flex gap-2">
            <Link to={`/albums/edit/${m.id}`} className="btn btn-secondary">
              <BsPencilSquare />
            </Link>
            <Button variant="danger" onClick={() => handleDelete(m.id)}>
              <MdDeleteOutline />
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <>
      <Layout>
        <header>
          <div className="d-flex justify-content-between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item active>
                  {t("album_manage_albums")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </header>
        <section id="albumList" className="mb-5 pb-5">
          <h2>{t("album_manage_albums")}</h2>
          <DataTable
            columns={columns}
            data={albumList.albums}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationComponentOptions={{noRowsPerPage: true}}
            paginationTotalRows={albumList.total}
            onChangePage={(page) => {
              changePagination({page});
            }}
            fixedHeader
            fixedHeaderScrollHeight="800px"
            highlightOnHover
            subHeader
            subHeaderComponent={
              <InputGroup className="mb-3" style={{width: "300px"}}>
                <Input ref={searchBoxRef} placeholder={t("album_manage_searchByName")} />
                <InputGroup.Button onClick={() => filterDispatch({
                  type: "set",
                  payload: {key: "term", value: searchBoxRef.current.value}
                })}>
                  <FaSearch />
                </InputGroup.Button>
              </InputGroup>
            }
            subHeaderAlign="right"
          />
        </section>
      </Layout>
      <ComponentGuard needsPermission={[Permission.TaxonomyStore]}>
        <FloatingButton
          onClick={() => {
            navigate("/taxonomies/7/terms/new");
          }}
          icon={<CgPlayListAdd />}
          name={t("album_manage_newAlbum")}
        />
      </ComponentGuard>
    </>
  );
}

export default AlbumListPage;
