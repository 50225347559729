import { useImmer } from "use-immer";

const defaultPagination = { page: 1, limit: 10 };

export function usePagination(initialPagination = defaultPagination) {
  const [pagination, updatePagination] = useImmer({
    ...defaultPagination,
    ...initialPagination,
  });
  const goToPrevPage = () => {
    updatePagination((p) => {
      p.page--;
      if (p.page < 1) {
        p.page = 1;
      }
    });
  };

  const goToNextPage = (totalPage) => {
    updatePagination((p) => {
      p.page++;
      if (p.page > totalPage) {
        p.page = totalPage;
      }
    });
  };

  const changePagination = (changes) => {
    updatePagination((p) => {
      p.page = changes.page ?? p.page;
      p.limit = changes.limit ?? p.limit;
    });
  };

  return { pagination, changePagination, goToNextPage, goToPrevPage };
}
