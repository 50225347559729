import {Form} from "react-bootstrap";
import {SelectPicker} from "rsuite";
import {Controller} from "react-hook-form";

export function LanguageSelector({disabled, control, loading, langs, errors, showLabel = true}) {
  return <Form.Group controlId="language_id">
    {showLabel && <Form.Label className="mark-required">Select Language</Form.Label>}
    <div>
      <Controller render={({field}) => <SelectPicker
        disabled={disabled}
        onChange={field.onChange}
        value={field.value}
        style={{width: "100%"}}
        data={langs.map(l => ({value: l.id, label: `${l.name} (${l.code})`}))}
        placeholder="Select a language"
        loading={loading}
      />
      } name="language_id" control={control} />
    </div>
    {errors.language_id?.message && (
      <Form.Text className="text-danger">
        {errors.language_id.message}
      </Form.Text>
    )}
  </Form.Group>;
}