import {useCallback, useEffect, useReducer, useState} from "react";
import {usePagination} from "./usePagination";
import {getPaginatedTaxonomies, removeTaxonomy} from "../services/taxonomy";
import filterReducers from "../reducers/filterReducer";

export function useTaxonomyList(initialFilter, initialPagination) {
  const [taxonomyList, setTaxonomyList] = useState({
    taxonomies: [],
    total: 0
  });

  const {pagination, changePagination} = usePagination(initialPagination ?? {
    page: 1,
    limit: 10
  });

  const [filter, filterDispatch] = useReducer(filterReducers, initialFilter ?? {});

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);


  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      const paginatedList = await getPaginatedTaxonomies({
        page: pagination.page,
        ...filter
      });
      setTaxonomyList({
        taxonomies: paginatedList.data,
        total: paginatedList.total
      });
      setIsLoading(false);
    } catch (err) {
      setError({
        title: "Something went wrong",
        message: err.message
      });
    }
  }, [filter, pagination]);

  const deleteTaxonomy = async (id) => {
    await removeTaxonomy(id);
    await fetch();
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    taxonomyList,
    pagination,
    changePagination,
    deleteTaxonomy,
    filterDispatch,
    isLoading,
    error
  };
}
