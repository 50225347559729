import React, {useState} from "react";
import {Link} from "react-router-dom";
import Layout from "../../components/layouts/layout";

import {Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";

import {yupResolver} from "@hookform/resolvers/yup";
import {MediaCreateSchema} from "./schema";
import {useLang} from "../../hooks";
import CircleLoader from "../../components/Loaders/CircleLoader";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {FileInput} from "../../components/MediaInputs/fileinput";
import {createMedia} from "../../services/media";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";
import {VideoInput} from "../../components/MediaInputs/videoInput";
import {useMediaCategories} from "../../hooks/useMediaCategories";
import {SelectPicker} from "rsuite";

function MediaCreatePage() {
  const {t} = useLang();
  const {notifyError, notifySuccess} = useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const {
    watch,
    register,
    handleSubmit,
    control,
    setError,
    formState: {errors}
  } = useForm({
    resolver: yupResolver(MediaCreateSchema(t))
  });
  const {categories, isLoading: isCategoriesLoading, error: catErr} = useMediaCategories();

  let mediaTypeSelected = watch("media_category_id");

  const addMedia = async ({image, video, document, thumbnail_url, external_url, ...data}) => {
    try {
      setIsLoading(true);
      const media = await createMedia({image, video, document, thumbnail_url, external_url, ...data});
      notifySuccess({
        title: "Media created successfully",
        message: `Media ${media.title} created!`
      }, "/media/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: err.message
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (catErr) {
    notifyError(catErr, "/error");
  }

  return (
    <Layout>
      {isLoading && <CircleLoader />}
      <div className="form_design">
        <h2 style={{color: "black", textDecoration: "underline"}}>{t("media_create_createMedia")}</h2>
        <Form onSubmit={handleSubmit(addMedia)}>
          <Form.Group>
            <Form.Label className="form_label mark-required">{t("media_input_selectMediaType")}</Form.Label>
            <Controller name="media_category_id" control={control} render={({field: {onChange, value}}) => (
              <SelectPicker onChange={onChange} value={value} data={categories?.map((item) => ({
                label: item.name,
                value: item.id
              }))} block searchable={false} loading={isCategoriesLoading} />
            )} />
            {errors?.media_category_id?.message &&
              <Form.Text className="text-danger">{errors.media_category_id.message}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label className="form_label mark-required">{t("media_input_title")}</Form.Label>
            <Form.Control {...register("title")} type="text" placeholder={t("media_input_title_p")} />
            {errors?.title?.message && <Form.Text className="text-danger">{errors.title.message}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label className="form_label">{t("media_input_alternativeText")}</Form.Label>
            <Form.Control {...register("alt_text")} type="text" placeholder={t("media_input_alternativeText_p")} />
            {errors?.alt_text?.message && <Form.Text className="text-danger">{errors.alt_text.message}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label className="form_label">{t("media_input_description")}</Form.Label>
            <Form.Control {...register("description")} as="textarea" rows={3}
                          placeholder={t("media_input_description_p")} />
            {errors?.description?.message &&
              <Form.Text className="text-danger">{errors.description.message}</Form.Text>}
          </Form.Group>
          {Number(mediaTypeSelected) === 1 && (
            <div>
              <Form.Group controlId="image" className="mb-3">
                <Form.Label className="form_label mark-required">{t("media_input_selectImage")}</Form.Label>
                <Controller
                  control={control}
                  name="image"
                  render={({field}) => (
                    <div style={{height: "300px"}}>
                      <ImageInput
                        isUpload
                        errorMessage={errors.image?.message}
                        onChange={field.onChange}
                        onError={() => {
                          setError("image", {message: "Invalid image file!"});
                        }}
                      />
                    </div>
                  )}
                />
                <Form.Text className="text-muted">
                  {t("media_input_selectImage_t")}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="thumbnail_width" className="mb-2">
                <Form.Label className="form_label">{t("media_input_thumbnail_width")}</Form.Label>
                <Form.Control {...register("thumbnail_width")} type="text" placeholder={t("media_input_thumbnail_width")} />
                {errors?.thumbnail_width?.message && <Form.Text className="text-danger">{errors.thumbnail_width.message}</Form.Text>}
              </Form.Group>
              <Form.Group controlId="thumbnail_height" className="mb-2">
                <Form.Label className="form_label">{t("media_input_thumbnail_height")}</Form.Label>
                <Form.Control {...register("thumbnail_height")} type="text" placeholder={t("media_input_thumbnail_height")} />
                {errors?.thumbnail_height?.message && <Form.Text className="text-danger">{errors.thumbnail_height.message}</Form.Text>}
              </Form.Group>
              <Form.Group controlId="custom_width" className="mb-2">
                <Form.Label className="form_label">{t("media_input_custom_width")}</Form.Label>
                <Form.Control {...register("custom_width")} type="text" placeholder={t("media_input_custom_width")} />
                {errors?.custom_width?.message && <Form.Text className="text-danger">{errors.custom_width.message}</Form.Text>}
              </Form.Group>
              <Form.Group controlId="custom_height" className="mb-2">
                <Form.Label className="form_label">{t("media_input_custom_height")}</Form.Label>
                <Form.Control {...register("custom_height")} type="text" placeholder={t("media_input_custom_height")} />
                {errors?.custom_height?.message && <Form.Text className="text-danger">{errors.custom_height.message}</Form.Text>}
              </Form.Group>
            </div>
          )}
          {Number(mediaTypeSelected) === 2 && (
            <div className="form-row mb-3">
              <Form.Group as={Col} controlId="video">
                <Form.Label className="form_label mark-required">{t("media_input_selectVideo")}</Form.Label>
                <Controller
                  control={control}
                  name="video"
                  render={({field}) => (
                    <div style={{height: "300px"}}>
                      <VideoInput
                        errorMessage={errors.video?.message}
                        onChange={field.onChange}
                        onError={() => {
                          setError("video", {message: "Invalid video file!"});
                        }}
                      />
                    </div>
                  )}
                />
                <Form.Text className="text-muted">
                  {t("media_input_selectVideo_t")}
                </Form.Text>
              </Form.Group>
              <Form.Group as={Col} controlId="thumbnail_url">
                <Form.Label className="form_label">{t("media_input_thumbnailImage")}</Form.Label>
                <Controller
                  control={control}
                  name="thumbnail_url"
                  render={({field}) => (
                    <div style={{height: "300px"}}>
                      <ImageInput
                        isUpload
                        errorMessage={errors.thumbnail_url?.message}
                        onChange={field.onChange}
                        onError={() => {
                          setError("thumbnail_url", {message: "Invalid image file!"});
                        }}
                      />
                    </div>
                  )}
                />
                <Form.Text className="text-muted">
                  {t("media_input_selectImage_t")}
                </Form.Text>
              </Form.Group>
            </div>
          )}
          {Number(mediaTypeSelected) === 3 && (
            <Form.Group controlId="document" className="mb-3">
              <Form.Label className="form_label mark-required">{t("media_input_selectDocument")}</Form.Label>
              <Controller
                control={control}
                name="document"
                render={({field}) => (
                  <div style={{height: "150px"}}>
                    <FileInput
                      errorMessage={errors.document?.message ?? errors.original_url?.message}
                      onChange={field.onChange}
                      onError={() => {
                        setError("document", {message: "Invalid Document file!"});
                      }}
                    />
                  </div>
                )}
              />
              <Form.Text className="text-muted">
                {t("media_input_selectDocument_t")}
              </Form.Text>
            </Form.Group>
          )}
          {Number(mediaTypeSelected) === 4 && (
            <div>
            <Form.Group controlId="external_url" className="mb-3">
              <Form.Label className="form_label mark-required">{t("media_input_selectUrl")}</Form.Label>
              <Form.Control {...register("external_url")} type="url" placeholder={t("media_input_selectUrl_p")} />
              {errors?.external_url?.message &&
                <Form.Text className="text-danger">{errors.external_url.message}</Form.Text>}
            </Form.Group>
            <Form.Group controlId="thumbnail_url">
              <Form.Label className="form_label">{t("media_input_thumbnailImage")}</Form.Label>
              <Controller
                control={control}
                name="thumbnail_url"
                render={({field}) => (
                  <div style={{height: "300px"}}>
                    <ImageInput
                      isUpload
                      errorMessage={errors.thumbnail_url?.message}
                      onChange={field.onChange}
                      onError={() => {
                        setError("thumbnail_url", {message: "Invalid image file!"});
                      }}
                    />
                  </div>
                )}
              />
              <Form.Text className="text-muted">
                {t("media_input_selectImage_t")}
              </Form.Text>
            </Form.Group>
            </div>
          )}
          <div class="mt-3 d-flex gap-2">
            <Button className="px-3" type="submit" variant="primary">
              {t("media_create_save")}
            </Button>
            <Link className="btn btn-danger px-3" to="/media/manage">
              {t("media_create_cancel")}
            </Link>
          </div>
        </Form>
      </div>
    </Layout>
  );
}

export default MediaCreatePage;
