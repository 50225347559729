import React from "react";
import { useState } from "react";
import { useLang, useTermList } from "../../hooks";
import { useNotification } from "../../hooks/useNotification";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubscriberInputSchema } from "./schema";
import { createSubscriber } from "./subscription.service";
import { InputValidationError } from "../../libs/error";
import Layout from "../../components/layouts/layout";
import CircleLoader from "../../components/Loaders/CircleLoader";
import { Breadcrumb, Button, Col, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { BackButton } from "../../components/BackButton/BackButton";
import { Toggle,Modal } from "rsuite";


function SubscriberCreate() {
    const { t } = useLang();
    const [isLoading, setIsLoading] = useState(false);

    const {
        termList,
        error: termsError,
        isLoading: termLoading
    } = useTermList(13, { limit: 1000, page: 1 });
    const { notifyError, notifySuccess } = useNotification();
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setError
    } = useForm({
        resolver: yupResolver(SubscriberInputSchema(t))
    });

    if (termsError) {
        notifyError(termsError, "/error");
    }


    const create = async (data) => {
        try {
            setIsLoading(true);
            const post = await createSubscriber(data);

            notifySuccess(
                {
                    title: "Subscriber created successfully",
                    message: `Subscriber created!`
                },
                "/subscription/manage"
            );
        } catch (err) {
            if (err instanceof InputValidationError) {
                err.setRHFError(setError);
                notifyError({
                    title: "Input validation failed",
                    message: err.message
                });
                return;
            }

            notifyError({
                title: "Failed to create pricing",
                message: err.message
            });
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <Layout>
            {isLoading && <CircleLoader />}
            <header>
                <div className="d-flex justify-content-between align-items-center">
                    <Breadcrumb className="d-flex align-items-center">
                        <li className="breadcrumb-item">
                            <Link to={`/subscription/manage`}> {t("subscriber")}</Link>
                        </li>
                        <Breadcrumb.Item active>{t("subscribe_add")}</Breadcrumb.Item>
                    </Breadcrumb>
                    <BackButton path={-1} text={t("pricing")} />
                </div>
            </header>
            <section id="#page-create">
                <h2>{t("subscribe_add")}</h2>
                <Form
                    onSubmit={handleSubmit(create)}
                    className="mt-4 d-flex  flex-column gap-3"
                >

                    <div className="form-row">
                        <Form.Group as={Col} controlId="name">
                            <Form.Label className="mark-required">
                                {t("subscribe_list_column_name")}
                            </Form.Label>
                            <Form.Control
                                {...register("name")}
                                placeholder={t("subscriber_enter_name")}
                                type="text"
                            />
                            {errors.name?.message && (
                                <Form.Text className="text-danger">
                                    {errors.name.message}
                                </Form.Text>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} controlId="email">
                            <Form.Label className="mark-required">{t("subscribe_list_column_email")}</Form.Label>
                            <Form.Control
                                {...register("email")}
                                placeholder={t("subscriber_enter_email")}
                                type="text"
                            />
                            {errors.email?.message && (
                                <Form.Text className="text-danger">
                                    {errors.email.message}
                                </Form.Text>
                            )}
                        </Form.Group>
                    </div>
                    <div className="form-row">
                        <Form.Group as={Col} controlId="phone_no">
                            <Form.Label className="mark-required">{t("subscribe_list_column_phone_number")}</Form.Label>
                            <Form.Control
                                {...register("phone_no")}
                                placeholder={t("subscriber_enter_phone")}
                                type="text"
                            />
                            {errors.phone_no?.message && (
                                <Form.Text className="text-danger">
                                    {errors.phone_no.message}
                                </Form.Text>
                            )}
                        </Form.Group>
                        <Form.Group as={Col} controlId="status">
                            <Form.Label >{t("subscriber_isActive")}</Form.Label>
                            <Controller
                                control={control}
                                name="status"
                                render={({ field }) => (
                                    <div>
                                        <Toggle
                                            {...field}
                                            size="lg"
                                            checkedChildren={<span>Active</span>}
                                            unCheckedChildren={<span>InActive</span>}
                                            va
                                            className="m-auto"
                                        />
                                    </div>
                                )}
                            />
                            {errors.status?.message && (
                                <Form.Text className="text-danger">
                                    {errors.status.message}
                                </Form.Text>
                            )}
                        </Form.Group>
                    </div>

                    <div className="d-flex justify-content-end gap-2">
                        <Button className="px-4" type="submit">
                            {t("pages_create_save")}
                        </Button>
                        <Link
                            className="btn btn-danger px-4"
                            to="/subscription/manage"
                            type="submit"
                        >
                            {t("pages_create_cancel")}
                        </Link>
                    </div>
                </Form>
            </section>
        </Layout>
    );
}

export default SubscriberCreate;
