import {useCallback, useEffect, useState} from "react";
import {getTerm, updateTerm} from "../services/term";

export function useTerm(id) {
  const [term, setTerm] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      setTerm(await getTerm(id));
    } catch (err) {
      setError({
        title: "Failed to fetch term!",
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  const editTerm = useCallback(async (input) => {
    try {
      setIsLoading(true);
      setTerm(await updateTerm(id, input));
    } finally {
      setIsLoading(false);
    }
  }, [])

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {term, isLoading, editTerm, error};
}
