export class InputValidationError extends Error {
  ve;

  static isAPIInputValidationError(err) {
    return (
      err?.response?.status === 400 &&
      err?.response?.data.message === "Validation Error."
    );
  }

  static fromAPIError(err) {
    if (!this.isAPIInputValidationError) {
      throw new Error("Invalid api input validation error");
    }
    return new InputValidationError(err.response.data.data);
  }

  constructor(ve) {
    super("Validation failed: " + Object.values(ve)[0]);
    this.ve = ve;
  }

  setRHFError(setError) {
    for (const [key, value] of Object.entries(this.ve)) {
      console.log("Validation error: ", key, value);
      setError(key, { type: "custom", message: value[0] });
    }
  }
}
