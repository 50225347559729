import {useCallback, useEffect, useReducer, useState} from "react";
import filterReducers from "../reducers/filterReducer";
import {usePagination} from "./usePagination";
import {getPaginatedEvent, removeEvent} from "../services/event";

export function useEventList() {
  const [list, setList] = useState({pages: [], total: 0});
  const [filter, filterDispatch] = useReducer(filterReducers, {});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const {pagination, changePagination} = usePagination({
    page: 1,
    limit: 10
  });

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      const events = await getPaginatedEvent({
        page: pagination.page,
        ...filter
      });
      setList({
        events: events.data,
        total: events.total
      });
      setIsLoading(false);
    } catch (err) {
      setError({
        title: "Failed to fetch event list",
        message: err.message
      });
    }
  }, [pagination, filter]);

  const deleteItem = async (id) => {
    await removeEvent(id);
    await fetch();
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    list,
    filterDispatch,
    pagination,
    changePagination,
    deleteItem,
    isLoading,
    error,
    filter
  };
}
