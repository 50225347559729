import {useCallback, useEffect, useReducer, useState} from "react";
import filterReducers from "../reducers/filterReducer";
import {usePagination} from "./usePagination";
import {usePrivateAPI} from "./usePrivateApi";
import {getPaginatedPost, removePost} from "../services/post";

export function usePostList() {
  const [postList, setPostList] = useState({posts: [], total: 0});
  const [filter, filterDispatch] = useReducer(filterReducers, {});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const {http} = usePrivateAPI();
  const {pagination, changePagination} = usePagination({
    page: 1,
    limit: 10,
  });

  const fetchPosts = useCallback(async () => {
    try {
      setIsLoading(true);
      const posts = await getPaginatedPost({
        page: pagination.page,
        ...filter,
      })
      setPostList({
        posts: posts.data,
        total: posts.total
      });

      setIsLoading(false);
    } catch (err) {
      setError({
        title: "Something went wrong",
        message: err.message,
      });
    }
  }, [pagination, filter]);

  const deletePost = async (id) => {
    await removePost(id)
    await fetchPosts();
  };

  useEffect(() => {
    fetchPosts();
  }, [pagination, filter]);

  return {
    postList,
    filterDispatch,
    pagination,
    changePagination,
    deletePost,
    isLoading,
    error,
    filter,
  };
}
