import http from "../../http";
import {FETCH_USER_ERR, FETCH_USER_REQ, FETCH_USER_SUCC} from "./listType";


export const fetch_user_req = () => {
  return {
    type: FETCH_USER_REQ
  }
}

export const fetch_user_succ = (data) => {

  return {
    type: FETCH_USER_SUCC,
    payload: data
  }
}

export const fetch_user_err = (err) => {
  return {
    type: FETCH_USER_ERR,
    payload: err
  }
}

export const fetchUsers = (cookies) => {
  // const cookies = new Cookies();
  return (dispatch) => {
    dispatch(fetch_user_req);
    http.get('/users/get/' + cookies.get('userAuth')?.user?.id, {
      headers: {
        "Authorization": "Bearer " + cookies.get('userAuth').token,
      }
    })
      .then(data => {
        const list_data = data.data.data;
        dispatch(fetch_user_succ(list_data))
      })
      .catch(err => {
        const error = err.message;
        dispatch(fetch_user_err(error))
      })
  }
}
