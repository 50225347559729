import {useRef, useState} from "react";
import {MdAddPhotoAlternate, MdClose} from "react-icons/md";
import { TbTablePlus } from "react-icons/tb";
import "./index.scss";
import {Tooltip, Whisper} from "rsuite";


export function LayoutInput({isUpload, defaultPreviewImage, previousImage, onChange, onError, errorMessage, handleRemove, media_id}) {


  const inputRef = useRef();
  const [touched, setTouched] = useState(false);
  const [newImage, setNewImage] = useState(null)

  previousImage = touched ? null : previousImage;

  const handleCancelInput = (e) => {
    if(isUpload) {
      inputRef.current.value = "";
      inputRef.current.type = "text";
      inputRef.current.type = "file";
    }
    setNewImage(null)
    setTouched(true);
    onChange?.(null)
  };

  const handleChange = (e) => {
    e.preventDefault();
    const upload = e.target.files?.[0] ?? null
    setNewImage(URL.createObjectURL(upload));
    setTouched(true)
    console.log("upload", upload)
    onChange?.(upload)
  }

  const handleError = () => {
    onError?.("Invalid image file")
  }

  const tooltip = errorMessage ? <Tooltip className="text-danger">{errorMessage}</Tooltip> :
    <Tooltip>{newImage?.toString() ?? previousImage ?? "Select Layout"}</Tooltip>

    return (
      <Whisper placement="auto" controlId="control-id-hover" trigger="hover" speaker={tooltip}>
        <div
          className={"media-edit " + (!errorMessage ? "" : " error")}
          onClick={(e) => { if(errorMessage) e.stopPropagation(); }}
        >
          {newImage ? <img
            onError={handleError}
            alt="Invalid"
            src={newImage}
          /> : previousImage ?? defaultPreviewImage ? <img
              onError={handleError}
              alt="Invalid"
              src={previousImage ?? defaultPreviewImage}
            /> :
            (<TbTablePlus size={30} />)}
          {(previousImage || newImage) && (<button onClick={(e) => {
            handleCancelInput(e);
            handleRemove(e, media_id);
          }} className="close-btn">
            <MdClose className="close-btn-content" />
          </button>)}
        </div>
      </Whisper>
    );
}
