import {useRef} from "react";
import {Breadcrumb, Button} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {BsGlobe, BsPencilSquare} from "react-icons/bs";
import {FaSearch} from "react-icons/fa";
import {MdDeleteOutline, MdNewLabel} from "react-icons/md";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Input, InputGroup} from "rsuite";
import {FloatingButton} from "../../components";
import Layout from "../../components/layouts/layout";
import {Permission} from "../../configs/constants";
import ComponentGuard from "../../helpers/ComponentGuard";
import {useLang, useTermList} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import useTaxonomy from "../../hooks/useTaxonomy";
import CircleLoader from "../../components/Loaders/CircleLoader";
import {BackButton} from "../../components/BackButton/BackButton";

export function TermListPage() {
  const {t} = useLang();
  const {id: taxonomyId} = useParams();
  const {
    taxonomy,
    error: taxonomyError,
    isLoading: isTaxonomyLoading
  } = useTaxonomy(taxonomyId);

  const {
    termList,
    pagination,
    changePagination,
    error,
    isLoading,
    filterDispatch,
    searchByTerm,
    deleteTerm
  } = useTermList(taxonomyId);
  const {notifyError, notifySuccess} = useNotification();
  const searchBoxRef = useRef();
  const navigate = useNavigate();

  if (taxonomyError || error) {
    notifyError(error);
    setTimeout(() => navigate("/"), 200);
  }

  const handleDelete = async (id) => {
    try {
      await deleteTerm(id);
      notifySuccess({
        title: "Term removed successfully"
      });
    } catch (err) {
      notifyError({
        title: "Failed to remove term!",
        message: err.message
      });
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) =>
        (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
      width: "50px"
    },
    {
      name: t("terms_manage_col_name"),
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: t("terms_manage_col_slug"),
      selector: (m) => m.slug
    },
    {
      name: t("terms_manage_col_action"),
      selector: (m) => {
        if (m.id > 17) {
          return (
            <div className="d-flex gap-2">
              <ComponentGuard needsPermission={[Permission.TermsUpdate]}>
                <Link to={`/taxonomies/${taxonomyId}/terms/${m.id}/edit`} className="btn btn-secondary">
                  <BsPencilSquare />
                </Link>
              </ComponentGuard>
              <ComponentGuard needsPermission={[Permission.TermsDelete]}>
                <Button variant="danger" onClick={() => handleDelete(m.id)}>
                  <MdDeleteOutline />
                </Button>
              </ComponentGuard>
              <ComponentGuard needsPermission={[Permission.TermLanguagesList]}>
                <Link to={`/langs/term/${m.id}`} className="btn btn-info">
                  <BsGlobe />
                </Link>
              </ComponentGuard>
            </div>
          );
        } else {
          return (
            <Link to={`/langs/term/${m.id}`} className="btn btn-info">
              <BsGlobe />
            </Link>
          );
        }
      }
    }
  ];

  return (
    <>
      <Layout>
        {isTaxonomyLoading && <CircleLoader />}
        <header>
          <div className="d-flex justify-content-between">
              <Breadcrumb>
                <Breadcrumb.Item active>
                  {t("terms_manage_terms")}
                </Breadcrumb.Item>
              </Breadcrumb>
              <BackButton path={-1} text={t("taxonomies_manage_taxonomies")}/>
          </div>
        </header>
        <section id="termList" className="mb-5 pb-5">
          <h2>{taxonomy?.name ?? "Taxonomy"} / {t("terms_manage_terms")}</h2>
          <DataTable
            columns={columns}
            data={termList.terms}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationComponentOptions={{noRowsPerPage: true}}
            paginationTotalRows={termList.total}
            onChangePage={(page) => {
              changePagination({page});
            }}
            fixedHeader
            fixedHeaderScrollHeight="800px"
            highlightOnHover
            subHeader
            subHeaderComponent={
              <InputGroup className="mb-3" style={{width: "300px"}}>
                <Input ref={searchBoxRef} placeholder={t("terms_manage_searchByName")} />
                <InputGroup.Button
                  onClick={() => filterDispatch({
                    type: "set",
                    payload: {
                      key: "term",
                      value: searchBoxRef.current.value
                    }
                  })}
                >
                  <FaSearch />
                </InputGroup.Button>
              </InputGroup>
            }
            subHeaderAlign="right"
          />
        </section>
      </Layout>
      <ComponentGuard needsPermission={[Permission.TermsStore]}>
        <FloatingButton
          onClick={() => {
            navigate(`/taxonomies/${taxonomyId}/terms/new`);
          }}
          icon={<MdNewLabel />}
          name={t("terms_manage_newTerm")}
        />
      </ComponentGuard>
    </>
  );
}

export default TermListPage;
