import {useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {createLanguage} from "../../services/language";
import {useLanguages} from "../../hooks/useLanguages";
import {LanguageSelector} from "../../components/Language/LanguageSelector";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {useLang} from "../../hooks";
import {BackButton} from "../../components/BackButton/BackButton";
import useComponent from "../../hooks/useComponent";
import {getUrlFromStoragePath} from "../../configs";
import {ComponentLanguageSchema} from "./schema";
import {useMediaSelector} from "../../components/MediaSelectorModal";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";
import MediaCardList from "../../components/MediaSelectorModal/MediaCardList";
import MediaCard from "../MediaManagement/components/MediaCard";
import {TextEdit} from "../../components";

export function ComponentLanguageCreate() {
  const {t} = useLang();
  const {resourceId} = useParams();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {component, isLoading: isComponentLoading, error} = useComponent(resourceId);
  const {langs, isLoading: isLangLoading, error: isLangError} = useLanguages();

  if (error || isLangError) {
    notifyError({
      title: "Something went wrong!",
      message: error.message
    }, "/error");
  }

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    reset
  } = useForm({
    resolver: yupResolver((ComponentLanguageSchema()))
  });

  const {
    checkValue,
    setCheckValue,
    selectedMedia,
    setSelectedMedia,
    allMediaId,
    setAllMediaId,
    handleRemove,
  } = useMediaSelector();

  useEffect(() => {
    if (component) {
      reset({
        title: component.title ?? "",
        description: component.description ?? "",
        subTitle: component.subTitle ?? "",
        description1: component.description1 ?? "",
        description2: component.description2 ?? "",
        youTubeID: component.youTubeID ?? "",
        link1: component.link1 ?? "",
        link2: component.link2 ?? "",
        link3: component.link3 ?? "",
      });

      let mediaIds = component?.files ? component?.files.map(m => m.media_id) : [];
      let media = component?.files ? component?.files.map(m => m.media_content) : [];

      setSelectedMedia(media);
      setCheckValue(mediaIds);
      setAllMediaId(mediaIds);
    }
  }, [component]);


  const create = async (data) => {
    try {
      setIsLoading(true);
      data.component_id = resourceId;
      data.image = data.image ?? "same_as_parent";
      const componentLangData = {...data, media_id: checkValue}
      await createLanguage("component", componentLangData);

      notifySuccess({
        title: "Language created successfully!",
        message: `Language created!`
      });
      navigate(-1);
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Input validation failed",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: "Language creation failed!"
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      {(isLoading || isLangLoading) && <CircleLoader />}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={"/taxonomies/manage"}>{t("taxonomies_manage_taxonomies")}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/langs/component/${resourceId}`}>{component?.name} Languages</Link>
            </li>
            <Breadcrumb.Item active>Create language</Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("taxonomies_manage_taxonomies")} />
        </div>
      </header>
      <section id="#component-create">

        <Form
          onSubmit={handleSubmit(create)}
          className="mt-4 d-flex  flex-column gap-3"
        >

          <LanguageSelector loading={isLangLoading} control={control} langs={langs} errors={errors} />
          <div className="form-row gap-2 gap-md-0">
            <Form.Group as={Col} md={12} className="flex-grow-1" controlId="title">
              <Form.Label className="mark-required">{t("components_create_title")}</Form.Label>
              <Form.Control {...register("title")} placeholder={t("components_create_title_plhd")}
                            type="text" />
              {errors.title?.message && (
                <Form.Text className="text-danger">{errors.title.message}</Form.Text>)}
            </Form.Group>
          </div>
          <Form.Group className="flex-grow-1" controlId="name">
            <Form.Label>{t("subtitle")}</Form.Label>
            <Form.Control {...register("subTitle")} placeholder={t("subtitle_phld")}
                          type="text" />
            {errors.subTitle?.message && (
              <Form.Text className="text-danger">{errors.subTitle.message}</Form.Text>)}
          </Form.Group>
          <div className="row">
            <Form.Group className="col-md-12" controlId="image1">
              <Form.Label>{t("image")}</Form.Label>
              <div className="w-100 overflow-auto">
                <Controller
                  control={control}
                  name="image1"
                  render={({field}) => (
                    <MediaModal selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                                checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                      {
                        selectedMedia?.length > 0 ? (
                          selectedMedia?.map((md) =>
                            <MediaCard key={md.id} media={md} isDelete handleRemove={handleRemove}/>
                          )
                        ): (
                          <div style={{height: "300px", width: "100%"}}>
                            <ImageInput
                              errorMessage={errors.image?.message}
                              onChange={field.onChange}
                              onError={() => {
                                setError("image", {message: "Invalid image file!"});
                              }}
                            />
                          </div>
                        )
                      }
                    </MediaModal>
                  )}
                />
              </div>
            </Form.Group>
          </div>
          <Form.Group controlId="description1">
            <Form.Label>{t("description")} {t("1")}</Form.Label>
            <Controller
              control={control}
              name="description1"
              render={({field}) => <TextEdit defaultValue={component?.description1} {...field} />}
            />
            {errors.description1?.message && (
              <Form.Text className="text-danger">
                {errors.description1.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="description2">
            <Form.Label>{t("description")} {t("2")}</Form.Label>
            <Controller
              control={control}
              name="description2"
              render={({field}) => <TextEdit defaultValue={component?.description2} {...field} />}
            />
            {errors.description2?.message && (
              <Form.Text className="text-danger">
                {errors.description2.message}
              </Form.Text>
            )}
          </Form.Group>
          <div className="form-row gap-3 md-2 gap-md-0">
            <Form.Group as={Col} md={6} className="flex-grow-1" controlId="link1">
              <Form.Label>{t("link")} {t("1")}</Form.Label>
              <Form.Control {...register("link1")} placeholder={t("link_phld")}
                            type="text" />
              {errors.link1?.message && (
                <Form.Text className="text-danger">{errors.link1.message}</Form.Text>)}
            </Form.Group>
            <Form.Group as={Col} md={6} className="flex-grow-1" controlId="link2">
              <Form.Label>{t("link")} {t("2")}</Form.Label>
              <Form.Control {...register("link2")} placeholder={t("link_phld")}
                            type="text" />
              {errors.link2?.message && (
                <Form.Text className="text-danger">{errors.link2.message}</Form.Text>)}
            </Form.Group>
          </div>
          <div className="form-row gap-3 md-2 gap-md-0">
            <Form.Group as={Col} md={6} className="flex-grow-1" controlId="link3">
              <Form.Label>{t("link")} {t("3")}</Form.Label>
              <Form.Control {...register("link3")} placeholder={t("link_phld")}
                            type="text" />
              {errors.link3?.message && (
                <Form.Text className="text-danger">{errors.link3.message}</Form.Text>)}
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="youTubeID">
              <Form.Label>{t("components_create_youtubeID")}</Form.Label>
              <Form.Control {...register("youTubeID")}
                            placeholder={t("components_create_youtubeID_plhd")} type="text" />
              {errors.youTubeID?.message && (
                <Form.Text className="text-danger">{errors.youTubeID.message}</Form.Text>)}
            </Form.Group>
          </div>
          <div className="row mt-2">
            <div className="d-flex justify-content-end">
              <Button className="px-4" type="submit">
                {t("components_create_create")}
              </Button>
            </div>
          </div>
        </Form>
      </section>
    </Layout>
  );
}
