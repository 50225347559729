import {useLang} from "../../../hooks";
import {useNotification} from "../../../hooks/useNotification";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {SocialSettingsSchema} from "../schema";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {InputValidationError} from "../../../libs/error";
import {DateRangePicker, Placeholder, Toggle} from "rsuite";
import {Button, Col, Form} from "react-bootstrap";
import {usePopUpSettings} from "../../../hooks/usePopupSettings";
import MediaModal from "../../../components/MediaSelectorModal/MediaModal";
import MediaCard from "../../MediaManagement/components/MediaCard";
import {ImageInput} from "../../../components/MediaInputs/imageInput";
import {useMediaSelector} from "../../../components/MediaSelectorModal";
import MediaCardListSmall from "../../../components/MediaSelectorModal/MediaCardListSmall";

export function PopupSettings({onLanguageEdit}) {
  const {t} = useLang();
  const {notifyError,notifySuccess} = useNotification();
  const {settings, error, isLoading, save} = usePopUpSettings();
  const {
    register,
    formState: {errors},
    setValue,
    handleSubmit,
    setError,
    control
  } = useForm({
    resolver: yupResolver(SocialSettingsSchema(t))
  });
  const {
    checkValue,
    setCheckValue,
    selectedMedia,
    setSelectedMedia,
    allMediaId,
    setAllMediaId,
    handleRemove,
  } = useMediaSelector();

  useEffect(() => {
    if (settings) {
      console.log("mehhhhja", settings);
      setValue("pop_up_status", settings?.pop_up_status === "active");
      setValue("dateTimeRange", [new Date(settings?.pop_up_start_date_time),
        new Date(settings?.pop_up_end_date_time)]);

      let mediaId = settings?.pop_up_image ? [settings?.pop_up_image.id] : [];
      let media = settings?.pop_up_image ? [settings?.pop_up_image] : [];

      setSelectedMedia(media);
      setCheckValue(mediaId);
      setAllMediaId(mediaId);
    }
  }, [settings]);

  const navigate = useNavigate();
  if (error) {
    notifyError(error);
    navigate("/dashbaord");
  }

  const handleUpdate = async (data) => {
    try {
      data = { ...data, pop_up_image : checkValue[0] ?? null }
      await save(data);
      notifySuccess({
        title:"Success",
        message:"Settings Updated Successfully"
      })
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input!",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Failed to update settings!",
        message: err.message
      });
    }
  };

  return <section id="social-settings" className="my-4">
    <h2 className="mb-3">{t("popup_settings_popup")}</h2>
    {isLoading ? <Placeholder.Graph active /> :
      <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit(handleUpdate)}>
        <div className="form-row">
          <Form.Group as={Col} md={6} lg={6} controlId="dateRange">
            <Form.Label className="mark-required">{t("events_input_startEndDateTime")}</Form.Label>
            <Controller
              control={control}
              name="dateTimeRange"
              render={({field}) => (
                <div>
                  <DateRangePicker value={field.value} onChange={field.onChange}
                                   format="dd MMM yyyy hh:mm:ss"
                                   showMeridian showOneCalendar/>
                </div>
              )}
            />
            {errors.dateTimeRange?.message && (
              <Form.Text className="text-danger">
                {errors.dateTimeRange.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="pop_up_status">
            <Form.Label className="mark-required">{t("events_input_status")}</Form.Label>
            <Controller
              control={control}
              name="pop_up_status"
              render={({field}) => (
                <div>
                  <Toggle
                    {...field}
                    checked={field.value}
                    size="lg"
                    checkedChildren={<span>Active</span>}
                    unCheckedChildren={<span>Deactive</span>}
                    className="m-auto"
                  />
                </div>
              )}
            />
            {errors.status?.message && <Form.Text className="text-danger"> {errors.status.message} </Form.Text>}
          </Form.Group>
        </div>
        <Form.Group controlId="pop_up_image">
          <Form.Label className="mark-required">{t("posts_create_image")}</Form.Label>
          <div className="w-100">
            <Controller
              control={control}
              name="pop_up_image"
              render={({field}) => (
                <MediaModal isSingleMode selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                            checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                  {
                    selectedMedia?.length > 0 ? (
                      selectedMedia?.map((md) =>
                        <MediaCardListSmall selectedMedia={selectedMedia} isDelete handleRemove={handleRemove} />
                      )
                    ): (
                      <div style={{height: "300px", width: "100%"}}>
                        <ImageInput
                          errorMessage={errors.image?.message}
                          onChange={field.onChange}
                          onError={() => {
                            setError("image", {message: "Invalid image file!"});
                          }}
                        />
                      </div>
                    )
                  }
                </MediaModal>
              )}
            />
          </div>
        </Form.Group>
        <div className="d-flex justify-content-end gap-2">
          <Button className="px-4" variant="success" type="submit">
            {t("social_settings_save")}
          </Button>
        </div>
      </Form>
    }

  </section>;
}