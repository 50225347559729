import * as yup from "yup";
import {phoneRegExp} from "../../configs/constants";

export const userInputSchema = yup
  .object({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phone: yup
      .string()
      .required("Phone is required"),
    gender: yup
      .string()
      .oneOf(["Male", "Female", "Other"], "Please select a gender")
      .required("Gender is required")
  })
  .required();

// export const userEditSchema = userInputSchema.omit(["email"]);