import Layout from "../../components/layouts/layout";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {ProductInputSchema} from "./schema";
import {useEffect} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import CircleLoader from "../../components/Loaders/CircleLoader";
import {CheckTreePicker, TagInput, TreePicker} from 'rsuite';
import {useProduct, useProductAttributes, useProductCategories} from "../../hooks";
import {InputValidationError} from "../../libs/error";
import "./Product.css";
import {useLang} from "../../hooks";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {getUrlFromStoragePath} from "../../configs";
import {BackButton} from "../../components/BackButton/BackButton";
import MediaCardList from "../../components/MediaSelectorModal/MediaCardList";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";
import {useMediaSelector} from "../../components/MediaSelectorModal";

export function ProductEditPage() {
  const {t} = useLang();
  const {id} = useParams();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();

  const {product, editProduct, handleMediaRemove, isLoading, error} = useProduct(id)
  const {attributes, isLoading: issAttrLoading, error: errAtt} = useProductAttributes()
  const {categories, isLoading: isCatLoading, error: errCat} = useProductCategories()

  const {
    selectedMedia,
    setSelectedMedia,
    checkValue,
    setCheckValue,
    allMediaId,
    setAllMediaId,
    handleRemove
  } = useMediaSelector();

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setValue,
    setError,
    watch
  } = useForm({
    resolver: yupResolver(ProductInputSchema(t)),
  });

  if (errAtt || errCat) {
    notifyError(errAtt ?? errCat)
    navigate(-1)
  }

  if (error) {
    notifyError(error);
    setTimeout(() => navigate("/products"), 500);
  }

  const handleEdit = async (data) => {
    try {
      let productData = {...data, media_id: checkValue}
      await editProduct(productData);
      notifySuccess({
        title: `Product "${product.name}" updated successfully`,
      });
      navigate("/products/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Failed to update image!",
        message: err.message,
      });
    }
  };

  const handleServerRemove = async (media_id) => {
    try {
      await handleMediaRemove(media_id);
      notifySuccess({
        title: `Media Removed successfully`
      });
      setCheckValue(checkValue.filter(m => m!== media_id))
      setSelectedMedia(selectedMedia.filter(m => m.id !== media_id))
      setAllMediaId(allMediaId.filter(m => m!==media_id));
    } catch (err) {
      notifyError({
        title: "Failed to Removed media!",
        message: err.message
      });
    }
  };

  useEffect(() => {
    if (product) {
      setValue("name", product.name ?? "");
      setValue("code", product.code ?? "");
      setValue("description", product.description ?? "");
      setValue("categoryId", product.categoryId.toString() ?? "");
      setValue("attribute_id", product.attributes ?? "");
      setValue("meta_title", product.meta_title ?? "");
      setValue("meta_tag", product.meta_tag?.split(",") ?? []);
      setValue("meta_description", product.meta_description ?? "");

      let mediaIds = product?.files.map(m => m.media_id);
      let media = product?.files.map(m => m.media_content);

      setSelectedMedia(media);
      setCheckValue(mediaIds);
      setAllMediaId(mediaIds);
    }
  }, [product, setValue]);

  let product_name = watch("name")

  return (
    <Layout>
      {isLoading && <CircleLoader/>}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/products/manage`}> {t("products_manage_products")}</Link>
            </li>
            <Breadcrumb.Item active>
              {product?.name} {t("products_edit_edit")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("products_manage_products")}/>
        </div>
      </header>
      <section id="#taxonomy-create">
        <h2>{t("products_edit_edit")} / <span className="text-muted">{product_name}</span></h2>
      </section>
      <Form onSubmit={handleSubmit(handleEdit)} className="container-fluid mt-4">
        <div className="row">
          <Form.Group className="col-md-6 overflow-auto">
            <Form.Label>{t("products_create_image")}</Form.Label>
            <Controller
              control={control}
              name="image"
              render={({field}) => (
                <MediaModal selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                            checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                  {
                    selectedMedia?.length > 0 ? (
                      <MediaCardList selectedMedia={selectedMedia} isDeleteServer handleServerRemove={handleServerRemove}/>
                    ): (
                      <div style={{height: "300px", width: "100%"}}>
                        <ImageInput
                          errorMessage={errors.image?.message}
                          onChange={field.onChange}
                          onError={() => {
                            setError("image", {message: "Invalid image file!"});
                          }}
                        />
                      </div>
                    )
                  }
                </MediaModal>
              )}
            />
          </Form.Group>
          <Col>
            <Form.Group className="flex-grow-1" controlId="name">
              <Form.Label className="mark-required">{t("products_create_name")}</Form.Label>
              <Form.Control {...register("name")} placeholder={t("products_create_name_plhd")} type="text"/>
              {errors.name?.message && (
                <Form.Text className="text-danger">{errors.name.message}</Form.Text>)}
            </Form.Group>
            <Form.Group className="flex-grow-1 mt-2" controlId="code">
              <Form.Label className="mark-required">{t("products_create_code")}</Form.Label>
              <Form.Control {...register("code")} placeholder={t("products_create_code_plhd")}
                            type="number"/>
              {errors.code?.message && (
                <Form.Text className="text-danger">{errors.code.message}</Form.Text>)}
            </Form.Group>
            <Form.Group className="flex-grow-1 mt-2" controlId="description">
              <Form.Label>{t("products_create_description")}</Form.Label>
              <Form.Control {...register("description")} placeholder={t("products_create_description_plhd")}
                            as="textarea" rows={3}/>
              {errors.description?.message && (
                <Form.Text className="text-danger">{errors.description.message}</Form.Text>)}
            </Form.Group>
          </Col>
        </div>
        <div className="row mt-2">
          <div className="col-sm-6 col-lg-6">
            <Form.Group className="flex-grow-1" controlId="categoryId">
              <Form.Label className="mark-required">{t("products_edit_category")}</Form.Label>
              <Controller control={control} name="categoryId"
                          render={({field}) =>
                            (<TreePicker loading={isCatLoading} {...field} defaultExpandAll data={categories} block
                                         searchable={false} placeholder="Select Categories"/>)}/>
              {errors.categoryId?.message && (
                <Form.Text className="text-danger">{errors.categoryId.message}</Form.Text>)}
            </Form.Group>
          </div>
          <div className="col-sm-6 col-lg-6">
            <Form.Group className="flex-grow-1" controlId="attribute_id">
              <Form.Label className="mark-required">{t("products_edit_attributes")}</Form.Label>
              <Controller control={control} name="attribute_id"
                          render={({field}) =>
                            (<CheckTreePicker loading={issAttrLoading} {...field} defaultExpandAll data={attributes}
                                              block
                                              searchable={false}
                                              placeholder="Select Attributes" cascade={false}/>)}/>
              {errors.attribute_id?.message && (
                <Form.Text className="text-danger">{errors.attribute_id.message}</Form.Text>)}
            </Form.Group>
          </div>
        </div>
        <div className="row mt-2">
          <h3>SEO</h3>
          <hr />
          <div className="col-sm-6 col-lg-6">
            <Form.Group className="flex-grow-1" controlId="name">
              <Form.Label>{t("products_edit_metaTitle")}</Form.Label>
              <Form.Control {...register("meta_title")} placeholder={t("products_edit_metaTitle_plhd")}
                            type="text" />
              {errors.meta_title?.message && (
                <Form.Text className="text-danger">{errors.meta_title.message}</Form.Text>)}
            </Form.Group>
          </div>
          <div className="col-sm-6 col-lg-6">
            <Form.Group controlId="metaTags">
              <Form.Label>{t("products_edit_metaTags")}</Form.Label>
              <Controller
                control={control}
                name="meta_tag"
                render={({field}) => (
                  <TagInput
                    onChange={field.onChange}
                    name={field.name}
                    disabled={field.disabled}
                    value={field.value}
                    placeholder={t("products_edit_metaTags_plhd")}
                    style={{borderColor: "#ced4da"}}
                    className="d-block"
                  />
                )}
              />
              {(errors.meta_tag?.message || Array.isArray(errors.meta_tag)) && (
                <Form.Text className="text-danger">
                  {errors?.meta_tag?.message ??
                    errors.meta_tag.find(Boolean).message}
                </Form.Text>
              )}
            </Form.Group>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-12 col-lg-12">
            <Form.Group className="flex-grow-1" controlId="name">
              <Form.Label>{t("products_edit_metaDescription")}</Form.Label>
              <Form.Control {...register("meta_description")}
                            placeholder={t("products_edit_metaDescription_plhd")}
                            as="textarea" rows={3}/>
              {errors.meta_description?.message && (
                <Form.Text className="text-danger">{errors.meta_description.message}</Form.Text>)}
            </Form.Group>
          </div>
        </div>
        <div className="row mt-2">
          <div className="d-flex justify-content-end">
            <Button variant="primary mr-2" type="submit">{t("products_edit_update")}</Button>
            <Link className="btn btn-danger" to={`/products/manage`}>
              {t("events_edit_cancel")}
            </Link>
          </div>
        </div>
      </Form>
    </Layout>
  );
}