import Layout from "../../components/layouts/layout";
import { Breadcrumb, Button, Col, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProductInputSchema, ProductInventoryInputSchema } from "./schema";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useNotification } from "../../hooks/useNotification";
import CircleLoader from "../../components/Loaders/CircleLoader";
import { SelectPicker, CheckTreePicker, TagInput, TreePicker } from 'rsuite';
import { useProduct, useProductAttributes, useProductInventory, useProductList } from "../../hooks";
import { InputValidationError } from "../../libs/error";
import "./Product.css";
import { useLang } from "../../hooks";
import { ImageInput } from "../../components/MediaInputs/imageInput";
import { getUrlFromStoragePath } from "../../configs";
import { BackButton } from "../../components/BackButton/BackButton";
import MediaCardList from "../../components/MediaSelectorModal/MediaCardList";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";
import { useMediaSelector } from "../../components/MediaSelectorModal";
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
export function ProductInventoryEditPage() {
    const { t } = useLang();
    const { id } = useParams();
    const navigate = useNavigate();
    const { notifyError, notifySuccess } = useNotification();

    const { product, editProductInventory, isLoading, error } = useProductInventory(id)
    const { attributes, isLoading: issAttrLoading, error: errAtt } = useProductAttributes()
    const {
        productList, pagination,
        changePagination, deleteProduct, searchByTerm,
        filter, filterDispatch
    } = useProductList();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        setError,
        watch
    } = useForm({
        resolver: yupResolver(ProductInventoryInputSchema(t)),
    });

    if (error) {
        notifyError(error);
        setTimeout(() => navigate("/products"), 500);
    }

    const handleEdit = async (data) => {
        console.log(data)
        try {
            await editProductInventory(data);
            notifySuccess({
                title: `Product updated successfully`,
            });
            navigate("/products/inventory");
        } catch (err) {
            if (err instanceof InputValidationError) {
                err.setRHFError(setError);
                notifyError({
                    title: "Invalid input data!",
                    message: err.message,
                });
                return;
            }
            notifyError({
                title: "Failed to update image!",
                message: err.message,
            });
        }
    };


    useEffect(() => {
        if (product) {
            setValue("quantity", product.quantity ?? "");
            setValue("product_id", product.product_id ?? "");
            setValue("attribute_id", product.attribute_id ?? "");

        }
    }, [product, setValue]);

    let product_name = watch("name")
    const renderMenu = (menu) => {
        if (!productList.products || productList.products.length === 0) {
            return (
                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                    <SpinnerIcon spin /> {t('loading')}
                </p>
            );
        }
        return menu;
    };

    return (
        <Layout>
            {isLoading && <CircleLoader />}
            <header>
                <div className="d-flex justify-content-between align-items-center">
                    <Breadcrumb className="d-flex align-items-center">
                        <li className="breadcrumb-item">
                            <Link to={`/products/inventory`}> {t("productsInventory_manage_products")}</Link>
                        </li>
                        <Breadcrumb.Item active>
                            {t("productsInventory_edit_edit")}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <BackButton path={-1} text={t("productsInventory_manage_products")} />
                </div>
            </header>
            <section id="#taxonomy-create">
                <h2>{t("productsInventory_edit_edit")} / <span className="text-muted">{product_name}</span></h2>
            </section>
            <Form onSubmit={handleSubmit(handleEdit)} className="container-fluid mt-4">
                <div className="row mt-2">
                    <div className="">
                        <Form.Group className="flex-grow-1" controlId="product_id">
                            <Form.Label className="mark-required">{t("productsInventory_edit_category")}</Form.Label>
                            <Controller
                                control={control}
                                name="product_id"
                                render={({ field }) => (
                                    <SelectPicker
                                        {...field}
                                        data={productList?.products?.map((item) => ({ label: item.name, value: Number(item.id) }))}
                                        onSearch={(value) => filterDispatch({
                                            type: "set",
                                            payload: { key: "term", value: value }
                                        })}
                                        style={{ width: '100%' }}
                                        renderMenu={renderMenu}
                                        placeholder={t("productsInventory_create_category_plhd")}
                                    />
                                )}
                            />
                            {errors.product_id?.message && (
                                <Form.Text className="text-danger">{errors.product_id.message}</Form.Text>)}
                        </Form.Group>
                    </div>
                    <div className="">
                        <Form.Group className="flex-grow-1" controlId="attribute_id">
                            <Form.Label className="mark-required">{t("productsInventory_create_attributes")}</Form.Label>
                            <Controller control={control} name="attribute_id"
                                render={({ field }) =>
                                (<SelectPicker loading={issAttrLoading}
                                    {...field} defaultExpandAll
                                    data={attributes}
                                    block
                                    searchable={false}
                                    placeholder="Select Attributes"
                                    cascade={false} />)} />
                            {errors.attribute_id?.message && (
                                <Form.Text className="text-danger">{errors.attribute_id.message}</Form.Text>)}
                        </Form.Group>
                    </div>
                    <div className="">
                        <Form.Group className="flex-grow-1" controlId="quantity">
                            <Form.Label className="mark-required">{t("productsInventory_create_quantity")}</Form.Label>
                            <Form.Control {...register("quantity")} placeholder={t("productsInventory_quantity_plhd")} type="text" />
                            {errors.quantity?.message && (
                                <Form.Text className="text-danger">{errors.quantity.message}</Form.Text>)}
                        </Form.Group>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="d-flex justify-content-end">
                        <Button variant="primary mr-2" type="submit">{t("productsInventory_edit_update")}</Button>
                        <Link className="btn btn-danger" to={`/products/inventory`}>
                            {t("productsInventory_edit_cancel")}
                        </Link>
                    </div>
                </div>
            </Form>
        </Layout>
    );
}