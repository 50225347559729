import React from "react";
import "../../pages/MediaManagement/media.css";
import MediaCardSmall from "./MediaCardSmall";

function MediaCardMiniList({selectedMedia, isDelete, isDeleteServer , handleRemove, handleServerRemove}) {
  if(isDelete) {
    return (
      <div className='d-flex gap-2'>
        {
          selectedMedia?.map((md) =>
            <MediaCardSmall key={md.id} media={md} isDelete handleRemove={handleRemove}/>
          )
        }
      </div>
    );
  } else if(isDeleteServer) {
    return (
      <div className='d-flex gap-2'>
        {
          selectedMedia?.map((md) =>
            <MediaCardSmall key={md.id} media={md} isDeleteServer handleServerRemove={handleServerRemove}/>
          )
        }
      </div>
    );
  }
}

export default MediaCardMiniList;
