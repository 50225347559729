import {useEffect} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {useLanguages} from "../../hooks/useLanguages";
import {LanguageSelector} from "../../components/Language/LanguageSelector";
import {useLanguage} from "../../hooks/useLanguage";
import {useLang, useProduct} from "../../hooks";
import {ProductLanguageInputSchema} from "./schema";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {getUrlFromStoragePath} from "../../configs";
import {useMediaSelector} from "../../components/MediaSelectorModal";
import MediaCardList from "../../components/MediaSelectorModal/MediaCardList";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";

export function ProductLanguageEditPage() {
  const {langId, resourceId} = useParams();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {t} = useLang();

  const {language, isLoading, error, edit, deleteLanguageMedia} = useLanguage("product", langId);
  const {product, isLoading: isContentLoading, error: productError} = useProduct(resourceId)

  const {langs, isLoading: isLangLoading, error: isLangError} = useLanguages();

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(ProductLanguageInputSchema(t)),
  });

  const {
    checkValue,
    setCheckValue,
    selectedMedia,
    setSelectedMedia,
    allMediaId,
    setAllMediaId,
  } = useMediaSelector();

  if (error || isLangError || productError) {
    notifyError(error)
    navigate(-1);
  }

  const handleServerRemove = async (media_id) => {
    try {
      await deleteLanguageMedia(media_id);
      notifySuccess({
        title: `Media Removed successfully`
      });
      setCheckValue(checkValue.filter(m => m!== media_id))
      setSelectedMedia(selectedMedia.filter(m => m.id !== media_id))
      setAllMediaId(allMediaId.filter(m => m!==media_id));
    } catch (err) {
      notifyError({
        title: "Failed to Removed media!",
        message: err.message
      });
    }
  };

  useEffect(() => {
    if (language) {
      setValue("language_id", language.language_id)
      setValue("name", language.name ?? "");
      setValue("description", language.description ?? "");

      let mediaIds = language?.files?.map(m => m.media_id) || [];
      let media = language?.files?.map(m => m.media_content) || [];

      setSelectedMedia(media);
      setCheckValue(mediaIds);
      setAllMediaId(mediaIds);
    }
  }, [language]);


  const handleEdit = async (data) => {
    try {
      const productLanguageData = {...data, product_id: resourceId, media_id: checkValue}
      await edit(productLanguageData);
      notifySuccess({
        title: `Language  updated successfully`,
      });
      navigate(-1);
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Failed to update language!",
        message: err.message,
      });
    }
  };

  return (
    <Layout>
      {(isLoading || isLangLoading || isContentLoading) && <CircleLoader/>}
      <section id="#product-language-create">
        <header>
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to={`/products/manage`}>Contents</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/langs/product/${resourceId}`}>{product?.title} Languages</Link>
            </li>
            <Breadcrumb.Item active>Edit language/ {langId}</Breadcrumb.Item>
          </Breadcrumb>
          <h3>Edit Language ({product?.name ?? resourceId})</h3>
        </header>
        <Form
          onSubmit={handleSubmit(handleEdit)}
          className="mt-4 d-flex  flex-column gap-3"
        >

          <LanguageSelector loading={isLangLoading} control={control} langs={langs} errors={errors}/>
          <div className="form-row">
            <Form.Group as={Col} md={12}>
              <Form.Label className="mark-required">{t("image")}</Form.Label>
              <Controller
                control={control}
                name="image"
                render={({field}) => (
                  <div className="overflow-auto">
                  <MediaModal selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                              checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                    {
                      selectedMedia?.length > 0 ? (
                        <MediaCardList selectedMedia={selectedMedia} isDeleteServer handleServerRemove={handleServerRemove}/>
                      ): (
                        <div style={{height: "300px", width: "100%"}}>
                          <ImageInput
                            errorMessage={errors.image?.message}
                            onChange={field.onChange}
                            onError={() => {
                              setError("image", {message: "Invalid image file!"});
                            }}
                          />
                        </div>
                      )
                    }
                  </MediaModal>
                  </div>
                )}
              />
            </Form.Group>
            <Col>
              <Form.Group className="flex-grow-1" controlId="name">
                <Form.Label className="mark-required">{t("products_create_name")}</Form.Label>
                <Form.Control {...register("name")} placeholder={t("products_create_name_plhd")} type="text"/>
                {errors.name?.message && (
                  <Form.Text className="text-danger">{errors.name.message}</Form.Text>)}
              </Form.Group>

              <Form.Group className="flex-grow-1 mt-2" controlId="description">
                <Form.Label className="mark-required">{t("products_create_description")}</Form.Label>
                <Form.Control {...register("description")} placeholder={t("products_create_description_plhd")}
                              as="textarea" rows={8}/>
                {errors.description?.message && (
                  <Form.Text className="text-danger">{errors.description.message}</Form.Text>)}
              </Form.Group>
            </Col>
          </div>
          <div className="d-flex gap-2 justify-content-end">
            <Button className="px-4" type="submit">
              Save
            </Button>
            <Link to={-1} className="px-4 btn btn-danger">
              Cancel
            </Link>
          </div>
        </Form>
      </section>
    </Layout>
  );
}
