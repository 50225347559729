import {api, parseAPIError} from "./api";
import {arrayToObject, jsonToFormData} from "../helpers";
import {processSeoInput} from "./seo";

export function processProductInput({attribute_id, ...input}) {
  if (attribute_id?.length > 0) {
    return {
      ...processSeoInput(input),
      ...arrayToObject("attribute_id", attribute_id)
    };
  } else {
    return {...processSeoInput(input)};
  }
}

export async function createProduct(input) {
  try {
    let data = processProductInput(input);
    data = jsonToFormData(data);
    const r = await api.post("/product/store", data);
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getProduct(id) {
  const r = await api.get(`/product/get/${id}`);
  return r.data.data;
}

export async function updateProduct(id, input) {
  try {
    let data = processProductInput(input);
    data = jsonToFormData(data);
    const r = await api.post(`/product/update/${id}`, data);
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getPaginatedProduct(options) {
  const r = await api.get("product/show", {
    params: options
  });
  if (r.data.data) {
    return r.data.data;
  }
  return {
    data: [],
    total: 0
  };
}

export async function removeProduct(id) {
  const r = await api.delete(`/product/destroy/${id}`);
  return r.data.data;
}

export async function removeProductMedia(id, data) {
  let processData = jsonToFormData(data);
  const r = await api.post(`/product/delete-media-id/${id}`, processData);
  return r.data;
}

export async function getProductAttrbutes() {
  const r = await api.get(`product/attributeList`);
  return r.data.data;
}

export async function getProductCategories() {
  const r = await api.get(`product/categoryList`);
  return r.data.data;
}

export async function getPaginatedProductInvetory(options) {
  const r = await api.get("product_inventory/show", {
    params: options
  });
  if (r.data.data) {
    return r.data.data;
  }
  return {
    data: [],
    total: 0
  };
}
export async function getProductInventory(id) {
  const r = await api.get(`/product_inventory/get/${id}`);
  return r.data.data;
}
export async function createProductInventory(input) {
  try {
    let data = jsonToFormData(input);
    const r = await api.post("/product_inventory/store", data);
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}
export async function removeProductInventory(id) {
  const r = await api.delete(`/product_inventory/delete/${id}`);
  return r.data.data;
}
export async function updateProductInventory(id, input) {
  try {
    let data = jsonToFormData(input);
    const r = await api.post(`/product_inventory/update/${id}`, data);
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

//
