import {useCallback, useEffect, useState} from "react";
import {getForm, updateForm} from "../../../services/formBuilder";

export function useFormBuilder(id) {
  const [formBuilder, setFormBuilder] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      let localData = await getForm(id)
      localStorage.setItem("formData",JSON.parse(localData.details));
      setFormBuilder(localData);
    } catch (err) {
      setError({
        title: "Failed to fetch formBuilder!",
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  async function edit(data) {
    try {
      setIsLoading(true);
      setFormBuilder(await updateForm(id, data));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {formBuilder, isLoading, edit, error};
}
