import * as yup from "yup";
import {
    DefaultDescriptionValidation,
    defaultImageValidation,
    DefaultNameValidation,
    languageSchema,
} from "../../configs/constants";

export const TermInputSchema = (t) => yup.object({
    name: DefaultNameValidation().required().label(t("terms_input_name")),
    description: DefaultDescriptionValidation().nullable().label(t("terms_input_description")),
    // termId
    parent_id: yup.number().label("Parent").nullable(),
});

export const TermLanguageSchema = () => languageSchema.shape({
    name: DefaultNameValidation().required(),
    description: DefaultDescriptionValidation().nullable(),
    parent_id: yup.number().label("Parent").nullable(),
})
