import React, { useEffect, useState } from "react";
import {Breadcrumb, Button, Form} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Checkbox, CheckboxGroup } from "rsuite";
import Cookies from "universal-cookie";
import Layout from "../../components/layouts/layout";
import loginCheck from "../../helpers/loginCheck";
import http from "../../http";
import "./role.css";
import {useLang} from "../../hooks";
import {BackButton} from "../../components/BackButton/BackButton";

const RolePermission = () => {
  const { register, handleSubmit } = useForm();
  const [data, setData] = useState("");
  const [permissionsList, setPermissionsList] = useState([]);
  const [roles, setRoles] = useState("");
  const [keyss, setKeys] = useState([]);
  const [values, setValues] = useState([]);
  const [getSuccess, setSuccess] = useState(false);
  const [getError, setError] = useState("");
  const [getErrorObj, setErrorObj] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [getPer, setPer] = useState([]);
  const [getLoader, setLoader] = useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const { id } = useParams();
  const {t} = useLang();

  useEffect(() => {
    if (!loginCheck()) {
      navigate("/login");
    }
  });

  useEffect(() => {
    fetchAllPermission();
  }, []);

  const fetchAllPermission = () => {
    http
      .get(`/role/get/${id}`, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuth").token,
        },
      })
      .then((res) => {
        setPer(res.data.data);
      });
  };

  const getSearch = (val) => {
    if (val.length > 0) {
      http
        .get(`/permission/search/${val}`, {
          headers: {
            Authorization: "Bearer " + cookies.get("userAuth").token,
          },
        })
        .then((response) => {
          setValues(response.data.data.data);
        });
    } else if (permissions.permission) {
      http
        .get(`/permission/get/${permissions.permission}`, {
          headers: {
            Authorization: "Bearer " + cookies.get("userAuth").token,
          },
        })
        .then((response) => {
          setValues([response.data.data]);
        });
    } else {
      setValues([]);
    }
  };
  useEffect((val) => {
    http
      .get(`/permission/all`, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuth").token,
        },
      })
      .then((response) => {
        let plist = {};
        response.data.data.map((perm) => {
          perm.name.split(".")[0] in plist
            ? (plist[`${perm.name.split(".")[0]}`] = [
                ...plist[perm.name.split(".")[0]],
                perm.name,
              ])
            : (plist[perm.name.split(".")[0]] = [perm.name]);
        });
        setPermissionsList(plist);
      });
  }, []);

  const onSubmit = (data, e) => {
    data.permissions = [...permissions];
    // data.user_id = cookies.get('userAuth').id
    setLoader(true);
    http
      .post(`/role/give-permission-to-role?id=${id}`, data, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuth").token,
          "Content-type": "Application/json",
        },
      })
      .then((res) => {
        // setSuccess(true);
        localStorage.setItem("success", true);
        // setLoader(false);
        setTimeout(() => {
          navigate("/role");
        }, 1000);
      })
      .catch((err) => {
        setLoader(false);
        // console.log(Object.keys(err.response.data.data))
        let errs = err.response.data.data;
        let keys = Object.keys(errs);
        console.log(keys);
        let errors = {};
        keys.map((key) => (errors[key] = errs[key][0]));
        console.info(errors);
        setError(err.response.data);

        setErrorObj(errors);
      });
  };
  const onError = (errors, e) => console.log(errors, e);

  const handleCheckAll = (value, checked) => {
    let permsToSet = permissionsList[value];
    const permsAlreadySet = permissions.filter((perm) =>
      permsToSet.includes(perm)
    );

    if (checked) {
      permsToSet = permsToSet.filter((perm) => !permsAlreadySet.includes(perm));
      setPermissions([...permissions, ...permsToSet]);
    } else {
      setPermissions(permissions.filter((perm) => !permsToSet.includes(perm)));
    }
  };

  const handleChange = (value) => {
    setPermissions(value);
  };

  useEffect(() => {
    setPermissions(getPer?.permissions?.map((per) => per.name));
  }, [getPer]);

  return (
    <>
      <Layout>
        {getLoader && (
            <div className="preloader">
              <div className="overlay__inner">
                <div className="overlay__content">
                  <span className="spin"></span>
                </div>
              </div>
            </div>
        )}

        <header>
          <div className="d-flex justify-content-between">
            <Breadcrumb>
              <li className="breadcrumb-item">
                <Link to={`/role`}> {t("acl_roleList_roleList")}</Link>
              </li>
              <Breadcrumb.Item active>
                {t("acl_givePermission_givePermission")}
              </Breadcrumb.Item>
            </Breadcrumb>
            <BackButton path={-1} text={t("acl_roleList_roleList")}/>
          </div>
        </header>

        <div className="form_design">
          <h2 style={{color: "black", textDecoration: "underline"}}>
            {t("acl_givePermission_givePermission")}
          </h2>

          <Form onSubmit={handleSubmit(onSubmit)}>
            {Object.keys(permissionsList).map((permSegment) => {
              return (
                  <>
                    <h5>
                      <Checkbox
                          indeterminate={
                              permissions?.filter(
                                  (perm) => perm.split(".")[0] === permSegment
                              ).length > 0 &&
                              permissions?.filter(
                                  (perm) => perm.split(".")[0] === permSegment
                              ).length < permissionsList[permSegment].length
                          }
                          checked={
                              permissions?.filter(
                                  (perm) => perm.split(".")[0] === permSegment
                              ).length === permissionsList[permSegment].length
                          }
                          onChange={handleCheckAll}
                          value={permSegment}
                      >
                        <span style={{fontWeight: "bold"}}>{permSegment}</span>
                      </Checkbox>
                    </h5>
                    <CheckboxGroup
                        name="grantPermissions"
                        value={permissions}
                        onChange={handleChange}
                    >
                      {permissionsList[permSegment].map((segmentPerm) => (
                          <Checkbox key={segmentPerm} value={segmentPerm}>
                            {segmentPerm}
                          </Checkbox>
                      ))}
                      <br/>
                      <br/>
                    </CheckboxGroup>
                  </>
              );
            })}

            {/* <Form.Group>
                    {getPer && 
                    <>
                        <Form.Label className="form_label">Permission List</Form.Label>
                        <p>Role Name: {getPer.name}</p>
                        <div className="d-flex gap-1 p-2 flex-wrap" style={{border:"1px solid #e5e5ea",borderRadius:"6px"}}>
                                {
                                    getPer?.permissions?.map(ele =>
                                        <Badge style={{fontSize:"1em",}}>{ele.name}</Badge>
                                        )
                                }
                        </div>
                    </>
                    }
                </Form.Group> */}

            {/* <Form.Group>
                    <Form.Label className="form_label">Add Permission</Form.Label>
                    <CheckPicker  
                        name="permission" 
                        data={values?.map((item) => ({ label: item.name, value: item.id }))} 
                        style={{ width: "100%" }} 
                        onSearch={(value) => getSearch(value)} 
                        onChange={(value) => setPermissions({...permissions, "permissions": value})} 
                    />

                    {getErrorObj.permissions? 
                    <span className="text-danger">
                    {getErrorObj.permissions}
                    </span> : null
                    }
                </Form.Group> */}

            <div className="mt-4">
              <Button className="action__btn" variant="primary" type="submit">
                {t("acl_givePermission_save")}
              </Button>
              <Link to="/role">
                <Button className="action__btn" variant="info">
                  {t("acl_givePermission_backToHome")}
                </Button>
              </Link>
            </div>
          </Form>
        </div>
      </Layout>
    </>
  );
};

export default RolePermission;

