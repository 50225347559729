import {useEffect, useState} from "react";
import {getComponent, removeComponentLanguageMedia, removeComponentMedia, updateComponent} from "../services/component";
import {removeEventMedia} from "../services/event";

export default function useComponent(id) {
  const [component, setComponent] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const fetchComponent = async () => {
    try {
      setIsLoading(true);
      setComponent(await getComponent(id));
    } catch (err) {
      setError({
        title: "Failed to fetch component!",
        message: err.message
      });
    } finally {
      setIsLoading(false);
    }
  };

  async function editComponent(data) {
    try {
      setIsLoading(true);
      setComponent(await updateComponent(id, data));
    } finally {
      setIsLoading(false);
    }
  }

  async function handleMediaRemove(data) {
    try {
      setIsLoading(true);
      let removeIds = {
        media_id: [data]
      };
      await removeComponentMedia(id, removeIds)
    } catch (e) {
      setError({
        title: "Failed to Remove Media!",
        message: e.message,
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleLanguageMediaRemove(data) {
    try {
      setIsLoading(true);
      let removeIds = {
        media_id: [data]
      };
      await removeComponentLanguageMedia(id, removeIds)
    } catch (e) {
      setError({
        title: "Failed to Remove Media!",
        message: e.message,
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchComponent();
  }, []);

  return {component, isLoading, editComponent, handleMediaRemove, handleLanguageMediaRemove, error};
}
