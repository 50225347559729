import {useCallback, useEffect, useState} from "react";
import {getTestimonial, updateTestimonial} from "../testimonial.service";
export function useTestimonial(id) {
  const [testimonial, setTestimonial] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      setTestimonial(await getTestimonial(id));
    } catch (err) {
      setError({
        title: "Failed to fetch event!",
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  async function edit(data) {
    try {
      setIsLoading(true);
      setTestimonial(await updateTestimonial(id, data));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {testimonial, isLoading, edit, error};
}
