import * as yup from "yup";
import {
  DefaultDescriptionValidation,
  defaultImageValidation,
  DefaultLinkValidation,
  DefaultNameValidation,
  defaultVideoValidation,
  languageSchema
} from "../../configs/constants";

export const ContentInputSchema = (t) => {
  return yup.object({
    title: DefaultNameValidation().label("Title").required().max(512).label(t('contents_create_name')),
    description: DefaultDescriptionValidation().nullable().label(t('contents_create_Description')),
    video: defaultVideoValidation.label(t('contents_create_video')),
    image: defaultImageValidation.label(t('contents_create_video')),
    link: DefaultLinkValidation().nullable().label(t('contents_create_link')),
    terms_id: yup.number().min(1).required().label(t('contents_create_term')),
  });
}

export const ContentLanguageSchema = (t) => languageSchema.shape({
  title: DefaultNameValidation().label("Title").required().label(t('contents_create_name')),
  description: DefaultDescriptionValidation().nullable().label(t('contents_create_Description')),
})