import {Button, Col, Form} from "react-bootstrap";
import IconPicker from 'react-icons-picker'
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useLang} from "../../../hooks";
import {MenuItemSchema} from "../schema";
import {InputValidationError} from "../../../libs/error";
import {useNotification} from "../../../hooks/useNotification";
import {ImageInput} from "../../../components/MediaInputs/imageInput";
import {usePageList} from "../../../hooks/usePageList";
import {SelectPicker, TreePicker} from "rsuite";
import {useMediaSelector} from "../../../components/MediaSelectorModal";
import MediaCard from "../../MediaManagement/components/MediaCard";
import MediaModal from "../../../components/MediaSelectorModal/MediaModal";
import {getUrlFromStoragePath} from "../../../configs";
import * as FaIcons from 'react-icons/fa';

const faIconNames = Object.keys(FaIcons);

const defaultMenuItemValues = {
  icon: "FaLink"
};

export function MenuItemCreateForm({parentList, onSubmit}) {
  const {t} = useLang();
  const {notifyError, notifySuccess} = useNotification();
  const {pageList, isLoading: pageLoading, error: pageErr} = usePageList(null, {page: 1, limit: 100});

  if (pageErr) {
    notifyError(pageErr, "/error");
  }

  const {
    register,
    control,
    handleSubmit,
    formState: {errors},
    setError,
    reset,
    setValue
  } = useForm({
    resolver: yupResolver(MenuItemSchema(t)),
    defaultValues: defaultMenuItemValues
  });

  const {
    selectedMedia,
    setSelectedMedia,
    checkValue,
    setCheckValue,
    allMediaId,
    setAllMediaId,
    handleRemove
  } = useMediaSelector();

  const submissionHandler = async (data) => {
    try {
      const menuData  = {...data, image: selectedMedia[0]?.id ?? null}
      await onSubmit(menuData);
      reset();
      notifySuccess({
        title: "Menu item added"
      });
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Input Validation Failed",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Failed to create menu item",
        message: err.message
      });
    } finally {
      setAllMediaId([])
      setCheckValue([])
      setSelectedMedia([])
      setValue("parent_id", null)
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };


  return (
    <div className="bg-light mt-4 py-3 px-4" style={{maxWidth: "800px"}}>
      <h2>Create Menu Item</h2>
      <Form
        onSubmit={handleSubmit(submissionHandler)}
        className="d-flex flex-column gap-2"
        onKeyDown={handleKeyDown}
      >
        <div className="form-row align-items-center">
          <Form.Group md={1} as={Col} className="mr-2" controlId="menuName">
            <Form.Label>{t("menu_content_icon")}</Form.Label>
            <Controller
              control={control}
              name="icon"
              render={({field}) => (
                <IconPicker
                  value={field.value ?? ""}
                  onChange={field.onChange}
                  modalFadeStyle={{
                    position: 'absolute',
                    zIndex: 9999,
                    border: "1px solid #ced4da"
                  }}
                  modalWrapperStyle={{
                    fontSize: '24px',
                    width: '500px',
                    backgroundColor: 'white'
                  }}
                  searchBarStyle={{
                    padding: '12px',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '16px',
                    color: 'rgba(0, 0, 0, 0.7)'

                  }}
                />
              )}
            />
            {errors?.icon?.message && (
              <Form.Text className="text-danger">
                {errors.icon.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} md={1} controlId="image">
            <Form.Label>{t("events_input_image")}</Form.Label>
            <Controller
              control={control}
              name="image"
              render={({field}) => (
                <MediaModal isSingleMode selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                            checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                  {
                    selectedMedia?.length > 0 ? (
                      <ImageInput
                        handleRemove={handleRemove}
                        previousImage={getUrlFromStoragePath(selectedMedia[0]?.original_url)}
                        media_id={selectedMedia[0]?.id}
                      />
                    ): (
                      <div style={{height: "50px", width: "50px"}}>
                        <ImageInput
                          errorMessage={errors.image?.message}
                          onChange={field.onChange}
                          onError={() => {
                            setError("image", {message: "Invalid image file!"});
                          }}
                        />
                      </div>
                    )
                  }
                </MediaModal>
              )}
            />
          </Form.Group>
          <Form.Group md={6} as={Col} controlId="menuTitle">
            <Form.Label>{t("menu_content_title")}</Form.Label>
            <Form.Control
              {...register("title")}
              type="text"
              placeholder={t("menu_content_title_plhd")}
            />
            {errors?.title?.message && (
              <Form.Text className="text-danger">
                {errors.title.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col} controlId="menuParent">
            <Form.Label>{t("menu_content_parent")}</Form.Label>
            <Controller control={control} render={
              ({field}) =>
                <TreePicker
                  value={field.value}
                  onChange={field.onChange}

                  data={parentList}
                  valueKey={"value"}
                  labelKey={"label"}
                  childrenKey={"children"}
                  style={{width: "100%"}}
                />
            } name="parent_id" />
            {errors?.parent_id?.message && (
              <Form.Text className="text-danger">
                {errors.parent_id.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>
        <div className="form-row">
          <Form.Group as={Col} md={6} controlId="page_id">
            <Form.Label>{t("menu_content_page")}</Form.Label>
            <Controller control={control} render={
              ({field}) =>
                <SelectPicker onChange={field.onChange} value={field.value} data={pageList?.pages.map(p => ({
                  label: p.title,
                  value: p.id
                }))} style={{width: "100%"}} />
            } name="page_id" />
            {errors?.page_id?.message && (
              <Form.Text className="text-danger">{errors.page_id.message}</Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="menuLink">
            <Form.Label>{t("menu_content_link")}</Form.Label>
            <Form.Control {...register("link")} />
            {errors?.link?.message && (
              <Form.Text className="text-danger">{errors.link.message}</Form.Text>
            )}
          </Form.Group>
        </div>

        <Form.Group md={8} controlId="menuDescription">
          <Form.Label>{t("menu_content_Description")}</Form.Label>
          <Form.Control {...register("description")} as="textarea" rows={3} />
          {errors?.description?.message && (
            <Form.Text className="text-danger">
              {errors.description.message}
            </Form.Text>
          )}
        </Form.Group>

        <div className="d-flex">
          <Button variant="primary" type="submit">
            {t("menu_content_submit")}
          </Button>
        </div>
      </Form>
    </div>
  );
}
