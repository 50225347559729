import {useEffect, useState} from "react";
import {usePrivateAPI} from "./usePrivateApi";
import {removeEventMedia} from "../services/event";
import {removeContentLanguageMedia, removeContentMedia} from "../services/content";
import {jsonToFormData} from "../helpers";

export function useContent(id) {
  const [content, setContent] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {http} = usePrivateAPI();

  const fetchContent = async () => {
    try {
      setIsLoading(true);
      const response = await http.get(`/master_crud/get/${id}`);
      setContent(response.data.data);
    } catch (err) {
      setError({
        title: "Failed to fetch content!",
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  async function editContent(data) {
    try {
      setIsLoading(true);
      if (data.image?.[0]) {
        data.image = data.image?.[0];
      }
      const form_data = jsonToFormData(data)
      const response = await http.post(`/master_crud/update/${id}`, form_data);
      setContent(response.data.data);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleMediaRemove(data) {
    try {
      setIsLoading(true);
      let removeIds = {
        media_id: [data]
      };
      await removeContentMedia(id, removeIds)
    } catch (e) {
      setError({
        title: "Failed to Remove Media!",
        message: e.message,
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleLanguageMediaRemove(data) {
    try {
      setIsLoading(true);
      let removeIds = {
        media_id: [data]
      };
      await removeContentLanguageMedia(id, removeIds)
    } catch (e) {
      setError({
        title: "Failed to Remove Media!",
        message: e.message,
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchContent();
  }, []);

  return {content, isLoading, editContent, handleMediaRemove, handleLanguageMediaRemove, error};
}
