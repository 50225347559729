import {DefaultNameValidation} from "../../configs/constants";
import * as yup from "yup";

export const PricingInputSchema = (t) => yup.object({
  package_name: DefaultNameValidation().required().max(255).label(t('pricing_package_name')),
  pricing_category_id: yup.string().required().label(t('pricing_category')),
  price: yup.string().nullable().label(t('pricing_price')),
  duration: yup.string().nullable().label(t('pricing_duration')),
  feature: yup.array(FeatureInputSchema(t)).label(t('pricing_feature')).default([]).min(1).required(),
  link: yup.string().nullable().label("link"),
});

export const FeatureInputSchema = (t) => yup.object({
  title: yup.string(255).required().label(t('title')),
  status: yup.boolean().label(t("status")).default(false)
})