import {useEffect} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";

import {getUrlFromStoragePath} from "../../configs";
import useTaxonomy from "../../hooks/useTaxonomy";
import {TaxonomyInputSchema} from "./schema";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {processTaxonomyInput} from "../../services/taxonomy";
import {useLang} from "../../hooks";
import {BackButton} from "../../components/BackButton/BackButton";

export function TaxonomyEditPage() {
  const {t} = useLang();
  const {id} = useParams();
  const {taxonomy, edit, isLoading, error} = useTaxonomy(id);
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setValue,
    setError,
    watch,
  } = useForm({
    resolver: yupResolver(TaxonomyInputSchema(t)),
  });

  const name = watch("name");

  if (error) {
    notifyError(error);
    setTimeout(() => navigate("/taxonomies/manage"), 500);
  }

  useEffect(() => {
    if (taxonomy) {
      setValue("name", taxonomy.name ?? "");
      setValue("description", taxonomy.description ?? "");
    }
  }, [taxonomy]);

  const handleEdit = async (data) => {
    try {
      await edit(processTaxonomyInput(data));
      notifySuccess({
        title: `Taxonomy "${taxonomy.name}" updated successfully`,
      });
      navigate("/taxonomies/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Failed to update image!",
        message: err.message,
      });
    }
  };

  return (
    <Layout>
      {isLoading && <CircleLoader/>}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/taxonomies/manage`}>{t("taxonomies_manage_taxonomies")}</Link>
            </li>
            <Breadcrumb.Item active>
              {t("taxonomies_edit_edit")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("taxonomies_manage_taxonomies")}/>
        </div>
      </header>
      <section id="#taxonomy-create">
        <h2>{t("taxonomies_edit_edit")} / {name}</h2>
      </section>
      <Form
        onSubmit={handleSubmit(handleEdit)}
        className="mt-4 d-flex  flex-column gap-3"
      >
        <div className="form-row">
          <Form.Group className="flex-grow-1" controlId="name">
            <Form.Label className="mark-required">{t("taxonomies_input_name")}</Form.Label>
            <Form.Control
              {...register("name")}
              placeholder={t("taxonomies_input_name_p")}
              type="text"
            />
            {errors.name?.message && (
              <Form.Text className="text-danger">
                {errors.name.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>
        <Form.Group controlId="description">
          <Form.Label className="mark-required">{t("taxonomies_input_description")}</Form.Label>
          <Form.Control
            {...register("description")}
            placeholder={t("taxonomies_input_description_p")}
            as="textarea"
            rows={3}
          />
          {errors.description?.message && (
            <Form.Text className="text-danger">
              {errors.description.message}
            </Form.Text>
          )}
        </Form.Group>
        <div className="d-flex justify-content-end gap-2">
          <Button className="px-4" variant="success" type="submit">
            {t("taxonomies_edit_save")}
          </Button>
          <Link className="btn btn-danger" to="/taxonomies/manage">
            {t("taxonomies_edit_cancel")}
          </Link>
        </div>
      </Form>
    </Layout>
  );
}
