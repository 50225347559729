import { useSelector } from "react-redux";

const langConfig  = {
  "US": require("../configs/lang/en.json"),
  "BD": require("../configs/lang/bn.json"),
}

export const useLang = () => {
  const {Language} = useSelector((state) => state);
  const dict = langConfig[Language.data] ?? langConfig["US"]
  const translate = (id) => {
    return dict[id]
  }

  return {t: translate, lang: Language.data}
}

