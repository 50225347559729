import {useState} from "react";
import {Breadcrumb, Button, Card, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {albumInputSchema} from "./albumInputSchema";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {createAlbum} from "./album.service";
import {Link} from "react-router-dom";
import {BackButton} from "../../components/BackButton/BackButton";
import {useLang} from "../../hooks";
import MediaCard from "../MediaManagement/components/MediaCard";

function AlbumCreatePage() {
  const {t} = useLang();
  const [checkValue, setCheckValue] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const {notifyError, notifySuccess} = useNotification();

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError
  } = useForm({
    resolver: yupResolver(albumInputSchema)
  });

  const addAlbum = async (data) => {
    try {
      setIsLoading(true);
      const album = await createAlbum({checkValue, ...data});
      notifySuccess({
        title: "Album created successfully",
        message: `Media ${album.name} created!`
      }, "/albums/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: err.message
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemove = (id) => {
    var newCheckList = checkValue?.filter((c) => c !== id);
    var newSelectedMedia = selectedMedia?.filter((m) => m.id !== id);
    setSelectedMedia(newSelectedMedia);
    setCheckValue(newCheckList);
  };

  return (
    <Layout>
      {isLoading && <CircleLoader />}
      <section id="#taxonomy-create">
        <header>
          <div className="d-flex justify-content-between align-items-center">
            <Breadcrumb className="d-flex align-items-center">
              <li className="breadcrumb-item">
                <Link to={`/albums/manage`}> {t("album_manage_albums")}</Link>
              </li>
              <Breadcrumb.Item active>
                {t("album_create_createAnAlbum")}
              </Breadcrumb.Item>
            </Breadcrumb>
            <BackButton path={-1} text={t("album_manage_albums")} />
          </div>
        </header>
        <h2>{t("album_create_createAnAlbum")}</h2>
      </section>
      <Form onSubmit={handleSubmit(addAlbum)} className="mt-4 container-fluid">
        <div className="form-row">
          <Form.Group as={Col} md={3}>
            <Form.Label className="mark-required">{t("album_input_image")}</Form.Label>
            <Controller
              control={control}
              name="image"
              render={({field}) => (
                <ImageInput
                  errorMessage={errors.image?.message}
                  onChange={field.onChange}
                  onError={() => {
                    setError("image", {message: "Invalid image file!"});
                  }}
                />
              )}
            />
          </Form.Group>
          <Col>
            <Form.Group controlId="name">
              <Form.Label className="mark-required">{t("album_input_name")}</Form.Label>
              <Form.Control {...register("name")} placeholder={t("album_input_name_p")} type="text" />
              {errors.name?.message && <Form.Text className="text-danger">{errors.name.message}</Form.Text>}
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label className="mark-required">{t("album_input_description")}</Form.Label>
              <Form.Control {...register("description")} placeholder={t("album_input_description_p")} as="textarea"
                            rows={3} />
              {errors.description?.message &&
                <Form.Text className="text-danger">{errors.description.message}</Form.Text>}
            </Form.Group>
          </Col>
        </div>
        <div className="row mt-5">
          <div className="d-flex">
            <MediaModal selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                        checkValue={checkValue} setCheckValue={setCheckValue} />
          </div>
        </div>
        <div className="row mt-4">
          {selectedMedia?.length > 0 ? (
            <Card>
              <Card.Body style={{minHeight: "320px"}}>
                {/*<MediaCardList media={selectedMedia} setSelectedMedia={setSelectedMedia}*/}
                {/*               checkValue={checkValue} setCheckValue={setCheckValue} isDelete />*/}
                <div className="d-flex flex-wrap flex-row gap-2">
                  {selectedMedia?.map((md) =>
                    <MediaCard key={md.id} media={md} isDelete handleRemove={handleRemove}/>
                  )}
                </div>
              </Card.Body>
            </Card>
          ) : ("")}
        </div>
        <div className="d-flex justify-content-end mt-4">
          <Button className="px-4" type="submit">
            {t("album_create_create")}
          </Button>
        </div>
      </Form>
    </Layout>
  );
}

export default AlbumCreatePage;
