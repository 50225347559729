import {useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {createLanguage} from "../../services/language";
import {useLanguages} from "../../hooks/useLanguages";
import {LanguageSelector} from "../../components/Language/LanguageSelector";
import {useLang} from "../../hooks";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {TextEdit} from "../../components";
import moment from "moment";
import {PostLanguageInputSchema} from "./schema";
import {usePost} from "../../hooks/usePost";
import {getUrlFromStoragePath} from "../../configs";
import {useMediaSelector} from "../../components/MediaSelectorModal";
import MediaCard from "../MediaManagement/components/MediaCard";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";

export function PostLanguageCreatePage() {
  const {resourceId} = useParams();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {t} = useLang()

  const {post, isLoading: isPostLoading, error} = usePost(resourceId)

  const {langs, isLoading: isLangLoading, error: isLangError} = useLanguages();

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(PostLanguageInputSchema(t)),
  });

  const {
    checkValue,
    setCheckValue,
    selectedMedia,
    setSelectedMedia,
    allMediaId,
    setAllMediaId,
    handleRemove,
  } = useMediaSelector();

  if (error || isLangError) {
    notifyError({
      title: "Something went wrong!",
      message: error.message
    })
    navigate(-1)
  }

  useEffect(() => {
    if (post) {
      setValue("name", post.name ?? "");
      setValue("post_date", moment(post.post_date).format("MMMM, DD, YYYY (hh:mm:ss a)") ?? "")
      setValue("description", post.description ?? "");
    }
  }, [post]);

  const create = async (data) => {
    try {
      setIsLoading(true);
      data.post_id = resourceId
      const postLangData = {...data, media_id: checkValue}
      await createLanguage("post", postLangData)
      notifySuccess({
        title: "language created successfully!",
        message: "Language Created",
      });
      navigate(-1);
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Failed to create language",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: "Language creation failed!",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      {(isLoading || isPostLoading || isLangLoading) && <CircleLoader/>}
      <section id="#post-lang-create">
        <header>
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to={`/posts/manage`}>Contents</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/langs/post/${resourceId}`}>{post?.name ?? resourceId} Languages</Link>
            </li>
            <Breadcrumb.Item active>create language</Breadcrumb.Item>
          </Breadcrumb>
          <h3>Create Language ({post?.name ?? resourceId})</h3>
        </header>
        <Form
          onSubmit={handleSubmit(create)}
          className="mt-4 d-flex  flex-column gap-3"
        >

          <LanguageSelector loading={isLangLoading} control={control} langs={langs} errors={errors}/>
          <div className="form-row">
            <Form.Group as={Col} controlId="name">
              <Form.Label className="mark-required">{t("posts_create_name")}</Form.Label>
              <Form.Control
                {...register("name")}
                placeholder={t("posts_create_name_plhd")}
                type="text"
              />
              {errors.name?.message && (
                <Form.Text className="text-danger">
                  {errors.name.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group as={Col} controlId="post_date">
              <Form.Label className="mark-required">{t("posts_create_postDate")}</Form.Label>
              <Form.Control
                {...register("post_date")}
                type="text"
              />
              {errors.post_date?.message && (
                <Form.Text className="text-danger">
                  {errors.post_date.message}
                </Form.Text>
              )}
            </Form.Group>
          </div>
          <Form.Group controlId="image">
            <Form.Label>{t("posts_create_image")}</Form.Label>
            <div className="w-100" style={{maxHeight: "400px"}}>
              <Controller
                control={control}
                name="image"
                render={({field}) => (
                  <MediaModal selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                              checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                    {
                      selectedMedia?.length > 0 ? (
                        selectedMedia?.map((md) =>
                          <MediaCard key={md.id} media={md} isDelete handleRemove={handleRemove}/>
                        )
                      ): (
                        <div style={{height: "300px", width: "100%"}}>
                          <ImageInput
                            errorMessage={errors.image?.message}
                            onChange={field.onChange}
                            onError={() => {
                              setError("image", {message: "Invalid image file!"});
                            }}
                          />
                        </div>
                      )
                    }
                  </MediaModal>
                )}
              />
            </div>
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label className="mark-required">{t("posts_create_description")}</Form.Label>
            <Controller
              control={control}
              name="description"
              render={({field}) => <TextEdit defaultValue={post?.description} {...field} />}
            />
            {errors.description?.message && (
              <Form.Text className="text-danger">
                {errors.description.message}
              </Form.Text>
            )}
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button className="px-4" type="submit">
              Create
            </Button>
          </div>
        </Form>
      </section>
    </Layout>
  );
}
