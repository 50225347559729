import {useCallback, useEffect, useState} from "react";
import {getCommonSettings, updateCommonSettings} from "../services/settings";


export function useCommonSettings() {
  const [settings, setSettings] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const fetchCommonSettings = useCallback(async () => {
    try {
      setIsLoading(true)
      setSettings(await getCommonSettings())
    } catch (err) {
      setError({
        title: "Failed to fetch common settings",
        message: err.message,
      })
    } finally {
      setIsLoading(false)
    }
  }, []);


  const save = async (data) => {
    try {
      setIsLoading(true)
      setSettings(await updateCommonSettings(data))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchCommonSettings()
  }, [fetchCommonSettings]);

  return {save, isLoading, settings, error}
}