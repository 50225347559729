import {api, parseAPIError} from "./api";
import {arrayToObject, jsonToFormData} from "../helpers";
import {processSeoInput} from "./seo";

export async function getPaginatedOrder(options) {
  const r = await api.get("/orders/show", {
    params: options
  });
  if (r.data.data) {
    return r.data.data;
  }
  return {
    data: [],
    total: 0
  };
}
export async function getOrder(id) {
  const r = await api.get(`/orders/get/${id}`);
  return r.data.data;
}
export async function createOrder(input) {
  try {
    let data = jsonToFormData(input);
    const r = await api.post("/orders/store", data);
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}
export async function removeOrder(id) {
  const r = await api.delete(`/orders/delete/${id}`);
  return r.data.data;
}
export async function updateOrder(id, input) {
  try {
    let data = jsonToFormData(input);
    const r = await api.post(`/orders/update/${id}`, data);
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

//
