import {Link} from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import {useLang} from "../../hooks";

export function BackButton({path, text}) {
    const {t} = useLang();
    return (
        <Link to={path} className="rs-btn rs-btn-xs d-flex align-items-center gap-2" title={text}>
                <FaAngleLeft className="d-block"/>
                <div>{t("go_back")}</div>
        </Link>
    )
}