import { useFooterSettings } from "../hooks/useFooterSettings";
import { useLang, useTermList } from "../../../hooks";
import { useNotification } from "../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FooterSettingsOneSchema } from "../schema";
import { useEffect } from "react";
import { InputValidationError } from "../../../libs/error";
import { Button, Col, Form } from "react-bootstrap";
import { ImageInput } from "../../../components/MediaInputs/imageInput";
import { InputGroup, Placeholder, SelectPicker } from "rsuite";
import { BsGlobe } from "react-icons/bs";
import { useMediaSelector } from "../../../components/MediaSelectorModal";
import MediaModal from "../../../components/MediaSelectorModal/MediaModal";
import MediaCardListSmall from "../../../components/MediaSelectorModal/MediaCardListSmall";

export function FooterSettingsOne({ onLanguageEdit }) {
  const { settings, error, isLoading, save } = useFooterSettings("one");
  const { t } = useLang();
  const { notifyError, notifySuccess } = useNotification();
  const { termList, isLoading: isTermsLoading, error: termError } =
    useTermList(8, { page: 1, limit: 10000 });
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    control,
    setError
  } = useForm({
    resolver: yupResolver(FooterSettingsOneSchema(t))
  });

  const {
    selectedMedia,
    setSelectedMedia,
    checkValue,
    setCheckValue,
    allMediaId,
    setAllMediaId,
    handleRemove
  } = useMediaSelector();

  if (error || termError) {
    navigate(error ?? termError, "/error");
  }

  useEffect(() => {
    if (settings) {
      console.log(settings);
      const { footer_logo_one, footer_menu_id_one, ...newInput } = settings;
      reset({
        footer_title_one: settings.footer_title_one?.value ?? "",
        footer_content_one: settings.footer_content_one?.value ?? "",
        footer_is_social_one: settings.footer_is_social_one?.value,
        footer_menu_id_one: settings.footer_menu_id_one ? String(settings.footer_menu_id_one.value) : "",
        is_search_one: settings.is_search_one?.value,
        is_subscription_one: settings.is_subscription_one?.value,
        is_gallery_one: settings.is_gallery_one?.value,
        is_post_one: settings.is_post_one?.value,
        button_text_one: settings.button_text_one?.value ?? "",
        button_link_one: settings.button_link_one?.value ?? "",
        footer_html_area_one: settings.footer_html_area_one?.value ?? "",
        select_form_one: settings.select_form_one?.value ?? "",
        title_footer_one: settings.title_footer_one?.value ?? "",
        description_footer_one: settings.description_footer_one?.value ?? "",
        copy_right_one: settings.copy_right_one?.value ?? "",
        copy_right_html_area_one: settings.copy_right_html_area_one?.value ?? "",
      });

      let footerLogo = footer_logo_one.value;
      let mediaId = footerLogo ? [footerLogo?.id] : [];
      let media = footerLogo ? [footerLogo] : [];

      setSelectedMedia(media);
      setCheckValue(mediaId);
      setAllMediaId(mediaId);
    }
  }, [settings]);

  const handleUpdate = async (data) => {
    console.log("setting", settings)
    try {
      await save({ ...data, footer_logo_one: checkValue[0] ?? null });
      notifySuccess({
        title: "Success",
        message: "First Column Updated Successfully"
      })
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input!",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Failed to update",
        message: err.message
      });
    }
  };

  return <section id="footer-settings-form" className="my-4">
    <h3 className="mb-3">{t("settings_footer_one")}</h3>
    {isLoading ? <Placeholder.Graph active /> :
      <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit(handleUpdate)}>
        <div className="form-row">
          <Form.Group as={Col} xl={2} style={{ minWidth: '250px' }}>
            <Form.Label>{t("settings_site_footer_logo")}</Form.Label>
            <div style={{ height: "200px", justifyContent: 'center' }}>
              <Controller
                control={control}
                name="footer_logo_one"
                render={({ field }) => (
                  <MediaModal isSingleMode selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                    checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                    {
                      selectedMedia?.length > 0 ? (
                        <MediaCardListSmall selectedMedia={selectedMedia} isDelete handleRemove={handleRemove} />
                      ) : (
                        <div style={{ height: "190px", width: "100%" }}>
                          <ImageInput
                            errorMessage={errors.image?.message}
                            onChange={field.onChange}
                            onError={() => {
                              setError("image", { message: "Invalid image file!" });
                            }}
                          />
                        </div>
                      )
                    }
                  </MediaModal>
                )}
              />
            </div>
          </Form.Group>

          <Col className="gap-2 d-flex flex-column">
            <div className="form-row">
              <Form.Group as={Col} className="flex-grow-1" controlId="footer_title_one">
                <Form.Label className="mark-required">{t("settings_footer_title")}</Form.Label>
                <InputGroup>
                  <Form.Control {...register("footer_title_one")} placeholder={t("settings_footer_title_phld")}
                    type="text" />

                  <Button onClick={() => {
                    onLanguageEdit(settings.footer_title_one?.id);
                  }}>
                    <BsGlobe />
                  </Button>
                </InputGroup>
                {errors.footer_title_one?.message && (
                  <Form.Text className="text-danger">{errors.footer_title_one.message}</Form.Text>)}
              </Form.Group>

              <Form.Group as={Col} className="flex-grow-1" controlId="footer_content_one">
                <Form.Label>{t("settings_footer_content")}</Form.Label>
                <InputGroup>
                  <Form.Control {...register("footer_content_one")} placeholder={t("settings_footer_content_phld")}
                    type="text" />
                  <Button onClick={() => {
                    onLanguageEdit(settings.footer_content_one?.id);
                  }}>
                    <BsGlobe />
                  </Button>
                </InputGroup>
                {errors.footer_content_one?.message && (
                  <Form.Text className="text-danger">{errors.footer_content_one.message}</Form.Text>)}
              </Form.Group>
            </div>
            <Form.Group controlId="footer_menu_id_one">
              <Form.Label>
                {t("settings_footer_menu")}
              </Form.Label>
              <div>
                <Controller render={({ field }) => <SelectPicker
                  onChange={field.onChange}
                  value={field.value}
                  style={{ width: "100%" }}
                  data={termList?.terms.map(t => ({ value: String(t.id), label: t.name }))}
                  placeholder={t("settings_footer_main_placeholder")}
                  loading={isTermsLoading}
                />
                } name="footer_menu_id_one" control={control} />
              </div>
              {errors.footer_menu_id_one?.message && (
                <Form.Text className="text-danger">
                  {errors.footer_menu_id_one.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="footer_is_social_one">
              <Form.Label>{t("settings_footer_is_social")}</Form.Label>
              <Form.Control
                {...register("footer_is_social_one")}
                as="select"
              >
                <option value="1">{t("settings_yes")}</option>
                <option value="0">{t("settings_no")}</option>
              </Form.Control>
              {errors.footer_is_social_one?.message && (
                <Form.Text className="text-danger">
                  {errors.footer_is_social_one.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </div>
        <div className="form-row">
          <Form.Group as={Col} className="flex-grow-1" controlId="is_search_one">
            <Form.Label>{t("settings_is_search_one")}</Form.Label>
            <Form.Control
              {...register("is_search_one")}
              as="select"
            >
              <option value="1">{t("settings_yes")}</option>
              <option value="0">{t("settings_no")}</option>
            </Form.Control>
            {errors.is_search_one?.message && (
              <Form.Text className="text-danger">
                {errors.is_search_one.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} className="flex-grow-1" controlId="is_subscription_one">
            <Form.Label>{t("settings_is_subscription_one")}</Form.Label>
            <Form.Control
              {...register("is_subscription_one")}
              as="select"
            >
              <option value="1">{t("settings_yes")}</option>
              <option value="0">{t("settings_no")}</option>
            </Form.Control>
            {errors.is_subscription_one?.message && (
              <Form.Text className="text-danger">
                {errors.is_subscription_one.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>
        <div className="form-row">
          <Form.Group as={Col} className="flex-grow-1" controlId="is_gallery_one">
            <Form.Label>{t("settings_is_gallery_one")}</Form.Label>
            <Form.Control
              {...register("is_gallery_one")}
              as="select"
            >
              <option value="1">{t("settings_yes")}</option>
              <option value="0">{t("settings_no")}</option>
            </Form.Control>
            {errors.is_gallery_one?.message && (
              <Form.Text className="text-danger">
                {errors.is_gallery_one.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} className="flex-grow-1" controlId="is_post_one">
            <Form.Label>{t("settings_is_post_one")}</Form.Label>
            <Form.Control
              {...register("is_post_one")}
              as="select"
            >
              <option value="1">{t("settings_yes")}</option>
              <option value="0">{t("settings_no")}</option>
            </Form.Control>
            {errors.is_post_one?.message && (
              <Form.Text className="text-danger">
                {errors.is_post_one.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>
        <div className="form-row">
          <Form.Group as={Col} className="flex-grow-1" controlId="button_text_one">
            <Form.Label className="">{t("settings_button_text_one")}</Form.Label>
            <Form.Control {...register("button_text_one")} placeholder={t("settings_button_text_one_phld")}
              type="text" />
            {errors.button_text_one?.message && (
              <Form.Text className="text-danger">{errors.button_text_one.message}</Form.Text>)}
          </Form.Group>
          <Form.Group as={Col} className="flex-grow-1" controlId="button_link_one">
            <Form.Label className="">{t("settings_button_link_one")}</Form.Label>
            <Form.Control {...register("button_link_one")} placeholder={t("settings_button_link_one_phld")}
              type="text" />
            {errors.button_link_one?.message && (
              <Form.Text className="text-danger">{errors.button_link_one.message}</Form.Text>)}
          </Form.Group>
        </div>
        <div className="form-row">

          <Form.Group className="flex-grow-1" controlId="footer_html_area_one">
            <Form.Label className="">{t("settings_footer_html_area_one")}</Form.Label>
            <Form.Control {...register("footer_html_area_one")} placeholder={t("settings_footer_html_area_one_phld")}
              type="text" />
            {errors.footer_html_area_one?.message && (
              <Form.Text className="text-danger">{errors.footer_html_area_one.message}</Form.Text>)}
          </Form.Group>

        </div>
        <div className="form-row">
          <Form.Group as={Col} className="flex-grow-1" controlId="select_form_one">
            <Form.Label className="">{t("settings_select_form_one")}</Form.Label>
            <Form.Control {...register("select_form_one")} placeholder={t("settings_select_form_one_phld")}
              type="text" />
            {errors.select_form_one?.message && (
              <Form.Text className="text-danger">{errors.select_form_one.message}</Form.Text>)}
          </Form.Group>
        </div>
        <div className="d-flex justify-content-end gap-2">
          <Button className="px-4" variant="success" type="submit">
            {t("common_settings_save")}
          </Button>
        </div>
      </Form>}
  </section>;
}