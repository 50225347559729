import {useEffect, useState} from "react";
import {usePrivateAPI} from "../../../hooks";
import {usePagination} from "../../../hooks/usePagination";

export function useMenuList() {
  const [isLoading, setIsLoading] = useState(false);
  const [menus, setMenuList] = useState([]);
  const {pagination, changePagination} = usePagination();
  const [error, setError] = useState();
  const {http} = usePrivateAPI();

  useEffect(() => {
    getMenus();
  }, [pagination]);

  async function getMenus() {
    try {
      setIsLoading(true);
      const res = await http.get("terms/terms-by-taxonomyid/8", {
        params: {
          page: pagination.page
        }
      });
      setMenuList({menus: res.data.data.data, total: res.data.data.total});
    } catch (err) {
      setError({
        title: "Failed to fetch menu",
        message: err.message
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function deleteMenu(id) {
    try {
      setIsLoading(true);
      await http.delete(`/terms/delete/${id}`);
      getMenus();
    } catch (err) {
      throw err;
    } finally {
      setIsLoading(false);
    }
  }

  return {isLoading, error, menus, deleteMenu, changePagination, pagination};
}
