import {useState} from "react";
import {Modal} from "rsuite";
import {Button} from "react-bootstrap";
import {useLang} from "../../hooks";
import LayoutManager from "./LayoutManager";

function LayoutModal({
                      selectedMedia,
                      setSelectedMedia,
                      isSingleMode,
                      handleMediaAddClick = () => {
                      },
                      type,
                      children
                    }) {
  const [mediaModalOpen, setMediaModalOpen] = useState(false);
  const [checkValue, setCheckValue] = useState([]);
  const [allMediaId, setAllMediaId] = useState([]);

  const handleMediaModalOpen = () => {
    if(isSingleMode) {
      if(selectedMedia) return;
    }
    setMediaModalOpen(true)
  };
  const handleMediaModalClose = () => {
    setMediaModalOpen(false);
    handleMediaAddClick();
  };
  const {t} = useLang();

  return (
    <>
      <div onClick={() => handleMediaModalOpen("full")} className="d-flex gap-2">
        { children }
      </div>
      <Modal size={"lg"} open={mediaModalOpen} onClose={handleMediaModalClose} backdrop={"static"}>
        <Modal.Header>
          <Modal.Title>Layouts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LayoutManager selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                        isSingleMode={isSingleMode} checkValue={checkValue} setCheckValue={setCheckValue}
                        allMediaId={allMediaId} handleMediaModalClose={handleMediaModalClose} type={type}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleMediaModalClose}>
            {t("save_btn")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LayoutModal;
