import React, { useState, useEffect } from "react";
import "./css/style.css";
import { FaUserAlt, FaKey } from "react-icons/fa";
import { Link, useNavigate, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from "react-bootstrap";
import http from "../../http";
import Cookies from "universal-cookie";
import update_logo from "../../images/image.png";

const cookies = new Cookies();
const ForgotPassword = () => {
  const { register, handleSubmit } = useForm();
  const [getLoginData, setLoginData] = useState("");
  const navigate = useNavigate();
  const [getError, setError] = useState({});
  const [getEmail, setEmail] = useState(false);

  const onSubmit = (data, e) => {
    setError({});
    // data.image_url = data.image_url[0];
    // setData(data);

    data = { ...getLoginData };
    http
      .post("/forgot-password", data)
      .then((res) => {
        if (res.status == 200) {
          setEmail(true);
        }
      })
      .then(() => {
        setTimeout(() => {
          setEmail(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message == "Validation Error.") {
          let errs = err.response.data.data;
          let keys = Object.keys(errs);
          console.log(keys);
          let errors = {};
          keys.map((key) => (errors[key] = errs[key][0]));
          console.info(errors);
          setError(errors);
        }
        // else if(err?.response?.data?.message == "Logical Error."){
        //     setAuthError(true)
        // }
        // else{
        //     setUnAuthorized(true)
        // }
      });
  };
  // console.log(getAuthError)
  const onError = (errors, e) => console.log(errors, e);

  console.log(getError);

  const handleChange = (e) => {
    setError("");

    let loginData = { ...getLoginData };
    loginData[e.target.name] = e.target.value;
    setLoginData(loginData);
  };

  return (
    <>
      {getEmail == true ? (
        <Toast
          bg="success"
          style={{
            position: "fixed",
            top: "30px",
            right: "0",
            zIndex: "111111",
          }}
        >
          <Toast.Body>Succesfully Sent Email</Toast.Body>
        </Toast>
      ) : null}

      <div className="limiter">
        <div
          className="container-login100"
          style={{ backgroundImage: 'url("Group-1241.png")' }}
        >
          <div className="wrap-login100 pt-4">
            {/* <span className="login100-form-title pt-4 pb-5">Reward Management</span> */}
            <img src={update_logo} className="mx-auto d-block img-fluid pt-4 pb-5"/>

            <Form
              className="login100-form validate-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Form.Group
                className={`wrap-input100 ${
                  getError.email ? "alert-validate" : null
                } validate-input mb-3`}
                data-validate={getError?.email}
              >
                <Form.Control
                  id="input100"
                  type="text"
                  name="email"
                  placeholder="Enter the Email"
                  onChange={(e) => handleChange(e)}
                />
                <span className="focus-input100" />
                <span className="symbol-input100">
                  <FaUserAlt />
                </span>
              </Form.Group>

              <div className="container-login100-form-btn pt-3">
                <Button
                  id="login100-form-btn"
                  variant="success"
                  type="submit"
                >
                  Submit
                </Button>
                <div className="text-center p-3">
                  <Link to="/login" style={{ color: "black" }}>
                    Back to Login Page
                  </Link>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
