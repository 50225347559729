import {useCallback, useEffect, useState} from "react";
import {createMenuItem, getMenuContent, removeItem, updateMenuItem} from "../menu.service";

export function useMenuContent(menuId) {
  const [menuList, setMenuList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      setMenuList(await getMenuContent(menuId));
    } catch (err) {
      setError({
        title: "Failed to fetch content",
        message: err.message
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const addItem = async (data) => {
    try {
      setIsLoading(true);
      await createMenuItem(data);
      await fetch();
    } finally {
      setIsLoading(false);
    }
  };

  const updateItem = async (id, data) => {
    try {
      setIsLoading(true);
      await updateMenuItem(id, data);
      await fetch();
    } finally {
      setIsLoading(false);
    }
  };

  const deleteItem = async (id) => {
    try {
      setIsLoading(true);
      await removeItem(id);
    } finally {
      await fetch();
      setIsLoading(false);
    }
  };


  return {
    data: menuList,
    isLoading,
    addItem,
    updateItem,
    deleteItem,
    error
  };
}
