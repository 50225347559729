import {api, parseAPIError} from "./api";

export function processTermInput(input) {
  // input.parent_id = input.parent_id ?? "NULL"
  // input.image = input.image ?? "NULL";
  return input
}

export async function createTerm(input) {
  try {
    const r = await api.post("terms/store", processTermInput(input));
    return r.data.data
  } catch (err) {
    throw parseAPIError(err)
  }
}

export async function getTerm(id) {
  const r = await api.get(`terms/get/${id}`);
  return r.data.data
}

export async function updateTerm(id, input) {
  try {
    const r = await api.post(`terms/update/${id}`, processTermInput(input));
    return r.data.data
  } catch (err) {
    throw parseAPIError(err)
  }
}

export async function getPaginatedTerm(options) {
  const r = await api.get("/terms/list", {
    params: options
  })
  if (r.data.data) {
    return r.data.data
  }
  return {
    data: [],
    total: 0,
  }
}

export async function removeTerm(id) {
  const r = await api.delete(`/terms/delete/${id}`);
  return r.data.data
}
