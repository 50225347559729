import * as xlsx from "xlsx";

export function bytesToMb(bytes) {
  if (bytes === 0) return "0 bytes";

  const sizes = ["Bytes", "KiB", "MiB", "GiB", "TiB"];
  const i = Math.min(sizes.length - 1, Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024))); // Handle negative values and edge cases

  return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
}

export function formatDate(milliseconds) {
  const date = new Date(milliseconds);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "Asia/Dhaka" // Adjust based on your location
  };
  return date.toLocaleDateString("en-US", options);
}

export function getFileNameFromUrl(url) {
  if (!url || !url.trim()) {
    return "";
  }
  const pathParts = url.split("/");
  const fileNameWithQuery = pathParts[pathParts.length - 1];
  const fileNameParts = fileNameWithQuery.split("?");
  return fileNameParts[0] || "";
}

export const exportToExcel = (data, fileName) => {
  const workbook = xlsx.utils.book_new();
  const worksheet = xlsx.utils.json_to_sheet(data);

  xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'buffer' });
  const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(excelData);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.xlsx`);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};