import {api, parseAPIError} from "./api";
import {processSeoInput} from "./seo";
import moment from "moment/moment";
import {jsonToFormData} from "../helpers";

export function processFormInput(input) {
  return input
}

export async function createForm(input) {
  try {
    let data = processFormInput(input);
    const r = await api.post("/form_builder/store", data);
    return r.data.data
  } catch (err) {
    throw parseAPIError(err)
  }
}

export async function getForm(id) {
  const r = await api.get(`form_builder/get/${id}`);
  return r.data.data
}

export async function updateForm(id, input) {
  try {
    let data = processFormInput(input);
    const r = await api.post(`form_builder/update/${id}`, data);
    return r.data.data
  } catch (err) {
    throw parseAPIError(err)
  }
}

export async function getPaginatedForm(options) {
  console.log(options)
  const r = await api.get("/form_builder/list", {
    params: options
  })
  if (r.data.data) {
    return r.data.data
  }
  return {
    data: [],
    total: 0,
  }
}

export async function removeForm(id) {
  const r = await api.delete(`/form_builder/delete/${id}`);
  return r.data.data
}
