import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";

import {useLang, useTermList} from "../../hooks";
import {BackButton} from "../../components/BackButton/BackButton";
import {FormBuilder} from "../../components/FormBuilder";
import {useEffect, useState} from "react";
import {useFormBuilder} from "./hooks/useFormBuilder";

export function FormBuilderEditPage() {
  const {t} = useLang();
  const {formId} = useParams();
  const {formBuilder, edit, isLoading, error} = useFormBuilder(formId)
  const {notifyError, notifySuccess} = useNotification();
  const [name,setName] = useState();
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    defaultValues: {
      status: false,
    },
  });

  if (error) {
    notifyError(error, "/error");
  }


  useEffect(() => {
    setName(formBuilder?.name);
    setData(localStorage.getItem("formData"));
  }, [formBuilder]);

  const handleEdit = async (data) => {
    try {
    let formData = {name, details: localStorage.getItem("formData")}
        await edit(formData);
      notifySuccess({
        title: `Form updated successfully`,
      }, "/form-builder/manage");
      localStorage.removeItem("formData")
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Failed to update pricing!",
        message: err.message,
      });
    }
  }

  function handleSubmitPreview() {
    setIsOpen(true);
    const data = JSON.parse(localStorage.getItem("formData"));
    setData(data);
  }

  function handlePreviewClose() {
    setIsOpen(false)
  }

  function handleCancel() {
    localStorage.removeItem("formData");
    navigate(-1);
  }

  return (
    <Layout>
      {/*{isLoading && <CircleLoader />}*/}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/form-builder/manage`}> {t("formBuilder_manage_forms")}</Link>
            </li>
            <Breadcrumb.Item active>
              {t("formBuilder_manage_newForms")}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </header>
      {/*<section id="#event-edit">*/}
      {/*  <h2>{t("events_edit_edit")} {name}</h2>*/}
      {/*</section>*/}
      <div className="d-flex gap-2 pb-2">
        <button
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={(e) => handleEdit()}
        >
          {t("save_btn")}
        </button>

        <button
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={(e) => handleCancel()}
        >
          {t("cancel_btn")}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={(e) => handleSubmitPreview()}
        >
          {t("preview")}
        </button>
      </div>
      <Form.Group as={Col} controlId="title" className="pb-2 w-50">
        <Form.Label className="mark-required">{t("name")}</Form.Label>
        <Form.Control
          value={name}
          placeholder={t("formBuilder_name_p")}
          type="text"
          onChange={(e) => setName(e.target.value)}
        />
        {errors.name?.message && (
          <Form.Text className="text-danger">
            {errors.name.message}
          </Form.Text>
        )}
      </Form.Group>

      {data != null &&
        <Form.Group as={Col} controlId="title">
          <Form.Label className="mark-required">{t("details")}</Form.Label>
          <Controller
            control={control}
            name="details"
            render={({field}) => (
              <FormBuilder isOpen={isOpen} onClose={handlePreviewClose} data={data} />
            )}
          />
          {errors.details?.message && (
            <Form.Text className="text-danger">
              {errors.details.message}
            </Form.Text>
          )}
        </Form.Group>
      }
    </Layout>
  );
}
