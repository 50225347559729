import {useEffect} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {DateRangePicker, SelectPicker, TagInput, Toggle} from "rsuite";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import {TextEdit} from "../../components";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";

import {useLang, useTermList} from "../../hooks";
import {EventInputSchema} from "./schema.js";
import {useEvent} from "../../hooks/useEvent";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {BackButton} from "../../components/BackButton/BackButton";
import {useMediaSelector} from "../../components/MediaSelectorModal";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";
import MediaCardList from "../../components/MediaSelectorModal/MediaCardList";
import {usePageList} from "../../hooks/usePageList";

export function EventEditPage() {
  const {t} = useLang();
  const {id} = useParams();
  const {event, edit, isLoading, error, handleMediaRemove} = useEvent(id)
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {
    termList,
    error: termsError,
    isLoading: isTermsLoading,
  } = useTermList(11, {limit: 1000, page: 1});
  const {pageList, isLoading: pageLoading, error: pageErr}
    = usePageList(null, {page: 1, limit: 100});

  const {
    selectedMedia,
    setSelectedMedia,
    checkValue,
    setCheckValue,
    allMediaId,
    setAllMediaId
  } = useMediaSelector()

  if (termsError) {
    notifyError(termsError);
    navigate("/events/manage");
  }

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setValue,
    setError,
    watch,
  } = useForm({
    resolver: yupResolver(EventInputSchema(t)),
  });

  const name = watch("name");

  if (error) {
    notifyError(error);
    setTimeout(() => navigate("/events/manage"), 500);
  }

  const handleServerRemove = async (media_id) => {
    try {
      await handleMediaRemove(media_id);
      notifySuccess({
        title: `Media Removed successfully`
      });
      setCheckValue(checkValue.filter(m => m!== media_id))
      setSelectedMedia(selectedMedia.filter(m => m.id !== media_id))
      setAllMediaId(allMediaId.filter(m => m!==media_id));
    } catch (err) {
      notifyError({
        title: "Failed to Removed media!",
        message: err.message
      });
    }
  };

  useEffect(() => {
    if (event && termList.terms.length > 0) {
      setValue("title", event?.title ?? "");
      setValue("status", event?.status === 1);
      setValue("dateTimeRange", [new Date(event?.start_date), new Date(event?.end_date)]);
      setValue("description", event?.description ?? "")
      setValue("event_category_id", event?.event_category_id)

      setValue("type", event?.type ?? -1)
      setValue("organised_by", event?.organised_by ?? "")
      setValue("resource_person", event?.resource_person ?? "")
      setValue("location", event?.location ?? "")

      setValue("meta_title", event?.meta_title ?? "");
      setValue("meta_tag", event?.meta_tag?.split(",") ?? []);
      setValue("meta_description", event?.meta_description ?? "");

      setValue("page_id", event?.page_id ?? "")


      let mediaIds = event?.files.map(m => m.media_id);
      let media = event?.files.map(m => m.media_content);

      setSelectedMedia(media);
      setCheckValue(mediaIds);
      setAllMediaId(mediaIds);
    }
  }, [event, termList, setValue]);

  const handleEdit = async (data) => {
    try {
      let eventData = {...data, media_id: checkValue}
      await edit(eventData);
      notifySuccess({
        title: `Events "${event.title}" updated successfully`,
      });
      navigate("/events/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Failed to update event!",
        message: err.message,
      });
    }
  };

  return (
    <Layout>
      {(isLoading || isTermsLoading) && <CircleLoader/>}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/events/manage`}> {t("events_manage_events")}</Link>
            </li>
            <Breadcrumb.Item active>
              {t("events_edit_edit")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("events_manage_events")}/>
        </div>
      </header>
      <section id="#event-edit">
        <h2>{t("events_edit_edit")} {name}</h2>
      </section>
      <Form
        onSubmit={handleSubmit(handleEdit)}
        className="mt-4 d-flex  flex-column gap-3"
      >
        <div className="form-row">
          <Form.Group as={Col} md={2} controlId="event_category_id">
            <Form.Label className="mark-required">{t("events_input_category")}</Form.Label>
            <Form.Control {...register("event_category_id")} as="select" custom>
              <option value={-1}>None</option>
              {termList.terms.map((trm) => (
                <option value={trm.id}>{trm.name}</option>
              ))}
            </Form.Control>
            {errors.event_category_id?.message && (
              <Form.Text className="text-danger">
                {errors.event_category_id.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col} controlId="title">
            <Form.Label className="mark-required">{t("events_input_title")}</Form.Label>
            <Form.Control
              {...register("title")}
              placeholder={t("events_input_title_p")}
              type="text"
            />
            {errors.title?.message && (
              <Form.Text className="text-danger">
                {errors.title.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>
        <div className="form-row">
          <Form.Group as={Col} md={6} lg={4} controlId="dateTimeRange">
            <Form.Label className="mark-required">{t("events_input_startEndDateTime")}</Form.Label>
            <Controller
              control={control}
              name="dateTimeRange"
              render={({field}) => (
                <div>
                  <DateRangePicker value={field.value} onChange={field.onChange} format="dd MMM yyyy hh:mm:ss aa"
                                   showMeridian/>
                </div>
              )}
            />
            {errors.dateTimeRange?.message && (
              <Form.Text className="text-danger">
                {errors.dateTimeRange.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="page_id">
            <Form.Label>{t("menu_content_page")}</Form.Label>
            <Controller control={control} render={
              ({field}) =>
                <SelectPicker onChange={field.onChange} value={field.value} data={pageList?.pages.map(p => ({
                  label: p.title,
                  value: p.id
                }))} style={{width: "100%"}} />
            } name="page_id" />

            {errors?.page_id?.message && (
              <Form.Text className="text-danger">{errors.page_id.message}</Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="status">
            <Form.Label>{t("events_input_status")}</Form.Label>
            <Controller
              control={control}
              name="status"
              render={({field}) => (
                <div>
                  <Toggle
                    {...field}
                    checked={field.value}
                    size="lg"
                    checkedChildren={<span>Active</span>}
                    unCheckedChildren={<span>Deactive</span>}
                    className="m-auto"
                  />
                </div>
              )}
            />
            {errors.status?.message && <Form.Text className="text-danger"> {errors.status.message} </Form.Text>}
          </Form.Group>
        </div>
        <Form.Group controlId="image">
          <Form.Label>{t("events_input_image")}</Form.Label>
          <div className="w-100">
            <Controller
              control={control}
              name="image"
              render={({field}) => (
                <MediaModal selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                            checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                  {
                    selectedMedia?.length > 0 ? (
                      <MediaCardList selectedMedia={selectedMedia} isDeleteServer handleServerRemove={handleServerRemove}/>
                    ): (
                      <div style={{height: "300px", width: "100%"}}>
                        <ImageInput
                          errorMessage={errors.image?.message}
                          onChange={field.onChange}
                          onError={() => {
                            setError("image", {message: "Invalid image file!"});
                          }}
                        />
                      </div>
                    )
                  }
                </MediaModal>
              )}
            />
          </div>
        </Form.Group>
        <Form.Group controlId="description">
          <Form.Label className="mark-required">{t("events_input_description")}</Form.Label>
          <Controller
            control={control}
            name="description"
            render={({field}) => <TextEdit defaultValue={event?.description ?? ""} {...field} />}
          />
          {errors.description?.message && (
            <Form.Text className="text-danger">
              {errors.description.message}
            </Form.Text>
          )}
        </Form.Group>

        <div className="form-row">
          <Form.Group as={Col} md={2} controlId="type">
            <Form.Label className="mark-required">{t("events_input_eventType")}</Form.Label>
            <Form.Control {...register("type")} as="select" custom>
              <option value={-1}>None</option>
              <option value="Ongoing">{t("events_input_eventType_ongoing")}</option>
              <option value="Upcoming">{t("events_input_eventType_upcoming")}</option>
              <option value="Concluded">{t("events_input_eventType_concluded")}</option>
            </Form.Control>
            {errors.type?.message && (
              <Form.Text className="text-danger">
                {errors.type.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} md={5} controlId="organised_by">
            <Form.Label>{t("events_input_organisedBy")}</Form.Label>
            <Form.Control
              {...register("organised_by")}
              placeholder={t("events_input_organisedBy_p")}
              type="text"
            />
            {errors.organised_by?.message && (
              <Form.Text className="text-danger">
                {errors.organised_by.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} md={5} controlId="resource_person">
            <Form.Label>{t("events_input_resourcePerson")}</Form.Label>
            <Form.Control
              {...register("resource_person")}
              placeholder={t("events_input_resourcePerson_p")}
              type="text"
            />
            {errors.resource_person?.message && (
              <Form.Text className="text-danger">
                {errors.resource_person.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>

        <Form.Group controlId="location">
          <Form.Label className="mark-required">{t("events_input_location")}</Form.Label>
          <Form.Control
            {...register("location")}
            placeholder={t("events_input_location_p")}
            as="textarea"
            rows={3}
          />
          {errors.location?.message && (
            <Form.Text className="text-danger">
              {errors.location.message}
            </Form.Text>
          )}
        </Form.Group>

        <article id="seo" className="mt-4">
          <h3>Seo</h3>

          <div className="mt-2 d-flex gap-2 flex-column">
            <Form.Group controlId="metaTitle">
              <Form.Label >{t("events_input_metaTitle")}</Form.Label>
              <Form.Control
                {...register("meta_title")}
                placeholder={t("events_input_metaTitle_p")}
                type="text"
              />
              {errors.meta_title?.message && (
                <Form.Text className="text-danger">
                  {errors.meta_title.message}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="metaTags">
              <Form.Label >{t("events_input_metaTags")}</Form.Label>
              <Controller
                control={control}
                name="meta_tag"
                render={({field}) => (
                  <TagInput
                    {...field}
                    placeholder={t("events_input_metaTags_p")}
                    style={{borderColor: "#ced4da"}}
                    className="d-block"
                  />
                )}
              />
              {(errors.meta_tag?.message || Array.isArray(errors.meta_tag)) && (
                <Form.Text className="text-danger">
                  {errors?.meta_tag?.message ??
                    errors.meta_tag.find(Boolean).message}
                </Form.Text>
              )}
            </Form.Group>


            <Form.Group controlId="metaDescription">
              <Form.Label>{t("events_input_metaDescription")}</Form.Label>
              <Form.Control
                {...register("meta_description")}
                as="textarea"
                rows={3}
                placeholder={t("events_input_metaDescription_p")}
              />
              {errors.meta_description?.message && (
                <Form.Text className="text-danger">
                  {errors.meta_description.message}
                </Form.Text>
              )}
            </Form.Group>
          </div>

        </article>

        <div className="d-flex justify-content-end gap-2">
          <Button className="px-4" variant="success" type="submit">
            {t("events_edit_save")}
          </Button>
          <Link className="btn btn-danger" to={`/events/manage`}>
            {t("events_edit_cancel")}
          </Link>
        </div>
      </Form>
    </Layout>
  );
}
