import { useFooterSettings } from "../hooks/useFooterSettings";
import { useLang, useTermList } from "../../../hooks";
import { useNotification } from "../../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FooterSettingsFiveSchema } from "../schema";
import { useEffect } from "react";
import { InputValidationError } from "../../../libs/error";
import { Button, Col, Form } from "react-bootstrap";
import { Placeholder } from "rsuite";
import { useMediaSelector } from "../../../components/MediaSelectorModal";
import ComponentSelectorModal from "../../../components/ComponentSelectorModal";
import LayoutCard from "../../../components/ComponentSelectorModal/LayoutCard";
import {LayoutInput} from "../../../components/MediaInputs/LayoutInput";

export function MainFooterSettings({ onLanguageEdit }) {
  const { settings, error, isLoading, save } = useFooterSettings("layout");
  const { t } = useLang();
  const { notifyError, notifySuccess } = useNotification();
  const { termList, isLoading: isTermsLoading, error: termError } =
    useTermList(8, { page: 1, limit: 10000 });
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    control,
    setError
  } = useForm();

  const {
    selectedMedia,
    setSelectedMedia,
    checkValue,
    setCheckValue,
    allMediaId,
    setAllMediaId,
    selectedLayout,
    setSelectedLayout,
    handleRemoveLayout
  } = useMediaSelector();


  if (error || termError) {
    navigate(error ?? termError);
    navigate("/dashboard");
  }

  useEffect(() => {
    if (settings) {
      console.log("Mejoy",settings);
      const { footer_logo_five, footer_menu_id_five, ...newInput } = settings;
      reset({
        main_footer_title: settings?.main_footer_title?.value ?? "",
        main_footer_description: settings?.main_footer_description?.value ?? "",
        main_footer_html: settings?.main_footer_html?.value ?? "",
        main_footer_copyright_text: settings?.main_footer_copyright_text?.value ?? "",
      });

      setSelectedLayout(settings?.main_footer_layout_pack_id?.value);
    }
  }, [settings]);

  const handleUpdate = async (data) => {
    try {
      await save({ ...data, main_footer_layout_pack_id: selectedLayout?.id ?? null });
      notifySuccess({
        title: "Success",
        message: "Main Footer Updated Successfully"
      })
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input!",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Failed to update",
        message: err.message
      });
    }
  };

  return <section id="header-settings-form" className="my-4">
    <h3 className="mb-3">{t("settings_main_mainFooterSettings")}</h3>
    {isLoading ? <Placeholder.Graph active /> :
      <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit(handleUpdate)}>
        <div className="form-row">
          <Form.Group as={Col} xl={3} controlId="layout_pack_id">
            <Form.Label
              className="mark-required">{t("components_create_selectIdentifier")}</Form.Label>
            <Controller
              control={control}
              name="layout_pack_id"
              render={({field}) => (
                <ComponentSelectorModal isSingleMode selectedMedia={selectedLayout} setSelectedMedia={setSelectedLayout}>
                  {
                    selectedLayout ? (
                      <LayoutCard media={selectedLayout} isDelete handleRemove={handleRemoveLayout}/>
                    ) : (
                      <div style={{height: "300px", width: "100%"}}>
                        <LayoutInput
                          onChange={field.onChange}
                          onError={() => {
                            setError("image", {message: "Invalid image file!"});
                          }}
                        />
                      </div>
                    )
                  }
                </ComponentSelectorModal>
              )
              } />
            {errors.layout_pack_id?.message && (
              <Form.Text className="text-danger">{errors.layout_pack_id.message}</Form.Text>)}
          </Form.Group>
          <Col className="gap-2 d-flex flex-column">
            <Form.Group controlId="main_footer_title">
              <Form.Label className="">{t("title")}</Form.Label>
              <Form.Control {...register("main_footer_title")} placeholder={t("title_phld")}
                            type="text" />
              {errors.main_footer_title?.message && (
                <Form.Text className="text-danger">{errors.main_footer_title.message}</Form.Text>)}
            </Form.Group>
            <Form.Group controlId="main_footer_description">
              <Form.Label className="">{t("description")}</Form.Label>
              <Form.Control {...register("main_footer_description")} placeholder={t("description")}
                            type="text" as="textarea"/>
              {errors.main_footer_description?.message && (
                <Form.Text className="text-danger">{errors.main_footer_description.message}</Form.Text>)}
            </Form.Group>
            <Form.Group controlId="main_footer_html">
              <Form.Label className="">{t("html")}</Form.Label>
              <Form.Control {...register("main_footer_html")} placeholder={t("settings_button_text_five_phld")}
                            type="text" />
              {errors.main_footer_html?.message && (
                <Form.Text className="text-danger">{errors.main_footer_html.message}</Form.Text>)}
            </Form.Group>
            <Form.Group controlId="footer_copyright_text">
              <Form.Label className="">{t("copyright_text")}</Form.Label>
              <Form.Control {...register("main_footer_copyright_text")} placeholder={t("settings_button_text_five_phld")}
                            type="text" />
              {errors.main_footer_copyright_text?.message && (
                <Form.Text className="text-danger">{errors.main_footer_copyright_text.message}</Form.Text>)}
            </Form.Group>
          </Col>
        </div>
        <div className="form-row">

        </div>
        <div className="d-flex justify-content-end gap-2">
          <Button className="px-4" variant="success" type="submit">
            {t("common_settings_save")}
          </Button>
        </div>
      </Form>}
  </section>;
}
