import {useCallback, useEffect, useReducer, useState} from "react";
import {getDashboardStat, getPaginatedActivityLog} from "./service";
import {useNotification} from "../../hooks/useNotification";
import {useImmer} from "use-immer";
import filterReducers from "../../reducers/filterReducer";
import {usePagination} from "../../hooks";
import {removeContent} from "../../services/content";

export function useDashBoardStat() {

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [stat, updateState] = useImmer();
  const {notifyError, notifyInfo} = useNotification();
  // const ws = new WebSocket(process.env.REACT_APP_WS);

  // const handleWSError = (err) => {
  //   notifyError({
  //     title: "Failed to communicate with server!",
  //     message: err.message
  //   });
  // };
  // const handleMessage = (msg) => {
  //   try {
  //     const payload = JSON.parse(msg?.data ?? {});
  //     const r = JSON.parse(payload?.data ?? {});
  //     switch (payload.event) {
  //       case "postEvent":
  //         updateState((draft) => {
  //           draft.post = r.message;
  //         });
  //         break;
  //       case "productEvent":
  //         updateState((draft) => {
  //           draft.product = r.message;
  //         });
  //         break;
  //       case   "pageEvent":
  //         updateState((draft) => {
  //           draft.page = r.message;
  //         });
  //         break;
  //       case   "userEvent":
  //         updateState((draft) => {
  //           draft.event = r.message;
  //         });
  //         break;
  //       default:
  //         console.log("WS: ", msg);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // const handleWsOpen = () => {
  //   if (ws?.readyState) {
  //     ws.send(JSON.stringify({
  //       "event": "pusher:subscribe",
  //       "data": {
  //         "auth": "",
  //         "channel": "real-time"
  //       }
  //     }));
  //   } else {
  //     setTimeout(handleWsOpen, 500);
  //   }
  // };
  // const handleClose = () => {
  //   // notifyError({title: "Server disconnected"});
  //   console.log("Server disconnected");
  // };

  //
  // ws.onerror = handleWSError;
  // ws.onmessage = handleMessage;
  // ws.onopen = handleWsOpen;
  // ws.onclose = handleClose;


  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      updateState(await getDashboardStat());
    } catch (err) {
      setError({
        title: "Failed to fetch dashboard stats!",
        message: err.message
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);


  return {stat, isLoading, error};
}

export function useActivityList() {
  const [activityList, setActivityList] = useState({contents: [], total: 0});
  const [filter, filterDispatch] = useReducer(filterReducers, {});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const {pagination, changePagination} = usePagination({
    page: 1,
    limit: 10
  });

  const fetch = useCallback(async () => {
    let permissions = localStorage.getItem("userPermissions");
    if(!permissions.includes("activity.All")) return;
    try {
      setIsLoading(true);
      const actvities = await getPaginatedActivityLog({
        page: pagination.page,
        ...filter
      });
      setActivityList({
        activities: actvities.data,
        total: actvities.total
      });
      setIsLoading(false);
    } catch (err) {
      setError({
        title: "Something went wrong",
        message: err.message
      });
    }
  }, [pagination, filter]);

  const deleteContent = async (id) => {
    await removeContent(id);
    await fetch();
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    activityList,
    filterDispatch,
    pagination,
    changePagination,
    deleteContent,
    isLoading,
    error,
    filter
  };
}
