import {useCallback, useEffect, useState} from "react";
import {getComponentCategories, gettComponentIdentifierListByCategoryId} from "../services/component";

export function useComponentCategories() {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState();


  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      setCategories(await getComponentCategories());
    } catch (err) {
      setError({
        title: "Failed to fetch component category!",
        message: err.message
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {error, isLoading, categories};
}

export function useIdentifiers(categoryId) {
  const [identifiers, setIdentifiers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      setIdentifiers(await gettComponentIdentifierListByCategoryId(categoryId));
    } catch (err) {
      setError({
        title: "Failed to fetch component identifier!",
        message: err.message
      });
    } finally {
      setIsLoading(false);
    }
  }, [categoryId]);

  useEffect(() => {
    if (categoryId) {
      fetch();
    }
  }, [fetch]);

  return {error, isLoading, identifiers};
}
