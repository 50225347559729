import { FETCH_UPDATED_USER_LIST_REQ, FETCH_UPDATED_USER_LIST_SUCC, FETCH_UPDATED_USER_LIST_ERR } from "./listType"

const initialState = {
    loading: false,
    data : [],
    err: ''
}

const UpdatedUserReducer = (store = initialState, action) =>{
    switch(action.type){
        case FETCH_UPDATED_USER_LIST_REQ : 
            return{
                ...store ,
                loading: true,
            }
        case FETCH_UPDATED_USER_LIST_SUCC : 
        // console.log(action.payload)
            return{
                loading: false,
                data: action.payload,
            }
        case FETCH_UPDATED_USER_LIST_ERR : 
            return{
                loading: false,
                data: [],
                err: action.payload
            }
        default : return store
    }
}
export default UpdatedUserReducer