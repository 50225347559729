import {Modal, Toggle} from "rsuite";
import {Button, Col, Container, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {FeatureInputSchema} from "../../schema";
import {useLang} from "../../../../hooks";
import {yupResolver} from "@hookform/resolvers/yup";

export function FeatureCreateForm({insertIdx, show, onCreate, onClose}) {
  const {t} = useLang()

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    reset,
  } = useForm({
    resolver: yupResolver(FeatureInputSchema(t)),
  });

  const handleCreate = (data) => {
    onCreate(insertIdx, data)
    reset()
    onClose()
  }




  return <Modal backdrop keyboard={false} open={show} onClose={onClose}>
    <Modal.Header>
      <Modal.Title>{t('pricing_feature_create_new')}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Container>
        <Form onSubmit={(e) => {
          e.stopPropagation()
          handleSubmit(handleCreate)(e)
        }}>
          <div className="form-row">
            <Form.Group as={Col} controlId="title">
              <Form.Label className="mark-required">{t("title")}</Form.Label>
              <Form.Control
                {...register("title")}
                placeholder={t("title_phld")}
                type="text"
              />
              {errors.title?.message && (
                <Form.Text className="text-danger">
                  {errors.title.message}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group as={Col} controlId="status">
              <Form.Label className="mark-required">{t("status")}</Form.Label>
              <Controller
                control={control}
                name="status"
                render={({field}) => (
                  <div>
                    <Toggle
                      {...field}
                      checked={field.value}
                      size="lg"
                      checkedChildren={<span>Active</span>}
                      unCheckedChildren={<span>Inactive</span>}
                      className="m-auto"
                    />
                  </div>
                )}
              />
              {errors.status?.message && <Form.Text className="text-danger">
                error.status.message
              </Form.Text>
              }
            </Form.Group>
          </div>
          <div className="form-row gap-2 justify-content-end">
            <Button type="submit" variant="success">
              {t("save_btn")}
            </Button>
            <Button onClick={onClose} variant="danger">
              {t("cancel_btn")}
            </Button>
          </div>
        </Form>
      </Container>
    </Modal.Body>
  </Modal>
}