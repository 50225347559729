import React, {useEffect, useState} from 'react'
import {Toast} from 'react-bootstrap'
import Sidebar from '../sidebar/Sidebar'
import {useLang} from "../../hooks";
import {setLocale} from "yup";
import {bn, en} from "../../configs/lang/yup"

export default function Layout({children}) {
    const {lang} = useLang()

    useEffect(() => {
        switch (lang) {
            case "BD":
                setLocale(bn);
                break;
            default:
                setLocale(en);
                break;
        }
    }, [lang])

    const [success, setSuccess] = useState(localStorage.getItem("success"));
    const [successEdit, setSuccessEdit] = useState(localStorage.getItem("successEdit"));

    if (success) {
        setTimeout(() => {
                localStorage.removeItem("success");
                setSuccess(false);
            }
            , 2000
        )
    }

    if (successEdit) {
        setTimeout(() => {
                localStorage.removeItem("successEdit");
                setSuccessEdit(false);
            }
            , 2000
        )
    }
    return (
        <>
            {
                success &&
                (
                    <Toast bg='success' style={{position: 'fixed', top: '30px', right: '0', zIndex: '111111'}}>
                        <Toast.Body>Succesfully Saved</Toast.Body>
                    </Toast>
                )
            }
            {
                successEdit &&
                (
                    <Toast bg='success' style={{position: 'fixed', top: '30px', right: '0', zIndex: '111111'}}>
                        <Toast.Body>Succesfully Updated</Toast.Body>
                    </Toast>
                )
            }
            <Sidebar>
                {children}
            </Sidebar>
        </>
    )
}
