import { useState } from 'react';

export const useMediaSelector = (saveOnClickFunc, fetchMediaFunc) => {
  const [checkValue, setCheckValue] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [allMediaId, setAllMediaId] = useState([]);
  const [selectedLayout, setSelectedLayout] = useState(null);


  const handleRemove = (e, id) => {
    e.stopPropagation();
    let newCheckList = checkValue?.filter((c) => c !== id);
    let newSelectedMedia = selectedMedia?.filter((m) => m.id !== id);
    setSelectedMedia(newSelectedMedia);
    setCheckValue(newCheckList);
  };

  const handleRemoveLayout = (event, media) => {
    event.stopPropagation();
    setSelectedLayout(null);
  }

  return {
    checkValue,
    setCheckValue,
    selectedMedia,
    setSelectedMedia,
    allMediaId,
    setAllMediaId,
    selectedLayout,
    setSelectedLayout,
    handleRemove,
    handleRemoveLayout
  };
};