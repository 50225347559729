import { useEffect, useState } from "react";
import { ReactFormBuilder, ReactFormGenerator } from "react-form-builder2";
import "react-form-builder2/dist/app.css";
import {Modal, Button, ButtonToolbar, Placeholder, Loader, TagInput} from "rsuite";
import 'rsuite/dist/rsuite.min.css';
import Layout from "../../components/layouts/layout";
import {Breadcrumb, Col, Form} from "react-bootstrap";
import {Controller,useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {EventInputSchema} from "../event/schema";
import {useLang} from "../../hooks";
import {FormBuilder} from "../../components/FormBuilder";
import {createEvent} from "../../services/event";
import {InputValidationError} from "../../libs/error";
import {Link, useNavigate} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {createForm} from "../../services/formBuilder";
import {BackButton} from "../../components/BackButton/BackButton";

const FormBuilderCreatePage = () => {
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const {t} = useLang();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const [name,setName] = useState();
  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    defaultValues: {
      status: false,
    },
  });

  function handlePreviewClose() {
    setIsOpen(false)
  }

  function handleCancel() {
    localStorage.removeItem("formData");
    navigate(-1);
  }

  function handleSubmitPreview() {
    setIsOpen(true);
    const data = JSON.parse(localStorage.getItem("formData"));
    setData(data);
  }

  const create = async () => {
    try {
      setIsLoading(true);
      let formData = {name, details: localStorage.getItem("formData")}
      const form = await createForm(formData)
      notifySuccess({
        title: "Form created successfully",
        // message: `Form "${form.name}" created!`,
      });
      localStorage.removeItem("formData")
      navigate('/form-builder/manage');
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Input validation failed",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Failed to create form",
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <Layout>
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/form-builder/manage`}> {t("formBuilder_manage_forms")}</Link>
            </li>
            <Breadcrumb.Item active>
              {t("formBuilder_manage_newForms")}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </header>
      <h2>{t("formBuilder_manage_createNewForm")}</h2>
      <div className="d-flex gap-2 pb-2 mt-3 mb-3">
        <button
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={(e) => create()}
        >
          {t("save_btn")}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={(e) => handleCancel()}
        >
          {t("cancel_btn")}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={(e) => handleSubmitPreview()}
        >
          {t("preview")}
        </button>
      </div>
      <Form.Group as={Col} controlId="title" className="pb-2 w-50">
        <Form.Label className="mark-required">{t("name")}</Form.Label>
        <Form.Control
          value={name}
          placeholder={t("formBuilder_name_p")}
          type="text"
          onChange={(e) => setName(e.target.value)}
        />
        {errors.name?.message && (
          <Form.Text className="text-danger">
            {errors.name.message}
          </Form.Text>
        )}
      </Form.Group>
      <Form.Group as={Col} controlId="title">
        <Form.Label className="mark-required">{t("details")}</Form.Label>
        <Controller
          control={control}
          name="details"
          render={({field}) => (
            <FormBuilder isOpen={isOpen} onClose={handlePreviewClose} data={data} />
          )}
        />
        {errors.details?.message && (
          <Form.Text className="text-danger">
            {errors.details.message}
          </Form.Text>
        )}
      </Form.Group>
    </Layout>
  );
};

export default FormBuilderCreatePage;
