export const mediaType = [
  {
    "id": 1,
    "name": "Image",
    "taxonomy_id": 6
  },
  {
    "id": 2,
    "name": "Video",
    "taxonomy_id": 6
  },
  {
    "id": 3,
    "name": "Documents",
    "taxonomy_id": 6
  },
  {
    "id": 4,
    "name": "External Link",
    "taxonomy_id": 6
  }
]