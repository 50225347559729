import { useState } from "react";
import { useToaster, Notification } from "rsuite";

/**
 * Use this function to push error notification
 * @deprecated use `useNotification` instead
 *
 * @returns {{pushError: object}}  
 */
export function useError() {
  const toaster = useToaster();

  const pushError = ({ title, message }) => {
      toaster.push(
        <Notification style={{zIndex: 10000}} closable type="error" header={title}>
          {message}
        </Notification>,
        {
          placement: "bottomEnd",
        }
      )
  };


  return { pushError };
}


