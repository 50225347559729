import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useState} from "react";
import "./index.scss";
import {BsPlus} from "react-icons/bs";
import {ComponentSelector} from "../ComponentSelector";
import {getUrlFromStoragePath} from "../../configs";
import {Badge, Button} from "react-bootstrap";
import {MdDelete} from "react-icons/md";
import {useLang} from "../../hooks";

function createDragKey(id) {
  return String(id) + Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export function processComponents(contents) {
  if (contents?.length > 0) {
    const cc = contents.map(c => ({
      dKey: createDragKey(c.name),
      ...c,
    })).toSorted((a, b) =>
        a.page_component_order - b.page_component_order);
    console.log("Processed component: ", cc)
    return cc
  }
  return [] }

export function PageBuilder({value, onChange}) {
  const [shouldShowComponentSelector, setShouldShowComponentSelector] = useState(false)
  const [insertIdx, setInsertIdx] = useState(0)
  const {t} = useLang();

  const  handleChange = (c) => {
    const changes = c.map((c, i) => ({
      ...c,
      page_component_order: i + 1,
    }))
    console.log("Changes", changes)
    onChange(changes)
  }
  const handleDragEnd = (result) => {
    if (!result.destination) {
      removeComponent(result.draggableId)
      return
    }
    changeOrder(result.source.index, result.destination.index)
  }

  const addComponent = (position, component) => {
    component = {
      dKey: createDragKey(component.id),
      ...component
    }

    if (value && value.length > 0)  {
      handleChange(value.toSpliced(position, 0, component));
    } else {
      handleChange([component] )
    }
    setShouldShowComponentSelector(false)
  }
  const removeComponent = (dKey) => {
    handleChange(value.filter((c) =>
        c.dKey!== dKey))
  }

  const changeOrder = (from, to) => {
      const temp = value[from]
      if (from > to) {
        for (let i = from; i > to; i--) {
          value[i] = value[i - 1]
        }
      } else if (from < to) {
        for (let i = from; i < to; i++) {
          value[i] = value[i + 1]
        }
      }
      value[to] = temp
      handleChange(value);
  }

  function toggleComponentSelector() {
    setShouldShowComponentSelector(prev => !prev);
  }


  return <div>
    <ComponentSelector onComponentSelect={addComponent} insertIdx={insertIdx} open={shouldShowComponentSelector}
                       handleClose={toggleComponentSelector}/>
    <div className="item-builder">
      <h3>{t("pages_create_pageBuilder")}</h3>
      {
        value?.length > 0 ?
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={snapshot.isDraggingOver ? "ondrag" : ""}
                >
                  <DividerAddButton onClick={() => {
                    setInsertIdx(0)
                    toggleComponentSelector()
                  }}/>
                  {value?.map((c, index) => (
                    <Draggable
                      key={c.dKey}
                      draggableId={c.dKey}
                      index={index}>
                      {(provided, snapshot) => (
                        <div>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`p-4 m-0 item bg-light shadow-sm ${snapshot.isDragging ? "dragged" : ""}`}
                          >
                            {(c.details?.image1 || c.details?.image2 || c.details?.image3) && <div className="item-image-container">
                              <img className="component-image" src={getUrlFromStoragePath(c.details.image1 ?? c.details.image2 ?? c.details.image3)} alt={c.title}/>
                            </div>}
                            <div>
                              <div className="component-body">
                                <div className="component-title h5">
                                  {c.details?.title ?? "Unknown Component"}
                                </div>
                                <Badge bg="secondary">
                                  {c.name}
                                </Badge>
                                <small> (Order: {c.page_component_order})</small>
                              </div>
                            </div>
                            <Button onClick={() => removeComponent(c.dKey)} className="component-remove text-danger" variant={"outline-light"}><MdDelete/></Button>
                          </div>
                          <DividerAddButton onClick={() => {
                            setInsertIdx(index + 1)
                            toggleComponentSelector()
                          }}/>
                        </div>
                      )}
                    </Draggable>

                  ))}
                  {provided.placeholder}
                </div>
              )}

            </Droppable>
          </DragDropContext>
          : <div className="new-item-btn d-flex flex-column align-items-center justify-content-center"
                 onClick={
                   () => {
                     setInsertIdx(0);
                     toggleComponentSelector();
                   }}>
            <BsPlus className="display-1"/>
            <div className="lead">
              {t("pages_create_addNewComponent")}
            </div>
          </div>
      }
    </div>
  </div>
}

function DividerAddButton({onClick}) {
  return <div onClick={onClick} className="item-add">
    <div className="item-add-content item-add-divider">
    </div>
    <div className="item-add-content item-add-btn">
    </div>
  </div>
}
