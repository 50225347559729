import {useCallback, useEffect, useState} from "react";
import {getPopUpSettings, updatePopUpSettings} from "../services/settings";


export function usePopUpSettings() {
  const [settings, setSettings] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      setSettings(await getPopUpSettings());
    } catch (err) {
      setError({
        message: err.message,
        title: "Failed to fetch PopUp settings"
      });
    } finally {
      setIsLoading(false);
    }
  }, []);


  const save = async (data) => {
    try {
      setIsLoading(true);
      let response = updatePopUpSettings(data)
      response.pop_up_status = Boolean(response.pop_up_status);
      setSettings(await response);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {save, isLoading, settings, error};
}