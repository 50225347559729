import {useCallback, useEffect, useState} from "react";
import {getSocialSettings, updateSocialSettings} from "../../../services/settings";


export function useSocialSettings() {
  const [settings, setSettings] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      setSettings(await getSocialSettings());
    } catch (err) {
      setError({
        message: err.message,
        title: "Failed to fetch social settings"
      });
    } finally {
      setIsLoading(false);
    }
  }, []);


  const save = async (data) => {
    try {
      setIsLoading(true);
      setSettings(await updateSocialSettings(data));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {save, isLoading, settings, error};
}