import {useCallback, useEffect, useState} from "react";
import {getFooterSettings, updateFooterSettings} from "../../../services/settings";


export function useFooterSettings(columnName) {
  const [settings, setSettings] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      setSettings(await getFooterSettings(columnName));
    } catch (err) {
      setError({
        message: err.message,
        title: "Failed to fetch footer settings"
      });
    } finally {
      setIsLoading(false);
    }
  }, []);


  const save = async (input) => {
    try {
      setIsLoading(true);
      setSettings(await updateFooterSettings(columnName, input))
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {save, isLoading, settings, error};
}