import {BsPencilSquare} from "react-icons/bs";
import {Link} from "react-router-dom";
import {Tooltip, Whisper} from "rsuite";

export const EditButton = ({ to }) => {

  const tooltip = (
    <Tooltip>
      Edit
    </Tooltip>
  );

  return (
    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={tooltip}>
      <Link to={to} className="btn btn-secondary">
        <BsPencilSquare />
      </Link>
    </Whisper>
  )
}