import {Notification, useToaster} from "rsuite";
import {useNavigate} from "react-router-dom";

export function useNotification() {
  const toaster = useToaster();
  const navigate = useNavigate();

  const redirectionHelper = (to, option) => {
    if (to) {
      setTimeout(() => {
        navigate(to, option);
      }, 500);
    }
  };

  const notifyError = ({title, message}, redirectTo) => {
    toaster.push(
      <Notification
        style={{zIndex: 10000}}
        closable
        type="error"
        header={title}
      >
        {message}
      </Notification>,
      {
        placement: "bottomEnd"
      }
    );

    redirectionHelper(redirectTo, {
      state: {errorTitle: title, errorMessage: message}
    });
  };

  const notifySuccess = ({title, message}, redirectTo) => {
    toaster.push(
      <Notification
        style={{zIndex: 10000}}
        closable
        type="success"
        header={title}
      >
        {message}
      </Notification>,
      {
        placement: "bottomCenter"
      }
    );
    redirectionHelper(redirectTo);
  };

  const notifyInfo = ({title, message}, redirectTo) => {
    toaster.push(
      <Notification
        style={{zIndex: 10000}}
        closable
        type="info"
        header={title}
      >
        {message}
      </Notification>,
      {
        placement: "bottomCenter"
      }
    );

    redirectionHelper(redirectTo);
  };

  return {notifyError, notifySuccess, notifyInfo};
}
