import {api, parseAPIError} from "./api";
import {processSeoInput} from "./seo";
import moment from "moment/moment";
import {jsonToFormData} from "../helpers";

export function processEventInput({dateTimeRange, ...input}) {
  input = processSeoInput(input)
  input.status = input.status ? 1 : 0;
  if (dateTimeRange && dateTimeRange.length === 2) {
    input.start_date = moment(dateTimeRange[0]).format("yyyy-MM-DD HH:mm:ss");
    input.end_date = moment(dateTimeRange[1]).format("yyyy-MM-DD HH:mm:ss");
  }
  return input
}

export async function createEvent(input) {
  try {
    let data = processEventInput(input);
    data = jsonToFormData(data);
    const r = await api.post("/event/store", data);
    return r.data.data
  } catch (err) {
    throw parseAPIError(err)
  }
}

export async function getEvent(id) {
  const r = await api.get(`event/get/${id}`);
  return r.data.data
}

export async function updateEvent(id, input) {
  try {
    let data = processEventInput(input);
    data = jsonToFormData(data);
    const r = await api.post(`event/update/${id}`, data);
    return r.data.data
  } catch (err) {
    throw parseAPIError(err)
  }
}

export async function getPaginatedEvent(options) {
  const r = await api.get("/event/list", {
    params: options
  })
  if (r.data.data) {
    return r.data.data
  }
  return {
    data: [],
    total: 0,
  }
}

export async function removeEvent(id) {
  const r = await api.delete(`/event/delete/${id}`);
  return r.data.data
}

export async function removeEventMedia(id, data) {
  let processData = jsonToFormData(data)
  const r = await api.post(`/event/delete-media-id/${id}`, processData);
  return r.data;
}
