import Card from "react-bootstrap/Card";
import React from "react";
import {CountUpInt} from "./CountUpInt";

export function StatCard({name, value, icon, ...props}) {
  return <Card style={{ minWidth: '300px', maxWidth: "300px" }} {...props}>
    <Card.Body>
      <div className="d-flex justify-content-around gap-2 align-items-center px-2">
        <div
          className="d-flex justify-content-center align-items-center display-1 rounded-circle"
          style={{width: "64px", height: "64px", background: "#DCFFEC", color: "#00AC4F", fontSize: 30}}
        >
          {icon}
        </div>
        <div>
          <h3 className="text-muted lead">{name}</h3>
          <div className="font-weight-bold text-right">
            <CountUpInt from={0} to={value} />
          </div>
        </div>
      </div>
    </Card.Body>
  </Card>;
}