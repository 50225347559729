import { useEffect, useState } from "react";
import { ReactFormBuilder, ReactFormGenerator } from "react-form-builder2";
import "react-form-builder2/dist/app.css";
import { Modal, Button, ButtonToolbar, Placeholder, Loader } from "rsuite";
import 'rsuite/dist/rsuite.min.css';
import {FromPreview} from "../../pages/FormBuilder/components/FormPreview";

export const FormBuilder = ({isOpen, onClose, data}) =>{
  const [allow, setAllow] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      console.log("1 second has passed!");
      setAllow(false);
    }, 1000); // Delay of 1 second

    return () => clearTimeout(timeoutId);
  }, [allow]);


  const closeModal = () => {
    onClose()
    setAllow(true);
  };

  const onPost = async (d) => {
    let storedData = JSON.parse(localStorage.getItem("formData")) || [];
    if (storedData) {
      const newData = d.task_data.filter((item) => item !== null); // Filter out null values from d.task_data
      const updatedData = [...newData];
      localStorage.setItem("formData", JSON.stringify(updatedData));
    } else {
      localStorage.setItem("formData", JSON.stringify(d.task_data));
    }
  };

  const onLoad = async () => {
    try {
      const storedData = await JSON.parse(localStorage.getItem("formData"));
      if (storedData) {
        return storedData;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    return [];
  };

  return(
    <>
      {allow ? (
        <div role="status">
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
      ) : (
        <ReactFormBuilder
          locale="en"
          saveAlways={false}
          editMode={false}
          onPost={onPost}
          onLoad={onLoad}
        />
      )}
      {isOpen && (
        <FromPreview open={isOpen} onClose={closeModal} data={data}/>
      )}
    </>
  )

}