import {useState} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {useLang, useTermList} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {BackButton} from "../../components/BackButton/BackButton";
import {createPricing} from "./pricing.service";
import {PricingInputSchema} from "./schema";
import {FeatureBuilder} from "./components/FeatureBuilder/FeatureBuilder";
import {SelectPicker} from "rsuite";

export function PricingCreatePage() {
  const {t} = useLang();
  const [isLoading, setIsLoading] = useState(false);
  const {
    termList,
    error: termsError,
    isLoading: termLoading,
  } = useTermList(13, {limit: 1000, page: 1});
  const {notifyError, notifySuccess} = useNotification();
  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    resolver: yupResolver(PricingInputSchema(t)),
  });

  if (termsError) {
    notifyError(termsError, "/error");
  }


  const create = async (data) => {
    try {
      setIsLoading(true);
      const post = await createPricing(data)

      notifySuccess({
        title: "Pricing created successfully",
        message: `Pricing created!`,
      }, "/pricing/manage");

    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Input validation failed",
          message: err.message,
        });
        return;
      }

      notifyError({
        title: "Failed to create pricing",
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  };



  return (
      <Layout>
        {isLoading && <CircleLoader/>}
        <header>
          <div className="d-flex justify-content-between align-items-center">
            <Breadcrumb className="d-flex align-items-center">
              <li className="breadcrumb-item">
                <Link to={`/pricing/manage`}> {t("pricing")}</Link>
              </li>
              <Breadcrumb.Item active>
                {t("pricing_create_new")}
              </Breadcrumb.Item>
            </Breadcrumb>
            <BackButton path={-1} text={t("pricing")}/>
          </div>
        </header>
        <section id="#page-create">
          <h2>{t("pricing_create_new")}</h2>
          <Form
              onSubmit={handleSubmit(create)}
              className="mt-4 d-flex  flex-column gap-3"
          >
            <div className="form-row">
              <Form.Group as={Col} controlId="package_name">
                <Form.Label className="mark-required">{t("pricing_package_name")}</Form.Label>
                <Form.Control
                  {...register("package_name")}
                  placeholder={t("pricing_package_name_phld")}
                  type="text"
                />
                {errors.package_name?.message && (
                  <Form.Text className="text-danger">
                    {errors.package_name.message}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group as={Col} controlId="pricing_category_id">
                <Form.Label className="mark-required">{t('pricing_category')}</Form.Label>
                <div>
                  <Controller render={({field}) => <SelectPicker
                    onChange={field.onChange}
                    value={field.value}
                    style={{width: "100%"}}
                    data={termList?.terms?.map(t => ({value: t.id, label: t.name})) ?? []}
                    placeholder={t('pricing_category_phld')}
                    loading={termLoading}
                  />
                  } name="pricing_category_id" control={control} />
                </div>
                {errors.pricing_category_id?.message && (
                  <Form.Text className="text-danger">
                    {errors.pricing_category_id.message}
                  </Form.Text>
                )}
              </Form.Group>
            </div>


            <div className="form-row">
              <Form.Group as={Col} controlId="price">
                <Form.Label>{t("pricing_price")}</Form.Label>
                <Form.Control
                  {...register("price")}
                  placeholder={t("pricing_price_phld")}
                  type="text"
                />
                {errors.price?.message && (
                  <Form.Text className="text-danger">
                    {errors.price.message}
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group as={Col} controlId="duration">
                <Form.Label>{t("pricing_duration")}</Form.Label>
                <Form.Control
                  {...register("duration")}
                  placeholder={t("pricing_duration_phld")}
                  type="text"
                />
                {errors.duration?.message && (
                  <Form.Text className="text-danger">
                    {errors.duration.message}
                  </Form.Text>
                )}
              </Form.Group>
            </div>

            <Form.Group controlId="link">
              <Form.Label>{t("link")}</Form.Label>
              <Form.Control
                {...register("link")}
                placeholder={t("link_phld")}
                type="text"
              />
              {errors.link?.message && (
                <Form.Text className="text-danger">
                  {errors.link.message}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group>
              <Controller
                control={control}
                name="feature"
                render={({field}) => (
                  <FeatureBuilder value={field.value} onChange={field.onChange}/>
                )}
              />
              {(errors.feature?.message || Array.isArray(errors.feature)) && (
                <Form.Text className="text-danger">
                  {errors?.feature?.message ??
                    errors.feature.find(Boolean).message}
                </Form.Text>
              )}
            </Form.Group>

            <div className="d-flex justify-content-end gap-2">
              <Button className="px-4" type="submit">
                {t("pages_create_save")}
              </Button>
              <Link className="btn btn-danger px-4" to="/pages/manage" type="submit">
                {t("pages_create_cancel")}
              </Link>
            </div>
          </Form>
        </section>
      </Layout>
  );
}