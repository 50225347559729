import {api, parseAPIError} from "../../services/api";

export function processPricingInput(input) {
  input.feature = input.feature.map(({title, status}) => ({title, status}))
  return input;
}

export async function createPricing(input) {
  try {
    const r = await api.post("/pricing/store",
      processPricingInput(input),
      {headers: {"content-type": "application/json"}});
    return r.data.data;

  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getPricing(id) {
  const r = await api.get(`/pricing/get/${id}`);
  return r.data.data;
}

export async function updatePricing(id, input) {
  try {
    const r = await api.post(`/pricing/update/${id}`, processPricingInput(input),
      {headers: {"content-type": "application/json"}}
    );
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getPaginatedPricing(options) {
  const r = await api.get("/pricing/list", {
    params: options
  });
  if (r.data.data) {
    return r.data.data;
  }
  return {
    data: [],
    total: 0
  };
}

export async function removePricing(id) {
  const r = await api.delete(`/pricing/delete/${id}`);
  return r.data.data;
}