import {Modal} from "rsuite";
import {useResourceLangList} from "../../../hooks/useResourceLangList";
import DataTable from "react-data-table-component";
import {BsPencilSquare} from "react-icons/bs";
import {MdDeleteOutline} from "react-icons/md";
import {useNotification} from "../../../hooks/useNotification";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {SettingsLanguageSchema} from "../schema";
import {InputValidationError} from "../../../libs/error";
import {useEffect, useState} from "react";
import {LanguageSelector} from "../../../components/Language/LanguageSelector";
import {Button, Col, Container, Form} from "react-bootstrap";
import {useLanguages} from "../../../hooks/useLanguages";

export function SettingsLangEdit({gsOptionId, onClose}) {
  const {notifyError, notifySuccess} = useNotification();
  const [isLoading, setIsLoading] = useState();
  const {langs, isLoading: isLangLoading, error: isLangError} = useLanguages();
  const [itemToEdit, setItemToEdit] = useState();
  const {
    list,
    pagination,
    refresh,
    changePagination,
    error,
    isLoading: languagesLoading,
    createItem,
    editItem,
    deleteItem
  } = useResourceLangList({
    resourceName: "settings",
    resourceId: gsOptionId
  });

  const {
    setValue,
    handleSubmit,
    setError,
    reset,
    formState: {errors},
    control,
    register
  } = useForm({
    resolver: yupResolver(SettingsLanguageSchema())
  });

  const create = async (data) => {
    try {
      setIsLoading(true);
      data.gs_option_id = gsOptionId;
      reset();
      await createItem(data);
      notifySuccess({
        title: "language created successfully!",
        message: "Language Created"
      });
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Failed to create language",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: err.message
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (itemToEdit) {
      reset({
        language_id: itemToEdit.language_id,
        value: itemToEdit.value
      });

    } else {
      reset({
        language_id: "",
        value: ""
      });
    }
  }, [itemToEdit]);


  const edit = async (data) => {
    try {
      setIsLoading(true);
      data.gs_option_id = gsOptionId;
      const l = await editItem(itemToEdit.id, data);
      reset();
      notifySuccess({
        title: "language created updated!"
      });
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Failed to create language",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: "Language creation failed!"
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteItem(id);
      notifySuccess({
        title: "Resource language removed successfully"
      });
    } catch (err) {
      notifyError({
        title: "Failed to remove resource language!",
        message: err.message
      });
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) =>
        (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
      width: "50px"
    },
    {
      name: "Language Code",
      selector: (row) => row.languages?.code ?? "Not Available",
      sortable: true
    },
    {
      name: "Country",
      selector: (row) =>
        row.languages?.name ?? "Not Available",

      sortable: true
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex gap-2">
          <Button onClick={() => setItemToEdit(row)}>
            <BsPencilSquare />
          </Button>
          <Button variant="danger" onClick={() => handleDelete(row.id)}>
            <MdDeleteOutline />
          </Button>
        </div>
      )
    }
  ];


  return <Modal open backdrop size="lg" keyboard={false} onClose={onClose}>
    <Modal.Header>
      <Modal.Title>Languages</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Container>
        <article id="create-form">
          <Form
            onSubmit={handleSubmit(itemToEdit ? edit : create)}
            className="mt-4 d-flex  flex-column gap-3"
          >
            <div className="form-row">

              <Col md={2}>
                <LanguageSelector disabled={!!itemToEdit} showLabel={false} loading={isLangLoading} control={control}
                                  langs={langs}
                                  errors={errors} />
              </Col>
              <Form.Group as={Col} controlId="value">
                <Form.Control
                  {...register("value")}
                  placeholder="Value"
                  type="text"
                />
                {errors.value?.message && (
                  <Form.Text className="text-danger">
                    {errors.value.message}
                  </Form.Text>
                )}
              </Form.Group>
              {itemToEdit ?
                <div className="d-flex gap-2">
                  <Button className="align-self-start" type="submit" variant="success">Save</Button>
                  <Button className="align-self-start" variant="danger"
                          onClick={() => {
                            setItemToEdit(null);
                          }}>Cancel</Button>
                </div>
                : <Button className="align-self-start" type="submit">Add</Button>
              }
            </div>
          </Form>
        </article>
      </Container>


      <DataTable
        columns={columns}
        data={list.events}
        progressPending={languagesLoading}
        pagination
        paginationServer
        paginationComponentOptions={{noRowsPerPage: true}}
        paginationTotalRows={list.total}
        onChangePage={(page) => {
          changePagination({page});
        }}
        fixedHeader
        fixedHeaderScrollHeight="800px"
        highlightOnHover
        subHeader
        subHeaderAlign="right"
      />
    </Modal.Body>
  </Modal>;

}