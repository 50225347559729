import {useCallback, useEffect, useState} from "react";
import {getMedia, updateMedia} from "../../../services/media";

export function useMedia(id) {
  const [media, setMedia] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMedia = useCallback(async () => {
    try {
      setIsLoading(true);
      setMedia(await getMedia(id));
    } catch (err) {
      setError({
        title: "Failed to fetch Media!",
        message: err.message
      });
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  async function edit(data) {
    try {
      setIsLoading(true);
      setMedia(await updateMedia(data, id));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchMedia();
  }, [fetchMedia]);

  return {media, isLoading, error};
}