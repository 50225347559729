import {useState} from "react";
import {Breadcrumb, Button, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {useLang, usePrivateAPI} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";
import {TagInput} from "rsuite";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {PageInputSchema} from "./pageSchema";
import {PageBuilder} from "../../components/PageBuilder";
import {BackButton} from "../../components/BackButton/BackButton";

export function PageCreatePage() {
  const {t} = useLang();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const {http} = usePrivateAPI();
  const {notifyError, notifySuccess} = useNotification();
  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    resolver: yupResolver(PageInputSchema(t)),
  });


  const createPage = async (data) => {
    try {
      setIsLoading(true);

      data.meta_title = data.meta_title ? data.meta_title : undefined
      data.meta_description = data.meta_description ? data.meta_description : undefined
      if (data.meta_tag && data.meta_tag.length > 0) {
        data.meta_tag = data.meta_tag.join(",")
      } else {
        data.meta_tag = undefined
      }
      data.contents = data.contents?.map(c => ({name: c.name, component_id: c.component_id, page_component_order: c.page_component_order}))


      const res = await http.post("/page/store", data, {
        headers: { 'content-type': 'application/json' },
      });

      notifySuccess({
        title: "Page created successfully",
        message: `Page "${res.data.data.title}" created!`,
      });

      navigate("/pages/manage");
    } catch (err) {
      if (InputValidationError.isAPIInputValidationError(err)) {
        const inputErr = InputValidationError.fromAPIError(err);
        inputErr.setRHFError(setError);
        notifyError({
          title: "Failed to create page!",
          message: inputErr.message,
        });
        return;
      }
      notifyError({
        title: "Failed to create page!",
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
      <Layout>
        {isLoading && <CircleLoader/>}
        <header>
          <div className="d-flex justify-content-between align-items-center">
            <Breadcrumb className="d-flex align-items-center">
              <li className="breadcrumb-item">
                <Link to={`/pages/manage`}> {t("pages_manage_pages")}</Link>
              </li>
              <Breadcrumb.Item active>
                {t("pages_create_createNewPage")}
              </Breadcrumb.Item>
            </Breadcrumb>
            <BackButton path={-1} text={t("pages_manage_pages")}/>
          </div>
        </header>
        <section id="#page-create">
          <h2>{t("pages_create_createNewPage")}</h2>
          <Form
              onSubmit={handleSubmit(createPage)}
              className="mt-4 d-flex  flex-column gap-3"
          >
            <Form.Group controlId="title">
              <Form.Label className="mark-required">{t("pages_create_title")}</Form.Label>
              <Form.Control
                  {...register("title")}
                  placeholder={t("pages_create_title_plhd")}
                  type="text"
              />
              {errors.title?.message && (
                  <Form.Text className="text-danger">
                    {errors.title.message}
                  </Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="description">
              <Form.Label>{t("pages_create_description")}</Form.Label>
              <Form.Control
                  {...register("description")}
                  placeholder={t("pages_create_description_plhd")}
                  as="textarea"
                  rows={3}
              />
              {errors.description?.message && (
                  <Form.Text className="text-danger">
                    {errors.description.message}
                  </Form.Text>
              )}
            </Form.Group>


            <Controller
                control={control}
                name="contents"
                render={({field}) => (
                    <PageBuilder value={field.value} onChange={field.onChange}/>
                )}
            />


            <article id="seo" className="mt-4">
              <h3>{t("pages_create_seo")}</h3>
              <div className="mt-2 d-flex gap-2 flex-column">
                <Form.Group controlId="metaTitle">
                  <Form.Label>{t("pages_create_metaTitle")}</Form.Label>
                  <Form.Control
                      {...register("meta_title")}
                      placeholder={t("pages_create_metaTitle_plhd")}
                      type="text"
                  />
                  {errors.meta_title?.message && (
                      <Form.Text className="text-danger">
                        {errors.meta_title.message}
                      </Form.Text>
                  )}
                </Form.Group>
                <Form.Group controlId="metaTags">
                  <Form.Label>{t("pages_create_metaTags")}</Form.Label>
                  <Controller
                      control={control}
                      name="meta_tag"
                      render={({field}) => (
                          <TagInput
                              {...field}
                              placeholder={t("pages_create_metaTags_plhd")}
                              style={{borderColor: "#ced4da"}}
                              className="d-block"
                          />
                      )}
                  />
                  {(errors.meta_tag?.message || Array.isArray(errors.meta_tag)) && (
                      <Form.Text className="text-danger">
                        {errors?.meta_tag?.message ??
                            errors.meta_tag.find(Boolean).message}
                      </Form.Text>
                  )}
                </Form.Group>


                <Form.Group controlId="metaDescription">
                  <Form.Label>{t("pages_create_metaDescription")}</Form.Label>
                  <Form.Control
                      {...register("meta_description")}
                      as="textarea"
                      rows={3}
                      placeholder={t("pages_create_metaDescription_plhd")}
                  />
                  {errors.meta_description?.message && (
                      <Form.Text className="text-danger">
                        {errors.meta_description.message}
                      </Form.Text>
                  )}
                </Form.Group>
              </div>

            </article>


            <div className="d-flex justify-content-end gap-2">
              <Button className="px-4" type="submit">
                {t("pages_create_save")}
              </Button>
              <Link className="btn btn-danger px-4" to="/pages/manage" type="submit">
                {t("pages_create_cancel")}
              </Link>
            </div>
          </Form>
        </section>
      </Layout>
  );
}