import React from 'react';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import {Button} from "react-bootstrap";
import imageData from '../../images/UZEY.png';

const GenericPdfDownloader = ({ content, downloadFileName }) => {
  const downloadPdfDocument = () => {
    let doc = new jsPDF();
    let elementHTML = document.getElementById(content);

    doc.html(elementHTML, {
      callback: function(doc) {
        // Save the PDF
        doc.save(downloadFileName+'.pdf');
      },
      margin: [15,15,15,15],
      width: 180, //target width in the PDF document
      windowWidth: 720, //window width in CSS pixels
      autoPaging: 'text',
      html2canvas: {
        letterRendering: true,
        dpi: 300,
        logging: false
      }
    });
  };

  return <Button onClick={downloadPdfDocument} className="mr-2">Download PDF</Button>;
};

export default GenericPdfDownloader;