import {useCallback, useEffect, useReducer, useState} from 'react'
import {getPaginatedTestimonial, removeTestimonial} from "../testimonial.service";
import {usePagination} from "../../../hooks";
import filterReducers from "../../../reducers/filterReducer";

export function useTestimonialList() {
  const [testimonialList, setTestimonialList] = useState({
    testimonials: [],
    total: 0,
  })
  const {pagination, changePagination} = usePagination({
    page: 1,
    limit: 10,
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [filter, filterDispatch] = useReducer(filterReducers, {});
  const [error, setError] = useState(false)
  const searchByTerm = async (value) => {
    setSearchTerm(value)
  }
  const fetch = useCallback(async () => {
    try {
      setIsLoading(true)
      const testimonials = await getPaginatedTestimonial({
        page: pagination.page,
        ...filter,
      })
      setTestimonialList({
        testimonials: testimonials.data,
        total: testimonials.total,
      })
      setIsLoading(false)
    } catch (err) {
      setError({
        title: 'Something went wrong',
        message: err.message,
      })
    }
  }, [pagination, filter])

  const deleteTestimonial = async (id) => {
    await removeTestimonial(id)
    await fetch()
  }

  useEffect(() => {
    fetch()
  }, [fetch])

  return {
    testimonialList,
    pagination,
    changePagination,
    deleteTestimonial,
    searchByTerm,
    isLoading,
    error,
    filter,
    filterDispatch
  }
}