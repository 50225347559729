import { combineReducers } from "redux";
import SubmanuStateReducer from "./Submenu/subReducer";


import UpdatedUserReducer from "./updateUserDashboard/listReducer";
import UserReducer from "./user/listReducer";
import LanguageReducer from "./language/listReducer";

 const rootReducer = combineReducers({
    User : UserReducer,
    UpdatedUser: UpdatedUserReducer,
    SubmanuState: SubmanuStateReducer,
    Language: LanguageReducer,

})

export default rootReducer;
