import {Button, Modal} from "rsuite";
import {ReactFormGenerator} from "react-form-builder2";
import {useLang} from "../../../hooks";

export const FromPreview = ({open,onClose,data}) => {
  const {t} = useLang();
  return (
      <Modal open={open} onClose={onClose}>
        <Modal.Body>
          <ReactFormGenerator
            download_path=""
            back_action="/"
            back_name="Back"
            answer_data={{}}
            action_name="Save"
            form_action="/"
            form_method="POST"
            read_only={true}
            variables={[]}
            hide_actions={true}
            data={data}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} appearance="subtle">
            {t("cancel_btn")}
          </Button>
        </Modal.Footer>
      </Modal>
  )
}