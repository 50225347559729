import {useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {createLanguage} from "../../services/language";
import {useLanguages} from "../../hooks/useLanguages";
import {LanguageSelector} from "../../components/Language/LanguageSelector";
import {useLang} from "../../hooks";
import {ProductLanguageInputSchema} from "./schema";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {useProduct} from "../../hooks/useProduct";
import {getUrlFromStoragePath} from "../../configs";
import {useMediaSelector} from "../../components/MediaSelectorModal";
import MediaCard from "../MediaManagement/components/MediaCard";
import MediaModal from "../../components/MediaSelectorModal/MediaModal";
import MediaCardList from "../../components/MediaSelectorModal/MediaCardList";

export function ProductLanguageCreatePage() {
  const {resourceId} = useParams();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {t} = useLang()

  const {product, isLoading: isContentLoading, error} = useProduct(resourceId)

  const {langs, isLoading: isLangLoading, error: isLangError} = useLanguages();

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(ProductLanguageInputSchema(t)),
  });

  const {
    checkValue,
    setCheckValue,
    selectedMedia,
    setSelectedMedia,
    allMediaId,
    setAllMediaId,
    handleRemove,
  } = useMediaSelector();

  if (error || isLangError) {
    notifyError({
      title: "Something went wrong!",
      message: error.message
    })
    navigate(-1)
  }

  useEffect(() => {
    if (product) {
      setValue("name", product.name ?? "");
      setValue("description", product.description ?? "");
    }
  }, [product]);

  const create = async (data) => {
    try {
      setIsLoading(true);
      data.product_id = resourceId
      const productLangData = {...data, media_id: checkValue}
      const l = await createLanguage("product", productLangData)
      notifySuccess({
        title: "language created successfully!",
        message: "Language Created",
      });
      navigate(-1);
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Failed to create language",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: "Language creation failed!",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      {(isLoading || isContentLoading || isLangLoading) && <CircleLoader/>}
      <section id="#product-lang-create">
        <header>
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to={`/products/manage`}>Contents</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/langs/product/${resourceId}`}>{product?.title ?? resourceId} Languages</Link>
            </li>
            <Breadcrumb.Item active>create language</Breadcrumb.Item>
          </Breadcrumb>
          <h3>Create Language ({product?.name ?? resourceId})</h3>
        </header>
        <Form
          onSubmit={handleSubmit(create)}
          className="mt-4 d-flex  flex-column gap-3"
        >

          <LanguageSelector loading={isLangLoading} control={control} langs={langs} errors={errors}/>
          <div className="form-row">
            <Form.Group as={Col} md={12}>
              <Form.Label className="mark-required">{t("image")}</Form.Label>
              <Controller
                control={control}
                name="image"
                render={({field}) => (
                  <div className="overflow-auto">
                  <MediaModal selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                              checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}>
                    {
                      selectedMedia?.length > 0 ? (
                        <MediaCardList selectedMedia={selectedMedia} isDelete handleRemove={handleRemove}/>
                      ): (
                        <div style={{height: "300px", width: "100%"}}>
                          <ImageInput
                            errorMessage={errors.image?.message}
                            onChange={field.onChange}
                            onError={() => {
                              setError("image", {message: "Invalid image file!"});
                            }}
                          />
                        </div>
                      )
                    }
                  </MediaModal>
                  </div>
                )}
              />
            </Form.Group>
            <Col>
              <Form.Group className="flex-grow-1" controlId="name">
                <Form.Label className="mark-required">{t("products_create_name")}</Form.Label>
                <Form.Control {...register("name")} placeholder={t("products_create_name_plhd")} type="text"/>
                {errors.name?.message && (
                  <Form.Text className="text-danger">{errors.name.message}</Form.Text>)}
              </Form.Group>

              <Form.Group className="flex-grow-1 mt-2" controlId="description">
                <Form.Label className="mark-required">{t("products_create_description")}</Form.Label>
                <Form.Control {...register("description")} placeholder={t("products_create_description_plhd")}
                              as="textarea" rows={8}/>
                {errors.description?.message && (
                  <Form.Text className="text-danger">{errors.description.message}</Form.Text>)}
              </Form.Group>
            </Col>
          </div>
          <div className="d-flex justify-content-end">
            <Button className="px-4" type="submit">
              Create
            </Button>
          </div>
        </Form>
      </section>
    </Layout>
  );
}
